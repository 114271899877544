import React, { useEffect, useState } from "react";
import { Box, Typography, Radio, RadioGroup, FormControlLabel, Dialog, useMediaQuery } from "@mui/material";
import SocialMediaCSS from "../../../SocialMediaCSS/SocialMedia.module.css";
import cancel from "../../../../../assets/icons/Cancel.svg"
import { useDispatch } from "react-redux";
import { resetDraftAndActivity, resetPostTags, resetSelectedPostAudience, setCreatePost, setPostAudience, setPostAudienceEdit, setSelectedActivity, setSelectedPostAudience, setSelectedThematic, setUploadedFiles } from "../../../../../store/slices/SocialMediaSlice";
import arrow from "../../../../../assets/icons/arrow-back.svg"
import CommonButton2 from "../../../../../common/components/CommonButton2";
import { reset } from "redux-form";
import theme from "../../../../../assets/material-ui/theme";
import DiscardAlert from "../DiscardAlert";


const ReshareAudience = ({ socialMedia, form, selectedPostTitle, setSelectedPostTitle, onPostSelect, master, open, onClose, post,setSelectedTag }) => {
    const dispatch = useDispatch();
    const [postDiscardOpen, setPostDiscardOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(selectedPostTitle || socialMedia.selectedPostAudience);
    const postTypeKey = post?.primary_post?.post_type_name;
    const postType = master.postTypes.find(type => type.key === postTypeKey);
    const postTypeValue = postType?.value;

    const handleDiscardDialogClose = () => {
        setPostDiscardOpen(false);
    };
    const handleDiscardDialogOpen = () => {
        setPostDiscardOpen(true);
    };

    const handleCreateCancel = () => {
        dispatch(setPostAudienceEdit(false))
        setSelectedPostTitle(postTypeValue)
        setSelectedTag('')
        dispatch(resetPostTags());
        dispatch(resetDraftAndActivity());
        onClose();
    };
    const handleBackClick = () => {
        dispatch(setPostAudienceEdit(false))
        setSelectedPostTitle(postTypeValue)
    }
    const handleDoneClick = () => {
        dispatch(setPostAudienceEdit(false))
    }
    const handleCardClick = (title) => {
        console.log(`Clicked on: ${title}`);
        onPostSelect(title);
        setSelectedPostTitle(title)
        // dispatch(setSelectedPostAudience(title));
        // dispatch(setPostAudience(false))
    }
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleDlgClose}
                aria-labelledby="Edit Post"
                PaperProps={{ classes: { root: SocialMediaCSS.shareToPopup } }}
            >
                <Box className={SocialMediaCSS.postCard}>
                    <Box className={SocialMediaCSS.activityHeader}>
                        <Box className={`${SocialMediaCSS.postGap}`}>
                            <img src={arrow} onClick={handleBackClick} />
                            <Typography className={SocialMediaCSS.selectActivity} >
                                Post Audience
                            </Typography>
                        </Box>
                        <Box className={SocialMediaCSS.cancelIcon} onClick={() => handleDiscardDialogOpen()}>
                            <img src={cancel} className={SocialMediaCSS.createPost} />
                        </Box>
                    </Box>
                    <Box>
                        <Typography className={SocialMediaCSS.audiencePost}>Who can view your post?</Typography>
                        <Typography className={SocialMediaCSS.audienceText}>Your default audience is set to connections, but you can change the audience of this specific post.</Typography>
                    </Box>
                    <Typography className={SocialMediaCSS.ChooseAudi}>Choose audience</Typography>
                    <Box className={SocialMediaCSS.scrollable}>
                        <RadioGroup value={selectedValue} onChange={(event) => {
                            setSelectedValue(event.target.value);
                            handleCardClick(event.target.value);
                        }}>
                            {master?.postTypes?.map((data, index) => (
                                <Box key={index} mb={index !== master?.postTypes.length - 1 ? 0 : 0}>
                                    <FormControlLabel
                                        value={data?.value}
                                        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20, padding: '0px' } }} />}
                                        label={
                                            <Box className={SocialMediaCSS.audiCard} onClick={() => handleCardClick(data?.value)}>
                                                <img src={data?.image} className={SocialMediaCSS.postAudienceIMG} />
                                                <Box>
                                                    <Typography className={SocialMediaCSS.autiTitle}>{data?.value}</Typography>
                                                    <Typography className={SocialMediaCSS.audiDescrip}>{data?.title}</Typography>
                                                </Box>
                                            </Box>
                                        }
                                    />
                                </Box>
                            ))}
                        </RadioGroup></Box>

                    <Box className={`${SocialMediaCSS.nextPost} ${SocialMediaCSS.margin}`} >
                        <CommonButton2
                            label="Done"
                            name="Done"
                            variant="contained"
                            type="button"
                            onClick={handleDoneClick}
                            style={{
                                padding: '6px 24px',
                                fontSize: '14px',
                                height: '32px',
                                fontWeight: '600',
                                background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%',
                                boxShadow: 'none'
                            }}
                        />
                    </Box>
                </Box>
            </Dialog>
            {postDiscardOpen &&
                <DiscardAlert
                    open={postDiscardOpen}
                    onClose={handleDiscardDialogClose}
                    handleCancel={handleCreateCancel}
                    title="Discard draft"
                    Content1="Your post isn't finished yet. Are you sure you want to exit and discard your draft?"
                    ButtonText2="Discard"
                    ButtonText1="Continue"
                />}
        </>
    )
}

export default ReshareAudience;
