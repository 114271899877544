import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Box, MenuItem } from '@mui/material';
import theme from '../../assets/material-ui/theme';
import ScheduleIcon from '@mui/icons-material/Schedule';

export const SelectField = ({
  form,
  input,
  label,
  children,
  placeholder,
  meta,
  disabled,
  customHeight,
  placeholderFontSize,
  style,
  radius,
  width,
  square,
  PaperPropsStyle,
  clockIcon
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: width || 200,
        ...PaperPropsStyle,
      },
    },
  };
  const id = `${form}_${input.name.replace('.', '/_/g')}`;
  return (
    <FormControl fullWidth className="common-select">
      <InputLabel htmlFor={id} sx={{ fontSize: placeholderFontSize }}>{label}</InputLabel>
      <Select
        // native
        value={input.value}
        disabled={disabled}
        displayEmpty
        name={input.name}
        inputProps={{
          name: input.name,
          id,
          ...input,

        }}
        IconComponent={clockIcon && ScheduleIcon}
        MenuProps={MenuProps}
        sx={{
          // border: 1.5,
          borderRadius: radius ? radius : 40,
          // height: 50,
          height: customHeight ? customHeight : 50,
          fontSize: placeholderFontSize,
          backgroundColor:"white",
          color: "#666",
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: square ? theme.palette.primary.dark : theme.palette.primary.main,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            transition: square ? 'border-color 0.3s ease' : "",
            borderColor: square ? theme.palette.primary.dark : theme.palette.success.dark,
          },
          '& .MuiSelect-select': {
            color: input.value && square? '#000000DE' : "#666",
          },
          ...style,
        }}
      >
        {children}
      </Select>
      {meta.touched &&
        (meta.error && (
          <Box className='error'>
            {meta.error}
          </Box>
        ))}
    </FormControl>
  );
};

SelectField.propTypes = {
  form: PropTypes.string,
  input: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  placeholderFontSize: PropTypes.string,

};
