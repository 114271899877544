import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "success",
  flag: false,
  message: "test message",
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      assignValueInState(action.payload, state);
    },
    resetSnackbar: (state) => {
      assignValueInState(initialState, state);
    },
  },
});

const assignValueInState = (obj, state) => {
  for (const key in obj) {
    state[key] = obj[key];
  }
};

export const { setSnackbar, resetSnackbar } = messageSlice.actions;
export default messageSlice.reducer;