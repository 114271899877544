import React from 'react';
import { makeStyles } from '@mui/styles';
import { Popover, List, ListItem, ListItemText, Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setCategory } from '../../../../store/slices/SocialMediaSlice';


const useStyles = makeStyles((theme) => ({
    popover: {
        boxShadow: '0px 0px 25px 0px #AAAAAA40',
    },
}));

const CategoryHeader = ({ options, anchorEl, open, onClose, checkedItems, setCheckedItems, anchorPosition }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleCheckboxChange = (option) => {
        const updatedCheckedItems = {
            ...checkedItems,
            [option]: !checkedItems[option],
        };
        setCheckedItems(updatedCheckedItems);
        dispatch(setCategory(Object.keys(updatedCheckedItems).filter(key => updatedCheckedItems[key])));
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            PaperProps={{
                style: {
                    width: '280px', height: '368px', scrollbarWidth: 'none', boxShadow: '0px 2px 4px -1px #00000012, 0px 4px 6px -1px #0000001F', border: '1px solid rgb(153 153 153/50%)', borderRadius: '6px',
                }
            }}
            style={{
                ...anchorPosition,
                top: '90px',
                right: '0px',
            }}
            transitionDuration={0}
        >
            <List className={classes.popover}>
                {options.map((option) => (
                    <ListItem key={option?.id} sx={{ gap: '10px', pb: 0 }}>
                        <Checkbox
                            checked={checkedItems[option?.id] || false}
                            onChange={() => handleCheckboxChange(option?.id)}
                            sx={{ padding: 0, '& .MuiSvgIcon-root': { width: '16px', height: '16px' } }}
                        />
                        <ListItemText primary={option?.key} sx={{ '& .MuiTypography-root': { fontSize: '14px', lineHeight: '21px' } }} />
                    </ListItem>
                ))}
            </List>
        </Popover>
    );
};

export default CategoryHeader;

