import React, { useRef, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import MainHeader from '../../../common/components/MainHeader';
import SocialMediaCSS from "../SocialMediaCSS/SocialMedia.module.css";
import LeftSidebar from "./LeftSidebar/LeftSidebar";
import Post from "./Posts/Post";
import RightSidebar from "./RightSidebar/RightSidebar";
import CustomSnackbar from "../../../common/components/CommonSnackbar";
import { useSelector } from "react-redux";

const SocialMediaDashboard = () => {
    const snackbarProps = useSelector((state) => state.message);
    const { socialMedia: { isPreviewOpen } } = useSelector((state => state));
    const [previewOpen, setPreviewOpen] = useState(false);
    const containerRef = useRef(null);
    return (
        <>
            <Box className={SocialMediaCSS.MainHeader} sx={{ zIndex: isPreviewOpen ? 0 : 99 }}>
                <MainHeader />
            </Box>
            {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Grid container className={SocialMediaCSS.bgGrey100}  >
                <Grid item xs={12} className={SocialMediaCSS.mainContent} sx={{ overflow: 'auto' }} ref={containerRef}>
                    <Container >
                        <Grid container spacing={3}  >
                            <Grid item xs={12} md={3} lg={3}>
                                <LeftSidebar />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} >
                                <Post setPreviewOpen={setPreviewOpen} containerRef={containerRef} />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <RightSidebar />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </>
    );
}

export default SocialMediaDashboard;
