import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import logger from 'redux-logger'
import userSlice from "./slices/UserSlice";
import { reducer as formReducer } from 'redux-form';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist';
import ReduxThunk from 'redux-thunk';
//import persistCombineReducers from 'redux-persist/es/persistCombineReducers';
import storage from 'redux-persist/lib/storage';
import messageSlice from "./slices/MessageSlice";
import profileSlice from "./slices/ProfileSlice";
import proposalSlice from "./slices/ProposalSlice";
import rfpSlice from "./slices/RfpSlice";
import masterSlice from "./slices/MasterSlice";
import eventSlice from "./slices/EventSlice";
import userManagementSlice from "./slices/UserManagementSlice";
import SocialMediaSlice from "./slices/SocialMediaSlice";
import bEngageSlice from "./slices/bEngageSlice";

const persistConfig = { key: 'root', version: 1, storage }


const persistedReducer = persistReducer(persistConfig, userSlice);
const persistedReducer2 = persistReducer(persistConfig, masterSlice);

const store = configureStore({
    reducer: {
        // reducer: persistedReducer,
        users: persistedReducer,
        message: messageSlice,
        profile: profileSlice,
        proposal: proposalSlice,
        rfp: rfpSlice,
        master: masterSlice,
        events: eventSlice,
        userManagement: userManagementSlice,
        socialMedia: SocialMediaSlice,
        bEngage: bEngageSlice,
        form: formReducer,
        middleware: [ReduxThunk, logger, (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),],
    },
    //  middleware: 
})

export default store;