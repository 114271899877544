import { Box, IconButton, Typography, Popover, Tooltip, tooltipClasses, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';

import BookmarkAddIcon from '../../../../../assets/icons/bookmark_add_lightGrey.svg';
import BookmarkRemoveIcon from '../../../../../assets/icons/bookmark_remove_lightGrey.svg';
import { ShareOtherPopup } from '../../Popup/ShareOtherPopup';
import ReportDrawer from '../../Drawer/ReportDrawer';
import { timeAgo } from '../../../../../common/utils';
import { Follow, allowNotification, deleteConfBookmark, deletePost, postBookMark, postRemoveBookMark, removeNotification, unfollow } from '../../../actions';
import { useDispatch } from 'react-redux';
import PostEditProcess from "../../../../SocialMedia/Components/Posts/PostEditPopup/PostEditProcess";
import { useNavigate } from 'react-router';
import { setBookmark } from '../../../../BEngage/actions';
import CommonInitials from '../../../../../common/components/CommonInitials';
import PostMoreItem from '../PostMoreItem';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.mainPalette.grey.white,
        color: theme.mainPalette.grey.lightText,
        boxShadow: theme.shadow.softShadow3,
        padding: '8px'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.mainPalette.grey.white,
    },
}));

const ConferenceBookmarkHeader = ({ post, users, master, socialMedia }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formattedTime, setFormattedTime] = useState(timeAgo(post?.created_at));
    const [anchorEl, setAnchorEl] = useState(null);
    const [shareToDialogOpen, setShareToDialogOpen] = useState(false);
    const [editPostOpen, setEditPostOpen] = useState(false);
    const [reportDraweropen, setReportDraweropen] = useState(false);
    const [drawerGid, setDrawerGid] = useState(false);


    const is_Share = post?.shared_module?.name === null ? false : true;

    const handleReportDrawerOpen = (gid) => {
        setReportDraweropen(true);
        setDrawerGid(gid);
        handlePopoverClose();
    };

    const handleReportDrawerClose = () => {
        setReportDraweropen(false);
    };

    const handlePopoverOpen = (event) => {
        if (users?.isLoggedIn) {
            setAnchorEl(event.currentTarget);
        } else {
            navigate('/login');
        }
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleShareToDialogOpen = () => {
        console.log("opening");
        setShareToDialogOpen(true);
    };

    const handleShareToDialogClose = () => {
        setShareToDialogOpen(false);
    };

    const handleDeleteClick = (gid) => {
        dispatch(deletePost(gid))
    };

    const handleFollowClick = (gid) => {
        dispatch(Follow(gid))
    };

    const handleUnFollowClick = (gid) => {
        dispatch(unfollow(gid))
    };

    const handleBookMarkClick = (gid) => {
        dispatch(postBookMark(gid));
    };

    const handleRemoveBookMarkClick = (gid) => {
        dispatch(postRemoveBookMark(gid))
    };
    const handleConferenceBookMarkClick = (gid) => {
        dispatch(setBookmark(gid));
    };

    const handleRemoveConferenceBookMarkClick = (gid) => {
        dispatch(deleteConfBookmark(gid));
    };
    const handleAllowNotificationClick = (gid) => {
        dispatch(allowNotification(gid))
    };

    const handleRemoveNotificationClick = (gid) => {
        dispatch(removeNotification(gid))
    };
    const handleEditDialogOpen = () => {
        // setPostEditActivity()
        // setPostEditBody(body)
        // setPostEditAudience()
        // setPostEditThematic()
        setEditPostOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditPostOpen(false);
    };
    const open = Boolean(anchorEl);
    const postMorePopover = open ? 'simple-popover' : undefined;
    const post_gid = post?.event_user_details?.user_gid;
    const user_detail = users?.userData?.gid;
    const post_Owner = post_gid === user_detail ? true : false;

    const postTypeName = post?.primary_post?.post_type_name;
    const postTypeObj = master?.postTypes?.find(type => type.key === postTypeName);
    const postTypeImage = postTypeObj ? postTypeObj.image : '';

    const postValueObj = master?.postTypes?.find(type => type.key === postTypeName);
    const postTypeTitle = postValueObj ? postValueObj.value : '';

    const postActivityName = post?.primary_post?.activity?.key;
    const postActivityObj = master?.postActivity?.find(type => type.key === postActivityName);
    const postActivityImage = postActivityObj ? postActivityObj.image : '';

    const postActivityNameObj = master?.postActivity?.find(type => type.key === postActivityName);
    const postActivityTitle = postActivityNameObj ? postActivityNameObj.title : '';

    const handleUserProfile = (gid) => {
        if (users?.isLoggedIn) {
            navigate(`/profile/${post?.event_user_details?.user_gid}`);
        } else {
            navigate('/login');
        }
    }
    const postReshare =
        !post?.shared_module?.event_gid &&
        !post?.shared_module?.conferenec_gid &&
        !post?.shared_module?.rfp_gid;
    return (
        <>
            <Box>
                <Box className={SocialMediaCSS.postHeader} >
                    <Box onClick={handleUserProfile} className={socialMedia?.cursor}>
                        <CommonInitials
                            name={post?.conference_user_details?.initials}
                            bgColor={post?.conference_user_details?.colour_code}
                            divStyle={{ padding: '0px', maxHeight: '130px', maxWidth: '130px', minHeight: '34px', minWidth: '34px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                            typoStyle={{ fontSize: '14px', lineHeight: '32px', }}
                            avatarStyle={{ width: '34px', height: '34px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                            src={post?.conference_user_details?.image}
                            companyLogo={post?.conference_user_details?.image}
                            avatar={true}
                        />
                    </Box>
                    <Box className={SocialMediaCSS.postHeaderInfo}>
                        <Typography className={SocialMediaCSS.postReshareHeader} >
                            {post?.conference_user_details?.pname}
                        </Typography>
                        <Typography className={SocialMediaCSS.postReshareTagline} >
                            {post?.conference_user_details?.tagline}
                        </Typography>
                    </Box>

                    <IconButton aria-describedby={postMorePopover} onClick={handlePopoverOpen}>
                        <MoreHorizIcon />
                    </IconButton>

                </Box>
            </Box >
            <Divider />
            <Popover
                id={postMorePopover}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={SocialMediaCSS.popoverPostMore}
            >
                <Box className={SocialMediaCSS.postMoreOptionBox} onClick={handlePopoverClose}>
                    {post?.bookrmark?.is_bookrmark !== true &&
                        <Box onClick={() => handleConferenceBookMarkClick(post.gid)}>
                            <PostMoreItem icon={BookmarkAddIcon} iconAlt='Add Bookmark' title='Bookmark Post' desc='Add post to your bookmarks' />
                        </Box>}
                    {post?.bookrmark?.is_bookrmark === true &&
                        <Box onClick={() => handleRemoveConferenceBookMarkClick(post?.bookrmark?.gid)}>
                            <PostMoreItem icon={BookmarkRemoveIcon} iconAlt='Remove Bookmark' title='Remove Bookmark' desc='Remove post from bookmarks' />
                        </Box>
                    }
                </Box>
            </Popover>
            <ShareOtherPopup
                open={shareToDialogOpen}
                onClose={handleShareToDialogClose}
                Title="Share to"
                post={post}
            />
            <PostEditProcess
                open={editPostOpen}
                onClose={handleEditDialogClose}
                usersImg={users}
                master={master}
                SocialMediaCSS={SocialMediaCSS}
                socialMedia={socialMedia}
                post={post}
            />
            <ReportDrawer open={reportDraweropen} gid={drawerGid} onClose={handleReportDrawerClose} socialMedia={socialMedia} master={master} title="Report Post" desc="Report an abusive or inappropriate post." />
        </>
    )
}

export default ConferenceBookmarkHeader;
