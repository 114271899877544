import React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';

export const RenderText = ({
  input,
  label,
  placeholder,
  type,
  fullWidth,
  maxLength,
  underlineStyle,
  errorStyle,
  style,
  toolTiptext,
  id,
  pattern,
  defaultValue,
  disabled,
  min,
  max,
  value,
  onKeyPress,
  meta: { touched, error, warning },
  inputProps,
  errorMessages,
  custom,
  borderBottom,
}) => {
  let inputType = type;
  errorStyle = {
    fontSize: '10px',
    color: 'rgb(244, 67, 54)',
    position: 'static',
  };
  return (
    <>
      <TextField
        inputProps={{
          maxLength,
          disabled,
          min: 0,
        }}
        className="TextField"
        // sx={(theme) => ({
        // //   height: 50,
        // //   borderRadius: 40,
        // // borderBottom:1.5,
        //   backgroundColor: theme.palette.secondary.dark,
        // })}
        sx={{
            "& .MuiInputLabel-root": { color: "green" },
            borderBottom: borderBottom||"1.5px solid #118AC2",
            // borderRadius: 1
          }}
          InputProps={{ disableUnderline: true }}
        label={label} // Add label prop for standard TextField
        placeholder={placeholder}
        // data-tootip={toolTiptext}
        // data-for="globalTooltip"
        // errorStyle={errorStyle}
        // underlineStyle={underlineStyle}
        id={id}
        disabled={disabled}
        autoComplete="off"
        pattern={pattern}
        type={inputType}
        onKeyPress={onKeyPress}
        fullWidth={fullWidth}
        value={defaultValue}
        variant="standard"
        min={min}
        max={max}
        style={style}
        {...input}
        {...custom}
      />
      {touched && error && (
        <Box className='error'>
          {error}
        </Box>
      )}
    </>
  );
};
