import React from 'react';
import TextField from '@mui/material/TextField';
import theme from '../../assets/material-ui/theme';
import { Box, InputAdornment, OutlinedInput, Typography } from '@mui/material';

export const RenderMultipleEmails = ({
  input,
  label,
  placeholder,
  type,
  fullWidth,
  maxLength,
  underlineStyle,
  errorStyle,
  style,
  toolTiptext,
  id,
  pattern,
  defaultValue,
  disabled,
  min,
  max,
  value,
  onKeyPress,
  meta: { touched, error, warning },
  inputProps,
  errorMessages,
  custom,
}) => {
  let inputType = type;

  // Updated logic for multiple emails
  const handleChange = (e) => {
    if (type === 'multipleEmails') {
      // Split emails by commas and trim whitespace
      const emails = e.target.value.split(',').map(email => email.trim());
      // Update the input value with formatted emails
      input.onChange(emails.join(', '));
    } else {
      input.onChange(e.target.value);
    }
  };

  errorStyle = {
    fontSize: '10px',
    color: 'rgb(244, 67, 54)',
    position: 'static',
  };

  return (
    <>
      <OutlinedInput
        inputProps={{
          maxLength,
          disabled,
          min: 0
        }}
        className="TextField"
        sx={(theme) => {
          return {
            height: 50,
            borderRadius: 40,
            backgroundColor: theme.palette.secondary.dark,
          }
        }}
        placeholder={placeholder}
        id={id}
        disabled={disabled}
        autoComplete="off"
        pattern={pattern}
        type={inputType}
        onKeyPress={onKeyPress}
        fullWidth={fullWidth}
        value={defaultValue}
        min={min}
        max={max}
        style={style}
        onChange={handleChange}  
        startAdornment={
            <InputAdornment position="start" >
                <Typography sx={{ fontSize: '16px', color: 'var(--gray-800-dark-text, #333)', fontWeight: '600' }}>Send To |</Typography>
            </InputAdornment>
        }
        {...input}
        {...custom}
      />
      {touched &&
        (error && (
          <Box className='error'>
            {error}
          </Box>
        ))}
    </>
  );
};
