import { Box, Dialog, DialogTitle, Fade, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react'
import NgoLogo from '../../assets/images/Non-profit logo.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} style={{ transitionDelay: ref ? '300ms' : '0ms' }} />;
});
const SelectOrganizationDialog = ({ open, onClose, CommonCss, title, handleCompanyTypeClick, companyTypes }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleHover = (id) => {
        setIsHovered(id);
    }
    const handleHoverLeave = () => {
        setIsHovered(null);
    }
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted PaperProps={{ sx: { minWidth: isXsScreen ? '900px' : '300px', borderRadius: '16px', padding: '8px 16px' } }}>
            <Grid container spacing={2} className={CommonCss.orgTypeRoot}>
                <Grid item xs={12} className={CommonCss.orgTypeHeading}>
                    <Typography className={CommonCss.orgTypeTitle}>{title}</Typography>
                    <Typography className={CommonCss.enableTrailActionTypo} onClick={onClose}>Close</Typography>
                </Grid>
                <Grid item xs={12} className={CommonCss.orgTypeContent}>
                    {companyTypes?.map((type) =>
                        <Grid item xs={5.8} className={CommonCss.orgTypeContentGrid} key={type.key} sx={{ background: isHovered === type.key ? '#06BB70' : '#FFF' }}
                            onMouseEnter={() => handleHover(type.key)}
                            onMouseLeave={handleHoverLeave}
                            onClick={() => handleCompanyTypeClick(type.key)}
                        >
                            <Box className={CommonCss.orgTypeContentImgBox} sx={{ background: isHovered === type.key ? '#FFF' : 'transparent', borderRadius: isHovered === type.key ? '50%' : 0 }}>
                                <img src={type?.image ? type?.image : NgoLogo} alt='ngoLogo' style={{ width: isHovered === type.key ? '32px' : '42px', height: isHovered === type.key ? '40px' : '42px', borderRadius: isHovered === type.key ? '50%' : '0%' }} />
                            </Box>
                            <Typography className={CommonCss.orgTypeContentTypo} sx={{ color: isHovered === type.key ? '#FFFFFF' : '#333' }}>
                                {type.value}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Dialog >
    )
}

export default SelectOrganizationDialog