import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import cancel from "../../../../assets/icons/Cancel.svg"
import { useDispatch } from "react-redux";
import { resetPostTags, setCreatePost, setDraftPost, setSelectedActivity, setSelectedThematic, setShowActivity, setUploadedFiles } from "../../../../store/slices/SocialMediaSlice";
import arrow from "../../../../assets/icons/arrow-back.svg"
import DiscardPost from "../Popup/DiscardPost";
import { reset } from "redux-form";
import { deletePost } from "../../actions";


const PostActivity = ({ socialMedia, form, onActivitySelect, master }) => {
    const dispatch = useDispatch();
    const [postDiscardOpen, setPostDiscardOpen] = useState(false);
    // const handleCreateCancel = () => {
    //     dispatch(setCreatePost(false))
    // };\
    const draft = socialMedia?.draftPost
    const handleBackClick = () => {
        dispatch(setShowActivity(false))
    }
    const handleProfileDialogOpen = () => {
        setPostDiscardOpen(true);
    };
    const handleCreateCancel = () => {
        dispatch(setShowActivity(false))
        dispatch(setCreatePost(false))
        dispatch(resetPostTags())
        dispatch(reset('CreatePostform'))
        dispatch(setUploadedFiles([]));
        dispatch(setSelectedActivity(""));
        dispatch(setSelectedThematic([]));
        handleProfileDialogClose();
        dispatch(setDraftPost([]));
        if (draft.gid) {
            dispatch(deletePost(draft.gid));
        }
    };
    const handleProfileDialogClose = () => {
        setPostDiscardOpen(false);
    };
    const handleCardClick = (title) => {
        console.log(`Clicked on: ${title}`);
        onActivitySelect(title);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        //dispatch(setSelectedActivity(title));
        dispatch(setShowActivity(false))
    }

    return (
        <>
            <Box className={SocialMediaCSS.postCard}>
                <Box className={SocialMediaCSS.activityHeader}>
                    <Box className={`${SocialMediaCSS.postGap}`}>
                        <img src={arrow} onClick={handleBackClick} />
                        <Typography className={SocialMediaCSS.selectActivity} >
                            Select Activity
                        </Typography>
                    </Box>
                    <Box className={SocialMediaCSS.cancelIcon} onClick={() => handleProfileDialogOpen()}>
                        <img src={cancel} className={SocialMediaCSS.createPost} />
                    </Box>
                </Box>
                <Box className={SocialMediaCSS.cardAlign}>
                    {master?.postActivity?.map((data, index) => (
                        <Box className={SocialMediaCSS.activityCard} key={index} onClick={() => handleCardClick(data?.title, data?.id)}>
                            <img src={data?.image} className={SocialMediaCSS.imgActivity} />
                            <Typography className={SocialMediaCSS.postTypo}>{data?.title}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            {postDiscardOpen &&
                <DiscardPost
                    open={postDiscardOpen}
                    onClose={handleProfileDialogClose}
                    ButtonText1="Save as Draft"
                    ButtonText2="Discard"
                    title="Save as Draft"
                    Content1="Do you want to save this post as Draft?"
                    Content2="The content of this post will be here whenever you come back."
                    handleDiscard={handleCreateCancel}
                />}
        </>
    )
}

export default PostActivity