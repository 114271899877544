import React, { useEffect, useState } from "react";
import { Box, Divider, Drawer, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { decodeHTMLEntities, decodeMultipleEncodedHTMLEntities } from "../../../../../common/utils";
import { useLocation, useNavigate, useParams } from "react-router";
import Report from "../../../../../assets/icons/repo.svg"
import ReportDrawer from "../../../../SocialMedia/Components/Drawer/ReportDrawer";
import { ShareRfp } from "../../../../SocialMedia/Components/Rfps/ShareRpf";
import CommonButton2 from "../../../../../common/components/CommonButton2";
import { getRFPSave, getSocialRfpPreview } from "../../../../SocialMedia/actions";
import ApplyRFP from "../../../../SocialMedia/Components/Rfps/ListingSubComponents/AppyRFP";
import BusinessSuite from "../../../../../common/components/BusinessSuite";
import { getBusinessSuite, getModuleType } from "../../../../../common/actions";
import { showRfpPreview } from "../../../actions";
import { setDashboardTab } from "../../../../../store/slices/RfpSlice";



const DetailPreview = ({ RFPCSS, dispatch, rfpDetailsFormData, rfpStatus, rfpGid, responded_at, self_Data }) => {
    const location = useLocation();
    const navigate = useNavigate();
    // const { rfp: { rfpDetailsFormData } } = useSelector((state) => { return state; });
    const { rfp, master, socialMedia, form, users } = useSelector((state) => { return state; });
    const isSocialRFp = location.pathname.startsWith(`/social-media/rfp`);
    const [reportDraweropen, setReportDraweropen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [drawerGid, setDrawerGid] = useState(false);
    const { gid } = useParams();

    const handleShareDialogOpen = () => {
        setShareDialogOpen(true);
        setDrawerGid(gid);
    };
    const handleShareDialogClose = () => {
        setShareDialogOpen(false);
    };
    //console.log("tree",self_Data)
    const handleReportDrawerOpen = () => {
        setReportDraweropen(true);
        setDrawerGid(gid);
        // handlePopoverClose();
    };

    const handleReportDrawerClose = () => {
        setReportDraweropen(false);
    };
    const handleSaveClick = () => {
        dispatch(getRFPSave(gid))
        if (gid) {
            // dispatch(showRfpPreview(gid));
            dispatch(getSocialRfpPreview(gid))
        }
    };

    const handleApplyClick = () => {
        setConfirmationDialogOpen(true);
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleGoToRFP = (rfpGid) => {
        let inbox = false;
        let sent = false;

        if (self_Data) {
            sent = true;
            dispatch(setDashboardTab("sent"))
        }

        if (responded_at) {
            inbox = true;
        }

        if (!self_Data && !responded_at) {
            inbox = true;
        }
        navigate(`/rfp/preview/${rfpGid}`, {
            state: { inbox, sent },
        });
    };

    const [isBussiness, setIsBussiness] = React.useState(false);
    const handleDrawerOpen = () => { setIsBussiness(true); dispatch(getModuleType()); dispatch(getBusinessSuite(users?.userData?.gid)); }
    const handleDrawerClose = () => { setIsBussiness(false); }
    return (
        <>
            <Grid container rowSpacing={2}>
                <Grid item xs={12} className={RFPCSS.DetailPreview}>
                    <Box className={RFPCSS.whitecardDetail} minHeight="1068px" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box className={RFPCSS.detailDescription} dangerouslySetInnerHTML={{ __html: decodeMultipleEncodedHTMLEntities(rfpDetailsFormData?.description) }} sx={{ wordBreak: 'break-word' }}></Box>

                        {isSocialRFp &&
                            <Box className={`${RFPCSS.f}  ${RFPCSS.jc_space_between} ${RFPCSS.cursor}`} gap={0.6} pt={2} sx={{ marginTop: "auto" }} >
                                <img src={Report} style={{ cursor: 'pointer' }} onClick={handleReportDrawerOpen} />
                                <Box className={`${RFPCSS.f}`} gap={2}  >
                                    {rfpStatus === "apply" &&
                                        <CommonButton2
                                            label="Apply"
                                            name="apply"
                                            variant="contained"
                                            type="button"
                                            onClick={handleApplyClick}
                                            style={{ padding: '8px 32px', fontSize: '14px', height: '32px', backgroundColor: "#0775BA", fontWeight: '600', boxShadow: 'none' }}
                                        />}
                                    {rfpStatus === "save_to_rfp" &&
                                        <CommonButton2
                                            label="Save to RFP"
                                            name="save"
                                            variant="contained"
                                            type="button"
                                            onClick={() => handleSaveClick()}
                                            style={{ padding: '8px 32px', borderColor: '#0775BA', fontSize: '14px', backgroundColor: "#0775BA", height: '32px', fontWeight: '600', boxShadow: 'none' }}
                                        />}
                                    {rfpStatus === "go_to_rfp" &&
                                        <CommonButton2
                                            label="Go to RFP"
                                            name="save"
                                            variant="contained"
                                            type="button"
                                            onClick={() => handleGoToRFP(rfpGid)}
                                            style={{ padding: '8px 32px', borderColor: '#0775BA', fontSize: '14px', backgroundColor: "#0775BA", height: '32px', fontWeight: '600', boxShadow: 'none' }}
                                        />}
                                    {rfpStatus === "disable" &&
                                        <CommonButton2
                                            label="Go to RFP"
                                            name="save"
                                            variant="contained"
                                            type="button"
                                            // onClick={() => handleGoToRFP(rfpGid)}
                                            disabled={rfpStatus === "disable"}
                                            style={{ padding: '8px 32px', borderColor: '#0775BA', opacity: rfpStatus === "disable" ? 0.5 : 1, fontSize: '14px', backgroundColor: "#0775BA", height: '32px', fontWeight: '600', boxShadow: 'none' }}
                                        />}
                                </Box >
                            </Box>}
                    </Box>
                </Grid>
            </Grid>

            <ShareRfp
                open={shareDialogOpen}
                onClose={handleShareDialogClose}
                Title="Share to"
                RfpId={drawerGid}
                master={master}
                form={form}
                user={users}
            />
            <ApplyRFP
                opens={confirmationDialogOpen}
                onClosed={handleConfirmationDialogClose}
                handleDrawerOpen={handleDrawerOpen}
                handleConfirmationDialogClose={handleConfirmationDialogClose}
                title="Confirm Apply"
                message="To apply for this RFP, you need to Purchase RFP Module. Click to proceed."
                ButtonText1="Cancel"
                ButtonText2="Proceed"
            />
            <Drawer
                open={isBussiness}
                onClose={handleDrawerClose}
                anchor="left"
                sx={{ marginTop: '60px' }}
                PaperProps={{
                    sx: {
                        top: '55px'
                    }
                }}>
                <BusinessSuite handleDrawerClose={handleDrawerClose} />
            </Drawer>
            <ReportDrawer open={reportDraweropen} gid={drawerGid} onClose={handleReportDrawerClose} socialMedia={socialMedia} master={master} title="Report RFP" desc="Report an abusive or inappropriate post." type="ProposalRequest" />
        </>
    );
}
export default DetailPreview;