import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import ginni from "../../../../assets/icons/Ginni.svg"

const SocialMediaFooter = ({ navigate }) => {
    const handleuserClick = () => {
        navigate("/user-Saftey");
    };
    const handlePrivacyClick = () => {
        navigate("/privacy-policy");
    };
    const handleConditionClick = () => {
        navigate("/terms-condition");
    };
    const handleContactClick = () => {
        navigate("/contact-us");
    };
    const handleAboutClick = () => {
        navigate("/about-us");
    };
    const handleSecurityClick = () => {
        navigate("/refund-policy");
    };
    return (
        <>
            <Box className={`${SocialMediaCSS.smFooter}`}>
                <Grid container rowSpacing={"16px"} className={`${SocialMediaFooter.footor}`}>
                    <Grid item xs={12}>
                        <Typography className={SocialMediaCSS.footorTypo1}>bvokal | © All Rights Reserved, 2023</Typography>
                    </Grid>
                    <Grid item xs={12} className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_center}`}>
                        <img src={ginni} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_center}`}>
                            <Typography onClick={handleuserClick} className={`${SocialMediaCSS.footorTypo1} ${SocialMediaCSS.cursor}`}>User Safety |</Typography> &nbsp;
                            <Typography onClick={handlePrivacyClick} className={`${SocialMediaCSS.footorTypo1} ${SocialMediaCSS.cursor}`}> Privacy Policy </Typography>
                        </Box>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_center}`}>
                            <Typography onClick={handleConditionClick} className={`${SocialMediaCSS.footorTypo1} ${SocialMediaCSS.cursor}`}> Terms & Conditions</Typography> &nbsp;
                            <Typography onClick={handleSecurityClick} className={`${SocialMediaCSS.footorTypo1} ${SocialMediaCSS.cursor}`}>| Refund Policy</Typography>
                        </Box>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_center}`}>
                            <Typography onClick={handleAboutClick} className={`${SocialMediaCSS.footorTypo1} ${SocialMediaCSS.cursor}`}> About Us</Typography>&nbsp;
                            <Typography onClick={handleContactClick} className={`${SocialMediaCSS.footorTypo1} ${SocialMediaCSS.cursor}`}>| Contact Us</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default SocialMediaFooter;
