import React, { useEffect } from 'react';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css'
import { Box, Grid, Typography } from '@mui/material';
import MainHeader from '../../../../../common/components/MainHeader';
import EventDetailsPreview from './EventDetailsPreview';
import PublicHeader from '../../../../../common/components/PublicHeader';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getEventPreview } from '../actions';
import { getMasterData } from "../../../actions";
import { useSelector } from 'react-redux';
import CustomSnackbar from '../../../../../common/components/CommonSnackbar';

const EventDetailsNewTabPreview = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const snackbarProps = useSelector((state) => state.message);
    const { users: { isLoggedIn },socialMedia: { isPreviewOpen } } = useSelector(state => state);
    useEffect(() => {
        if (params?.gid) {
            dispatch(getEventPreview(params?.gid,navigate));
            dispatch(getMasterData());
        }
    }, [params?.gid])

    return (
        <>

            <Box className={SocialMediaCSS.MainHeader} sx={{ zIndex: isPreviewOpen ? 0 : 99 }}>
            {isLoggedIn ? <MainHeader /> : <PublicHeader />}
            </Box>
            {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Grid container className={SocialMediaCSS.bgGrey100} height="100vh" >
                <Grid item xs={12} className={SocialMediaCSS.mainContent}>
                    <Box className={SocialMediaCSS.eventBodyContainer}>
                        <EventDetailsPreview />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default EventDetailsNewTabPreview
