import { Grid, Box, Typography, Skeleton } from "@mui/material";
import React from "react";
import { makeStyles } from '@mui/styles';
import theme from "../../../../../../assets/material-ui/theme";
import { decodeHTMLEntities } from "../../../../../../common/utils";

const useStyles = makeStyles((theme) => ({
    TextID: {
        display: 'flex',
        fontSize: '16px !important',
        fontWeight: '400',
        fontStyle: 'Poppins',
        textAlign: 'justify',
        color: '#333333',
        wordBreak: 'break-word'
    },
}));
const EventNotes = ({ events, data }) => {
    const classes = useStyles();
    return (
        <>
            <Box sx={{ boxShadow: theme.shadow.softShadow3, borderRadius: '16px', display: 'flex', flexDirection: 'column', backgroundColor: theme.mainPalette.grey.white }} p={2} gap={2}>
                <Typography sx={{ fontSize: '20px!important', fontWeight: '600', textAlign: 'center', color: 'var(--Primary-110, #0775BA)' }}>Notes for Volunteers</Typography>
                {data?.volunteer_notes ? (
                    <Typography className={classes.TextID}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "stretch",
                            wordWrap: "break-word"
                        }}
                        dangerouslySetInnerHTML={{
                            __html: decodeHTMLEntities(data?.volunteer_notes),
                        }}>
                        {/* {data && data?.volunteer_notes && data?.volunteer_notes.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))} */}
                    </Typography>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={1}>
                        {/* This one is static just for UI perspective and can be removed */}
                        {/* <Typography className={classes.TextID}>
                            A warm and sincere thank you to all our incredible volunteers! Your selfless dedication and hard work have made a significant impact on our community. Your commitment to making a positive difference is truly inspiring. Together, we are creating posito
                        </Typography> */}
                        {/* This one is static just for UI perspective and can be removed */}

                        {/* Below Commented Section Should be uncommented */}
                        <Skeleton animation="wave" variant="rounded" height='1rem' width='100%' />
                        <Skeleton animation="wave" variant="rounded" height='1rem' width='100%' />
                        <Skeleton animation="wave" variant="rounded" height='1rem' width='100%' />
                        <Skeleton animation="wave" variant="rounded" height='1rem' width='100%' />
                        <Skeleton animation="wave" variant="rounded" height='1rem' width='40%' />
                    </Box>)}
            </Box>
        </>
    )
}

export default EventNotes;