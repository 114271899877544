import React from 'react';
import ImageCarousel from './ImageCarousel';

const PostMedia = ({ post, eventData, isEvent, bEngageData, isBEngage }) => {
    let eventImage;
    if (isEvent) {
        eventImage = [
            { images_url: eventData?.cover_photo }
        ]
    }
    let bEngageBanner;
    if (isBEngage) {
        bEngageBanner = [
            { images_url: bEngageData?.banner }
        ]
    }
    const isShare = post?.shared_module?.name !== null;
    const isEventShare = post?.shared_module?.event_name !== null;
    let eventShare;
    if (isEventShare) {
        eventShare = [
            { images_url: post?.shared_module?.event_image }
        ]
    }
    const images = isEvent ? eventImage : isBEngage ? bEngageBanner : isShare ? post?.shared_module?.shared_images : isEventShare ? eventShare : post?.primary_post?.post_images;
    const video = isShare ? post?.shared_module?.shared_video : post?.primary_post?.post_video;
    return (
        <>
            <ImageCarousel
                images={images}
                video={video}
            />
        </>
    );
};

export default PostMedia;