import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import ProfileSummary from "./ProfileSummary";
import ConnectionSummary from "./ConnectionSummary";
import CSRSection from "./CSRSection";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getSocialUserDetail } from "../../../../common/actions";
import LeftSideCardNew from "./LeftSideCardNew";

const LeftSidebar = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {socialMedia, users, master, form} = useSelector((state) => { return state; });
    const isSocialImpact = location.pathname.startsWith(`/social-media/social-impacts`);
    
    // useEffect(() => {
    //     dispatch(getSocialUserDetail());
    //   }, []);
    // console.log("master Home", master);

    return (
        <>
            <Grid container rowSpacing={2}>
                {!isSocialImpact &&
                    <Grid item xs={12}>
                        <ProfileSummary 
                            socialMedia={socialMedia} 
                            dispatch={dispatch}
                        />
                    </Grid>}
                {!isSocialImpact && users?.isLoggedIn &&
                    <Grid item xs={12}>
                        <ConnectionSummary 
                            socialMedia={socialMedia} 
                            users={users} 
                            dispatch={dispatch} 
                            master={master} 
                            form={form} 
                        />
                    </Grid>}
                    {!users?.isLoggedIn &&
                        <Grid item xs={12}>
                            <LeftSideCardNew />
                        </Grid>
                    }
                {users?.isLoggedIn && <Grid item xs={12}>
                    <CSRSection 
                        socialMedia={socialMedia} 
                        dispatch={dispatch} 
                        master={master} 
                    />
                </Grid>}
            </Grid>
        </>
    );
}

export default LeftSidebar;
