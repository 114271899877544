import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import trend from "../../../../assets/icons/trend.svg";
import trend_Blue from "../../../../assets/icons/trend_Blue.svg";
import Nature from "../../../../assets/images/Water.svg";
import trend_White from "../../../../assets/icons/trend_White.svg";
import info_Blue from "../../../../assets/icons/info_Blue.svg"
import eye from "../../../../assets/icons/eye_solid_grey.svg"
import { useNavigate } from "react-router";
import { getIndianCSRList } from "../../actions";
import { useDispatch } from "react-redux";

const CSRSection = ({ socialMedia }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState("Indian CSR");

    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
        console.log("tabName", tabName)
    };

    const handleThematicClick = (index, slug) => {
        // console.log("Clicked index:", index);
        // console.log("Clicked slug:", slug);
        navigate(`/social-media/social-impacts/${slug}`);
    };
    useEffect(() => {
        dispatch(getIndianCSRList())
    }, []);

    return (
        <>
            <Box className={`${SocialMediaCSS.smCard} ${SocialMediaCSS.CsrCard}`}>
                <Grid container>
                    <Grid item xs={12} className={SocialMediaCSS.data} mt={2}>
                        <Box className={`${SocialMediaCSS.MyDatabase} ${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`}>
                            <img src={trend_White} alt="Indian CSR" />
                            <Typography className={SocialMediaCSS.databaseTypo} pl={1}>Indian CSR</Typography>
                        </Box>
                        <Typography className={SocialMediaCSS.connects}>
                            <Tooltip title="Enhance your post's visibility and connect with our dynamic CSR community by using relevant hashtags. Join the conversation and increase your engagement! Happy Posting!">
                                <img src={info_Blue} alt="Info" className={SocialMediaCSS.csreye} />
                            </Tooltip>
                        </Typography>
                    </Grid>

                    {/*Do not remove Uncomment when this is ready for development */}

                    {/* Indian CSR tab */}
                    {/* <Grid item xs={6} className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center} ${SocialMediaCSS.cardRadiusLeft} ${selectedTab === "Indian CSR" ? SocialMediaCSS.selectedCSR : SocialMediaCSS.notSelectedCSR}`} onClick={() => handleTabClick("Indian CSR")}>
                        <img src={selectedTab === "Indian CSR" ? trend_Blue : trend} alt="Indian CSR" />
                        <Typography className={selectedTab === "Indian CSR" ? SocialMediaCSS.Csrblue : SocialMediaCSS.Csrgrey}>Indian CSR</Typography>
                        {selectedTab === "Indian CSR" &&
                            <Tooltip title="More info about Indian CSR">
                                <img src={info_Blue} alt="Info" />
                            </Tooltip>}
                    </Grid> */}
                    {/* SDGs tab */}
                    {/* <Grid item xs={6} className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center} ${SocialMediaCSS.cardRadiusRight} ${selectedTab === "SDGs" ? SocialMediaCSS.selectedCSR : SocialMediaCSS.notSelectedCSR}`} onClick={() => handleTabClick("SDGs")}>
                        <img src={selectedTab === "SDGs" ? trend_Blue : trend} alt="SDGs" />
                        <Typography className={selectedTab === "SDGs" ? SocialMediaCSS.Csrblue : SocialMediaCSS.Csrgrey}>SDGs</Typography>
                        {selectedTab === "SDGs" &&
                            <Tooltip title="More info about SDGs">
                                <img src={info_Blue} alt="Info" />
                            </Tooltip>}
                    </Grid> */}
                </Grid>
                <Grid container className={`${SocialMediaCSS.CSRGrid}`}>
                    {socialMedia?.indianCsrList?.map((data, index) => (
                        <Grid item xs={12} className={SocialMediaCSS.CsrGridCard} key={index} onClick={() => handleThematicClick(index, data.slug)} >
                            <img src={data.image_url} className={SocialMediaCSS.csrImage} />
                            <Box width="100%">
                                <Typography className={`${SocialMediaCSS.cardTypo} ${SocialMediaCSS.cursor}`}>{data.title} </Typography>
                                <Box className={`${SocialMediaCSS.viewCountBox} `} >
                                    <IconButton sx={{ padding: '0px' }}><img src={eye} /></IconButton>
                                    <Typography className={SocialMediaCSS.csrCount}>{data.view_count}</Typography>
                                </Box>
                            </Box>
                        </Grid>))}
                </Grid>
            </Box>
        </>
    );
};

export default CSRSection;

