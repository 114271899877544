import React from "react";
import { Typography } from '@mui/material';
import theme from "../../../assets/material-ui/theme";

const FieldTitle = (props) => {
    let customStyle = props.style || {
        fontWeight: '600', fontSize: '14px', marginBottom: '5px', color: theme.palette.success.light
    }
    return (
        <>
            <Typography style={{ ...customStyle }}>{props.title}</Typography>
        </>
    )
}
export default FieldTitle;