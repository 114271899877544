import { Box, Typography } from '@mui/material';
import React from 'react';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';


const EventTypeLabel = ({type}) => {
    return(
        <>
            <Box className={SocialMediaCSS.evTypeLabel}>
                <Typography variant='body5' className={type === 'Public' ? SocialMediaCSS.publicEvLabel : SocialMediaCSS.privateEvLabel}>
                    {type}
                </Typography>
            </Box>
        </>
    );
};

export default EventTypeLabel



