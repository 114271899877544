import { Box, CircularProgress, Fade, Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CommonButton2 from '../../../../../common/components/CommonButton2'
import theme from '../../../../../assets/material-ui/theme'
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';
import userImage2 from '../../../../../assets/images/user_profile_image.jpeg';
import CommonInitials from '../../../../../common/components/CommonInitials';
import _ from 'lodash-es';
import { debounce, getCompanyTypeByKey } from '../../../../../common/utils';
import { useNavigate } from 'react-router';
import DiscardPost from '../../Popup/DiscardPost';
import { connectionRemove, getAllConnection } from '../../../actions';
import { setConnectionUserList } from '../../../../../store/slices/SocialMediaSlice';
import { setUserProfile } from '../../../../../store/slices/ProfileSlice';
import SocialMediaEventCSS from '../../../SocialMediaCSS/SocialMediaEvent.module.css';

const ConnectionList = ({ dispatch, navigate, connectionUserList, containerRef, totalConnectionListCount, offset, selectedConnTab }) => {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const isFetching = useRef(false);
    const lastScrollTop = useRef(0);
    let totalCount = useRef(0);
    let totalData = useRef(0);

    useEffect(() => {
        if (connectionUserList) {
            totalCount.current = connectionUserList.length;
            totalData.current = totalConnectionListCount;
        }
    }, [connectionUserList, totalConnectionListCount])

    const handleScroll = useCallback(() => {
        if (isFetching.current) return;
        const container = containerRef.current;
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;

        if (scrollTop > lastScrollTop.current && scrollTop + clientHeight >= scrollHeight - 50 && totalCount.current < totalData.current) { // Adjusted threshold for triggering
            fetchMoreData();
        }

        lastScrollTop.current = scrollTop;
    }, []);

    const debouncedHandleScroll = debounce(handleScroll, 300);
    const fetchMoreData = () => {
        try {
            isFetching.current = true;
            offset.current += 10;
            dispatch(getAllConnection(offset.current, true, setLoading, isFetching));
        } catch (error) {
            isFetching.current = false;
        }
    };
    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('scroll', debouncedHandleScroll);
        return () => {
            container.removeEventListener('scroll', debouncedHandleScroll);
        };
    }, []);
    const handleProfile = (gid) => {
        dispatch(setUserProfile([]));
        navigate(`/profile/${gid}`);
        dispatch(setConnectionUserList([]));
    }

    const handleConfirmDialogClose = () => {
        setSelectedProfile(null);
        setConfirmDialog(false);
    }

    const handleConfirmDialogOpen = (profile) => {
        setSelectedProfile(profile);
        setConfirmDialog(true);
    }

    const handleConfirmRemove = () => {
        dispatch(connectionRemove(selectedProfile?.gid))
        setSelectedProfile(null);
        setConfirmDialog(false);
    }

    const goToProfile = (gid) => {
        navigate(`/profile/${gid}`)
    }

    return (
        <>
            <Grid item xs={12}>
                <Box className={SocialMediaCSS.communityMainCardGrid}>
                    <Grid container spacing={2}>
                        {!_.isEmpty(connectionUserList) && connectionUserList.map((data) => (
                            <Grid item xs={12} sm={6} md={6} xl={3} key={data?.gid}>
                                <Box className={SocialMediaCSS.communityMainCardBox}>
                                    <Box className={SocialMediaCSS.communityMainCardBox2}>
                                        <CommonInitials
                                            name={data?.initials}
                                            bgColor={data?.colour_code}
                                            divStyle={{ padding: '3px', maxHeight: '70px', maxWidth: '70px', minHeight: '70px', minWidth: '70px', borderRadius: '10px', boxShadow: theme.shadow.softShadow2, flex: '0 0 70px', cursor: 'pointer', }}
                                            typoStyle={{ fontSize: '26px', lineHeight: '60px', }}
                                            avatarStyle={{ width: '70px', height: '70px', boxShadow: theme.shadow.softShadow2, cursor: 'pointer', borderRadius: '10px', }}
                                            src={data?.profile_pic}
                                            companyLogo={data?.profile_pic}
                                            avatar={true}
                                            onClick={() => goToProfile(data?.user_gid)}
                                        />
                                        <Box className={SocialMediaCSS.communityMainCardBox4}>
                                            <Typography className={SocialMediaCSS.communityMainCardTypo}>{data?.profile_name}</Typography>
                                            {/* <Typography className={SocialMediaCSS.communityMainCardTypo2}>{dispatch(getCompanyTypeByKey(data?.profile_type))}</Typography> */}
                                            {data?.profile_tagline && <Typography className={SocialMediaCSS.communityMainCardTypo2}>{data?.profile_tagline}</Typography>}
                                        </Box>
                                    </Box>
                                    <Box className={SocialMediaCSS.communityMainCardBox3}>
                                        <CommonButton2
                                            label="Profile"
                                            name="Profile"
                                            type="Profile"
                                            variant="contained"
                                            // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                            style={{ padding: '2px 16px', backgroundColor: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, boxShadow: 'none', width: '100px', borderRadius: '20px' }}
                                            onClick={() => { handleProfile(data?.user_gid) }}
                                        />
                                        <CommonButton2
                                            label='Remove'
                                            name='Remove'
                                            type='Remove'
                                            variant="outlined"
                                            // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                            style={{ padding: '2px 16px', color: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, borderColor: theme.mainPalette.primary.primary110, width: '100px', borderRadius: '20px' }}
                                            onClick={() => handleConfirmDialogOpen(data)}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {loading && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '100ms' : '0ms',
                        }}
                        unmountOnExit
                    >
                        <Box className={SocialMediaEventCSS.loadingBox}>
                            <CircularProgress />
                        </Box>
                    </Fade>
                </Box>}
                {_.isEmpty(connectionUserList) && <Box className={`${SocialMediaEventCSS.f} ${SocialMediaEventCSS.jc_center}`} sx={{ m: '230px 0px', width: '100%' }}>
                    <Typography sx={{ lineHeight: '36px', fontSize: '30px', fontWeight: 500, color: '#666666' }}>{selectedConnTab === "personal" ? 'No Individual' : selectedConnTab === 'csr' ? 'No CSR/ESG Expert' : selectedConnTab === 'agency' ? 'No Govt. Agency' : selectedConnTab === 'academia' ? 'No Academia' : 'No'} Connections yet.</Typography>
                </Box>}
            </Grid>
            {confirmDialog && <DiscardPost
                open={confirmDialog}
                onClose={handleConfirmDialogClose}
                ButtonText1="Yes, Remove"
                ButtonText2="Cancel"
                title="Remove Connection"
                Content1={`Are you sure you want to remove ${selectedProfile?.profile_name} from your connections?`}
                Content2="This action can't be undone, you've to send request again."
                handleConfirm={handleConfirmRemove}
                connect={true}
            />}
        </>
    )
}

export default ConnectionList