import { IconButton, Tooltip } from '@mui/material';
import React from 'react'
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';

import DownloadIcon from '../../../../../assets/icons/download.svg';
import CheckIcon from '../../../../../assets/icons/check.svg';
import { STATUS_CODE } from '../../../../../common/constants';

const EventLisitingActionBtn = ({ view, status, certificateIssued }) => {
    let actionData;

    switch (status?.toLowerCase()) {
        case STATUS_CODE.COMPLETE.STATUS_TEXT:
            actionData = certificateIssued === true ? (
                <IconButton variant="contained" className={view === 'gridView' ? SocialMediaCSS.eventListingGridAction : SocialMediaCSS.eventListingListAction} disableRipple>
                    <img src={DownloadIcon} alt='Download' className={view === 'gridView' ? SocialMediaCSS.h16 : SocialMediaCSS.h24} />
                </IconButton>) :
                (view === 'gridView' ? <IconButton variant="contained" className={`${SocialMediaCSS.eventListingActionDisabled} ${view === 'gridView' ? SocialMediaCSS.eventListingGridAction : SocialMediaCSS.eventListingListAction}`} disabled>
                    <img src={DownloadIcon} alt='Download' className={view === 'gridView' ? SocialMediaCSS.h16 : SocialMediaCSS.h24} />
                </IconButton> :
                    <Tooltip title="Certificate issued to be soon" placement="top" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -5], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }}>
                        <span>
                            <IconButton variant="contained" className={`${SocialMediaCSS.eventListingActionDisabled} ${view === 'gridView' ? SocialMediaCSS.eventListingGridAction : SocialMediaCSS.eventListingListAction}`} disabled>
                                <img src={DownloadIcon} alt='Download' className={view === 'gridView' ? SocialMediaCSS.h16 : SocialMediaCSS.h24} />
                            </IconButton>
                        </span>
                    </Tooltip>);
            break;
        case STATUS_CODE.UPCOMING.STATUS_TEXT.toLowerCase():
            actionData = (
                <IconButton variant="contained" className={view === 'gridView' ? SocialMediaCSS.eventListingGridAction : SocialMediaCSS.eventListingListAction} disableRipple disabled>
                    <img src={CheckIcon} alt='Download' className={view === 'gridView' ? SocialMediaCSS.h16 : SocialMediaCSS.h24} />
                </IconButton>
            )
            break;
        default:
            actionData = null;
            break;
    }
    return (
        <>
            {actionData}
        </>
    )
}

export default EventLisitingActionBtn