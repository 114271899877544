import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery } from '@mui/material';
import theme from '../../assets/material-ui/theme';
import CommonButton2 from './CommonButton2';
import Cancel_sechedule from "../../assets/icons/CancelledRed.svg";

const CommonDialog = ({ open, handleClose, handleConfirm, message, title }) => {
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { minWidth: isXsScreen ? '600px' : '300px', borderRadius: '24px' } }}>
            <DialogTitle sx={{ color: theme.palette.success.dark, textAlign: 'center', padding: '16px', fontFamily: 'poppins', borderBottom: '1px solid #CCC' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>{title}</div>
                    
                    <img src={Cancel_sechedule} onClick={handleClose} style={{ cursor: 'pointer',boxShadow:'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.07)) drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.12))' }} /> 
                </div>
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'left', padding: '32px 16px !important', fontSize: '16px', color: theme.palette.success.light }}>
                {message}
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 16px 10px 16px', borderTop: '1px solid #CCC' }}>
                <CommonButton2
                    label="Delete"
                    name="Delete"
                    type="Delete"
                    color="primary"
                    variant='contained'
                    style={{ padding: '6px 32px', fontSize: '14px', backgroundColor: theme.palette.primary.dark }}
                    onClick={() => handleConfirm()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default CommonDialog;