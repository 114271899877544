import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import { useLocation, useNavigate } from "react-router";
import { getDashboardPost } from "../../actions";
import { useDispatch } from "react-redux";
import RfpQuesPreview from "./RfpQuesPreview";
import DetailPreview from "../../../RFP/RFPnew/components/PreviewComponents/DetailPreview";
import RFPCSS from "../../../RFP/RFPcss/RFP.module.css"
import IconStack from "../../../RFP/RFPnew/components/subcomponents/IconStack";

const LeftRFPSection = () => {
    const { socialMedia: { rfpDetailsFormData, rfpStatus, glimpseFormData }, form, } = useSelector((state) => { return state; });
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const rfpGid = glimpseFormData.gid
    const handleBackClick = () => {
        navigate(`/social-media/rfps`)
    };
    const self_Data = glimpseFormData?.self_rfp
    const responded_at = glimpseFormData?.responded_at
    return (
        <>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <RfpQuesPreview SocialMediaCSS={SocialMediaCSS} />
                </Grid>
                <Grid item xs={12}>
                    <DetailPreview RFPCSS={RFPCSS} dispatch={dispatch} rfpDetailsFormData={rfpDetailsFormData} rfpStatus={rfpStatus} rfpGid={rfpGid} self_Data={self_Data} responded_at={responded_at}/>
                </Grid>

                <Box className={RFPCSS.pageActionBox}>
                    <Box onClick={handleBackClick}>
                        <IconStack iconName="Back" title="Back" />
                    </Box>
                </Box>

            </Grid>
        </>
    );
}
export default LeftRFPSection;
