import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Drawer, IconButton, Typography, Radio, RadioGroup, Button, FormControlLabel, List, ListItem, ListItemButton } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import theme from '../../../../assets/material-ui/theme';
import FlagIcon from '../../../../assets/icons/flag_white.svg';
import CommonButton2 from '../../../../common/components/CommonButton2';
import { disputes, disputesHashtag } from '../../actions';
import { useDispatch } from 'react-redux';

const ReportDrawer = ({ open, onClose, title, desc, master, socialMedia, gid, type = 'Post',hash }) => {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState('accord1');
    const [selectedValues, setSelectedValues] = useState({});
    console.log("types", type)
    const handleChange = (accord) => (event, newExpanded) => {
        setExpanded(newExpanded ? accord : false);
    };
    const handleRadioChange = (accord) => (event) => {
        setSelectedValues({
            ...selectedValues,
            [accord]: event.target.value,
        });
    };

    const handleReportClick = () => {
        const selectedArray = Object.values(selectedValues);
        console.log('Selected Values:', selectedArray);
        if (type === 'Hashtag') {
            dispatch(disputesHashtag(gid, selectedArray, type,hash))
        }
        if (type !== 'Hashtag') {
            dispatch(disputes(gid, selectedArray, type))
        }
        setSelectedValues({});
        onClose();
    };

    const grievanceTypes = master?.grievanceTypes || {};

    return (
        <Drawer open={open} onClose={() => { onClose(); setSelectedValues({}); setExpanded('accord1'); }}>
            <Box className={SocialMediaCSS.drawerContainer}>
                <Box className={SocialMediaCSS.drawerHeader}>
                    <Typography variant="h5" className={SocialMediaCSS.reportDrawerLabel}>{title}</Typography>
                    <IconButton onClick={() => { onClose(); setSelectedValues({}); setExpanded('accord1'); }} size='small'>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className={SocialMediaCSS.drawerBody}>
                    <Typography variant="body2" className={SocialMediaCSS.reportDrawerDescription}>
                        {desc}
                    </Typography>
                    <Box>
                        {/* {Object.keys(options).map((accord) => ( */}
                        {Object.keys(grievanceTypes).map((accord) => (
                            <Accordion expanded={expanded === accord} onChange={handleChange(accord)} key={accord}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant='body1' className={SocialMediaCSS.accSummary}>
                                        {/* {options[accord].summary} */}
                                        {accord}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: 0 }}>
                                    <RadioGroup name={`accRadioGrp${accord}`} onChange={handleRadioChange(accord)}>
                                        <List>
                                            {/* {grievanceTypes[accord].options.map((text, index) => ( */}
                                            {grievanceTypes[accord].map((text, index) => (
                                                <ListItem key={index} disablePadding>
                                                    <ListItemButton>
                                                        <FormControlLabel value={text} control={<Radio />} label={<Typography variant='body2' className={SocialMediaCSS.reportRadioLabel}>{text}</Typography>} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </RadioGroup>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                    <Box className={SocialMediaCSS.reportBtnBox}>
                        <CommonButton2
                            label="Report"
                            name="Report"
                            startIcon={<img src={FlagIcon} className={SocialMediaCSS.addIcon} />}
                            type="Report"
                            variant="contained"
                            classes={{
                                root: SocialMediaCSS.reportBtn,
                                focused: SocialMediaCSS.reportBtnFocused,
                            }}
                            sx={{ color: Object.keys(selectedValues).length === 0 ? 'grey' : 'white', backgroundColor: Object.keys(selectedValues).length !== 0 && "#D45454 !important" }}
                            onClick={handleReportClick}
                            disabled={Object.keys(selectedValues).length === 0}
                        />
                    </Box>
                </Box>

            </Box>
        </Drawer>
    );
};

export default ReportDrawer;
