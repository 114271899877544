import React, { useEffect, useState } from 'react'
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';
import ProfileCss from '../../../../Profile/NewProfile/Common/ProfileCss/Profile.module.css';
import theme from '../../../../../assets/material-ui/theme';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { setConnectionUserList, setSelectedConnTab } from '../../../../../store/slices/SocialMediaSlice';
import { BorderBottom } from '@mui/icons-material';
import { getAllConnection, getCompaniesTab, getNgoTab } from '../../../actions';
import { CONNECTION_TABS, CONNECTION_TAB_LIST } from '../../../../../common/constants';
import _ from 'lodash-es';

const ConnectionHeaderTabs = ({ dispatch, connectionCount }) => {
    const initialTab = connectionCount && connectionCount[0] ? connectionCount[0].key : 'personal';
    const [value, setValue] = useState('');
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setSelectedConnTab(newValue));
        dispatch(setConnectionUserList([]));
        if (newValue === CONNECTION_TABS.NGO) {
            dispatch(getNgoTab());
        } else if (newValue === CONNECTION_TABS.COMPANIES) {
            dispatch(getCompaniesTab());
        } else {
            dispatch(getAllConnection());
        }
        console.log("Tab", newValue);
    };

    const tabStyle = (tabValue) => ({
        color: theme.mainPalette.grey.lightText,
        fontSize: '16px',
        fontWeight: '500',
        padding: '0px 5px',
        height: '26px',
        minHeight: '26px',
        minWidth: 'unset',
        transition: ' all 0.3s ease',
        '&.Mui-selected': {
            color: theme.mainPalette.primary.primary110,
            transition: ' all 0.3s ease',
        },
        '&:hover': {
            // color: theme.mainPalette.secondary.secondary110,
            borderBottom: `2px solid ${theme.mainPalette.grey.lightText}`,
            transition: ' all 0.1s ease',
            opacity: 1,
        },
    });
    const CustomLabel = ({ mainLabel, count }) => (
        <Box className={SocialMediaCSS.all}>
            <Typography className={SocialMediaCSS.mainLabel2}>{mainLabel}</Typography>
            <Typography className={SocialMediaCSS.countLabel2} >({count})</Typography>
        </Box>
    );
    // Create an array of Tab components interleaved with divider components
    const tabComponents = [];
    if (!_.isEmpty(connectionCount)) {
        connectionCount.forEach((type, index) => {
            tabComponents.push(
                <Tab
                    key={type.key}
                    value={type.key}
                    label={<CustomLabel mainLabel={type.value} count={type.count} />}
                    sx={tabStyle}
                />
            );
            if (index !== connectionCount.length - 1) {
                tabComponents.push(
                    <Box key={`divider-${index}`} className={ProfileCss.dividerStyle}></Box>
                );
            }
        });
    }

    useEffect(() => {
        dispatch(setSelectedConnTab(initialTab));
        setValue(initialTab);
    }, [initialTab])

    return (
        <Grid item xs={12}>
            <Box className={ProfileCss.headerTabs}>
                <Tabs
                    value={value}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="none"
                    variant="scrollable"
                    // scrollButtons="auto"
                    sx={{ "& .MuiTabs-indicator": { backgroundColor: theme.palette.primary.dark, height: '2px', borderRadius: '0px' }, minHeight: '26px', height: '26px', }}
                    aria-label="Profile Tabs"
                >
                    {tabComponents}
                </Tabs>
            </Box>
        </Grid>
    )
}

export default ConnectionHeaderTabs