import React from 'react';
import TextField from '@mui/material/TextField';
import theme from '../../assets/material-ui/theme';
import { Box, OutlinedInput } from '@mui/material';

export const renderLoginText = ({
  input,
  label,
  placeholder,
  type,
  fullWidth,
  maxLength,
  underlineStyle,
  errorStyle,
  style,
  toolTiptext,
  id,
  pattern,
  defaultValue,
  disabled,
  min,
  max,
  value,
  onKeyPress,
  meta: { touched, error, warning },
  inputProps,
  errorMessages,
  custom,
}) => {
  let inputType = type;
  errorStyle = {
    fontSize: '10px',
    color: 'rgb(244, 67, 54)',
    position: 'static',
  };
  return (
    <>
      <OutlinedInput
        inputProps={{
          maxLength,
          disabled,
          min: 0
        }}
        className="TextField"
        sx={(theme) => {
          return {
            border: "8px",
            height: 48,
            borderRadius: 10,
            backgroundColor:theme.palette.secondary.dark,
            // borderColor: theme.palette.secondary.main,
            // color: "#333333",
            // '& .MuiInputLabel-root': {
            //   color: theme.palette.secondary.dark,
            // },
            // '& .MuiInputBase-root': {
            //   color: theme.palette.secondary.dark,
            // },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.success.dark,
            },
            "&:hover  .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.success.dark
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.main
            },
            "& input:-webkit-autofill": {
                height: 12,
                '-webkit-box-shadow': '0 0 0 1000px transparent inset',
                '-webkit-text-fill-color': '#333333 !important',
                'transition': 'background-color 5000s ease-in-out 0s',
            },
          }
     
        }}
        placeholder={placeholder}
        // data-tootip={toolTiptext}
        // data-for="globalTooltip"
        // errorStyle={errorStyle}
        // underlineStyle={underlineStyle}
        id={id}
        disabled={disabled}
        autoComplete="off"
        pattern={pattern}
        type={inputType}
        onKeyPress={onKeyPress}
        fullWidth={fullWidth}
        value={defaultValue}
        min={min}
        max={max}
        style={style}

        {...input}
        {...custom}
      />
      {touched &&
        (error && (
          <Box className='error'>
            {error}
          </Box>
        ))}
    </>
  );
};


