import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';

export default function CommonButton2(props) {
    const { variant } = props;
    return (
        <div className={props.className}>
            <LoadingButton
                color={props.color}
                size={props.size || 'large'}
                fullWidth={!props.smallWidth}
                id={props.name}
                onClick={props.onClick}
                sx={{
                    fontFamily: 'poppins',
                    fontSize: '16px',
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                    wordBreak: 'break-word',
                    paddingRight: 6,
                    paddingLeft: 6,
                    paddingTop: 1,
                    paddingBottom: 1,
                    borderRadius: 40,
                    // color:'#FFFFFF'
                    color: variant === 'contained' ? 'white' : undefined,
                    ...props.style,
                }}
                className="custom-button"
                startIcon={props.startIcon}
                endIcon={props.endIcon}
                variant={props.variant}
                type={props.type}
                disabled={props.disabled}
                loading={props.loading}
                disableRipple
                {...props}
            >
                {props.label}
            </LoadingButton>
        </div>
    );
}