import { createSlice } from "@reduxjs/toolkit";
const initialState  = {
    aboutUs: {},
    addressFormData: [],
    analyticsData: {},
    userProfile: [],
    contactInfo:[],
    worksIn: [],
    displayContactFields: true,
    addressFlag: true,  
    catchmentAreas:[],
    catchmentFlag: false,
    selectedTab: 'about',
    projectLocationFlag: false,
    projectArea:[],
    pastProjectData:[],
    pastProjectFlag:true,
    uploadedData: [],
    complianceData: [],
    annexureData:[],
    annexureId: null,
    userRoles:[],
    modalFlag: false,
    modalMessage: "",
    profileTabs: "About",
    isTabDataLoading: false,
    suggestedUsers: [],
    sendOtp : false,
}
const profileSlice = createSlice({
    name:"user",
    initialState,
    reducers: {
        resetProfileSlice:() => initialState,
        setContactPerson(state, action){
            state.contactInfo.push(action.payload);
        },
        setContactPersonFlag(state, action){
            state.displayContactFields = action.payload;
        },
        setWorksIn(state, action){
            state.worksIn = (action.payload);
        },
        updateContactPerson(state, action){
            state.contactInfo = action.payload;
        },
        addAddressData(state, action){
            state.addressFormData.push(action.payload);
        },
        updateAddressData(state, action){
            state.addressFormData = action.payload;
        },
        setAddressFlag(state, action){
            state.addressFlag = action.payload;
        },
        addCatchmentAreas(state, action){
            state.catchmentAreas.push(action.payload);
        },
        updateCatchmentAreas(state, action){
            state.catchmentAreas = action.payload;
        },
        setCatchmentFlag(state, action){
            state.catchmentFlag = action.payload;
        },
        addUserState(state, action){
            state.state = action.payload;
        },
        addAnalyticsData(state, action){
            state.analyticsData = action.payload;
        },
        setSuggestedUsers(state, action){
            state.suggestedUsers = action.payload;
        },
        addProfileDistrict(state, action){
            state.district = action.payload;
        },
        addThematicAreas(state, action){
            state.thematicAreas = action.payload;
        },
        addAddressType(state, action){
            state.addressType = action.payload;
        },
        addAboutUsData(state, action){
            state.aboutUs = action.payload;
        },
        setProfileTab(state, action){
            state.selectedTab = action.payload;
        },
        setProjectLocationFlag(state, action){
            state.projectLocationFlag = action.payload;
        },
        updateProjectLocation(state, action){
            state.projectArea = action.payload;
        },
        addProjectLocation(state, action){
          
            state.projectArea.push(action.payload);
        },
        addPastProjectData(state, action){
            state.pastProjectData.push(action.payload);
        },
        updatePastProjectData(state, action){
            state.pastProjectData = action.payload;
        },
        setPastProjectFlag(state, action){
            state.pastProjectFlag = action.payload;
        },
        setUploadedData(state, action){
            state.uploadedData = action.payload;
        },
        addAnnexureList(state, action){
            state.annexureList = action.payload;
        },
        addAnnexureData(state, action){
            state.annexureData = action.payload
        },
        setAnnexureId(state, action){
            state.annexureId = action.payload
        },
        addComplianceData(state, action){
            state.complianceData = action.payload
        },
        setUserRoles(state, action){
            state.userRoles = action.payload
        },
        setModalFlag(state, action){
            state.modalFlag = action.payload
        },
        setModalMessage(state, action){
            state.modalMessage = action.payload
        },
        setProfileTabs(state, action){
            state.profileTabs = action.payload
        },
        setTabDataLoading(state, action){
            state.isTabDataLoading = action.payload
        },
        setUserProfile(state, action){
            state.userProfile = action.payload
        },
        setSendOtp(state, action){
            state.sendOtp = action.payload
        },
    }
})

export default profileSlice.reducer;
export const { 
    setModalFlag,
    setModalMessage,
    resetProfileSlice,
    setContactPerson, 
    setContactPersonFlag,
    updateContactPerson,
    setWorksIn,
    addAddressData,
    setAddressFlag,
    updateAddressData,
    addCatchmentAreas,
    updateCatchmentAreas,
    setCatchmentFlag,
    addUserState,
    addThematicAreas,
    addAddressType,
    addAboutUsData,
    setProfileTab,
    setProjectLocationFlag,
    updateProjectLocation,
    addProjectLocation,
    addPastProjectData,
    updatePastProjectData,
    setPastProjectFlag,
    setUploadedData,
    addAnnexureList,
    addAnnexureData,
    setAnnexureId,
    addComplianceData,
    addProfileDistrict,
    setUserRoles,
    setProfileTabs,
    setTabDataLoading,
    setUserProfile,
    addAnalyticsData,
    setSuggestedUsers,
    setSendOtp,
}  = profileSlice.actions;