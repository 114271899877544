import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RenderInputNew as textField } from './RectangularInputs/RenderInputNew.js';
import renderPasswordNew from './RectangularInputs/RenderPasswordNew.js';
import RenderEmailNew from './RectangularInputs/RenderEmailNew.js';


const CommonInputNew = ({ keyboardType, showCount, toolTiptext, placeholder, customHeight, onKeyPress, placeholderFontSize, validate, ...otherProps }) => {
  let component;
  if (otherProps.type === 'password') {
    component = renderPasswordNew;
  } else if (otherProps.type === 'Email') {
    component = RenderEmailNew;
  } else {
    component = textField;
  }
  return (
    <Field
      inputProps={{ ...otherProps }}
      name={otherProps.name}
      // component={otherProps.type === 'password' ? renderPassword : RenderEmail}
      component={component}
      id={otherProps.name}
      label={otherProps.label}
      placeholder={placeholder}
      type={otherProps.type}
      rows={otherProps.rows}
      keyboardType={keyboardType}
      placeholderFontSize={placeholderFontSize}
      customHeight={customHeight}
      fullWidth
      onKeyPress={onKeyPress}
      toolTiptext={toolTiptext}
      validate={validate}
      multiline={otherProps.multiline}
      radius={otherProps.customRadius}
      maxLength={otherProps.maxLength}
      onChange={otherProps.onChange}
      defaultValue={otherProps.defaultValue || ''}
      onBlur={otherProps.onBlur}
      disabled={otherProps.disabled}
      min={otherProps.min}
      max={otherProps.max}
      showCount={showCount}
      errorWidth={otherProps.errorWidth}
      normalize={otherProps.normalize}
      borderBottom={otherProps.borderBottom}
      maxWords={otherProps.maxWords}
      handleSubmit={otherProps.handleSubmit}
    />
  )
};


CommonInputNew.propTypes = {
  name: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  keyboardType: PropTypes.string,
  validate: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.func,
  ]),
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  toolTiptext: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  normalize: PropTypes.func,
  borderBottom: PropTypes.string,
  placeholderFontSize: PropTypes.string,
  maxWords: PropTypes.number,
  customHeight: PropTypes.string,
};

export default CommonInputNew;

