import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import theme from '../../../../assets/material-ui/theme'
import CommonInputNew from '../../../../common/components/CommonInputNew'
import CommonButtonNew from '../../../../common/components/RectangularInputs/CommonButtonNew'
import ReduxForm from '../../../../common/components/ReduxForm'
import * as clientValidations from '../../../../common/validations'
import { doTempRegister } from '../../actions'
import { useSelector } from 'react-redux'
import CreateAccountForm from './CreateAccountForm'
import { setTempUserData } from '../../../../store/slices/UserSlice'

const CreateAccount = ({ dispatch, location, navigate, RegCSS, selectedOption, selectedCompanyType }) => {
    const { form, users } = useSelector((state) => state);
    const handleSubmit = (values) => { 
        dispatch(doTempRegister(values, selectedOption, selectedCompanyType, navigate));
     }
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (form?.singUP?.values?.name !== '' || form?.singUP?.values?.email !== '') {
                dispatch(setTempUserData({}));
                const message = 'Changes you made may not be saved.';
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [form?.singUP?.values]);
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={RegCSS.signUpMailHeading} textAlign={'center'}>
                        Create an account
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <CreateAccountForm
                        dispatch={dispatch}
                        navigate={navigate}
                        location={location}
                        RegCSS={RegCSS}
                        selectedOption={selectedOption}
                        selectedCompanyType={selectedCompanyType}
                        users={users}
                        handleSubmit={handleSubmit}
                        isBusinessSuite={false}
                    />
                    {/* <ReduxForm
                        onSubmit={(values) => dispatch(doTempRegister(values, selectedOption, selectedCompanyType, navigate))}
                        initialValues={initialValues}
                        form="signUP"
                        label="SignUP"
                        submitButton="Register"
                    >
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sx={{ mt: '22px !important' }}>
                                        <Typography className={RegCSS.signUpMailTypo4}>{selectedOption === 'personal' ? 'Full name' : 'Organization name'} <span style={{ color: theme.palette.error.main }}>*</span></Typography>
                                        <CommonInputNew
                                            name="name"
                                            placeholder={selectedOption === 'personal' ? "Enter your full name" : "Enter your Organization's name"}
                                            type="text"
                                            keyboardType="default"
                                            fullWidth
                                            validate={[clientValidations.NameRequired]}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={RegCSS.signUpMailTypo4}>Email address <span style={{ color: theme.palette.error.main }}>*</span></Typography>
                                        <CommonInputNew
                                            name="email"
                                            placeholder={selectedOption === 'personal' ? "Enter your personal email address" : "Enter your Organization's email address"}
                                            type="Email"
                                            keyboardType="default"
                                            fullWidth
                                            validate={[clientValidations.EmailRequired, clientValidations.emailCheck]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display='flex' justifyContent='center' flexWrap='wrap' px={1}>
                                    <span className={RegCSS.createAccSpan}> By clicking "Agree & Register" you agree to the bvokal with an</span>
                                    <Link to="/user-agreement" underline="always" className={RegCSS.createAccLink}>
                                        user agreement
                                    </Link>
                                    <span className={RegCSS.createAccSpan2}>,</span>
                                    <Link to="/privacy-policy" underline="always" className={RegCSS.createAccLink}>
                                        privacy policy
                                    </Link>
                                    <span className={RegCSS.createAccSpan2}>and</span>
                                    <Link to="/terms-policy" underline="always" className={RegCSS.createAccLink}>
                                        Cookies policy
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display={'flex'} justifyContent={'center'}>
                                    <CommonButtonNew
                                        label="Agree & Register"
                                        name="Register"
                                        type="submit"
                                        style={{ background: theme.palette.primary.dark, color: 'white', boxShadow: theme.shadow.softShadow4 }}
                                        smallWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display='flex' justifyContent='center'>
                                    <Typography sx={{ fontSize: '14px', color: theme.mainPalette.grey.darkText, fontWeight: theme.typography.fontWeightMedium }}>Already have an account?</Typography>
                                    <Link to='/login' underline="always" style={{ marginLeft: 8, color: theme.palette.primary.dark, fontSize: '14px', fontWeight: theme.typography.fontWeightMedium }}>Login</Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </ReduxForm> */}
                </Grid>
            </Grid>
            
        </>
    )
}

export default CreateAccount
