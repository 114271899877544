import React from 'react';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { Box, Skeleton } from '@mui/material';

const EventCardSkeleton = () => {
    return (
        <>
            {
                Array.from(new Array(5)).map((_, index) => (
                    <Box className={SocialMediaCSS.CsrDetailSkeleton}>
                        <Box className={SocialMediaCSS.eventImageSkeleton}>
                            <Skeleton animation="wave" variant="circular" width={46} height={46} />
                        </Box>
                        <Box className={SocialMediaCSS.flexGrow}>
                            <Skeleton animation="wave" variant="text" className={`${SocialMediaCSS.resourcetypo}`} width="60%" />
                            <Skeleton animation="wave" variant="text" className={`${SocialMediaCSS.eventThematic}`} width="40%" />
                            <Box>
                                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between} ${SocialMediaCSS.ai_stretch}`}>
                                    <Box className={SocialMediaCSS.f}>
                                        <Skeleton animation="wave" variant="circular" width={16} height={16} className={SocialMediaCSS.imgPadSkeleton} />
                                        <Skeleton animation="wave" variant="text" className={SocialMediaCSS.eventLocation} width="40px" />
                                    </Box>
                                    <Box className={SocialMediaCSS.f}>
                                        <Skeleton animation="wave" variant="circular" width={16} height={16} className={SocialMediaCSS.imgPadSkeleton} />
                                        <Skeleton animation="wave" variant="text" className={SocialMediaCSS.eventLocation} width="48px" />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ))
            }
        </>
    )
}

export default EventCardSkeleton
