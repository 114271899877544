import { getFileSizeInKBFromBase64 } from "./utils";

export const required = value => {

  return value ? undefined : 'This is required field';
};

export const requiredList = value => {
  return value.length > 0 ? undefined : 'This is required field';
};

export const PasswordRequired = value =>
  value ? undefined : 'Please enter password';
export const EmailRequired = value =>
  value ? undefined : 'Please enter email';
export const NameRequired = value =>
  value ? undefined : 'Please enter name';
export const OrganizationNameRequired = value =>
  value ? undefined : 'Please enter organization name';

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength6 = maxLength(6);
export const maxLength8 = maxLength(8);
export const maxLength10 = maxLength(10);
export const maxLength12 = maxLength(12);
export const maxLength15 = maxLength(15);
export const maxLength16 = maxLength(16);
export const maxLength25 = maxLength(25);
export const maxLength30 = maxLength(30);
export const maxLength50 = maxLength(50);
export const maxLength100 = maxLength(100);
export const maxLength150 = maxLength(150);
export const maxLength200 = maxLength(200);
export const maxLength500 = maxLength(500);
export const maxLength1000 = maxLength(1000);

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLength6 = minLength(6);
export const minLength8 = minLength(8);
export const minLength10 = minLength(10);
export const minLength12 = minLength(12);


export const maxSize = max => value =>
  value && getFileSizeInKBFromBase64(value) > max ? `File size must be ${max} KB or less` : undefined;

export const maxSize2000 = maxSize(2000);
export const maxSize2048 = maxSize(2048);
export const maxSize800 = maxSize(800);

export const emailCheck = value =>
  // value && !/^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  value && !/^[^@]+@[^@.]+(\.[^@.]+)+$/.test(value)
    ? 'Invalid email address'
    : undefined;

// export const passwordCheck = value =>
//   value && !/^(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,16}).*$/i.test(value)
//     ? 'Password should have atleast one uppercase,one lowecase one number and one special characters.'
//     : undefined;
// export const passwordCheck = value =>
//   value && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=]).{8,16}$/.test(value)
//     ? undefined
//     : 'Password should have at least one uppercase,one lowercase,one number and one special character.';
export const passwordCheck = value => {
  if (!value) {
    return 'Password is required';
  }
  if (/\s/.test(value)) {
    return 'Password should not contain any spaces.';
  }
  if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=]).{8,16}$/.test(value)) {
    return 'Password should have at least one uppercase, one lowercase, one number, and one special character.';
  }
  return undefined;
};

export const number = value =>
  value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined;

export const phoneNumber = value =>
  value && !/^(0|[5-9][0-9]{9})$/i.test(value)
    ? 'Please enter a valid 10 digit mobile number.'
    : undefined;

export const onlyText = value => {
  if (value && !/^[a-zA-Z0-9 ]*$/i.test(value)) return 'Must be text only';
  // if (value && value.trim && value !== value.trim())
  //   return 'Must not start or end with whitespace';
  return undefined;
};
export const phoneNumberPoc = value =>
  value && !/^[5-9]\d{7,10}$/i.test(value)
    // value && !/^(0|[5-9][0-9]{9})$/i.test(value)
    ? 'Please enter a valid 8 - 11 Contact number.'
    : undefined;

export const onlyTextandNumber = value => {
  if (value) {
    if (value.startsWith('.') || /^\s/.test(value) || /\s$/.test(value)) {
      return 'Value must not start/end with a dot or whitespace';
    }
  }

  return undefined;
};

export const pincode = value =>
  value && !/^[1-9][0-9]{5}$/i.test(value) ? 'Invalid Pin Code' : undefined;

export const onlyAlphaNumeric = value =>
  value && !/^([a-zA-Z0-9 _-]+)$/i.test(value)
    ? 'Must be alphanumeric only.'
    : undefined;

export const maxCharacter4 = value =>
  value && value.length > 4 ? 'Must be 4 characters or less' : undefined;

export const maxCharacter10 = value =>
  value && value.length > 10 ? 'Must be 10 characters or less' : undefined;

export const maxCharacter15 = value =>
  value && value.length > 15 ? 'Must be 15 characters or less' : undefined;

export const maxCharacter100 = value =>
  value && value.length > 100 ? 'Must be 100 characters or less' : undefined;

export const maxCharacter200 = value =>
  value && value.length > 200 ? 'Must be 200 characters or less' : undefined;

export const maxCharacter250 = value =>
  value && value.length > 250 ? 'Must be 250 characters or less' : undefined;

export const maxCharacter300 = value =>
  value && value.length > 300 ? 'Must be 300 characters or less' : undefined;

export const maxCharacter50 = value =>
  value && value.length > 50 ? 'Must be 50 characters or less' : undefined;

export const maxCharacter40 = value =>
  value && value.length > 40 ? 'Must be 40 characters or less' : undefined;

export const maxCharacter60 = value =>
  value && value.length > 60 ? 'Must be 60 characters or less' : undefined;

export const maxCharacter25 = value =>
  value && value.length > 25 ? 'Must be 25 characters or less' : undefined;

export const maxCharacter30 = value =>
  value && value.length > 30 ? 'Must be 30 characters or less' : undefined;

export const maxCharacter80 = value =>
  value && value.length > 80 ? 'Must be 80 characters or less' : undefined;

export const maxCharacter500 = value =>
  value && value.length > 500 ? 'Must be 500 characters or less' : undefined;

export const maxCharacter5000 = value =>
  value && value.length > 5000 ? 'Must be 5000 characters or less' : undefined;

export const maxCharacter1000 = value =>
  value && value.length > 1000 ? 'Must be 1000 characters or less' : undefined;

export const maxCharacter1500 = value =>
  value && value.length > 1500 ? 'Must be 1500 characters or less' : undefined;

export const maxCharacter2000 = value =>
  value && value.length > 2000 ? 'Must be 2000 characters or less' : undefined;

export const maxCharacter2500 = value =>
  value && value.length > 2500 ? 'Must be 2500 characters or less' : undefined;

export const maxCharacter750 = value =>
  value && value.length > 750 ? 'Must be 750 characters or less' : undefined;

export const maxCharacter125 = value =>
  value && value.length > 125 ? 'Must be 125 characters or less' : undefined;

export const maxCharacter150 = value =>
  value && value.length > 150 ? 'Must be 150 characters or less' : undefined;

const maxWords = limit => value => {
  const words = value.trim().split(/\s+/); // Split the input value into an array of words
  if (words.length > limit) {
    return `Must be ${limit} words or less`;
  }
  return undefined;
};
export const maxWords500 = maxWords(500);

export const mustIncludeAlphabet = value => {
  const hasAlphabets = /[a-zA-Z]/.test(value); // Check if value includes alphabets
  const hasNumbers = /[0-9]/.test(value); // Check if value includes numbers
  const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(value); // Check if value includes special characters

  if (!hasAlphabets) {
    return 'Text must include alphabets';
  }

  return undefined;
};
export const integerAmount = value =>
  value && !/^[1-9][0-9]*?$/i.test(value) ? 'Must be number and Do not use decimal' : undefined;

export const alphanumericValidation = value => {
  const hasSpecialChars = /[!@#$%^&*()?"':;<>/|{}[]|<>]/.test(value); // Check if value includes special characters

  if (hasSpecialChars) {
    return 'Field must contain only alphanumeric characters';
  }

  return undefined;
};
export const noSpecialCharacters = value => {
  const hasSpecialChars = /[!@#$%^&*()?"':;<>/|{}[\]]/.test(value);

  if (hasSpecialChars) {
    return 'Field should not contain special characters';
  }

  return undefined;
};

export const noSpecialCharactersEmail = value => {
  const hasSpecialChars = /[!#$%^&*()?"':;<>/|{}[\]]/.test(value);

  if (hasSpecialChars) {
    return 'Field should not contain special characters';
  }

  return undefined;
};

export const dotValidation = value => {
  if (!value) {
    return '';
  }

  if (!/\./.test(value)) {
    return 'Invalid website Url';
  }

  return undefined;
};

export const alphabeticCharacters = value => {
  const hasNonAlphabeticChars = /[^a-zA-Z\s]/.test(value);

  if (hasNonAlphabeticChars) {
    return 'Field must contain only alphabetic characters';
  }

  return undefined;
};
export const noNumericCharacters = (value) => {
  // Regular expression to test for any numeric digit (0-9)
  const hasNumericChars = /\d/.test(value);

  if (hasNumericChars) {
    return 'Field must not contain numeric characters';
  }

  return undefined;
};

export const noAlphabeticCharacters = value => {
  const hasAlphabeticChars = /[a-zA-Z]/.test(value);

  if (hasAlphabeticChars) {
    return 'Field must not contain alphabetic characters';
  }

  return undefined;
};

export const onlyUppercase = value => {
  if (value && value !== value.toUpperCase()) {
    return 'Only uppercase characters are allowed';
  }
  return undefined;
};

export const pannumber = value =>
  value &&
    !/^([a-zA-Z]){4}([a-zA-Z]){1}([0-9]){4}([a-zA-Z]){1}$/i.test(value)
    ? 'Invalid PAN Number'
    : undefined;

export const gstinumber = value =>
  value &&
    !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}?$/i.test(value)
    ? 'Invalid GST Number'
    : undefined;
export const endDateAfterStartDate = (value, allValues) => {
  if (value && allValues.start_date) {
    const startDate = new Date(allValues.start_date);
    const endDate = new Date(value);

    if (endDate <= startDate) {
      return "End date must be after the start date";
    }
  }
  return undefined;
};
export const endDateAfterAwardDate = (value, allValues) => {
  if (value && allValues.award_date) {
    // Create date objects from award_date and nomination_last_date
    const awardDate = new Date(allValues.award_date);
    const nominationDate = new Date(allValues.nomination_last_date);

    // Strip the time part by setting hours, minutes, seconds, and milliseconds to zero
    const awardDateOnly = new Date(awardDate.setHours(0, 0, 0, 0));
    const nominationDateOnly = new Date(nominationDate.setHours(0, 0, 0, 0));

    // Check if nomination date is greater than award date
    if (nominationDateOnly > awardDateOnly) {
      return "Nomination Date should not be greater than the Award Date";
    }
  }
  return undefined;
};
export const endTimeAfterStartTime = (value, allValues) => {
  if (value && allValues.start_date) {
    const startDate = new Date(allValues.start_date);
    const endDate = new Date(value);

    if (endDate <= startDate) {
      return "End time must be after the start time";
    }
  }
  return undefined;
};

export const endTimeAfterStartTimeAgenda = (value, allValues) => {
  if (value && allValues.start_time) {
    const convertToMinutes = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };

    const startMinutes = convertToMinutes(allValues.start_time);
    const endMinutes = convertToMinutes(value);

    if (endMinutes <= startMinutes) {
      return "End time must be after the start time";
    }
  }
  return undefined;
};

export const endTimeAfterStartTimeOnly = (endTime, startTime) => {
  if (endTime && startTime) {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const startDate = new Date();
    startDate.setHours(startHours, startMinutes, 0, 0);

    const endDate = new Date();
    endDate.setHours(endHours, endMinutes, 0, 0);

    if (endDate <= startDate) {
      return "End time must be after the start time";
    }
  }
  return undefined;
};

export const startDateAfterCurrentDate = value => {
  const selectedDate = new Date(value);
  // const currentDate = new Date();

  // Calculate the start of the current day
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  if (selectedDate < startOfDay) {
    return 'Start date should not before current date';
  }

  return undefined;
};
export const pastAndCurrentDate = value => {
  const selectedDate = new Date(value);
  const currentDate = new Date();

  if (selectedDate > currentDate) {
    return 'Please select a date from the past or today';
  }

  return undefined;
};
export const numericMaxValue = maxValue => value => {
  if (value && isNaN(Number(value))) {
    return 'Must be a numeric value';
  }

  if (value && Number(value) > maxValue) {
    return `Value must be less than or equal to ${maxValue}`;
  }

  return undefined;
};

export const numericMaxValue2 = maxValue => value => {
  if (value && isNaN(Number(value))) {
    return 'Must be a numeric value';
  }

  if (value && Number(value) > maxValue) {
    return `Value must be less than or equal to 1 CR.`;
  }

  return undefined;
};

export const numericMaxValue10000000 = numericMaxValue(10000000);
export const oneCR = numericMaxValue2(10000000);

export const maxDigits = max => value => {
  if (value && /^\d{1,}$/.test(value) && value.length > max) {
    return `Must be ${max} digits or less`;
  }
  return undefined;
};

export const maxDigits12 = maxDigits(12);
export const noHtmlTags = value => {
  if (value && /<[a-z][\s\S]*>/i.test(value)) {
    return 'HTML tags are not allowed';
  }
  return undefined;
};
export const dateAfter1900 = value => {
  if (value) {
    const inputDate = new Date(value);
    const minimumDate = new Date("1900-01-01"); // January 1, 1900

    if (inputDate < minimumDate) {
      return 'Date must be after the year 1900';
    }
  }

  return undefined;
};
export const numericRange240 = value => {
  if (value !== undefined && (isNaN(Number(value)) || Number(value) < 0 || Number(value) > 240)) {
    return 'Value must be between 0 and 240';
  }
  return undefined;
};

export const numericMaxValue1000 = value => {
  if (value && isNaN(Number(value))) {
    return 'Must be a numeric value';
  }

  if (value && Number(value) > 1000) {
    return 'Value must be less than or equal to 1000';
  }

  return undefined;
};


export const normalizeNumericInput = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^0-9]/g, '');
};

export const maxSelections = max => value => {
  if (value && Array.isArray(value) && value.length > max) {
    return `You can select up to ${max} options`;
  }
  return undefined;
};

export const maxTwoSelections = maxSelections(2);

export const timeNotPast = value => {
  if (!value) {
    return undefined;
  }
  const currentTime = new Date();  // Current date and time
  const inputTime = new Date(value);  // Convert to Date
  if (inputTime < currentTime) {
    return 'Time must not be in the past';
  }
  return undefined;
};

// export const normalizeValueLessThan1000 = (value) => {
//   const numericValue = parseFloat(value);
//   if (!isNaN(numericValue)) {
//     // Ensure the value is not greater than 1000
//     return Math.min(numericValue, 1000).toString();
//   }
//   return ""; // Clear the input if it's not a valid number
// };
export const wordCount = (value = '') => {
  const words = value.trim().split(/\s+/).filter(word => word.length > 0);

  if (words.length > 50) {
    return 'Must be 50 words or less ';
  }
  return undefined;
};

export const endYearAfterStartYear = (value, allValues) => {
  if (value && allValues.start_date) {
    const projectStartDate = new Date(allValues.start_date);
    const endDate = new Date(value);

    if (endDate <= projectStartDate) {
      return "End date year should not be less than the starting date year";
    }
  }
  return undefined;
};

const isOver18YearsOld = (birthDate) => {
  // Parse the birth date as a Date object
  const birthDateObj = new Date(birthDate);

  // Calculate the current date
  const currentDate = new Date();

  // Calculate the age difference
  const ageDiff = currentDate - birthDateObj;

  // Calculate the age in years
  const ageInYears = ageDiff / (1000 * 60 * 60 * 24 * 365.25); // Approximate years

  // Check if the age is 18 or more
  return ageInYears >= 18;
}

export const isBirthDate = (value) => {
  const birthDate = new Date(value); // Replace with the birth date to validate
  if (value) {
    if (isOver18YearsOld(birthDate)) {
      // console.log('The person is over 18 years old.');
    } else {
      // console.log('Must be 18 years old or older.');
      return 'Must be 18 years old or older.';
    }
  }
}
// export const isValidWebsiteURL = (url) => {
//   const urlPattern = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/;
//   if (!url) {
//     return undefined; // No error if URL is empty or null
//   }
//   if (url.match(urlPattern)) {
//     return undefined; // Valid URL
//   } else {
//     return 'Please enter a valid website URL';
//   }
// };
export const isValidWebsiteURL = (url) => {
  const urlPattern = /^((https?|ftp|smtp):\/\/)?(www\.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
  if (!url) {
    return undefined;
  }
  if (url.match(urlPattern)) {
    return undefined;
  } else {
    return 'Please enter a valid website URL';
  }
};

export const endWithTitleDot = value => {
  // Check if the last character in the string is a dot
  const endsWithDot = value?.endsWith('.');

  if (!endsWithDot) {
    return 'Title must end with a dot';
  }

  return undefined;
};
export const NonZero = value => {
  if (!value) {
    return 'Value is required';
  }

  const isInteger = /^\d+$/.test(value); // Check if it's a positive integer
  const isNotZero = parseInt(value, 10) !== 0; // Check if it's not equal to 0

  if (!isInteger || !isNotZero) {
    return 'Value cannot be equal to 0';
  }

  return undefined;
};

export const validateOnlineMeetingUrl = url => {
  const urlPattern = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/;
  if (!url) {
    return undefined;
  }
  if (url.match(urlPattern)) {
    return undefined;
  } else {
    return 'Please enter a valid online event URL';
  }
};

export const noWhitespaces = value => {
  if (value && /\s/.test(value)) {
    return 'Value should not contain any whitespaces';
  }
  return undefined;
};

export const duplicateOption = (value, allValues, props, name) => {
  const index = name.split('[')[1].split(']')[0];
  const currentOption = value.trim().toLowerCase();
  if (Array.isArray(allValues.customQuestion)) {
    const currentQuestionIndex = index;
    // If the current question is found, check for duplicates within that question
    if (currentQuestionIndex !== -1) {
      const currentQuestion = allValues.customQuestion[currentQuestionIndex];
      const isDuplicate = currentQuestion?.options?.filter(
        (option, index) => option?.trim()?.toLowerCase() === currentOption
      ).length > 1;
      return isDuplicate ? 'Duplicate option found' : undefined;
    }
  }
  return undefined;
};

export const validEmailList = value => {
  if (!value) {
    return undefined;
  }
  const emailArray = value.split(',');
  const uniqueEmails = new Set(); //This Set to store unique email addresses

  // Check each email in the array for validity and uniqueness
  for (const email of emailArray) {
    const trimmedEmail = email.trim();
    if (!/^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(trimmedEmail)) {
      return 'Invalid email address';
    }
    if (uniqueEmails.has(trimmedEmail)) {
      return 'Duplicate email address';
    }
    uniqueEmails.add(trimmedEmail);
  }

  return undefined;
};
export const notEmail = value => {
  if (value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
    return 'Value cannot be an email address';
  }
  return undefined;
};
export const numericLessThanEqual240 = (value) => {
  if (!value) {
    return undefined;
  }

  const numericValue = parseFloat(value); // Convert value to a float or integer
  if (isNaN(numericValue) || numericValue > 240) {
    return "Value must be less than or equal to 240";
  }
  return undefined;
};

export const validateEmptySpaces = (value) => {
  if (!value || value.trim() === '') {
    return 'This field is required';
  }

  // Check for multiple spaces
  if (/\s{2,}/.test(value)) {
    return 'This field contains multiple spaces';
  }

  return undefined;
};

export const containsEmoji = (value) => {
  if (!value) {
    return undefined;
  }

  // const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
  const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}\u{2934}-\u{2935}\u{25AA}-\u{25AB}\u{2B06}\u{2194}-\u{21AA}]/u
  if (emojiRegex.test(value)) {
    return 'Value cannot contain emojis';
  }

  return undefined;
};
export const pocNameRequired = value => {
  return value ? undefined : 'Name is required, only alphbets.';
};
export const pocRequired = value => {
  return (value || value === 0) ? undefined : 'Please select a POC category.';
};
export const pocPhoneRequired = value => {
  return value ? undefined : 'Please enter a valid number.';
};
export const pocEmailRequired = value => {
  return value ? undefined : 'Please enter a valid email.';
};

