import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Alert, Box, Slide, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/slices/MessageSlice";
import theme from "../../assets/material-ui/theme";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

// const Alert = React.forwardRef(function Alert(props, ref) {
//     return <MuiAlert ref={ref}  {...props} />;
//   });

export default function CustomSnackbar(props) {
  // const { snackbarProps, setSnackbarProps } = props;
  const snackbarProps = useSelector((state) => state.message);
  const dispatch = useDispatch();

  // snackbarProps = {type: "error" | "success", flag: <Boolean>, message: ""}

  const [state, setState] = React.useState({
    vertical: "bottom",
    horizontal: "left",
  });

  const { vertical, horizontal } = state;

  React.useEffect(() => {

    const timer = setTimeout(() => {
      if (snackbarProps?.flag == true) {
        handleClose()
      }
    }, 4000)
    return () => clearTimeout(timer)
  }, [snackbarProps?.flag])

  const handleClose = (event, reason) => {
    // setSnackbarProps({});
    dispatch(setSnackbar({ flag: false }));
    // return;
    // if (reason === 'clickaway'){
    //     return;
    // }
    // setState({ ...state, open: false });
  };

  return (
    <Box textAlign='center'>
      {/* <Button
        variant='outlined'
        onClick={handleClick}>
        Test Snackbar
      </Button> */}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackbarProps?.flag}
        onClose={handleClose}
        key={snackbarProps.message}
        autoHideDuration={8000}
        TransitionComponent={Slide}
        // message={snackbarProps?.flag == true && snackbarProps?.message}
        sx={{
          minHeight: { xs: "calc(6vh + 10rem)", sm: "calc(2vh + 2rem)" },
          // textAlign:'center',
          // display:'flex',
          // alignItems:'center',
          "@media (max-width: 600px)": {
            right: "unset",
            left: "12px",
          },
          "@media (min-width: 601px)": {
            right: "130px",
          },
          "@media (min-width: 961px)": {
            right: "160px",
          },

          "& .MuiSnackbarContent-root": {
            // backgroundColor: '#08231B',
            // color: snackbarProps?.type === "error" ? "#FF0045" : "#00D744",
            // boxShadow: "none",
            // width: { xs: "90vw", sm: "70vw", md: "60vw",lg:'60vw', xl: "50vw" },
            // border:'1px solid #707070',
            // fontFamily:'Rubik',
            // fontSize:{xs:'9px',md:'10px',lg:'11px',xl:'13px'},
            // textAlign:'center'
          },


        }}

        // action={
        //   <IconButton
        //     size="small"
        //     aria-label="close"
        //     color="inherit"
        //     onClick={handleClose}
        //   >
        //     <CloseIcon fontSize="small" />
        //   </IconButton>
        // }
      // sx={{
      //   position: 'fixed',
      //   top: '100px', // Adjust the top position as desired
      //   right: '150px', // Adjust the right position as desired
      // }}
      >
        <Alert
          onClose={handleClose}
          // iconMapping={{
          //   success: <CheckCircleOutlineRoundedIcon fontSize="inherit" />,
          //   error: <WarningAmberRoundedIcon fontSize="inherit" />
          // }}
          // icon={snackbarProps.type == "error" ? <WarningAmberRoundedIcon sx={{ textAlign: 'center', color: theme.palette.error.contrastText }} /> : <CheckCircleOutlineRoundedIcon sx={{ textAlign: 'center', color: theme.palette.success.main }} />}
          severity={snackbarProps.type}
          variant="filled"
          sx={{
            // backgroundColor: snackbarProps?.type == "error" ? theme.palette.error.main : theme.palette.tertiary.light,
            // color: snackbarProps?.type == "error" ? theme.palette.error.contrastText : theme.palette.success.main,
            // width: { xs: "90vw", sm: "70vw", md: "60vw",lg:'60vw', xl: "50vw" }, 
            minWidth: '300px',
            textAlign: 'center',
            '& .MuiAlert-message': { textAlign: "center", width: 'inherit', fontSize: { xs: '12px', md: '13px', lg: '14px', xl: '15px' }, fontWeight: 400 },
            minHeight: '45px',
            display: 'flex',
            alignItems: 'center',
    
            height: { md: '45px', lg: '45px', xl: '50px' }
          }}
        >
          {snackbarProps?.message && snackbarProps?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}