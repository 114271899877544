import { Box, Button, Grid, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';
import { useSelector } from 'react-redux';
import GridViewIcon from '../../../../../assets/icons/gridView.svg';
import ListViewIcon from '../../../../../assets/icons/listView.svg';
import DashboardViewIcon from '../../../../../assets/icons/dashboardView.svg';
import { useDispatch } from 'react-redux';
import { getMyEventsList } from '../../Events/actions';

const CustomTab = (props) => (
    props.tooltip ? <Tooltip title={props.title} placement="top" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -10], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }} arrow>
        <Tab
            {...props}
            classes={{
                root: SocialMediaCSS.customTab,
                selected: SocialMediaCSS.customTabSelected,
                wrapper: SocialMediaCSS.customTabWrapper,
            }}
            label={viewBtns(props.iconName)}
            disableRipple
        />
    </Tooltip> : <Tab
        {...props}
        classes={{
            root: SocialMediaCSS.customTab,
            selected: SocialMediaCSS.customTabSelected,
            wrapper: SocialMediaCSS.customTabWrapper,
        }}
        label={viewBtns(props.iconName)}
        disableRipple
    />
);

const viewBtns = (iconName) => {
    return (
        <Box className={SocialMediaCSS.iconContainer}>

            <img src={iconName} alt='Icon' className={SocialMediaCSS.h24} />
        </Box>
    )
};

const viewTabSelProp = (index) => {
    return {
        id: `view-tab-${index}`,
        'aria-controls': `view-tabpanel-${index}`,
    };
};

const BengateMainHeader = ({ value, handleChange, offset,isIndividual }) => {
    const { master, socialMedia: { eventSortBy, eventCategory }, form } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [sortAnchorEl, setSortAnchorEl] = useState(null); // State for SortByPopover anchor element
    const [eventAnchorEl, setEventAnchorEl] = useState(null);
  
    const handleSortClose = () => {
        setSortAnchorEl(null);
    };

    const handleEventClose = () => {
        setEventAnchorEl(null);
    };

    const handleSelectEventOption = (option) => {
        console.log('Selected sort option:', option);
        dispatch(getMyEventsList(offset.current, false, '', option));
        handleEventClose();
    };
    return (
        <>
            <Box className={SocialMediaCSS.eventMainHeader}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Typography className={SocialMediaCSS.Bengge}>b-Engage</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={SocialMediaCSS.eventViewContainer}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="Event Listing View Tabs"
                                TabIndicatorProps={{ style: { display: 'none' } }}
                            >
                                <CustomTab iconName={GridViewIcon} {...viewTabSelProp(0)} tooltip={true} title={'Grid View'} />
                                <CustomTab iconName={ListViewIcon} {...viewTabSelProp(1)} tooltip={true} title={'List View'} />
                                {!isIndividual &&<CustomTab iconName={DashboardViewIcon} {...viewTabSelProp(2)} />}
                            </Tabs>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

        </>
    )
}

export default BengateMainHeader;
