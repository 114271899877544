import React, { useState } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import theme from '../../assets/material-ui/theme';
import info from '../../assets/icons/Info.svg';
import { TextField, Tooltip } from '@mui/material';

export default function RenderTooltipInput({
    input,
    label,
    placeholder,
    type,
    fullWidth,
    maxLength,
    underlineStyle,
    onKeyPress,
    errorStyle,
    style,
    toolTiptext,
    id,
    pattern,
    defaultValue,
    disabled,
    min,
    max,
    value,
    meta: { touched, error, warning },
    inputProps,
    errorMessages,
    custom,
    placeholderFontSize,
    customHeight,
    errorWidth,
}) {
    let inputType = type;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };
    return (
        <>
            <FormControl sx={{ width: '100%' }} variant="outlined">
                <OutlinedInput
                    inputProps={{
                        maxLength,
                        disabled,
                        min: 0
                    }}
                    className="TextField"
                    sx={(theme) => {
                        return {
                            // border: 1.5,
                            // ...placeholderStyle,
                            height: customHeight ? customHeight : 50,
                            borderRadius: 40,
                            backgroundColor: theme.palette.secondary.dark,
                            //  '&::placeholder': { fontSize: placeholderFontSize }
                            "& input::placeholder": { fontSize: placeholderFontSize },
                            '& .MuiOutlinedInput-input': {
                                fontSize: placeholderFontSize, // Set placeholder font size
                            },
                            // borderColor: theme.palette.secondary.main,
                            // color: "#333333",
                            // '& .MuiInputLabel-root': {
                            //   color: theme.palette.secondary.dark,
                            // },
                            // '& .MuiInputBase-root': {
                            //   color: theme.palette.secondary.dark,
                            // },
                        }
                    }}
                    placeholder={placeholder}

                    id={id}
                    disabled={disabled}
                    autoComplete="off"
                    pattern={pattern}
                    type={inputType}
                    onKeyPress={onKeyPress}
                    fullWidth={fullWidth}
                    value={defaultValue}
                    // value={input.value || defaultValue || ''} 
                    onChange={input.onChange}
                    min={min}
                    max={max}
                    style={style}

                    {...input}
                    {...custom}

                    endAdornment={
                        <InputAdornment position="end" onClick={handleTooltipOpen}>
                            <Tooltip
                                open={tooltipOpen}
                                onClose={handleTooltipClose}
                                title={toolTiptext}
                                arrow
                            >
                                <img src={info} alt="info" style={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </InputAdornment>
                    }
                />
            </FormControl>
            {touched && error && <div style={{ color: 'red', fontSize: '12px', padding: 0, paddingLeft: '10px', wordBreak: 'break-word', lineHeight: '14px', maxWidth: errorWidth ? errorWidth : '400px' }}>{error}</div>}
        </>
    );
}
