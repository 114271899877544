import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import slugify from 'slugify';
import { moveToSocialMedia } from '../../../common/utils';
import { useDispatch } from 'react-redux';
import { setIsLoad } from '../../../store/slices/MasterSlice';
import PageLoader from '../../../common/components/PageLoader';
import { useSelector } from 'react-redux';
import { doLogin } from '../../Registration/actions';
import CustomSnackbar from '../../../common/components/CommonSnackbar';
import { Box } from '@mui/material';

const RedirectBEngage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const master = useSelector(state => state.master);
  const snackbarProps = useSelector((state) => state.message);

  const email = params?.email;
  const pass = params?.pass;
  const title = params?.title;
  const gid = params?.gid;
  // Create slug from title
  const slug = title && slugify(title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
  const slugUrl = `${slug}-${gid}`;
  const shareUrl = `/volunteer/${slugUrl}`;

  useEffect(() => {
    dispatch(setIsLoad(true));
    if (email && pass && title && gid && shareUrl) {
      const values = {
        email,
        password: pass,
      }
      const isBEngage = true;
      dispatch(doLogin(values, navigate, '', false, isBEngage, shareUrl));
    } else {
      moveToSocialMedia();
    }
  }, [email, pass, title, gid])

  return (
    <>
      {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
      <PageLoader open={master.isLoad} />
    </>
  )
}

export default RedirectBEngage