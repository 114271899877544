import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import theme from '../../../../assets/material-ui/theme';
import CommonButton2 from '../../../../common/components/CommonButton2';
import ReduxForm from '../../../../common/components/ReduxForm';
import * as clientValidations from '../../../../common/validations';
import { useSelector } from 'react-redux';
import CommonImageUploader from '../../../../common/components/CommonImageUploader';
import { UPLOAD_DATA, UPLOAD_FORMAT } from '../../../../common/constants';
import { useDispatch } from 'react-redux';
import SocialMediaCss from '../../SocialMediaCSS/SocialMedia.module.css';
import { submit } from 'redux-form';
import { uploadProfile } from '../../../Profile/actions';
import { base64ToFile } from '../../../../common/utils';

const UploadImagePopup = ({ open, handleClose, handleConfirm, message, title, dispatch }) => {
    const { users: { userData, isUploaded } } = useSelector((state) => state);
    const [showError, setShowError] = useState(false);
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    const handleSubmit = () => {
        if(isUploaded){
            let file = base64ToFile(isUploaded, 'profile_pic', true);
            dispatch(uploadProfile(file, 'profile_pic', true));
            handleClose();
        } else{
            setShowError(true);
        }
        // dispatch(submit('ProfilePicForm'))
    }
    useEffect(() => {
      if(isUploaded){
        setShowError(false);
      }
    }, [isUploaded])
    
    return (
        <ReduxForm
            // onSubmit={(values) => dispatch(submitAboutUS(values, true, onClose))}
            // form="ProfilePicForm"
            // label="profilePic"
            // submitButton="Save"
            initialValues={{ ...userData }}
        >
            <Dialog open={open} PaperProps={{ sx: { minWidth: isXsScreen ? '600px' : '400px', minHeight: '355px', borderRadius: '16px', padding: '16px', display: 'inline-flex', flexDirection: 'column', maxHeight: '320px' } }}>
                <DialogTitle sx={{ color: theme.palette.success.dark, textAlign: 'center', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', alignItems: 'center', }}>
                        <div>{title}</div>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ paddingTop: '30px !important', p: 0, fontSize: '16px', color: theme.palette.success.light, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    {showError && <Typography className={SocialMediaCss.profileUploaderError}>!! Please upload your profile image !!</Typography>}
                    <CommonImageUploader
                        name="profile_pic"
                        label="Upload an image"
                        uploadFormat={UPLOAD_FORMAT.LOGO}
                        uploadInfo={UPLOAD_DATA.LOGO}
                        sizeLimit={2048}
                        validate={[clientValidations.maxSize2048, clientValidations.required]}
                        loadImage={{ image: userData?.profile_pic, name: "company_logo" }}
                        className={SocialMediaCss.profileUploader}
                        initials={userData?.initials}
                        colorCode={userData?.color_code}
                        showEdit={false}
                        showUpload={'true'}
                        style={{ minHeight: '180px', minWidth: '180px' }}
                    />
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', p: 0, pt: '32px' }}>
                    <Typography sx={{ color: theme.palette.primary.dark, fontFamily: 'Poppins', fontSize: '16px', fontStyle: 'normal', fontWeight: '500', lineHeight: '16px', textDecorationLine: 'underline', cursor: 'pointer' }} onClick={handleClose}>Skip</Typography>
                    <CommonButton2
                        label="Done"
                        name="Done"
                        type="Done"
                        color="primary"
                        variant='contained'
                        style={{ padding: '4px 24px', fontSize: '14px', backgroundColor: theme.palette.primary.dark, borderRadius: '20px' }}
                        onClick={handleSubmit}
                    />
                </DialogActions>
            </Dialog>
        </ReduxForm>
    );
}

export default UploadImagePopup;