import React, { useEffect, useState } from "react";
import { Box, Dialog, Typography, useMediaQuery } from "@mui/material";
import SocialMediaCSS from "../../../SocialMediaCSS/SocialMedia.module.css";
import cancel from "../../../../../assets/icons/Cancel.svg"
import { useDispatch } from "react-redux";
import { resetDraftAndActivity, resetPostTags, resetSelectedPostAudience, setCreatePost, setSelectedActivity, setSelectedThematic, setShowActivity, setUploadedFiles } from "../../../../../store/slices/SocialMediaSlice";
import arrow from "../../../../../assets/icons/arrow-back.svg";
import { reset } from "redux-form";
import { setPostActivityEdit } from "../../../../../store/slices/SocialMediaSlice";
import theme from "../../../../../assets/material-ui/theme";
import DiscardAlert from "../DiscardAlert";


const ReshareActivity = ({ socialMedia, form, onActivitySelect, master, open, onClose, post ,setSelectedTag}) => {
    const dispatch = useDispatch();
    const [postDiscardOpen, setPostDiscardOpen] = useState(false);

    const handleDiscardDialogClose = () => {
        setPostDiscardOpen(false);
    };
    
    const handleDiscardDialogOpen = () => {
        setPostDiscardOpen(true);
    };

    const handleBackClick = () => {
        dispatch(setPostActivityEdit(false))
    }
    const handleCreateCancel = () => {
        dispatch(setPostActivityEdit(false))
        onClose();
        setSelectedTag('');
        dispatch(resetPostTags());
        dispatch(reset('postReshareform'));
        setSelectedTag("");
        dispatch(resetDraftAndActivity());
        dispatch(resetSelectedPostAudience());
        
    };
    const handleCardClick = (title) => {
        console.log(`Clicked on: ${title}`);
        onActivitySelect(title);
        // dispatch(setSelectedActivity(title));
        dispatch(setPostActivityEdit(false))
    }
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }
    // useEffect(() => {
    //     if (post?.primary_post?.activity?.key) {
    //         dispatch(setSelectedActivity(post?.primary_post?.activity?.key));
    //     }
    // }, [post?.primary_post?.activity?.key]);
    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleDlgClose}
                aria-labelledby="Edit Post"
                PaperProps={{ classes: { root: SocialMediaCSS.shareToPopup } }}
            >
                <Box className={SocialMediaCSS.postCard}>
                    <Box className={SocialMediaCSS.activityHeader}>
                        <Box className={`${SocialMediaCSS.postGap}`}>
                            <img src={arrow} onClick={handleBackClick} />
                            <Typography className={SocialMediaCSS.selectActivity} >
                                Select Activity
                            </Typography>
                        </Box>
                        <Box className={SocialMediaCSS.cancelIcon} onClick={() => handleDiscardDialogOpen()}>
                            <img src={cancel} className={SocialMediaCSS.createPost} />
                        </Box>
                    </Box>
                    <Box className={SocialMediaCSS.cardAlign}>
                        {master?.postActivity?.map((data, index) => (
                            <Box className={SocialMediaCSS.activityCard} key={index} onClick={() => handleCardClick(data?.title, data?.id)}>
                                <img src={data?.image} className={SocialMediaCSS.imgActivity} />
                                <Typography className={SocialMediaCSS.postTypo}>{data?.title}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Dialog>
            {postDiscardOpen &&
                <DiscardAlert
                    open={postDiscardOpen}
                    onClose={handleDiscardDialogClose}
                    handleCancel={handleCreateCancel}
                    title="Discard draft"
                    Content1="Your post isn't finished yet. Are you sure you want to exit and discard your draft?"
                    ButtonText2="Discard"
                    ButtonText1="Continue"
                />}
        </>
    )
}

export default ReshareActivity;