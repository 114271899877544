import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import CreateEditPopup from "./CreateEditPopup";
import PostActivityEdit from "./PostActivityEdit";
import PostAudienceEdit from "./PostAudienceEdit";
import PostCategoryEdit from "./PostCategoryEdit";
import { useDispatch } from "react-redux";
import PostTagPeopleEdit from "./PostTagPeopleEdit";
import { capitalizeFirstLetter } from "../../../../../common/utils";


const PostCreateProcess = ({ open, onClose, Title, post, usersImg, isEvent, eventGid, eventThematic }) => {
    const { socialMedia, form, master, users } = useSelector((state) => { return state; });
    const dispatch = useDispatch();
    const postTypeKey = post?.primary_post?.post_type_name || 'public';
    const postType = master?.postTypes?.find(type => type?.key === postTypeKey);
    const postTypeValue = postType?.value;

    const [selectedActivity, setSelectedActivity] = useState(capitalizeFirstLetter(post?.primary_post?.activity?.key));
    const [selectedTag, setSelectedTag] = useState('');
    const [unSelectedTag, setUnSelectedTag] = useState('');
    const [selectedPostTitle, setSelectedPostTitle] = useState(postTypeValue);
    const [postCategory, setPostCategory] = useState(post?.primary_post?.post_thematic_area_ids);
    const Activity = socialMedia.postActivityEdit;
    const tagPost = socialMedia.postTagPeopleEdit;
    const postAudience = socialMedia.postAudienceEdit;
    const showPostThematic = socialMedia.postCategoryEdit;
    // console.log("red",postType)
    const handleActivitySelect = (title) => {
        console.log(`Selected activity: ${title}`);
        setSelectedActivity(title);
    };
    const handleTagSelect = (tag) => {
        console.log(`Selected tags are: ${tag}`);
        setSelectedTag(tag);
    };
    const handlePostSelect = (post) => {
        console.log(`Selected Posts are: ${post}`);
        setSelectedPostTitle(post);
    }
    const handleTagUnselect = (tag) => {
        console.log(`Unselected tag: ${tag}`);
        setUnSelectedTag(tag);
    };
    return (
        <>
            {postAudience === false && showPostThematic === false && Activity === false && tagPost === false &&
                <Grid item xs={12}>
                    <CreateEditPopup opens={open} onClose={onClose} selectedActivity={selectedActivity} selectedPostTitle={selectedPostTitle} setSelectedPostTitle={setSelectedPostTitle} setSelectedActivity1={setSelectedActivity} selectedTag={selectedTag} unSelectedTag={unSelectedTag} Title={Title} post={post} socialMedia={socialMedia} master={master} usersImg={users} dispatch={dispatch} form={form} isEvent={isEvent} eventGid={eventGid} eventThematic={eventThematic} />
                </Grid>}
            {postAudience === true &&
                < Grid item xs={12}>
                    <PostAudienceEdit onPostSelect={handlePostSelect} setSelectedPostTitle={setSelectedPostTitle} selectedPostTitle={selectedPostTitle} open={open} onClose={onClose} Title={Title} post={post} socialMedia={socialMedia} master={master} dispatch={dispatch} />
                </Grid >}
            {Activity === true &&
                <Grid item xs={12}>
                    <PostActivityEdit onActivitySelect={handleActivitySelect} socialMedia={socialMedia} master={master} open={open} onClose={onClose} Title={Title} post={post} />
                </Grid>}
            {tagPost === true &&
                <Grid item xs={12}>
                    <PostTagPeopleEdit onTagSelect={handleTagSelect} onTagUnselect={handleTagUnselect} socialMedia={socialMedia} master={master} opens={open} onClose={onClose} Title={Title} post={post}  form={form}/>
                </Grid>}
            {showPostThematic === true &&
                <Grid item xs={12}>
                    <PostCategoryEdit socialMedia={socialMedia} master={master} open={open} onClose={onClose} Title={Title} post={post} />
                </Grid>}
        </>
    );
}
export default PostCreateProcess;
