import { Box, IconButton, Typography, Popover, Tooltip, tooltipClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PostTime from '../../../../assets/icons/clock_Solid.svg';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import PublicIcon from '../../../../assets/icons/globe_lightGrey.svg';
import PostMoreItem from './PostMoreItem';
import EditIcon from '../../../../assets/icons/edit_lightGrey.svg';
import DeleteIcon from '../../../../assets/icons/delete_lightGrey.svg';
import FlagIcon from '../../../../assets/icons/flag_lightGrey.svg';
import FollowIcon from '../../../../assets/icons/follow_lightGrey.svg';
import UnfollowIcon from '../../../../assets/icons/unfollow_lightGrey.svg';
import NotificationOnIcon from '../../../../assets/icons/notificationOn_lightGrey.svg';
import NotificationOffIcon from '../../../../assets/icons/notificationOff_lightGrey.svg';
import ShareToIcon from '../../../../assets/icons/shareTo_lightGrey.svg';
import BookmarkAddIcon from '../../../../assets/icons/bookmark_add_lightGrey.svg';
import BookmarkRemoveIcon from '../../../../assets/icons/bookmark_remove_lightGrey.svg';
import { ShareOtherPopup } from '../Popup/ShareOtherPopup';
import ReportDrawer from '../Drawer/ReportDrawer';
import { capitalizeFirstLetter, timeAgo } from '../../../../common/utils';
import CommonInitials from '../../../../common/components/CommonInitials';
import { Follow, allowNotification, deletePost, postBookMark, postRemoveBookMark, removeNotification, unfollow } from '../../actions';
import { useDispatch } from 'react-redux';
import share from "../../../../assets/icons/share_lightGrey.svg"
import PostEditProcess from "../../../SocialMedia/Components/Posts/PostEditPopup/PostEditProcess";
import { useNavigate } from 'react-router';
import slugify from 'slugify';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.mainPalette.grey.white,
        color: theme.mainPalette.grey.lightText,
        boxShadow: theme.shadow.softShadow3,
        padding: '8px'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.mainPalette.grey.white,
    },
}));

const PostHeader = ({ post, users, master, socialMedia }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const formattedTime = timeAgo(post?.posted_time);
    const [formattedTime, setFormattedTime] = useState(timeAgo(post?.created_at));
    const [anchorEl, setAnchorEl] = useState(null);
    const [shareToDialogOpen, setShareToDialogOpen] = useState(false);
    const [editPostOpen, setEditPostOpen] = useState(false);
    const [reportDraweropen, setReportDraweropen] = useState(false);
    const [drawerGid, setDrawerGid] = useState(false);
    const [postGid, setPostGid] = useState(false);
    const [postEditActivity, setPostEditActivity] = useState(false);
    const [postEditAudience, setPostEditAudience] = useState(false);
    const [postEditBody, setPostEditBody] = useState(false);
    const [postEditThematic, setPostEditThematic] = useState(false);
    const is_Share = post?.shared_module?.name === null ? false : true;
    //console.log(post?.deleted_content)
    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setFormattedTime(timeAgo(post?.created_at));
    //     }, 60000);
    //     return () => { clearInterval(intervalId); };
    // }, [post?.created_at]);
    useEffect(() => {
        const updateFormattedTime = () => {
            setFormattedTime(timeAgo(post?.created_at));
        };

        updateFormattedTime(); // Update the time immediately when the component mounts

        const intervalId = setInterval(updateFormattedTime, 60000);

        return () => { clearInterval(intervalId); };
    }, [post?.created_at])

    const handleReportDrawerOpen = (gid) => {
        setReportDraweropen(true);
        setDrawerGid(gid);
        handlePopoverClose();
    };

    const handleReportDrawerClose = () => {
        setReportDraweropen(false);
    };

    const handlePopoverOpen = (event) => {
        event.stopPropagation();
        if (users?.isLoggedIn) {
            setAnchorEl(event.currentTarget);
        } else {
            navigate('/login');
        }
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleShareToDialogOpen = () => {
        console.log("opening");
        setShareToDialogOpen(true);
    };

    const handleShareToDialogClose = () => {
        setShareToDialogOpen(false);
    };

    const handleDeleteClick = (gid) => {
        dispatch(deletePost(gid))
    };

    const handleFollowClick = (gid) => {
        dispatch(Follow(gid))
    };

    const handleUnFollowClick = (gid) => {
        dispatch(unfollow(gid))
    };

    const handleBookMarkClick = (gid) => {
        dispatch(postBookMark(gid))
    };

    const handleRemoveBookMarkClick = (gid) => {
        dispatch(postRemoveBookMark(gid))
    };

    const handleAllowNotificationClick = (gid) => {
        // dispatch(allowNotification(gid))
        dispatch(removeNotification(gid))
    };

    const handleRemoveNotificationClick = (gid) => {
        // dispatch(removeNotification(gid))
        dispatch(allowNotification(gid))
    };
    const handleEditDialogOpen = () => {
        // setPostEditActivity()
        // setPostEditBody(body)
        // setPostEditAudience()
        // setPostEditThematic()
        setEditPostOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditPostOpen(false);
    };
    const open = Boolean(anchorEl);
    const postMorePopover = open ? 'simple-popover' : undefined;
    const post_gid = post?.primary_post?.post_user_details?.gid;
    const user_detail = users?.userData?.gid;
    const post_Owner = post_gid === user_detail ? true : false;


    const postTypeName = post?.primary_post?.post_type_name;
    const postTypeObj = master?.postTypes?.find(type => type.key === postTypeName);
    const postTypeImage = postTypeObj ? postTypeObj.image : '';


    const postValueObj = master?.postTypes?.find(type => type.key === postTypeName);
    const postTypeTitle = postValueObj ? postValueObj.value : '';

    const postActivityName = post?.primary_post?.activity?.key;
    const postActivityObj = master?.postActivity?.find(type => type.key === postActivityName);
    const postActivityImage = postActivityObj ? postActivityObj.image : '';

    const postActivityNameObj = master?.postActivity?.find(type => type.key === postActivityName);
    const postActivityTitle = postActivityNameObj ? postActivityNameObj.title : '';
    //console.log("hello", post)
    const handleUserProfile = (e) => {
        e.stopPropagation();
        if (users?.isLoggedIn) {
            navigate(`/profile/${post?.primary_post?.post_user_details?.gid}`);
        } else {
            navigate('/login');
        }
    }
    const postReshare =
        !post?.shared_module?.event_gid &&
        !post?.shared_module?.conferenec_gid &&
        !post?.shared_module?.rfp_gid;

   
    const handleConferenceClick = (post, e) => {
        e.stopPropagation();
        if (users?.isLoggedIn) {
            // if (post?.shareable && post?.shared_module?.conferenec_gid) {
            //     const slug = slugify(post?.event_name, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
            //     const slugUrl = `${slug}-${post?.conferenec_gid}`;
            //     navigate(`/volunteer/${slugUrl}`);
            // } else if (post?.shareable && post?.shared_module?.event_gid) {
            //     navigate(`/social-media/events/${post?.shared_module?.event_gid}`)
            // } else if (post?.shared_module?.rfp_title) {
            //     navigate(`/social-media/rfp/${post?.shared_module?.rfp_gid}`)
             if (post?.multiple_shared_post && !(post?.shared_module?.conferenec_gid || post?.shared_module?.event_gid || post?.shared_module?.rfp_gid)) {
                if (post?.post_gid) {
                    navigate(`/social-media/posts/${post?.post_gid}`)
                }
            }
        }
    }
    return (
        <>
            <Box className={`${SocialMediaCSS.postHeader} ${post?.multiple_shared_post && !(post?.shared_module?.conferenec_gid || post?.shared_module?.event_gid || post?.shared_module?.rfp_gid) ? SocialMediaCSS.cursor : ''}`} onClick={handleUserProfile}>
                <Box className={`${SocialMediaCSS.postHeaderLogo} ${SocialMediaCSS.cursor}`} onClick={handleUserProfile}>
                    <CommonInitials
                        name={post?.primary_post?.post_user_details?.initials}
                        bgColor={post?.primary_post?.post_user_details?.colour_code}
                        divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                        typoStyle={{ fontSize: '18px', lineHeight: '44px', }}
                        avatarStyle={{ width: '46px', height: '46px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                        src={post?.primary_post?.post_user_details?.image?.url}
                        companyLogo={post?.primary_post?.post_user_details?.image?.url}
                        avatar={true}
                    />
                </Box>
                <Box className={SocialMediaCSS.postHeaderInfo} onClick={(e) =>handleConferenceClick(post,e)}>
                    <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`}>
                        <Typography variant='body2' className={`${SocialMediaCSS.postHeaderName} ${SocialMediaCSS.cursor}`} onClick={(e)=>handleUserProfile(e)}>
                            {post?.primary_post?.post_user_details?.name}
                        </Typography>&nbsp;
                        {postActivityName &&
                            <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`} gap={"5px"}>-
                                <img src={postActivityImage} alt='Connections' className={SocialMediaCSS.ProfileTypePost} />
                                <Typography variant='body4' className={SocialMediaCSS.postHeaderTagline}>{postActivityTitle}</Typography>
                            </Box>}
                    </Box>
                    <Typography variant='body4' className={SocialMediaCSS.postHeaderTagline} >
                        {post?.primary_post?.post_user_details?.tagline}
                    </Typography>
                    <Box className={SocialMediaCSS.postHeaderOther}>
                        <Box className={SocialMediaCSS.postHeaderTime}>
                            <img src={PostTime} alt='Post Time' />
                            <Typography variant='body4' className={SocialMediaCSS.otherText} >
                                {formattedTime}
                            </Typography>
                        </Box>
                        <Box className={SocialMediaCSS.postHeaderConnCount}>
                            <LightTooltip title={postTypeTitle} placement='top' arrow>
                                <img src={postTypeImage} alt='Connections' className={SocialMediaCSS.ProfileTypePost} />
                            </LightTooltip>

                            <Typography variant='body4' className={SocialMediaCSS.otherText} >
                                {post?.primary_post?.post_user_details?.connections} Connections
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {(is_Share === true || post?.deleted_content === true) && postReshare &&
                    <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ReshareBorder}`} gap={0.5}>
                        <img src={share} />
                        <Typography variant='body4' className={SocialMediaCSS.otherText}>RESHARED</Typography>
                    </Box>}
                <IconButton aria-describedby={postMorePopover} onClick={(event)=>handlePopoverOpen(event)}>
                    <MoreHorizIcon />
                </IconButton>

            </Box >
            <Popover
                id={postMorePopover}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={SocialMediaCSS.popoverPostMore}
            >
                <Box className={SocialMediaCSS.postMoreOptionBox} onClick={handlePopoverClose}>
                    {post_Owner === true &&
                        <Box onClick={() => handleEditDialogOpen(post.body, post.shareable)}>
                            <PostMoreItem icon={EditIcon} iconAlt='Edit Post' title='Edit Post' desc='Modify your Post' />
                        </Box>}
                    {post_Owner !== false &&
                        <Box onClick={() => handleDeleteClick(post.gid)}>
                            <PostMoreItem icon={DeleteIcon} iconAlt='Delete Post' title='Delete Post' desc='Remove from Profile' />
                        </Box>}
                    {post_Owner === false &&
                        <Box onClick={() => handleReportDrawerOpen(post.gid)} >
                            <PostMoreItem icon={FlagIcon} iconAlt='Report Post' title='Report this Post' desc='Seeing something inappropriate' />
                        </Box>}
                    {post?.follow?.is_follow !== true && post_Owner === false &&
                        <Box onClick={() => handleFollowClick(post.gid)} >
                            <PostMoreItem icon={FollowIcon} iconAlt='Follow' title='Follow' desc='See all updated from this user' />
                        </Box>}
                    {post?.follow?.is_follow === true && post_Owner === false &&
                        <Box onClick={() => handleUnFollowClick(post?.primary_post?.post_user_details?.gid)} >
                            <PostMoreItem icon={UnfollowIcon} iconAlt='Unfollow' title='Unfollow' desc='Stop seeing updates but stay connected' />
                        </Box>}
                    {post?.enable_notification?.is_enable !== true && post_Owner === false &&
                        <Box onClick={() => handleAllowNotificationClick(post.gid)}>
                            <PostMoreItem icon={NotificationOnIcon} iconAlt='Notification On' title='Turn on Notification' desc='Turn on notifications for this post' />
                        </Box>}
                    {post?.enable_notification?.is_enable === true && post_Owner === false &&
                        <Box onClick={() => handleRemoveNotificationClick(post?.gid)}>
                            <PostMoreItem icon={NotificationOffIcon} iconAlt='Notification Off' title='Turn off Notification' desc='Turn off notifications for this post' />
                        </Box>}
                    {post?.shareable !== true && post?.deleted_content !== true && post?.multiple_shared_post !== true &&
                        <Box onClick={handleShareToDialogOpen}>
                            <PostMoreItem icon={ShareToIcon} iconAlt='Share To' title='Share to' desc='Share post to other platforms' />
                        </Box>}
                    {post?.bookrmark?.is_bookrmark !== true &&
                        <Box onClick={() => handleBookMarkClick(post.gid)}>
                            <PostMoreItem icon={BookmarkAddIcon} iconAlt='Add Bookmark' title='Bookmark Post' desc='Add post to your bookmarks' />
                        </Box>}
                    {post?.bookrmark?.is_bookrmark === true &&
                        <Box onClick={() => handleRemoveBookMarkClick(post?.bookrmark?.gid)}>
                            <PostMoreItem icon={BookmarkRemoveIcon} iconAlt='Remove Bookmark' title='Remove Bookmark' desc='Remove post from bookmarks' />
                        </Box>
                    }
                </Box>
            </Popover>
            <ShareOtherPopup
                open={shareToDialogOpen}
                onClose={handleShareToDialogClose}
                Title="Share to"
                post={post}
            />
            {/* <CreateEditPopup */}
            <PostEditProcess
                open={editPostOpen}
                onClose={handleEditDialogClose}
                usersImg={users}
                master={master}
                SocialMediaCSS={SocialMediaCSS}
                socialMedia={socialMedia}
                post={post}
            // postEditBody={postEditBody}
            />
            <ReportDrawer open={reportDraweropen} gid={drawerGid} onClose={handleReportDrawerClose} socialMedia={socialMedia} master={master} title="Report Post" desc="Report an abusive or inappropriate post." />
        </>
    )
}

export default PostHeader
