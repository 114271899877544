import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    district: [],
    uploadedData: [],
    eventData: {},
    selectedTab: 'details',
    uniqueId: null,
    volunteers: [],
    allVolunteers: [],
    admins: [
        { id: 1, name: 'Ram', email: 'Ram@cisco.com' },
        { id: 2, name: 'John', email: 'John@cisco.com' },
        { id: 3, name: 'Mohit', email: 'Mohit@Gmail.com' },
        { id: 4, name: 'Vimal', email: 'Vimal@cisco.com' },
        { id: 5, name: 'Kamal', email: 'Kamal@Gmail.com' },
    ],
    secondaryTab: 'published',
    primaryTab: 'event-diary',
    insightData: {},
    eventList: [],
    brandList: [],
    outcomeData: {},
    totalEventCount: 0,
    totalVolunteersCount: 0,
    volunteerList: [],
    tileData: [],
    certificate: [],
    addPopupErrorData: [],
    markAttendance: false,
    isprivate: false,
    volunteersCount: 0,
    downloadCertificate: [],
    assigneeList: [],
    assigneeComments: [],
    leaderboardList: [],
    cancelFeedbacks: {},
    cerificateSkipped: false,
    newPoc: [],
    newPocFlag: false,
    leftFlag: false,
    rightFlag: false,
    eventScheduleDates: [],
    eventScheduleTimes: [],
    selectedEventType: "",
    selectedEventMode: "",
    filterData: [],
    category: null,
    acceptedVolunteer: [],
    ratingList: [],
    eventPublish:"",
    unqId:"",
    event_Approval: "",
    approval_status_code:"",
    certificate_selected:"",
    eventInitialValues :{},
    event_Status_code : "",
    isLoad: false,
    publishMessage:'',
    management_id:"",
    gid:"",
    goTo:"",
}
const eventSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        resetEventSlice: () => initialState,
        addEventDistrict(state, action) {
            state.district = action.payload;
        },
        addEventUploadedData(state, action) {
            state.uploadedData.push(action.payload);
        },
        setGoTo(state, action) {
            state.goTo = action.payload;
        },
        updateEventUploadedData(state, action) {
            state.uploadedData = action.payload;
        },
        addEventData(state, action) {
            state.eventData = action.payload;
        },
        setEventTab(state, action) {
            state.selectedTab = action.payload;
        },
        setUniqueId(state, action) {
            state.gid = action.payload;
        },
        addVolunteers(state, action) {
            state.volunteers.push(action.payload)
        },
        resetVolunteers(state) {
            state.volunteers = initialState.volunteers
        },
        addAllVolunteers(state, action) {
            state.allVolunteers.push(...action.payload)
        },
        updateAllVolunteers(state, action) {
            state.allVolunteers = action.payload
        },
        updateAdmins(state, action) {
            state.admins = action.payload
        },
        setEventPrimaryTab(state, action) {
            state.primaryTab = action.payload;
        },
        setEventSecondaryTab(state, action) {
            state.secondaryTab = action.payload;
        },
        setEventInsightData(state, action) {
            state.insightData = action.payload;
        },
        setEventListData(state, action) {
            state.eventList = action.payload;
        },
        setBrandListData(state, action) {
            state.brandList = action.payload;
        },
        addOutcomeData(state, action) {
            state.outcomeData = action.payload;
        },
        resetOutcomeData(state, action) {
            state.outcomeData = initialState.outcomeData
        },
        setTotalCount(state, action) {
            state.totalEventCount = action.payload;
        },
        setTotalVolunteersCount(state, action) {
            state.totalVolunteersCount = action.payload;
        },
        setVolunteerList(state, action) {
            state.volunteerList = action.payload;
        },
        setMarkAttendance(state, action) {
            state.markAttendance = action.payload;
        },
        setTileData(state, action) {
            state.tileData = action.payload;
        },
        setAddPopupErrorData(state, action) {
            state.addPopupErrorData = action.payload;
        },
        setPrivateEvent(state, action) {
            state.isprivate = action.payload;
        },
        setVolunteersCount(state, action) {
            state.volunteersCount = action.payload;
        },
        setCertificate(state, action) {
            state.certificate = action.payload;
        },
        setDownloadCertificate(state, action) {
            state.downloadCertificate = action.payload;
        },
        setAssigneeList(state, action) {
            state.assigneeList = action.payload;
        },
        setAssigneeComments(state, action) {
            state.assigneeComments = action.payload;
        },
        setLeaderboardList(state, action) {
            state.leaderboardList = action.payload;
        },
        setCancelledFeedbacksList(state, action) {
            state.cancelFeedbacks = action.payload;
        },
        setCerificateSkipped(state, action) {
            state.cerificateSkipped = action.payload;
        },
        addingPOC(state, action) {
            state.newPoc.push(action.payload);
        },
        updatePOC(state, action) {
            state.newPoc = action.payload;
        },
        setNewPocFlag(state, action) {
            state.newPocFlag = action.payload;
        },
        setLeftFlag(state, action) {
            state.leftFlag = action.payload;
        },
        setRightFlag(state, action) {
            state.rightFlag = action.payload;
        },
        setCategory(state, action) {
            state.category = action.payload;
        },
        setAcceptedVolunteerList(state, action) {
            state.acceptedVolunteer = action.payload;
        },
        setFIlterData(state, action) {
            state.filterData = action.payload;
        },
        setEventScheduleDates(state, action) {
            state.eventScheduleDates = action.payload;
        },
        setEventScheduleTime(state, action) {
            state.eventScheduleTimes = action.payload;
        },
        setSelectedEventType(state, action) {
            state.selectedEventType = action.payload;
        },
        setSelectedEventMode(state, action) {
            state.selectedEventMode = action.payload;
        },
        setEventPublish(state,action){
            state.eventPublish = action.payload;
        },
        setUnqId(state,action){
            state.unqId = action.payload;
        },
        setRatingsList(state, action) {
            state.ratingList = action.payload;
        },
        setEvent_Approval(state,action){
            state.event_Approval = action.payload;
        },
        setApproval_status_code(state,action){
            state.approval_status_code = action.payload;
        },
        setCertificate_selected(state,action){
            state.certificate_selected = action.payload;
        },
        resetNewPoc(state) {
            state.newPoc = initialState.newPoc;
        },
        // resetNewPoc(state) {
        //     state.eventData.newPoc = [];
        // },
        setEventInitalValues(state, action){
            state.eventInitialValues = action.payload;
        },
        setEvent_Status_code(state, action){
            state.event_Status_code = action.payload;
        },
        setEventIsLoad(state, action){
            state.isLoad = action.payload;
        },
        setPublishMessage(state, action){
            state.publishMessage = action.payload;
        },
        setManagement_id(state, action){
            state.management_id = action.payload;
        },
    }
})

export default eventSlice.reducer;
export const {
    setEventInitalValues,
    setEventScheduleTime,
    setEventScheduleDates,
    setEventInsightData,
    setEventListData,
    setEventSecondaryTab,
    setEventPrimaryTab,
    resetEventSlice,
    addEventDistrict,
    addEventUploadedData,
    addEventData,
    setEventTab,
    setUniqueId,
    addVolunteers,
    addAllVolunteers,
    resetVolunteers,
    updateAllVolunteers,
    updateAdmins,
    setTileData,
    setBrandListData,
    updateEventUploadedData,
    addOutcomeData,
    resetOutcomeData,
    setTotalCount,
    setTotalVolunteersCount,
    setMarkAttendance,
    setPrivateEvent,
    setVolunteersCount,
    setAddPopupErrorData,
    setCertificate,
    setVolunteerList,
    setDownloadCertificate,
    setAssigneeList,
    setAssigneeComments,
    setLeaderboardList,
    setCancelledFeedbacksList,
    setCerificateSkipped,
    updatePOC,
    addingPOC,
    setNewPocFlag,
    setLeftFlag,
    setRightFlag,
    setSelectedEventType,
    setSelectedEventMode,
    setFIlterData,
    setCategory,
    setAcceptedVolunteerList,
    setEventPublish,
    setUnqId,
    setRatingsList,
    setEvent_Approval,
    setApproval_status_code,
    setCertificate_selected,
    resetNewPoc,
    setEvent_Status_code,
    setEventIsLoad,
    setPublishMessage,
    setManagement_id,
    setGoTo,
} = eventSlice.actions;