import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '../../../../assets/icons/searchThin.svg';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#666',
    width: '100%',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1.5, 1, 1.5),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2.5)})`,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '90ch',
                backgroundColor: alpha(theme.palette.common.white, 1),
                borderRadius: '40px'
            },
        },
    },
}));

const CommonSearchBar = ({query, onChange, onClick, ProfileCss}) => {
  return (
      <Search className={ProfileCss.worksInSearchBox}>
          <SearchIconWrapper>
              <img src={SearchIcon} alt="search" />
          </SearchIconWrapper>
          <StyledInputBase
              placeholder="Search here..."
              inputProps={{ 'aria-label': 'search' }}
              onChange={onChange}
              value={query}
              onClick={onClick}
          />
      </Search>
  )
}

export default CommonSearchBar
