import * as React from 'react';
import { styled, alpha, keyframes } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '../../assets/icons/searchThin.svg';
import { Box } from '@mui/material';

const slideIn = keyframes`
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: 60ch;
    opacity: 1;
  }
`;
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#666',
    width: '60ch',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    transition: 'width 0.3s ease-in-out',
    animation: `${slideIn} 0.3s ease-in-out`,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1.5, 1, 1.5),
        paddingRight: `calc(1em + ${theme.spacing(2.5)})`, // Add padding to the right
        paddingLeft: `calc(1em + ${theme.spacing(0)})`,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '90ch',
                backgroundColor: alpha(theme.palette.common.white, 1),
                borderRadius: '40px'
            },
        },
    },
}));

const HeaderSearch = ({ query, onChange, onClick, searchOpen, handleSearchToggle }) => {
    const searchRef = React.useRef(null);
    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target) && !event.target.closest('.MuiMenuItem-root')) {
                handleSearchToggle();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleSearchToggle]);
    return (
        <Search ref={searchRef} sx={{ display: 'flex', alignItems: 'center', gap: '4px', borderRadius: '40px', position: 'absolute', border: '1px solid #ADB5BD', right: '0px'}}>
            <Box display='flex' alignItems='center'>
                <StyledInputBase
                    placeholder="Search users"
                    inputProps={{ 'aria-label': 'search',maxLength: 80 }}
                    onChange={onChange}
                    value={query}
                    onClick={onClick}
                />
                <SearchIconWrapper onClick={handleSearchToggle}>
                    <img src={SearchIcon} alt="search" style={{ cursor: 'pointer' }} />
                </SearchIconWrapper>
            </Box>
        </Search>
    )
}

export default HeaderSearch;
