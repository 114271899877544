import { Box, Drawer, Typography } from '@mui/material'
import React from 'react';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';
import NewTabIcon from '../../../../../assets/icons/newTab.svg';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Link } from 'react-router-dom';
import EventDetailsPreview from './EventDetailsPreview';


const EventDetailsDrawer = ({ open, onClose, title, desc, gid}) => {
    const handleNewTabClick = (event) => {
        event.preventDefault();
        const url = `/social-media/events/${gid}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <>
            <Drawer
                open={open}
                onClose={onClose}
                anchor='right'
                className={SocialMediaCSS.eventDetailDrawer}
                classes={{
                    paper: SocialMediaCSS.eventDetailDrawerPaper
                }}
            >
                <Box className={SocialMediaCSS.eventDrawerContainer}>
                    <Box className={SocialMediaCSS.eventDrawerHeader}>
                        <Typography variant='h6' className={SocialMediaCSS.eventDrawerPreview}>
                            Event preview
                        </Typography>
                        <Box className={SocialMediaCSS.newTabBox}>
                            <img src={NewTabIcon} alt='New Tab Icon' className={SocialMediaCSS.h20}/>
                            <Link to="/social-media/events/NewTabPreview" onClick={handleNewTabClick} className={SocialMediaCSS.newTabText} >
                                Open in new tab
                            </Link>
                        </Box>
                    </Box>
                    <Box className={SocialMediaCSS.mainEventBody}>
                        <EventDetailsPreview />
                    </Box>
                    
                </Box>
                <Box onClick={onClose} className={SocialMediaCSS.drawerCloseBtn}>
                    <CloseRoundedIcon />
                </Box>
            </Drawer>
        </>
    )
}

export default EventDetailsDrawer
