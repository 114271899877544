import React from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';

const ConnectionSkeleton = () => {
    return (
        <>
            {
                Array.from(new Array(6)).map((_, index) => (
                    <Grid item xs={12} className={SocialMediaCSS.userCard} key={index}>
                        <Box className={SocialMediaCSS.contactTypeSkeleton}>
                            <Box className={SocialMediaCSS.userPadding}>
                                <Skeleton  animation="wave" variant="circular" width={40} height={40} />
                            </Box>
                            <Typography className={SocialMediaCSS.userTypeSkeleton}>
                                <Skeleton  animation="wave" width="120px" variant='rounded' />
                            </Typography>
                        </Box>
                        <Typography className={SocialMediaCSS.usercount}>
                            <Skeleton  animation="wave" width="20px" />
                        </Typography>
                    </Grid>
                ))
            }
        </>
    )
}

export default ConnectionSkeleton
