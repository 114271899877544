import React, { useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import Bvokal from '../../assets/images/BvokalLogo.svg';
import RightHeader from './RightHeader';
import { AppBar, Toolbar } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from "lodash-es";
import CommonButton2 from './CommonButton2';
import { Link, useNavigate } from 'react-router-dom';
import colors from 'react-multi-date-picker/plugins/colors';
import theme from '../../assets/material-ui/theme';
import DialogLogin from '../../modules/Registration/components/DialogLogin';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';

export default function PublicHeader() {
    const dispatch = useDispatch();
    const [openLoginDialog, setOpenLoginDialog] = useState(false);
    const { users: { userData } } = useSelector((state) => {
        return state;
    })
    const handleLogin = () => {
        setOpenLoginDialog(true);
        dispatch(reset('loginForm'));
    }
    const handleCloseDialog = () => {
        setOpenLoginDialog(false);
    };
    return (
        <Box py='1'>
            <AppBar elevation={0} style={{ borderBottom: '1px solid #dee2e6', background: '#FFF', boxShadow: '-1px 0px 14px 0px rgba(0, 0, 0, 0.25)' }} color="default" >
                <Toolbar variant="dense">
                    <Box alignItems="center" padding="9.5px 0px 9.5px 0px" display='flex' justifyContent='space-between' flexGrow={1} >
                        <Box>
                            <a href={`${window.location.origin}/social-media`} style={{ textDecoration: 'none', lineHeight: 0, paddingLeft: "8px" }}>
                                <img src={Bvokal} alt="Logo" style={{ height: '24.297px', Width: '100%' }} />
                            </a>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }} >
                            <CommonButton2
                                label="Login"
                                name="Login"
                                type="button"
                                color="primary"
                                variant='contained'
                                style={{ fontSize: '14px', padding: '4px 32px', backgroundColor: theme.mainPalette.primary.primary110 }}
                                onClick={handleLogin}
                            />
                            <Link to={'/signup'} style={{ color: theme.mainPalette.primary.primary110, fontWeight: 700, fontSize: '14px', margin: '0 32px' }}>
                                Signup
                            </Link>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <DialogLogin
                open={openLoginDialog}
                handleClose={handleCloseDialog}
                registerPage={true}
            />
        </Box>
    );
}

