import React, { useEffect, useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import cancel from "../../../../assets/icons/Cancel.svg"
import { useDispatch } from "react-redux";
import image from "../../../../assets/images/Life.svg";
import { resetPostTags, setCreatePost, setDraftPost, setSelectedActivity, setSelectedTags, setSelectedThematic, setShowPostThematic, setTagPost, setUploadedFiles } from "../../../../store/slices/SocialMediaSlice";
import arrow from "../../../../assets/icons/arrow-back.svg"
import Search from "./PostSubComponents/Search";
import CommonButton2 from "../../../../common/components/CommonButton2";
import CommonInitials from "../../../../common/components/CommonInitials";
import { reset } from "redux-form";
import DiscardPost from "../Popup/DiscardPost";
import { deletePost } from "../../actions";

const TagPost = ({ socialMedia, onTagSelect,onTagUnselect, master,form }) => {
    const dispatch = useDispatch();
    const [selectedItems, setSelectedItems] = useState([]);
    const [unselectedItems, setUnselectedItems] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [allPosts, setAllPosts] = useState([]);
    const [postDiscardOpen, setPostDiscardOpen] = useState(false);
    const draft = socialMedia?.draftPost
    const handleProfileDialogOpen = () => {
        setPostDiscardOpen(true);
    };
    useEffect(() => {
        setUnselectedItems([]);
    }, []);
    const handleCreateCancel = () => {
        dispatch(setTagPost(false))
        dispatch(resetPostTags())
        dispatch(setCreatePost(false))
        dispatch(reset('CreatePostform'))
        dispatch(setUploadedFiles([]));
        dispatch(setSelectedActivity(""));
        dispatch(setSelectedThematic([]));
        handleProfileDialogClose();
        dispatch(deletePost(draft.gid));
        dispatch(setDraftPost([]));
       
    };
    
    const handleProfileDialogClose = () => {
        setPostDiscardOpen(false);
    };
    const handleBackClick = () => {
        dispatch(setTagPost(false))
    }
 
    const handleDoneClick = () => {
        //console.log("ffff", selectedItems)
        const formattedValues = selectedItems.map(item => `@[${item.display}](${item.gid})`);
        const unselectedValues = unselectedItems.map(item => `@[${item.display}](${item.gid})`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (formattedValues.length > 0) {
            onTagSelect(formattedValues);
            dispatch(setSelectedTags(formattedValues));
        }
        if (unselectedValues.length > 0) {
            onTagUnselect(unselectedValues);
        }
        
        dispatch(setTagPost(false))
    }

    const parsedValues = socialMedia.selectedTags.map(value => {
        if (typeof value === 'string') {
            const gid = value?.match(/\(([^)]+)\)/)[1];
            const name = value?.match(/\[(.*?)\]/)[1];
            return { gid, display: name };
        } else {
            console.error("Invalid value type:", value);
            return null;
        }
    });

  
    useEffect(() => {
        if (master?.tagMentions) {
            setAllPosts(master.tagMentions);
        }
    }, [master.tagMentions]);

    useEffect(() => {
        console.log("Selected Items:", selectedItems);
        // onTagSelect(selectedItems.map(item => item.display));
    }, [selectedItems]);

    useEffect(() => {
        setFilteredPosts(allPosts);
    }, [allPosts]);

    useEffect(() => {
        setSelectedItems(socialMedia.selectedTags);
        setSelectedItems(parsedValues);
    }, []);

    const handleTagLineSearch = (search) => {
        setSearchQuery(search);
        const filtered = allPosts.filter(item =>
            item.value.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredPosts(filtered);
    };
    const handleCheckboxChange = (gid, display) => {
        if (selectedItems.some(item => item.gid === gid)) {
            const unselected = selectedItems.find(selectedItem => selectedItem.gid === gid);
            setSelectedItems(selectedItems.filter(selectedItem => selectedItem.gid !== gid));
            setUnselectedItems([...unselectedItems, unselected]);
        } else {
            setSelectedItems([...selectedItems, { gid, display }]);
            setUnselectedItems(unselectedItems.filter(item => item.gid !== gid));
        }
    };

    const handleRowClick = (gid, display) => {
        const isSelected = selectedItems.some(item => item.gid === gid);
        if (isSelected) {
            const unselected = selectedItems.find(item => item.gid === gid);
            setSelectedItems(selectedItems.filter(item => item.gid !== gid));
            setUnselectedItems([...unselectedItems, unselected]);
        } else {
            setSelectedItems([...selectedItems, { gid, display }]);
            setUnselectedItems(unselectedItems.filter(item => item.gid !== gid));
        }
    };
    // const handleCheckboxChange = (gid, display) => {
    //     if (selectedItems.some(item => item.gid === gid)) {
    //         const unselected = selectedItems.find(item => item.gid === gid);
    //         console.log("Unselected:", { gid, display });
    //         setSelectedItems(selectedItems.filter(selectedItem => selectedItem.gid !== gid));
    //     } else {
    //         setSelectedItems([...selectedItems, { gid, display }]);
    //     }
    //     // console.log("Clicked: ", { id: gid, value: display });
    // }

    // const handleRowClick = (gid, display) => {
    //     const isSelected = selectedItems.some(item => item.gid === gid);
    //     if (isSelected) {
    //         setSelectedItems(selectedItems.filter(item => item.gid !== gid));
    //     } else {
    //         setSelectedItems([...selectedItems, { gid, display }]);
    //     }
    //     // console.log("Clicked44: ", { id: gid, value: display });
    //     // dispatch(setSelectedTags(selectedItems));
    // };
    // console.log("real",filteredPosts)
    useEffect(() => {
        const userIdRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
        const userIds = [];
        let match;
        // while ((match = userIdRegex.exec(socialMedia?.draftPost?.body)) !== null) {
            while ((match = userIdRegex.exec(form?.CreatePostform?.values?.post_description)) !== null) {
            userIds.push(match[2]);
        }
        const initialSelectedItems = allPosts
            .filter(item => userIds.includes(item.gid))
            .map(item => ({ gid: item.gid, display: item.value }));
        setSelectedItems(initialSelectedItems);
    }, [allPosts, socialMedia?.draftPost?.body]);
    
    return (
        <>
            <Box className={SocialMediaCSS.postCard}>
                <Box className={SocialMediaCSS.activityHeader}>
                    <Box className={`${SocialMediaCSS.postGap}`}>
                        <img src={arrow} onClick={handleBackClick} />
                        <Typography className={SocialMediaCSS.selectActivity} >
                            Tag People
                        </Typography>
                    </Box>
                    <Box className={SocialMediaCSS.cancelIcon} onClick={() => handleProfileDialogOpen()} >
                        <img src={cancel} className={SocialMediaCSS.createPost} />
                    </Box>
                </Box>
                <Box className={SocialMediaCSS.searchBox}>
                    <Search onSearch={handleTagLineSearch} />
                </Box>
                {filteredPosts.length === 0 && searchQuery && (
                    <Typography>No result found for this search query!!!</Typography>
                )}
                <Box className={`${SocialMediaCSS.scrollable} ${SocialMediaCSS.AudiAlign}`}>
                    {filteredPosts.map((data, index) => (
                        <Box className={SocialMediaCSS.tagCard} key={data?.gid} onClick={() => handleRowClick(data?.gid, data?.value)}>
                            <Box className={`${SocialMediaCSS.tagPostCard}`} mb={1}>
                                <CommonInitials
                                    name={data?.initials}
                                    bgColor={data?.colour_code}
                                    divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '36px', minWidth: '36px', borderRadius: '50%', cursor: 'pointer' }}
                                    typoStyle={{ fontSize: '14px', lineHeight: '36px', }}
                                    avatarStyle={{ width: '36px', height: '36px', boxShadow: " 5px 5px 10px 0px rgba(170, 170, 204, 0.50), -5px -5px 10px 0px #FFF" }}
                                    src={data?.image}
                                    companyLogo={data?.image}
                                    avatar={true}
                                />
                                <Box>
                                    <Typography className={SocialMediaCSS.tagHead}>{data?.value}</Typography>
                                    <Typography className={SocialMediaCSS.tagType}>{data?.text}</Typography>
                                </Box>
                                <Box className={SocialMediaCSS.margin}>
                                    <Checkbox
                                        sx={{
                                            width: '16px', height: '16px',
                                            '& .MuiSvgIcon-root': {
                                                fontSize: '16px',
                                            }
                                        }}
                                        checked={selectedItems.some(item => item?.gid === data?.gid)}
                                        onChange={() => handleCheckboxChange(data?.gid, data?.value)}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className={`${SocialMediaCSS.nextPost} ${SocialMediaCSS.margin}`} >
                    <CommonButton2
                        label="Done"
                        name="Done"
                        variant="contained"
                        type="button"
                        onClick={() => handleDoneClick()}
                        style={{ padding: '6px 24px', fontSize: '14px', height: '32px', fontWeight: '600', background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%', boxShadow: 'none' }}
                    />
                </Box>
            </Box>
            {postDiscardOpen &&
                <DiscardPost
                    open={postDiscardOpen}
                    onClose={handleProfileDialogClose}
                    ButtonText1="Save as Draft"
                    ButtonText2="Discard"
                    title="Save as Draft"
                    Content1="Do you want to save this post as Draft?"
                    Content2="The content of this post will be here whenever you come back."
                    handleDiscard={handleCreateCancel}
                />}
        </>
    )
}

export default TagPost;
