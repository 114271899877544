import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    allUsers:[],
    totalUsersCount:0,
    addUserEmailError:[],
    adminName:"",
};

const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        updateAllUsers(state, action){
            state.allUsers = action.payload
        },
        setTotalUsersCount(state, action){
            state.totalUsersCount = action.payload;
        },
        setAddUserEmailError(state, action){
            state.addUserEmailError = action.payload;
        },
        setAdminName(state, action){
            state.adminName = action.payload;
        },

    }
});
export default userManagementSlice.reducer;
export { initialState };
export const {
    updateAllUsers,
    setTotalUsersCount,
    setAddUserEmailError,
    setAdminName,

} = userManagementSlice.actions;