import React from "react";
import { Field } from "redux-form";
import { RenderProgress } from "./RenderProgress";

const CommonProgress = (props) => {
    return (
        <div className="mt-10">
            <Field
                name={props.name}
                component={RenderProgress}
                val={props.value}
                backgroundColor={props.backgroundColor}
                validate={props.validate}
                disabled={props.disabled}
                min={props.min}
                max={props.max}
            />
        </div>
    );
};

export default CommonProgress;
