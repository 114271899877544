import { Grid } from '@mui/material'
import React from 'react'
import ConnectionSummary from '../LeftSidebar/ConnectionSummary'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import SocialMediaFooter from '../RightSidebar/SocialMediaFooter';
import { useNavigate } from 'react-router';
import CommunitySidecard from '../LeftSidebar/CommunitySidecard';

const RightChatSideBar = ({ isIndividual, showCommunity, selectedCard, handleCardClick }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {socialMedia, users, master} = useSelector((state) => { return state; });

    return (
        <Grid container rowSpacing={2}>
            {showCommunity && <Grid item xs={12}>
                <CommunitySidecard selectedCard={selectedCard} handleCardClick={handleCardClick} isIndividual={isIndividual} users={users} master={master} socialMedia={socialMedia}/>
            </Grid>}
            <Grid item xs={12}>
                {/* <ConnectionSummary socialMedia={socialMedia} users={users} dispatch={dispatch} master={master} /> */}
                <ConnectionSummary 
                    socialMedia={socialMedia} 
                    users={users} 
                    dispatch={dispatch} 
                    master={master} 
                    // form={form} 
                />
            </Grid>
            <Grid item xs={12}>
                <SocialMediaFooter navigate={navigate} />
            </Grid>
        </Grid>
    )
}

export default RightChatSideBar