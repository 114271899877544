import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CommonButton2 from "./CommonButton2";
import { useLocation, useNavigate } from "react-router";

const Security = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path;
    };
    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Grid container xs={12} sx={{ padding: '30px 50px 50px 50px', display: 'flex', justifyContent: 'center' }}>
                <Paper elevation={7} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12} sx={{ padding: '30px 50px 50px 50px' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', }}><b>Refund Policy</b></Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '20px' }}><b>Overview</b></Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '20px', textAlign: 'justify' }}>
                            At bVokal, we strive to provide our users with the best possible experience. To maintain the
                            quality and integrity of our services, we have established a strict no-refund policy for all our
                            products and services. Please read this policy carefully before making a purchase or
                            subscribing to any of our offerings.
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', }}><b>No Refund Policy</b></Typography>
                        <Box sx={{ paddingLeft: '20px' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left',paddingTop:"20px" }}><b>1. No Refunds</b></Typography>
                            <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                                <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '0px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                                All sales are final. Once a payment is made for any product, service,
                                subscription, or event registration on the bVokal platform, it is non-refundable. This includes but is not limited to fees for event tickets,
                                subscriptions, proposal services, and any other products or services offered
                                by bVokal.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', }}><b>2. Service Disruptions</b></Typography>
                            <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                                <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '0px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                                In the event of any technical issues or service disruptions caused by bVokal
                                that significantly impact your ability to use our services, we will work
                                diligently to resolve the issue. However, these occurrences do not entitle
                                users to a refund.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', }}><b>3. Cancellation Policy</b></Typography>
                            <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                                <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '0px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                                You may cancel your subscription or event registration at any time, but no
                                refunds will be provided for any amounts previously paid. Cancellation
                                requests should be directed to our customer support team at
                                info@bvokal.com.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', }}><b>4. Changes to the Policy</b></Typography>
                            <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                                <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '0px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                                bVokal reserves the right to modify this Refund Policy at any time. Any
                                changes will be posted on our website and will take effect immediately upon
                                posting. It is your responsibility to review this policy periodically to ensure
                                you are aware of any updates.
                            </Typography>
                        </Box>

                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left',paddingTop:"20px" }}><b>Contact Information</b></Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '15px', textAlign: 'justify' }}>
                            If you have any questions or concerns regarding this Refund Policy, please contact our
                            customer support team:
                        </Typography>
                        <Box sx={{ paddingLeft: '25px' }}>
                            <Typography sx={{ paddingTop: '20px', textAlign: 'justify', paddingLeft: '40px' }}>
                                <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '0px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                                Email: info@bvokal.com
                            </Typography>
                            <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                                <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '0px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                                Phone: 9999880378
                            </Typography>
                        </Box>
                        <Typography sx={{ fontSize: '16px', paddingTop: '15px', textAlign: 'justify' }}>
                            We are here to assist you and ensure your experience with bVokal is positive and
                            productive. Thank you for understanding and supporting our policies.
                        </Typography>
                        <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                            <Grid item xs={12} sm={12} md={1.5} gap={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CommonButton2
                                    label="Go Back"
                                    name="Go Back"
                                    type="button"
                                    color="primary"
                                    variant='contained'
                                    // onClick={() => navigate('/login')}
                                    onClick={() => window.history.back()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default Security;