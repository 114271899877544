import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import PageLoader from "../../common/components/PageLoader";
import { seo } from '../../common/utils';
import { SEO } from "../../common/constants";
import MainHeader from '../../common/components/MainHeader';
import SocialMediaDashboard from "./Components/SocialMediaDashboard";

import Schedule7Layout from "./Components/Schedule7Layout";
import Events from "./Components/Events/Events";
import RfpsListing from "./Components/Rfps/RfpsListing";
import { getMasterData } from "./actions";
import UploadImagePopup from "./Components/Popup/UploadImagePopup";
import { setProfileSkip } from "../../store/slices/UserSlice";

const SocialMediaComponent = (props) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const { master, users: { isProfileSkiped, userData } } = useSelector((state) => state);
    useEffect(() => {
        dispatch(getMasterData());
        seo(SEO.PROFILE);
        if(!userData?.profile_pic){
            if(!isProfileSkiped){
                setIsOpen(!isProfileSkiped);
            }
        }
    }, [])

    const handleClose = () => {
        dispatch(setProfileSkip(true));
        setIsOpen(false);
    }

    return (
        <>
            <PageLoader open={master.isLoad} />
            <SocialMediaDashboard />
            <UploadImagePopup open={isOpen} handleClose={handleClose} title={'Upload your profile image'} message={'Please upload photo'} dispatch={dispatch} />
            {/* <Routes>
                <Route path="social-media" element={<SocialMediaDashboard />} />
                <Route path="social-impacts/:slug" element={<Schedule7Layout />}/>
                <Route path="social-media/events" element={<Events />} />
                <Route path="social-media/rfps" element={<RfpsListing/>} />
            </Routes> */}
        </>
    );
}
export default SocialMediaComponent;