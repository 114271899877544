import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, Box, Typography, Fade } from '@mui/material';
import theme from '../../assets/material-ui/theme';
import CommonButton2 from './CommonButton2';
import Cancel_sechedule from "../../assets/icons/CancelledRed.svg";
import tick from '../../assets/icons/tick_green.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} style={{ transitionDelay: ref ? '300ms' : '0ms' }} />;
});

const CommonEnableTrialDialog = ({ open, handleClose, handleConfirm, handleConfirm2, message, title, titleStyle, closeIcon, btnText1, btnText2, btnStyle1, btnStyle2, tickIcon, CommonCss }) => {
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted PaperProps={{ sx: { minWidth: isXsScreen ? '600px' : '300px', borderRadius: '24px' } }}>
            <DialogTitle sx={{ color: theme.palette.success.dark, textAlign: 'center', padding: '16px', fontFamily: 'poppins', borderBottom: '1px solid #CCC' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ ...titleStyle }}>{title}</div>
                    {closeIcon &&
                        <img src={Cancel_sechedule} onClick={handleClose} style={{ cursor: 'pointer', boxShadow: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.07)) drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.12))' }} />}
                </div>
            </DialogTitle>
            {message && <DialogContent className={CommonCss.enableTrailContent}>
                <Typography className={CommonCss.enableTrailContentTypo}>{message}</Typography>
                {btnText1 && btnText2 && <Box className={CommonCss.enableTrailContentBox}>
                    <CommonButton2
                        label={btnText1}
                        name={btnText1}
                        type={btnText1}
                        color="primary"
                        variant='contained'
                        style={{ ...btnStyle1 }}
                        onClick={() => handleConfirm()}
                    />
                    <CommonButton2
                        label={btnText2}
                        name={btnText2}
                        type={btnText2}
                        color="primary"
                        variant='outlined'
                        style={{ ...btnStyle2 }}
                        onClick={() => handleConfirm2()}
                    />
                </Box>}
            </DialogContent>}
            {tickIcon &&
                <DialogContent className={CommonCss.trailTickRoot}>
                    <Box className={CommonCss.trailTickBox}>
                        <img src={tick} alt='tick' className={CommonCss.tickAnimation} />
                        <Typography className={CommonCss.trailTickTypo}>Trial is active</Typography>
                    </Box>
                </DialogContent>
            }
            <DialogActions className={CommonCss.enableTrailAction}>
                <Typography className={CommonCss.enableTrailActionTypo} onClick={handleClose}>Close</Typography>
            </DialogActions>
        </Dialog>
    );
};

export default CommonEnableTrialDialog;