import { Box, Dialog, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import CloseIcon from "../../../../assets/icons/closeDialogue.svg"
import ReduxForm from '../../../../common/components/ReduxForm';
import CommonInput from '../../../../common/components/CommonInput';
import theme from '../../../../assets/material-ui/theme';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { Star, StarBorder } from '@mui/icons-material';
import { Field, submit } from 'redux-form';
import { setSnackbar } from '../../../../store/slices/MessageSlice';
import CommonButton2 from '../../../../common/components/CommonButton2';
import { sendRatings } from '../Events/actions';

const FeedbackPopup = ({ open, onClose, dispatch, form, gid , setFeedbackComplete}) => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [numStarsFilled, setNumStarsFilled] = useState(0);
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }
    const handleStarClick = (starIndex) => {
        if (starIndex === numStarsFilled - 1) {
            setNumStarsFilled(starIndex);
        } else {
            setNumStarsFilled(starIndex + 1);
        }
        // alert(starIndex + 1);
        // console.log('Number of stars clicked:', starIndex + 1);
    };
    const handleSubmit = () => {
        if (numStarsFilled === 0) {
            dispatch(setSnackbar({ flag: true, message: 'Please select Rating', type: 'error' }));
        } else {
            dispatch(submit('RateEventForm'));
            setFeedbackComplete(true);
        }
    }
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleDlgClose}
            aria-labelledby="Share Post"
            PaperProps={{ classes: { root: SocialMediaCSS.feedbackPopup } }}
        >
            <Box display={'flex'} flexDirection={'row-reverse'} rowGap={1} position={'absolute'} right={'10px'} top={'10px'}>
                <IconButton onClick={() => onClose()} sx={{ alignSelf: 'start' }}>
                    <img src={CloseIcon} alt='Close Dialog' />
                </IconButton>
            </Box>
            <ReduxForm
                onSubmit={(values) => { dispatch(sendRatings(gid, values, onClose))}}
                form="RateEventForm"
                label="eventFeedback"
                submitButton="Next">
                <Grid container>
                    <Grid item xs={12} mt={-1.2} className={SocialMediaCSS.feedbackmainGrid}>
                        <Grid item xs={12} className={SocialMediaCSS.feedbackGrid}>
                            <Box className={SocialMediaCSS.feedbackBox1}>
                                <Typography className={SocialMediaCSS.renderHeading}>
                                    How you rate this event?
                                </Typography>
                                <Box className={SocialMediaCSS.renderStars}>
                                    <Field
                                        name="rating"
                                        component={({ input }) => (
                                            <div>
                                                {[...Array(5)].map((_, index) => (
                                                    <IconButton
                                                        key={index}
                                                        onClick={() => {
                                                            handleStarClick(index);
                                                            input.onChange(index + 1);
                                                        }}
                                                        sx={{ padding: '0' }}
                                                    >
                                                        {index < numStarsFilled ? (
                                                            <Star fontSize='large' sx={{ color: theme.palette.neutral.main }} />
                                                        ) : (
                                                            <StarBorder fontSize='large' />
                                                        )}
                                                    </IconButton>
                                                ))}
                                            </div>
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Grid xs={12} display={'flex'} flexDirection={'column'} width={'100%'}>
                                <Typography className={SocialMediaCSS.feedbackTypo}>
                                    Feedback
                                </Typography>
                                <CommonInput
                                    name="comment"
                                    placeholder="Write your feedback..."
                                    placeholderFontSize={'14px'}
                                    type="TextArea"
                                    maxLength={'500'}
                                    showCount={false}
                                    countFontSize="12px"
                                    multiline={true}
                                    rows={5}
                                    classes={{ root: SocialMediaCSS.feedbackInput }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} className={SocialMediaCSS.feedbackSubmit}>
                    <CommonButton2
                        label="Submit"
                        name="submit"
                        variant="contained"
                        type="button"
                        onClick={() => handleSubmit()}
                        disabled={!form?.RateEventForm?.values || !form?.RateEventForm?.values?.comment || !form?.RateEventForm?.values?.rating}
                        style={{
                            padding: '6px 32px', fontSize: '14px', height: '32px', fontWeight: '600', boxShadow: 'none',
                            borderRadius: '27px',
                            background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0775BA 0%, #0775BA 100%', color: '#FFF',
                            opacity: !form?.RateEventForm?.values || !form?.RateEventForm?.values?.comment || !form?.RateEventForm?.values?.rating ? 0.5 : 1
                        }}
                    />
                    </Grid>
                </Grid>
            </ReduxForm>
        </Dialog>
    )
}

export default FeedbackPopup