import React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import theme from '../../assets/material-ui/theme';

export default function RenderPassword({
    input,
    label,
    placeholder,
    type,
    fullWidth,
    maxLength,
    underlineStyle,
    errorStyle,
    style,
    toolTiptext,
    id,
    pattern,
    defaultValue,
    disabled,
    min,
    max,
    value,
    meta: { touched, error, warning },
    inputProps,
    errorMessages,
    custom,
}) {
    return (
        <>
            <FormControl sx={{ width: '100%' }} variant="outlined">
                <OutlinedInput
                    id={input.name}
                    type='TextField'
                    value={defaultValue}
                    min={min}
                    max={max}
                    fullWidth={fullWidth}
                    {...input}
                    {...custom}
                    className='TextField'
                    sx={{
                        border: 1,
                        borderColor: theme.palette.success.contrastText,
                        backgroundColor: theme.palette.secondary.dark,
                        borderRadius: 10,
                        fontSize: 16,
                        height: 45,
                        fontWeight: 400,
                        color: theme.palette.success.dark,
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.success.dark,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.success.dark
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.primary.main
                        },
                        "& input:-webkit-autofill": {
                            height: 12,
                            '-webkit-box-shadow': '0 0 0 1000px transparent inset',
                            '-webkit-text-fill-color': '#333333 !important',
                            'transition': 'background-color 5000s ease-in-out 0s',
                        },
                    }}
                    endAdornment={
                        <InputAdornment position="end" >
                            <CorporateFareOutlinedIcon sx={{ color: theme.palette.grey.lightGreyVoilet , height: '20px', width: '25px', marginRight: '-8px' }} />
                        </InputAdornment>
                    }
                    placeholder={placeholder}
                />
            </FormControl>
            {touched && error && <div className='error'>{error}</div>}
        </>
    );
}
