import { Box, Grid, IconButton, InputBase } from '@mui/material';
import React, { useState } from 'react';
import UserProfileImg from '../../../../assets/images/projectTempImage.jpg';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import Chat from '../../../../assets/icons/Chat.svg';
import { postCommentSubmit, postRepliesSubmit } from '../../actions';
import { useDispatch } from 'react-redux';
import CommonInitials from '../../../../common/components/CommonInitials';
import { useNavigate } from 'react-router';
import { handleUserData } from '../../../../common/actions';
import CommonInput from '../../../../common/components/CommonInput';
import ReduxForm from '../../../../common/components/ReduxForm';
import { useSelector } from 'react-redux';

const CommentInputContainer = ({ gid, postId = 0, comment, show = true, value = '', editFlag = false, setIsEditing = null, users, master }) => {
    const { userData } = users;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [newMessage, setNewMessage] = useState(value);
    const [clearInput, setClearInput] = useState(false);

    const { form } = useSelector((state) => { return state; });

    const tags = master?.hashtags?.hashtags;
    const usersMention = master?.mentions;

    const handleInputChange = (e) => {
        // setNewMessage(e.target.value);
        const input = e.target.value;
        if (input.length <= 1000) {
            setNewMessage(input);
        }
    };

    // const handleKeyDown = (e) => {
    //     if (!newMessage.trim()) {
    //         if (e.key === 'Enter' && !e.shiftKey) {
    //             e.preventDefault();
    //             handleSendMessage();
    //         }
    //     }
    // };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            // Prevent sending the message if newMessage is empty
            if (!newMessage.trim()) {
                e.preventDefault();
                return;
            }

            // If Shift + Enter is not pressed, send the message
            if (!e.shiftKey) {
                e.preventDefault();
                handleSendMessage();

            }
        }
    };

    // const handleSendMessage = () => {
    //     console.log(newMessage);
    //     const commentValue = form?.commentform?.values?.comment_description;
    //     const replyValue = form?.commentform?.values?.reply_description;
    //     setNewMessage('');
    //     if (comment) {
    //         dispatch(postCommentSubmit(commentValue, gid, postId, editFlag, setIsEditing));
    //     } else {
    //         dispatch(postRepliesSubmit(replyValue, gid, postId, editFlag, setIsEditing));
    //     }
    // };
    const handleSendMessage = () => {
        const messageValue = newMessage.trim();
        if (comment) {
            dispatch(postCommentSubmit(messageValue, gid, postId, editFlag, setIsEditing,setClearInput));
        } else {
            dispatch(postRepliesSubmit(messageValue, gid, postId, editFlag, setIsEditing,setClearInput));
        }
        setNewMessage('');
        // setClearInput(true)
    };
    const handleUserProfile = () => {
        navigate(`/profile/${userData?.gid}`);
    }
    console.log("real", newMessage)
    return (
        <>
            <Box className={SocialMediaCSS.commentHeader}>
                {show &&
                    <Box className={SocialMediaCSS.cursor} onClick={handleUserProfile}>
                        <CommonInitials name={userData?.initials} bgColor={userData?.color_code} divStyle={{ padding: '3px', maxHeight: '42px', maxWidth: '42px', minHeight: '42px', minWidth: '42px', borderRadius: '50%', flex: '0 0 42px', borderColor: '#e9ecef', border: '1px solid #dee2e6' }} typoStyle={{ fontSize: '16px', lineHeight: '35px', }} avatarStyle={{ width: '42px', height: '42px', }} src={userData?.profile_pic} companyLogo={userData?.profile_pic} avatar={true} />
                    </Box>
                }
                <Box className={SocialMediaCSS.commentInputBox} >
                    <ReduxForm
                        form="commentform"
                        label="comment"
                        // initialValues={}
                        submitButton="send"
                        onSubmit={handleSendMessage}>
                        {/* <InputBase
                        placeholder="Write your comment..."
                        fullWidth
                        multiline
                        minRows={1}
                        maxRows={1}
                        classes={{
                            root: SocialMediaCSS.commentTextInput,
                            input: SocialMediaCSS.commentTextInputInput,
                            focused: SocialMediaCSS.commentTextInputFocused,
                        }}
                        value={newMessage}
                        // onChange={(e) => setNewMessage(e.target.value)}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        endAdornment={(
                            <IconButton
                                color="primary"
                                aria-label="Send"
                                className={SocialMediaCSS.sendCommentIcon}
                                onClick={handleSendMessage}
                                disabled={!newMessage.trim()}
                            >
                                <img src={Chat} alt='Send' className={SocialMediaCSS.h18} />
                            </IconButton>
                        )}
                    /> */}

                        <CommonInput
                            name={comment ? "comment_description" : "reply_description"}
                            placeholder="Write your comment..."
                            type="commentInput"
                            users={usersMention}
                            tags={tags}
                            maxLength={50}
                            comment={comment}
                            clearInput={clearInput}
                            setClearInput={setClearInput}
                            // showCount={true}
                            // countFontSize="12px"
                            rows="1"
                            onKeyDown={handleKeyDown}
                            endAdornment={(
                                <IconButton
                                    color="primary"
                                    aria-label="Send"
                                    className={SocialMediaCSS.sendCommIcon}
                                    onClick={handleSendMessage}
                                    disabled={!newMessage.trim()}
                                >
                                    <img src={Chat} alt='Send' className={SocialMediaCSS.h18} />
                                </IconButton>
                            )}
                            setNewMessage={setNewMessage}
                            editFlag={editFlag}
                            initialValue={value}
                        // onExceedLimit={handleExceedLimit}
                        // normalize={!draft?.draft_post ? parseEmptySpaces : undefined}
                        />

                    </ReduxForm>
                </Box>
            </Box>
        </>
    )
}

export default CommentInputContainer
