import React, { useEffect, useState } from 'react';
import { Box, Dialog, Divider, Grid, useMediaQuery } from '@mui/material';
import SocialMediaCSS from "../../../SocialMediaCSS/SocialMedia.module.css";
import { Typography } from '@material-ui/core';
import cancel from "../../../../../assets/icons/Cancel.svg"
import CommonInitials from '../../../../../common/components/CommonInitials';
import { useDispatch } from 'react-redux';
import { getShareData, getSharedPostList, } from '../../../actions';
import { useNavigate } from 'react-router';

export const SharedList = ({ open, onClose, Title, socialMedia,gid }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }

    const handleCardClick = (gid) => {
        navigate(`/profile/${gid}`);
    }

    const getResourceData = () => {
        return socialMedia.sharedList;
    };
    useEffect(() => {
        dispatch(getSharedPostList(gid));
    }, []);
    const resourceData = getResourceData();

    return (
        <Dialog open={open} onClose={handleDlgClose} PaperProps={{ sx: { minWidth: isXsScreen ? '900px' : '300px', borderRadius: '16px', padding: '8px' } }}>
            <Grid cointainer className={SocialMediaCSS.connection}>
                <Grid item xs={12} className={SocialMediaCSS.connectionList}>
                    <Typography className={SocialMediaCSS.followerTypo}>{Title}</Typography>
                    <Box className={SocialMediaCSS.cancel}>
                        <img src={cancel} className={SocialMediaCSS.cursor} onClick={() => onClose()} />
                    </Box>
                </Grid>
            </Grid>
            <Divider className={SocialMediaCSS.followers} />

            {resourceData.length === 0 ? (
                <Typography variant="body1" align="center" className={SocialMediaCSS.MarginEmpty}>
                    No records found !!
                </Typography>
            ) : (
                <Grid item xs={12} className={`${SocialMediaCSS.followersList} ${SocialMediaCSS.scrollable}`}>
                    {getResourceData().map((data, index) => {
                        return (
                            <Box className={`${SocialMediaCSS.connectionGap} ${SocialMediaCSS.cursor}`} onClick={() => handleCardClick(data.gid)}>
                                <Box className={`${SocialMediaCSS.connectionCard}`}>
                                    <CommonInitials
                                        name={data?.initials}
                                        bgColor={data?.colour_code}
                                        divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '44px', minWidth: '44px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                                        typoStyle={{ fontSize: '18px', lineHeight: '42px', }}
                                        avatarStyle={{ width: '44px', height: '44px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                                        src={data?.image_url}
                                        companyLogo={data?.image_url}
                                        avatar={true}
                                    />
                                    <Box>
                                        <Typography className={SocialMediaCSS.resourcetypo}>{data.name}</Typography>
                                        <Typography className={SocialMediaCSS.eventThematic}>{data.profile_tagline}</Typography>
                                    </Box>

                                </Box>
                            </Box>);
                    })}
                </Grid>
            )}
        </Dialog >
    );
};