import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { reset, submit } from 'redux-form'
import bvokalLogo from '../../../assets/images/BvokalNew.svg'
import theme from '../../../assets/material-ui/theme'
import CommonInputNew from '../../../common/components/CommonInputNew'
import CommonButtonNew from '../../../common/components/RectangularInputs/CommonButtonNew'
import ReduxForm from '../../../common/components/ReduxForm'
import * as clientValidations from '../../../common/validations'
import { sendForgotPassLink } from '../actions'
import RegCSS from '../common/RegistrationCSS/RegCSS.module.css'

const ForgotPasswordNew = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const initialValues = {
        email: location.state?.email || ""
    };
    const handleSubmit = () => {
        dispatch(submit('forgotPassword'));
    }

    return (
        <Grid container bgcolor={theme.palette.grey.white} borderRadius={3} boxShadow={theme.shadow.softShadow4}>
            <Grid item xs={12} sx={{ height: '560px' }}>
                <Grid item className={RegCSS.createAccRoot} sx={{pb: '142px !important'}}>
                    <Grid item xs={12} textAlign={'center'}>
                        <img src={bvokalLogo} height={50} alt='logo' />
                    </Grid>
                    <Grid item xs={12} textAlign={'center'} pt={1}>
                        <Typography className={RegCSS.signUpMailHeading}>
                            Forgot Password?
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ReduxForm
                            onSubmit={(values) => dispatch(sendForgotPassLink(values, navigate))}
                            initialValues={initialValues}
                            form="forgotPassword"
                            label="Log in to your account"
                            submitButton="Login"
                        >
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sx={{pt: '16px !important'}}>
                                            <Typography className={RegCSS.signUpMailTypo4}>Email address <span style={{ color: theme.palette.error.main }}>*</span></Typography>
                                            <CommonInputNew
                                                name="email"
                                                placeholder={"Enter your email address"}
                                                type="Email"
                                                keyboardType="default"
                                                fullWidth
                                                validate={[clientValidations.EmailRequired, clientValidations.emailCheck]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={RegCSS.signUpMailGrid}>
                                    <Typography className={RegCSS.forgotMailTypo3}>
                                        We'll send a verification code to this email if it matches an existing bvokal account.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{pt: '24px !important'}}>
                                    <Box display={'flex'} justifyContent={'center'}>
                                        <CommonButtonNew
                                            label="Send Code"
                                            name="Register"
                                            type="submit"
                                            style={{ background: theme.palette.primary.dark, color: 'white', boxShadow: theme.shadow.softShadow4 }}
                                            smallWidth
                                            onClick={handleSubmit}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{ pt: '24px !important' }}>
                                    <Box display='flex' justifyContent='center'>
                                        <Typography sx={{ fontSize: '14px', color: theme.mainPalette.grey.darkText, fontWeight: theme.typography.fontWeightMedium }}>Already have an account?</Typography>
                                        <Link to='/login' underline="always" style={{ marginLeft: 8, color: theme.palette.primary.dark, fontSize: '14px', fontWeight: theme.typography.fontWeightMedium }} onClick={() => dispatch(reset('forgotPassword'))}>Login</Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ReduxForm>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ForgotPasswordNew
