import { Backdrop } from "@mui/material";
import React from "react";
import Bvokal from '../../assets/images/BvokalLogo.svg';

const PageLoader = ({open}) => {
    return <>
        <Backdrop
            sx={{ color: '#fff', backgroundColor: '#FFFFFF', opacity: 1, zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: 'blur(4px)', display: 'flex', flexDirection: 'column' }}
            open={open}
        >
            <div 
               // class="container-fluid"
            >

                <div class="row " style={{marginTop: '40px'}} >
                    <div 
                        //class="col-12 text-center"
                        style={{textAlign: 'center'}}
                    >
                        <img width='60%' src={Bvokal} alt="" srcset="" />
                        <span class=" loader2 " style={{justifyContent: 'center', marginTop: '32px'}}></span>
                    </div>
                </div>

            </div>
        </Backdrop>
    </>
}

export default PageLoader