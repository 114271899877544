import React, { useEffect, useMemo, useRef, useState, lazy } from "react";
import { Box, CircularProgress, Divider, Fade, Grid, Typography } from "@mui/material";
import SinglePostLayout from './SinglePostLayout';
import EventShare from "./EventShare";
import RfpPost from "./RfpPost";
import { useSelector } from "react-redux";
import PostCreateProcess from "./PostSubComponents/PostCreateProcess";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import { useLocation, useNavigate, useParams } from "react-router";
import { getBookMarkPost, getDashboardPost, getHashtagData } from "../../actions";
import { useDispatch } from "react-redux";
import EmptyPostCard from "./PostSubComponents/EmptyPostCard";
import _ from 'lodash-es';
import PostSkeleton from "./PostSubComponents/PostSkeleton";
import EmptyBookMarkCard from "../BookMarks/EmptyBookMarkCard";
import { setBookmarkType, setMyFeed, setNotificationPostGid, setNotificationPostType, setSelectedHashtag } from "../../../../store/slices/SocialMediaSlice";
import EventBookMark from "./EventBookMark";
import { Helmet } from "react-helmet";
import PostReshareHeader from "./PostSubComponents/PostReshareHeader";
import PostActions from "./PostActions";
import { debounce } from "../../../../common/utils";
import HashtagFollow from "./HashtagFollow";
import EmptyHashtag from "./EmptyHashtag";
import ConferenceBookMark from "./ConferenceBookMark";

// const SinglePostLayout = lazy(() => import('./SinglePostLayout'));
const Post = ({ containerRef }) => {
    const { socialMedia, form, master, users } = useSelector((state) => { return state; });
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false)
    let offset = useRef(10);
    let totalCount = useRef(0);
    let totalPosts = useRef(0);
    let isFecthing = useRef(false);
    const lastScrollTop = useRef(0);
    // const isFecthing = useRef(false);

    useEffect(() => {
        if (socialMedia?.postDashboard) {
            totalCount.current = socialMedia?.postDashboard?.length;
            totalPosts.current = socialMedia?.totalPostCount;
        }
    }, [socialMedia?.postDashboard, socialMedia?.totalPostCount])
    const handleScroll = () => {
        if (isSocialImpact) {
            return;
        }
        // Detect if user has scrolled to the bottom of the page
        if (isFecthing.current) return;
        const container = containerRef.current;
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;
        // console.log(scrollTop, scrollHeight, clientHeight, lastScrollTop, 'scroll')
        if (scrollTop > lastScrollTop.current && scrollTop + clientHeight >= scrollHeight - 50 && totalCount.current < totalPosts.current) {
            // Fetch more data when user scrolls to the bottom
            try {

                offset.current = offset.current + 10;
                console.log("New Offset:", offset.current);
                if (totalCount.current && !isHashtag) {
                    dispatch(getDashboardPost(offset.current, true, setLoading, isFecthing));
                }
                if (isHashtag && totalCount.current) {
                    dispatch(getHashtagData(params.hashtag, navigate, offset.current, true, setLoading, isFecthing));
                    dispatch(setSelectedHashtag(params.hashtag));
                }
            } catch (error) {
                isFecthing = false;
            }
        } else {
            setLoading(false);
        }
        lastScrollTop.current = scrollTop;
    };
    const debouncedHandleScroll = debounce(handleScroll, 300);

    useEffect(() => {
        const container = containerRef?.current;
        container?.addEventListener('scroll', debouncedHandleScroll);
        return () => {
            container?.removeEventListener('scroll', debouncedHandleScroll);
        };
    }, [debouncedHandleScroll]);

    const isSocialImpact = location.pathname.startsWith(`/social-media/social-impacts`);
    const isSocialHastags = location.pathname.startsWith(`/social-media/hashtags`);
    const isBookMarkEvents = location.pathname.startsWith(`/social-media/bookmark/event`);
    const isBookMarkConference = location.pathname.startsWith(`/social-media/bookmark/conference`);
    const isBookMarkPost = location.pathname.startsWith(`/social-media/bookmark/post`);
    const isNotificationPost = location.pathname.startsWith(`/social-media/posts`);
    const isMyFeed = location.pathname.startsWith(`/social-media/my-posts`);
    const isHashtag = location.pathname.startsWith(`/social-media/hashtags/`);
    useEffect(() => {
        if (!isSocialHastags && !isSocialImpact && !isBookMarkPost && !isBookMarkEvents && !isNotificationPost && !isBookMarkConference) {
            dispatch(getDashboardPost(offset.current))
        }
    }, []);

    useEffect(() => {
        if (isBookMarkPost) {
            dispatch(setBookmarkType('Post'));
        }
        if (isBookMarkEvents) {
            dispatch(setBookmarkType('SocialEvent'));
        }
        if (isBookMarkConference) {
            dispatch(setBookmarkType('Conference'));
        }
        if (isBookMarkPost || isBookMarkEvents || isBookMarkConference) {
            dispatch(getDashboardPost(offset.current));
        }
    }, [socialMedia.bookmarkType]);
    useEffect(() => {
        if (isNotificationPost) {
            dispatch(setNotificationPostType('posts'));
            if (params.gid) {
                dispatch(setNotificationPostGid(params.gid))
            }
        }
        if (isNotificationPost) {
            dispatch(getDashboardPost(offset.current, "", "", "", navigate));
        }
    }, [socialMedia.notificationPostType]);
    useEffect(() => {
        if (isMyFeed) {
            dispatch(setMyFeed('myPosts'));
        }
        if (isMyFeed) {
            dispatch(getDashboardPost(offset.current));
        }
    }, [socialMedia.myFeed]);
    useEffect(() => {
        if (isHashtag) {
            window.scrollTo(0, 0);
            dispatch(getHashtagData(params.hashtag, navigate));
            dispatch(setSelectedHashtag(params.hashtag));
        }
    }, [isHashtag, params.hashtag]);

    // console.log("sell", socialMedia?.postDashboard)


    // const postDashboardArray = Array.isArray(socialMedia?.postDashboard) ? socialMedia?.postDashboard : [socialMedia?.postDashboard];
    const postDashboardArray = useMemo(() => Array.isArray(socialMedia?.postDashboard) ? socialMedia?.postDashboard : [socialMedia?.postDashboard], [socialMedia?.postDashboard]);

    console.log(socialMedia?.postHashtag?.hashtag_follow, "ree")
    return (
        <>
            <Grid container rowSpacing={2}>
                {!isSocialImpact && !isMyFeed &&
                    <PostCreateProcess />
                }
                {isHashtag &&
                    <Box pt={2} width="100%">
                        <HashtagFollow />
                    </Box>
                }
                {/* {!_.isEmpty(socialMedia?.postDashboard) && socialMedia.postDashboard.length > 0 ? ( */}
                {!_.isEmpty(socialMedia?.indianCsrPreview) && socialMedia?.indianCsrPreview?.posts?.map((post) => (
                    <>
                        <Grid item xs={12}>
                            <SinglePostLayout socialMedia={socialMedia} key={post.gid} post={post} form={form} master={master} users={users} offset={offset} />
                        </Grid>
                    </>
                ))}


                {/* {!_.isEmpty(socialMedia?.postDashboard) && socialMedia?.postDashboard?.map((post) => ( */}
                {!_.isEmpty(postDashboardArray) && postDashboardArray.map((post) => (
                    <>
                        {post?.multiple_shared_post &&
                            <Grid item xs={12} >
                                <Box className={SocialMediaCSS.postCard}>
                                    <Box className={SocialMediaCSS.postHeader} sx={{ padding: post?.multiple_shared_post ? '16px 0px 16px 0px' : "" }}>
                                        <PostReshareHeader post={post} />
                                    </Box>
                                    <Divider sx={{ marginTop: '0px' }} />
                                    <SinglePostLayout socialMedia={socialMedia} key={post.gid} post={post} form={form} master={master} users={users} offset={offset} />
                                    <Divider />
                                    <PostActions post={post} master={master} users={users} form={form} />
                                </Box>
                            </Grid>
                        }

                        {post?.post_type === 'Post' && !post?.multiple_shared_post &&
                            <Grid item xs={12}>
                                <SinglePostLayout socialMedia={socialMedia} key={post.gid} post={post} form={form} master={master} users={users} offset={offset} />
                            </Grid>}
                        {post?.post_type === 'ProposalRequest' &&
                            <Grid item xs={12}>
                                <RfpPost post={post} />
                            </Grid>}
                        {post?.post_type === 'SocialEvent' &&
                            <Grid item xs={12}>
                                <EventShare post={post} />
                            </Grid>}
                        {post?.post_type === 'Conference' &&
                            <Grid item xs={12}>
                                <EventShare post={post} />
                            </Grid>}
                        {post?.post_type === 'bookmark_event' &&
                            <Grid item xs={12}>
                                <EventBookMark post={post} socialMedia={socialMedia} master={master} users={users} />
                            </Grid>}
                        {post?.post_type === 'bookmark_conference' &&
                            <Grid item xs={12}>
                                <ConferenceBookMark post={post} socialMedia={socialMedia} master={master} users={users} />
                            </Grid>}
                    </>
                ))}
                {/* ) : ( */}

                <Grid item xs={12}>
                    {_.isEmpty(socialMedia?.postDashboard) && !isBookMarkEvents && !isBookMarkConference && !isBookMarkPost && !isMyFeed && !isNotificationPost && !isHashtag && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                        {/* <Fade in={(loading)} style={{ transitionDelay: (loading) ? '100ms' : '0ms', }} unmountOnExit> */}
                        <Fade in={_.isEmpty(socialMedia?.postDashboard)} style={{ transitionDelay: _.isEmpty(socialMedia?.postDashboard) ? '100ms' : '0ms', }} unmountOnExit>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
                                <PostSkeleton />
                            </Box>
                        </Fade>
                    </Box>
                    }
                </Grid>
                {/* {
                    !master.isLoad && <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
                            <PostSkeleton />
                        </Box>
                    </>
                } */}
                {_.isEmpty(socialMedia.postDashboard) && master.isLoad && !loading && <Grid item xs={12}>
                    <EmptyPostCard />
                </Grid>}
                {_.isEmpty(socialMedia.postDashboard) && (isMyFeed || isNotificationPost) && <Grid item xs={12}>
                    <EmptyPostCard />
                </Grid>}
                {(_.isEmpty(socialMedia.postDashboard) && isHashtag) &&
                    <Grid item xs={12}>
                        <EmptyHashtag />
                    </Grid>}
                {_.isEmpty(socialMedia.postDashboard) && (isBookMarkPost || isBookMarkEvents || isBookMarkConference) && <Grid item xs={12}>
                    <EmptyBookMarkCard />
                </Grid>}

                {/* <Grid item xs={12}>
                    <SharedPostLayout socialMedia={socialMedia} dispatch={dispatch} />
                </Grid> */}

            </Grid >
        </>
    );
}
export default Post;
