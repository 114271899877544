import { change, reset, submit } from 'redux-form'
import _ from 'lodash-es';
import {
    setContactPerson,
    setContactPersonFlag,
    updateContactPerson,
    addAddressData,
    updateAddressData,
    setAddressFlag,
    updateCatchmentAreas,
    addCatchmentAreas,
    setCatchmentFlag,
    addAboutUsData,
    setProfileTab,
    addProjectLocation,
    updateProjectLocation,
    setProjectLocationFlag,
    setPastProjectFlag,
    addPastProjectData,
    updatePastProjectData,
    setUploadedData,
    addAnnexureData,
    addComplianceData,
    addProfileDistrict,
    setWorksIn,
    setTabDataLoading,
    setUserProfile,
    addAnalyticsData,
    setSuggestedUsers,
    setSendOtp,
} from '../../store/slices/ProfileSlice';
import {
    addAnnexureList,
    addAddressType,
    addUserDistrict,
    addUserState,
    addThematicAreas,
    setIsLoad,
    addLanguage,
    addSchedule7,
    addColorCodes,
} from '../../store/slices/MasterSlice';
import {
    STATE_URL,
    DISTRICT_URL,
    THEMATIC_AREAS,
    ADDRESS_URL,
    ADDRESS_TYPE,
    PROFILE_PHOTO,
    ABOUTUS_URL,
    PROJECT_URL,
    DELETE_FILE,
    ANNEXURE_URL,
    ANNEXURE_UPLOAD,
    DELETE_ANNEXURE,
    LANGUAGE,
    COMPLIANCE_URL,
    DELETE_ADDRESS,
    DELETE_PROJECT,
    MASTER_URL,
    COMPLETE_PROFILE,
    SCHEDULE7,
    UPGRADE_ACCOUNT,
    USER_PROFILE,
    VOLUNTEER_INTEREST_URL,
    WORKS_IN_URL,
    DELETE_WORKS_IN,
    ADDRESS_PRIVACY,
    PROJECT_DOCUMENT_DOWNLOAD_URL,
    CATCHMENT_AREA,
    PREVIEW_URL,
    ANALYTICS_URL,
    SUGGESTED_USERS_URL,
    CONNECTION_REQ_URL,
    FOLLOW_URL,
    UNFOLLOW_URL,
    SEND_FEEDBACK_URL,
    CONNECTION_REMOVE,
    SEND_CHANGE_OTP,
    CHANGE_PASSWORD,
} from '../../http/apiUrls';

import { httpMethod, COMMON_MSG, UPLOAD_KEYS, UPLOAD_DATA, PROFILE_FLOW_KEYS, FILE_HOST } from '../../common/constants';
import { fetchAPI, getAddressTypes, getAllCountry, getAllDistrict, getAllState, getCompanyId, getLanguage, getPostActivity, getModuleType, getSchedule7, getConnectionTypes } from '../../common/actions';
import { setSnackbar } from '../../store/slices/MessageSlice';
import { getCompanyType, getUserType } from '../Registration/actions';
import { setUserData } from '../../store/slices/UserSlice';
import { base64ToFile, isDataURL, isURL } from '../../common/utils';
import { getAllSuggestions, getSuggestions } from '../SocialMedia/actions';
import { setRecommendations } from '../../store/slices/SocialMediaSlice';


export const getMasterData = () => (dispatch, getState) => {
    dispatch(getAllState());
    dispatch(getAllCountry());
    dispatch(getAddressTypes());
    dispatch(getSchedule7());
    dispatch(getAllDistrict());
    dispatch(getLanguage());
    dispatch(getAnnexureList());
    dispatch(getThematicAreas());
    dispatch(getCompanyType());
    dispatch(getConnectionTypes());
    dispatch(getModuleType());
}

export const getCompleteProfile = (showLoad = false) => (dispatch, getState) => {
    const { profile } = getState();
    // if(!_.isEmpty(profile?.aboutUs)) { // stop calling if we have data present already
    //      return;
    // }
    setTimeout(() => {
        showLoad ? dispatch(setIsLoad(true)) : dispatch(setIsLoad(false));
        let company_id = dispatch(getCompanyId());
        let APIUrl = `${COMPLETE_PROFILE}/${company_id}`;
        if (!company_id) return;
        dispatch(fetchAPI(APIUrl, httpMethod.GET, '', '', '', '')).then(
            response => {
                dispatch(setIsLoad(false));
                if (response.success) {
                    const { complianceData, addressFormData, pastProjectData, annexureData, aboutUs, uploadedData } = response.data;
                    if (complianceData) {
                        dispatch(addComplianceData(complianceData));
                    }
                    if (Array.isArray(addressFormData) && addressFormData.length > 0) {
                        dispatch(updateAddressData(addressFormData));
                    }
                    if (Array.isArray(pastProjectData) && pastProjectData.length > 0) {
                        dispatch(updatePastProjectData(pastProjectData));
                    }
                    if (Array.isArray(annexureData) && annexureData.length > 0) {
                        dispatch(addAnnexureData(annexureData));
                    }
                    // if (aboutUs && aboutUs.summary) {
                    if (aboutUs) {
                        dispatch(addAboutUsData(aboutUs));
                    }
                    if (Array.isArray(aboutUs.catchmentArea) && aboutUs.catchmentArea.length > 0) {
                        dispatch(updateCatchmentAreas(aboutUs.catchmentArea));
                    }
                    dispatch(setUploadedData(uploadedData));
                } else if (response.error) {
                    dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
                }
            },
        );
    }, 1000);
}

export const getAllProfile = (gid,navigate) => (dispatch, getState) => {
    const { users } = getState();
    let id = users && users?.userData?.gid;
    dispatch(setTabDataLoading(true));
    let dataToPost = { gid }
    dispatch(fetchAPI(PREVIEW_URL, httpMethod.GET, dataToPost)).then(
        response => {
            if (response?.profile_details) {
                const newData = {
                    aboutUs: response?.profile_details,
                    contactInfo: response?.company_addresses,
                    pastProjectData: response?.company_projects,
                    worksIn: response?.profile_works,
                    complianceData: response?.compliances,
                    annexureData: response?.annexures,
                    catchmentAreas: response?.catchment_areas?.catchment_area,
                }
                dispatch(setUserProfile(newData));
                dispatch(getSuggestedUsers(id));
            } else if (response.error) {
                if (response.error.errorCode === 422 && response.error.errorMsg === "User not found!! ") {
                    navigate('/page-not-found');
                } else {
                    dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
                }
               
            }
        },
    ).finally(() => {
        // You can optionally add a delay here if needed
        setTimeout(() => {
            dispatch(setTabDataLoading(false));
        }, 1000); // 1 second delay
    });
}

export const getAnalytics = (gid) => (dispatch) => {
    let dataToPost = { gid }
    dispatch(fetchAPI(ANALYTICS_URL, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(addAnalyticsData(response.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getSuggestedUsers = (id) => (dispatch) => {
    let dataToPost = {
        gid: id
    }
    dispatch(fetchAPI(SUGGESTED_USERS_URL, httpMethod.GET, id ? dataToPost : '')).then(
        response => {
            if (response.success) {
                dispatch(setSuggestedUsers(response.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getUserProfile = (gid) => (dispatch) => {
    dispatch(setTabDataLoading(true));
    dispatch(fetchAPI(`${ABOUTUS_URL}${gid}`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addAboutUsData(response.data));
                dispatch(getAnalytics(gid));
                dispatch(getSuggestedUsers(gid));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    ).finally(() => {
        // You can optionally add a delay here if needed
        setTimeout(() => {
            dispatch(setTabDataLoading(false));
        }, 1000); // 1 second delay
    });
}

export const sendConnectionReq = (email, community) => (dispatch, getState) => {
    const { socialMedia: { recommendationList } } = getState();
    let dataToPost = {
        connection_request: {
            requested_user_email: email,
        }
    }
    dispatch(fetchAPI(CONNECTION_REQ_URL, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                if(community === 'community'){
                    dispatch(getSuggestions());
                } else if(community === 'suggest'){
                    let filteredList = recommendationList?.filter(data => data.email !== email);
                    dispatch(setRecommendations(filteredList));
                    // dispatch(getAllSuggestions());
                } else {
                    dispatch(getSuggestedUsers());
                }
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const sendProfileConnectionReq = (email, community) => (dispatch, getState) => {
    const { profile: { userProfile } } = getState();
    let dataToPost = {
        connection_request: {
            requested_user_email: email,
        }
    }
    dispatch(fetchAPI(CONNECTION_REQ_URL, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                let newData = {
                    ...userProfile,
                    aboutUs: {
                        ...userProfile.aboutUs,
                        connect: {
                            gid: response?.data?.connection_request_gid,
                            status: "Withdraw",
                        },
                        follow: true,
                    }
                }
                dispatch(setUserProfile(newData));
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const invitationAction = (gid, action) => (dispatch, getState) => {
    const { profile: { userProfile } } = getState();
    let dataToPost = {
        connection_request: {
            status: action,
        }
    }
    dispatch(fetchAPI(`${CONNECTION_REQ_URL}/${gid}`, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                if (action === "Withdrawn" || action === "Decline") {
                    let newArr = {
                        ...userProfile,
                        aboutUs: {
                            ...userProfile.aboutUs,
                            connect: {
                                gid: null,
                                status: "Connect",
                            },
                            follow: true,
                        }
                    }
                    dispatch(setUserProfile(newArr));
                } else if (action === "Accepted") {
                    let newArr = {
                        ...userProfile,
                        aboutUs: {
                            ...userProfile.aboutUs,
                            connect: {
                                gid: response?.data?.user_connection_gid,
                                status: "Disconnect",
                            },
                            follow: true,
                        }
                    }
                    dispatch(setUserProfile(newArr));
                }
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const sendFeedBack = (project, values, onClose) => (dispatch, getState) => {
    const { profile: { aboutUs } } = getState();
    let dataToPost = {
        id: project?.id,
        gid: aboutUs?.gid,
        feedback: values?.feedback,
    }
    dispatch(fetchAPI(SEND_FEEDBACK_URL, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                onClose();
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const FollowUser = (gid, status) => (dispatch) => {
    // let APIUrl = FOLLOW_URL;
    let APIUrl = !status ? FOLLOW_URL : UNFOLLOW_URL;
    let dataToPost = {
        gid
    }

    dispatch(fetchAPI(APIUrl, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                // dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const FollowProfile = (gid, status) => (dispatch, getState) => {
    const { profile: { userProfile } } = getState();
    // let APIUrl = FOLLOW_URL;
    let APIUrl = !status ? FOLLOW_URL : UNFOLLOW_URL;
    let dataToPost = {
        gid
    }

    dispatch(fetchAPI(APIUrl, httpMethod.PUT, dataToPost)).then(
        response => {
            if (!status ? response?.user_follower?.success : response.success) {
                let newData = {
                    ...userProfile,
                    aboutUs: {
                        ...userProfile.aboutUs,
                        follow: !status
                    }
                }
                dispatch(setUserProfile(newData));
                // dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const connectionRemove = (gid) => (dispatch, getState) => {
    const { profile: { userProfile } } = getState();
    dispatch(fetchAPI(`${CONNECTION_REMOVE}${gid}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                let newData = {
                    ...userProfile,
                    aboutUs: {
                        ...userProfile.aboutUs,
                        connect: {
                            gid: null,
                            status: "Connect",
                        },
                        follow: false
                    }
                }
                dispatch(setUserProfile(newData));
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getWorkIn = (gid) => (dispatch) => {
    dispatch(setTabDataLoading(true));
    const dataToPost = { gid };
    dispatch(fetchAPI(WORKS_IN_URL, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setWorksIn(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    ).finally(() => {
        // You can optionally add a delay here if needed
        setTimeout(() => {
            dispatch(setTabDataLoading(false));
        }, 1000); // 1 second delay
    });
}

export const submitWorkIn = (values, gid) => (dispatch) => {
    dispatch(setTabDataLoading(true));
    const dataToPost = {
        thematic_area_ids: values,
        gid
    };
    dispatch(fetchAPI(WORKS_IN_URL, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setWorksIn(response?.data));
                dispatch(getAnalytics(gid));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    ).finally(() => {
        // You can optionally add a delay here if needed
        setTimeout(() => {
            dispatch(setTabDataLoading(false));
        }, 1000); // 1 second delay
    });
}

export const deleteWorkIn = (values, gid) => (dispatch) => {
    dispatch(setTabDataLoading(true));
    const dataToPost = {
        thematic_area_ids: values,
        gid
    };
    dispatch(fetchAPI(DELETE_WORKS_IN, httpMethod.DELETE, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setWorksIn(response?.data));
                dispatch(getAnalytics(gid));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    ).finally(() => {
        // You can optionally add a delay here if needed
        setTimeout(() => {
            dispatch(setTabDataLoading(false));
        }, 1000); // 1 second delay
    });
}

export const getContactInfo = (gid) => (dispatch) => {
    dispatch(setTabDataLoading(true));
    const dataToPost = { gid };
    dispatch(fetchAPI(ADDRESS_URL, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(updateContactPerson(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    ).finally(() => {
        // You can optionally add a delay here if needed
        setTimeout(() => {
            dispatch(setTabDataLoading(false));
        }, 1000); // 1 second delay
    });
}

export const setContactPrivacy = (value, index, gid) => (dispatch, getState) => {
    const { profile: { contactInfo, aboutUs } } = getState();
    const dataToPost = {
        company_address: {
            private: value,
        },
        gid: aboutUs?.gid,
        id: contactInfo[index]?.id,
    };
    dispatch(fetchAPI(ADDRESS_PRIVACY, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const submitContactInfo = (values, editFlag, index, gid, onClose) => (dispatch, getState) => {
    const { profile: { contactInfo } } = getState();
    dispatch(setTabDataLoading(true));
    let APIUrl = ADDRESS_URL;
    let method = httpMethod.POST;
    let dataToPost = {
        company_address: {
            ...values,
            company_contacts_attributes: values?.contact,
        },
        gid
    };
    if (editFlag) {
        let id = contactInfo[index].id;
        APIUrl = `${ADDRESS_URL}/${id}`;
        method = httpMethod.PUT;
    }
    delete dataToPost.company_address.contact;
    dispatch(fetchAPI(APIUrl, method, dataToPost)).then(
        response => {
            if (response.success) {
                if (editFlag) {
                    let newArr = [...contactInfo];
                    newArr[index] = response.data;
                    dispatch(updateContactPerson(newArr));
                } else {
                    let newArr = [...contactInfo, response.data];
                    dispatch(updateContactPerson(newArr));
                }
                onClose();
                dispatch(getAnalytics(gid));
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    ).finally(() => {
        // You can optionally add a delay here if needed
        setTimeout(() => {
            dispatch(setTabDataLoading(false));
        }, 1000); // 1 second delay
    });
}

export const checkForTab = (tab) => (dispatch, getState) => {
    const { users } = getState();
    if (users && users.userData && users.userData.data && users.userData.data.profileRoles) {
        return users.userData.data.profileRoles.indexOf(tab) > -1 ? true : false;
    }
}

export const submitAddress = (values, editFlag, index, navigate, isIndividual, onClose) => (dispatch, getState) => {
    let { profile: { contactInfo, addressFormData }, form: { contactPersonForm }, users: { userData: { data: { role_key } } } } = getState();
    // if (contactInfo.length < 1) {
    //     dispatch(setSnackbar({ flag: true, message: COMMON_MSG.CONTACT_PERSON_ERROR, type: "error" }));
    //     return;
    // }
    let dataToSend = { company_id: dispatch(getCompanyId()) };
    if (editFlag) {
        let newArr = [...addressFormData];
        newArr[index] = values;
        newArr[index]['contactInfo'] = contactInfo;
        dataToSend = { ...dataToSend, ...newArr[index] };
    } else {
        let newContactArr = [...contactInfo];
        // if (contactPersonForm?.values && Object.keys(contactPersonForm.values)?.length > 0) {
        //     newContactArr?.push(contactPersonForm?.values)
        // }
        if (values.id) {
            delete values.id;
        }
        let newData = { ...values, contactInfo: newContactArr };
        dataToSend = { ...dataToSend, ...newData }
    }
    if (role_key === PROFILE_FLOW_KEYS.INDIVIDUAL) {
        delete dataToSend.addressType;
    }
    dispatch(fetchAPI(ADDRESS_URL, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                //need to disapatch some action
                if (editFlag) {
                    let newArr = [...addressFormData];
                    newArr[index] = response.data;
                    dispatch(updateAddressData(newArr));
                } else {
                    dispatch(addAddressData(response.data));
                }
                if (role_key !== PROFILE_FLOW_KEYS.INDIVIDUAL) {
                    dispatch(updateContactPerson([]));
                    dispatch(reset('contactPersonForm'));
                    dispatch(reset('addressForm'));
                    dispatch(setAddressFlag(false));
                }

                if (dispatch(checkForTab('projects'))) {
                    // dispatch(setProfileTab('projects'));
                    // navigate("/user-profile/past-project");
                } else {
                    dispatch(setProfileTab('profile'));
                    // navigate("/user-profile/public-profile");
                }
                onClose();
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const submitContactAddress = (values, editFlag, index) => (dispatch, getState) => {
    if (editFlag) {
        let { profile: { contactInfo } } = getState();
        let newArr = [...contactInfo];
        newArr[index] = values;
        dispatch(updateContactPerson(newArr));
    } else {
        console.log("sumthing");
        dispatch(setContactPerson(values));
    }
    dispatch(submit('addressForm'));
    dispatch(setContactPersonFlag(false));
}

export const editContactPerson = () => (dispatch, getState) => {
    const { profile: { } } = getState();
}

export const getAboutUSPayload = (values, isUserProfile) => (dispatch, getState) => {
    let dataToPost;
    if (isUserProfile) {
        dataToPost = {
            name: values?.name,
            tagline: values?.tagline,
            phone: values?.phone,
            state_id: values?.state_id,
            country_id: values?.country_id,
            pincode: values?.pincode,
            address: values?.address
        }
    } else {
        dataToPost = {
            summary: values?.summary,
        }
    }
    return dataToPost;
}

export const getAboutUSPayload_old = (values) => (dispatch, getState) => {
    const { profile: { aboutUs, catchmentAreas } } = getState();
    const catchmentArea = catchmentAreas.length > 0 ? catchmentAreas : undefined;
    const thematicArea = (values.thematicArea && values.thematicArea.length) > 0 ? values.thematicArea : undefined;
    let dataToPost = {
        summary: values.summary,
        facebookURL: values.facebookURL,
        linkedInURL: values.linkedInURL,
        twitterURL: values.twitterURL,
        instagramURL: values.instagramURL,
        brandName: values.brandName,
        website: values.website,
        prefferedEventArea: values.prefferedEventArea,
        profileHeadline: values.profileHeadline,
        language: values.language,
        companyName: values.companyName,
        birthDate: values.birthDate,
        phoneNumber: values.phoneNumber,
        preferredState: values.preferredState,
        designation: values.designation,
        thematicArea,
        catchmentArea,
    }
    if (!aboutUs.company_id) {
        dataToPost = {
            ...dataToPost,
            company_id: dispatch(getCompanyId()),
        }
    }
    return dataToPost;
}

export const submitAboutUS = (values, isUserProfile, onClose) => (dispatch, getState) => {
    let { profile: { aboutUs } } = getState();
    let APIUrl = `${ABOUTUS_URL}${aboutUs?.gid}`;
    let dataToSend = dispatch(getAboutUSPayload(values, isUserProfile));
    dispatch(fetchAPI(APIUrl, httpMethod.PUT, dataToSend)).then(
        response => {
            if (response.success) {
                let newData = {
                    ...aboutUs,
                    ...response.data,
                }
                dispatch(addAboutUsData(newData));
                onClose();
                dispatch(getAnalytics(aboutUs?.gid));
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const submitVolunteerInterest = (values, onClose) => (dispatch, getState) => {
    let { profile: { aboutUs } } = getState();
    let dataToSend = {
        thematic_area_ids: values?.thematic_area_ids,
        language_ids: values?.language_ids,
        state_ids: values?.state_ids,
        gid: aboutUs?.gid,
    }
    dispatch(fetchAPI(VOLUNTEER_INTEREST_URL, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                let newData = {
                    ...aboutUs,
                    ...response.data,
                }
                dispatch(addAboutUsData(newData));
                onClose();
                dispatch(getAnalytics(aboutUs?.gid));
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const submitCatchmentAreas_old = (values, editFlag, index, onClose) => (dispatch, getState) => {
    let { profile: { catchmentAreas } } = getState();
    dispatch(setCatchmentFlag(true));
    dispatch(reset('catchmentAreaForm'));
    if (editFlag) {
        let newArr = [...catchmentAreas];
        newArr[index] = values;
        dispatch(updateCatchmentAreas(newArr));
    } else {
        const stateExists = catchmentAreas.some(area => area.state === values.state);
        if (!stateExists) {
            dispatch(addCatchmentAreas(values));
        }
        else {
            dispatch(setSnackbar({ flag: true, message: COMMON_MSG.SAME_STATE, type: "error" }));
        }
    }
    dispatch(setCatchmentFlag(false));
    dispatch(reset('catchmentAreaForm'));
    onClose();
}


export const getDistrict = (state) => (dispatch, getState) => {
    const { master: { district } } = getState();
    const newArr = district.filter((data) => data.state_id == state);
    dispatch(addProfileDistrict(newArr));
}

export const getMultipleDistrict = (states) => (dispatch, getState) => {
    const { master: { district } } = getState();
    const stateArray = Array.isArray(states) ? states : [states];

    const newArr = district.filter((data) => stateArray.includes(data.state_id));
    dispatch(addProfileDistrict(newArr));
}

export const getThematicAreas = () => (dispatch) => {
    dispatch(fetchAPI(THEMATIC_AREAS, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addThematicAreas(response.data.thematic_areas))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getAddressType = () => (dispatch) => {
    dispatch(fetchAPI(ADDRESS_TYPE, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addAddressType(response.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}


export const getStateId = (state) => (dispatch, getState) => {
    const { master } = getState();
    const searchObject = master && master.state.find((data) => data.name == state);
    return searchObject.id;
}
export const getUploadPayload = (file, uploadInfo) => (dispatch, getState) => {
    let uploadPayload = {
        filename: uploadInfo.FIELD_NAME,
        key: uploadInfo.KEY,
        file
    }
    if (uploadInfo.KEY === UPLOAD_KEYS.LOGO || uploadInfo.KEY === UPLOAD_KEYS.COMPLIANCE) {
        uploadPayload = {
            ...uploadPayload,
            id: dispatch(getCompanyId()),
        }
    }
    else if (uploadInfo.KEY === UPLOAD_KEYS.PROJECT) {
        if (uploadInfo.editFlag) {
            const { profile: { pastProjectData } } = getState();
            uploadPayload = {
                ...uploadPayload,
                id: pastProjectData[uploadInfo.formIndex].id,
            }
        } else {
            let tempId = dispatch(getProjectUploadId(uploadInfo));
            if (tempId) {
                uploadPayload = {
                    ...uploadPayload,
                    tempId,
                }
            }
        }
    }
    return uploadPayload;
}

export const getUploadedDocURL = (uploadInfo) => (dispatch, getState) => {
    const { profile: { uploadedData } } = getState();
    const searchObject = uploadedData && uploadedData.find((data) => data?.key === uploadInfo?.FIELD_NAME);
    if (searchObject) {
        let firlURL = `${FILE_HOST}${searchObject.filename}`;
        if (searchObject.filename) {
            return firlURL;
        }
    }
}

export const getUploadedDocName = (uploadInfo) => (dispatch, getState) => {
    const { profile: { uploadedData } } = getState();
    const searchObject = uploadedData && uploadedData?.find((data) => data?.key === uploadInfo?.FIELD_NAME);
    if (searchObject) {
        const filename = searchObject.filename === "" || searchObject.filename === null
        return filename;
        // let firlURL = `${FILE_HOST}${searchObject.filename}`;
        // if (searchObject.filename) {
        //     return firlURL;
        // }
    } else {
        return true;
    }
}

export const getProjectUploadId = (uploadInfo) => (dispatch, getState) => {
    const { profile: { uploadedData } } = getState();
    const searchObject = uploadedData && uploadedData.find((data) => data.key === uploadInfo.FIELD_NAME);
    if (searchObject) return searchObject.id;
}

export const addUploadedData = (data, key) => (dispatch, getState) => {
    const { profile: { uploadedData } } = getState();
    const newArr = uploadedData.filter((data) => (
        data.key != key
    ));
    newArr.push(data);
    dispatch(setUploadedData(newArr));
}

export const removeUploadedData = (key) => (dispatch, getState) => {
    const { profile: { uploadedData } } = getState();
    const newArr = uploadedData.filter((data) => (
        data.key != key
    ));
    dispatch(setUploadedData(newArr));
}


export const uploadProfile = (file, name, isUploadPopup) => (dispatch, getState) => {
    const { profile: { aboutUs }, users: { userData } } = getState();
    const formData = new FormData();
    formData.append(name, file);
    formData.append('gid', userData?.gid);
    return dispatch(fetchAPI(PROFILE_PHOTO, httpMethod.PUT, formData, '', true)).then(
        response => {
            if (response.success) {
                let newData = {
                    ...aboutUs,
                    [name]: response.data[name]
                }
                dispatch(addAboutUsData(newData));
                if (name === 'profile_pic') {
                    let newUserData = {
                        ...userData,
                        [name]: response.data[name]
                    }
                    dispatch(setUserData(newUserData));
                }
                if (!isUploadPopup) {
                    dispatch(getAnalytics(aboutUs?.gid));
                }
                // return response.data;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg || response.error, type: "error" }));
                return false;
            }
        },
    );
}

export const uploadProfile_Old = (file, uploadInfo) => (dispatch, getState) => { // this functions in not in use now
    const { profile: { aboutUs } } = getState();
    if (uploadInfo.FIELD_NAME === UPLOAD_DATA.ANNEXURE.FIELD_NAME) { // condition for annexure upload
        dispatch(uploadAnnexure(file, uploadInfo));
        return;
    }
    const dataToPost = dispatch(getUploadPayload(file, uploadInfo));
    let formData = new FormData();
    for (const key in dataToPost) {
        formData.append(key, dataToPost[key])
    }
    return dispatch(fetchAPI(PROFILE_PHOTO, httpMethod.POST, formData, '', true)).then(
        response => {
            if (response.success) {
                const uploadedData = {
                    ...response.data,
                    key: uploadInfo.FIELD_NAME,
                }
                dispatch(addUploadedData(uploadedData, uploadInfo.FIELD_NAME));
                if (uploadInfo.KEY === UPLOAD_KEYS.LOGO && !_.isEmpty(aboutUs)) { // update the logo in aboutUS
                    let newData = {
                        ...aboutUs,
                        company_logo: response.data.filename
                    }
                    dispatch(addAboutUsData(newData));
                }
                if (uploadInfo.KEY === UPLOAD_KEYS.PROJECT && uploadInfo.editFlag) {
                    dispatch(getCompleteProfile());
                }
                // update the profile logo in login data
                if (uploadInfo.FIELD_NAME === 'company_logo') {
                    // dispatch(updateUserListLogo(response.data.filename));
                }

                return response.data;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
                return false;
            }
        },
    );
}

export const deleteProjectUploadedFile = (key, filename, editFlag, index) => (dispatch, getState) => {
    let dataToSend = {
        filename,
        key
    }
    if (editFlag) {
        const { profile: { pastProjectData } } = getState();
        dataToSend = {
            ...dataToSend,
            id: pastProjectData[index].id,
        }
    } else {
        // deleteing from the temp
        const { profile: { uploadedData } } = getState();
        const searchObject = uploadedData && uploadedData.find((data) => data.key === filename);
        if (searchObject && searchObject.id) {
            dataToSend = {
                ...dataToSend,
                tempId: searchObject.id
            }
        }
    }

    dispatch(fetchAPI(DELETE_FILE, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(removeUploadedData(filename));
                if (editFlag) {
                    dispatch(getCompleteProfile());
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const deleteUploadedFile = (key, filename) => (dispatch) => {
    let dataToSend = {
        filename,
        key,
        id: dispatch(getCompanyId()),
    }
    dispatch(fetchAPI(DELETE_FILE, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(removeUploadedData(filename));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
// export const uploadProfile = (file, uploadInfo) => async(dispatch, getState) => {
//    const {users} = getState();
//    const headers = {
//      Authorization: `Bearer ${users.userData.token.accessToken}`,
//     }
//     const dataToPost = dispatch(getUploadPayload(file,uploadInfo));

//     let formData = new FormData();
//     for (const key in dataToPost) {
//       formData.append(key, dataToPost[key])
//     }
//     console.log('formData', formData);
//    // const boundary = formData.getBoundary();
//    let response;
//    let restArguments = {
//     method: httpMethod.POST,
//         body: formData,
//         headers: {
//             ...headers,
//            // 'Content-Type': `multipart/form-data`
//         }
//    }
//    try {
//      response = await fetch(PROFILE_PHOTO, restArguments);
//      console.log('response', response);
//    }catch (err) {
//     console.log('err', err);
//    }
//     // fetch(PROFILE_PHOTO, restArguments)
//     //     .then(response => {
//     //       // Handle the response
//     //       console.log("Response", response)
//     //     })
//     //     .catch(error => {
//     //       // Handle errors
//     //       console.log("error", error)
//     //     });

//     // dispatch(fetchAPI(PROFILE_PHOTO, httpMethod.POST, dataToPost, true)).then(
//     //     response => {
//     //         if(response.success){
//     //             dispatch(setSnackbar({flag: true, message: response.data.message, type: "success" }))
//     //         }else if(response.error){
//     //            // dispatch(setSnackbar({flag: true, message: response.error.errorMsg, type: "error" }));
//     //         }
//     //     },
//     // );
// }

export const deleteCatchmentArea_old = (deleteIndex) => (dispatch, getState) => {
    const { profile: { catchmentAreas } } = getState();
    const newArr = catchmentAreas.filter((data, index) => (
        index != deleteIndex
    ));
    dispatch(updateCatchmentAreas(newArr));
}

export const deleteContactPerson = (deleteIndex) => (dispatch, getState) => {
    const { profile: { contactInfo } } = getState();
    const newArr = contactInfo.filter((data, index) => (
        index != deleteIndex
    ));
    dispatch(updateContactPerson(newArr));
}

export const deleteAddress = (deleteIndex) => (dispatch, getState) => {
    const { profile: { contactInfo, aboutUs } } = getState();
    const id = contactInfo[deleteIndex]?.id;
    const dataToPost = {
        gid: aboutUs?.gid,
    }
    dispatch(fetchAPI(`${ADDRESS_URL}/${id}`, httpMethod.DELETE, dataToPost)).then(
        response => {
            if (response.success) {
                const newArr = contactInfo.filter((data, index) => (
                    index != deleteIndex
                ));
                dispatch(dispatch(updateContactPerson(newArr)));
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.ADDRESS_DELETED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const submitProjectLocation = (values, editFlag, index) => (dispatch, getState) => {
    let { profile: { projectArea } } = getState();
    dispatch(setProjectLocationFlag(true));
    dispatch(reset('projectLocationForm'));
    if (editFlag) {
        let newArr = [...projectArea];
        newArr[index] = values;
        dispatch(updateProjectLocation(newArr));
    } else {
        const stateExists = projectArea.some(area => area.state === values.state);
        if (!stateExists) {
            dispatch(addProjectLocation(values));
        }
        else {
            dispatch(setSnackbar({ flag: true, message: COMMON_MSG.SAME_STATE, type: "error" }));
        }
    }
    dispatch(setProjectLocationFlag(false));
    dispatch(reset('projectLocationForm'));
}

export const deleteProjectLocation = (deleteIndex) => (dispatch, getState) => {
    const { profile: { projectArea } } = getState();
    const newArr = projectArea.filter((data, index) => (
        index != deleteIndex
    ));
    dispatch(updateProjectLocation(newArr));

}

export const getProjectUploadTempIds = () => (dispatch, getState) => {
    const { profile: { uploadedData } } = getState();
    const tempIds = uploadedData && uploadedData.map((data) => {
        if (data.key === UPLOAD_DATA.PROJECT_PHOTO.FIELD_NAME || data.key === UPLOAD_DATA.PROJECT_DOC.FIELD_NAME) {
            return data.id
        }
    });
    return [...new Set(tempIds)];
}

export const getProjects = (gid) => (dispatch) => {
    dispatch(setTabDataLoading(true));
    const dataToPost = { gid };
    dispatch(fetchAPI(PROJECT_URL, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(updatePastProjectData(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    ).finally(() => {
        // You can optionally add a delay here if needed
        setTimeout(() => {
            dispatch(setTabDataLoading(false));
        }, 1000); // 1 second delay
    });
}

export const getDocumentDownload = (id) => (dispatch, getState) => {
    const { profile: { aboutUs } } = getState();
    const dataToPost = { id, gid: aboutUs?.gid };
    dispatch(fetchAPI(PROJECT_DOCUMENT_DOWNLOAD_URL, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                const url = response?.data?.url;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'document'); // Optional: Set a default filename
                link.setAttribute('target', '_blank'); // Open in a new tab
                link.setAttribute('rel', 'noopener noreferrer'); // Security measure
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const submitPastProjects = (values, editFlag, index, onClose) => (dispatch, getState) => {
    let { profile: { aboutUs: { gid }, pastProjectData } } = getState();
    let APIUrl = PROJECT_URL;
    let method = httpMethod.POST;
    if (editFlag) {
        let id = pastProjectData[index].id;
        APIUrl = `${PROJECT_URL}/${id}`;
        method = httpMethod.PUT
    }
    let dataToSend = {
        ...values,
        gid,
        budget_private: !values.budget_private ? false : true
    }
    let formData = new FormData();
    if (values?.photos) {
        if (isDataURL(values?.photos)) {
            let photo = base64ToFile(values.photos, UPLOAD_DATA.PROJECT_PHOTO.FIELD_NAME, true);
            formData.append('photos', photo);
            delete dataToSend.photos;
        } else {
            delete dataToSend.photos;
        }
    }
    if (values?.documents) {
        if (isDataURL(values?.documents)) {
            let document = base64ToFile(values.documents, UPLOAD_DATA.PROJECT_DOC.FIELD_NAME, true);
            formData.append('documents', document);
            delete dataToSend.documents;
        } else {
            delete dataToSend.documents;
        }
    }
    for (const key in dataToSend) {
        if (dataToSend.hasOwnProperty(key)) {
            if (Array.isArray(dataToSend[key])) {
                // If the property is an array, append each value with the correct key format
                dataToSend[key].forEach(value => {
                    let formKey = `${key}[]`;
                    formData.append(formKey, parseInt(value));
                });
            } else {
                // If the property is not an array, append it as it is
                formData.append(key, dataToSend[key]);
            }
        }
    }
    dispatch(fetchAPI(APIUrl, method, formData, '', true)).then(
        response => {
            if (response.success) {
                if (editFlag) {
                    let newArr = [...pastProjectData];
                    newArr[index] = response.data;
                    dispatch(updatePastProjectData(newArr));
                } else {
                    let newArr = [response.data, ...pastProjectData];
                    dispatch(updatePastProjectData(newArr));
                }
                onClose();
                dispatch(getAnalytics(gid));
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg || response?.message, type: "error" }));
            }
        },
    );
}

export const submitPastProjects_old = (values, editFlag, index, onClose) => (dispatch, getState) => {
    let { profile: { projectArea, pastProjectData }, form: { projectLocationForm } } = getState();
    // if (projectArea.length < 1) {
    //     dispatch(setSnackbar({ flag: true, message: COMMON_MSG.PROJECT_LOCATION_ERROR, type: "error" }));
    //     return;
    // }
    let APIUrl = PROJECT_URL;
    let insertedData = "";
    if (editFlag) {
        let id = pastProjectData[index].id;
        APIUrl = `${PROJECT_URL}/${id}`;
        let newArr = [...pastProjectData];
        newArr[index] = values;
        newArr[index]['projectArea'] = projectArea;
        insertedData = { ...newArr[index] };
    } else {
        let newContactArr = [...projectArea];
        if (projectLocationForm.values && Object.keys(projectLocationForm.values).length > 0) {
            newContactArr.push(projectLocationForm.values)
        }
        insertedData = { ...values, projectArea: newContactArr };
    }
    let dataToSend = {
        company_id: dispatch(getCompanyId()),
        name: insertedData.name,
        projectArea: insertedData.projectArea,
        summary: insertedData.summary,
        thematicArea: insertedData.thematicArea,
        projectStartDate: insertedData.projectStartDate,
        projectEndDate: insertedData.projectEndDate,
        impact: insertedData.impact,
        project_title: insertedData.project_title,
    }
    if (!editFlag) { // condition for applyin tempids for uploaded doc
        dataToSend = {
            ...dataToSend,
            fileTempId: dispatch(getProjectUploadTempIds()),
        }
    }
    dispatch(fetchAPI(APIUrl, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                //need to disapatch some action
                if (editFlag) {
                    let newArr = [...pastProjectData];
                    newArr[index] = response.data;
                    dispatch(updatePastProjectData(newArr));
                } else {
                    dispatch(addPastProjectData(response.data));
                }
                dispatch(removeUploadedDataFromTemp());
                dispatch(reset('pastProjectForm'));
                // dispatch(reset('projectLocationForm'));
                // dispatch(updateProjectLocation([]));
                dispatch(setPastProjectFlag(false));
                onClose();
                if (dispatch(checkForTab('compliances'))) {
                    // dispatch(setProfileTab('compliances'));
                    // navigate("/user-profile/compliances");
                }
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}


// export const submitPastProjects = (values, editFlag, index,onClose) => (dispatch, getState) => {
//     let { profile: { projectArea, pastProjectData }, form: { projectLocationForm } } = getState();
//     // if (projectArea.length < 1) {
//     //     dispatch(setSnackbar({ flag: true, message: COMMON_MSG.PROJECT_LOCATION_ERROR, type: "error" }));
//     //     return;
//     // }
//     let APIUrl = PROJECT_URL;
//     let insertedData = "";
//     if (editFlag) {
//         let id = pastProjectData[index].id;
//         APIUrl = `${PROJECT_URL}/${id}`;
//         let newArr = [...pastProjectData];
//         newArr[index] = values;
//         newArr[index]['projectArea'] = projectArea;
//         insertedData = { ...newArr[index] };
//     } else {
//         let newContactArr = [...projectArea];
//         if (projectLocationForm.values && Object.keys(projectLocationForm.values).length > 0) {
//             newContactArr.push(projectLocationForm.values)
//         }
//         insertedData = { ...values, projectArea: newContactArr };
//     }
//     let dataToSend = {
//         company_id: dispatch(getCompanyId()),
//         name: insertedData.name,
//         projectArea: insertedData.projectArea,
//         summary: insertedData.summary,
//         thematicArea: insertedData.thematicArea,
//         projectStartDate: insertedData.projectStartDate,
//         projectEndDate: insertedData.projectEndDate,
//         impact: insertedData.impact,
//     }
//     if (!editFlag) { // condition for applyin tempids for uploaded doc
//         dataToSend = {
//             ...dataToSend,
//             fileTempId: dispatch(getProjectUploadTempIds()),
//         }
//     }
//     dispatch(fetchAPI(APIUrl, httpMethod.POST, dataToSend)).then(
//         response => {
//             if (response.success) {
//                 //need to disapatch some action
//                 if (editFlag) {
//                     let newArr = [...pastProjectData];
//                     newArr[index] = response.data;
//                     dispatch(updatePastProjectData(newArr));
//                 } else {
//                     dispatch(addPastProjectData(response.data));
//                 }
//                 dispatch(removeUploadedDataFromTemp());
//                 dispatch(reset('pastProjectForm'));
//                 dispatch(reset('projectLocationForm'));
//                 dispatch(updateProjectLocation([]));
//                 dispatch(setPastProjectFlag(false));
//                 onClose();
//                 if (dispatch(checkForTab('compliances'))) {
//                     // dispatch(setProfileTab('compliances'));
//                     // navigate("/user-profile/compliances");
//                 } else {
//                     // navigate("/user-profile/public-profile");
//                 }
//                 dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
//             } else if (response.error) {
//                 dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
//             }
//         },
//     );
// }


export const removeUploadedDataFromTemp = () => (dispatch, getState) => {
    const { profile: { uploadedData } } = getState();
    const newArr = uploadedData.filter((data) => {
        if (data.key != "project_photos" && data.key != "project_documents") {
            return data;
        }
    });
    dispatch(setUploadedData(newArr));
}


export const deletePastProjects = (deleteIndex) => (dispatch, getState) => {
    const { profile: { pastProjectData, aboutUs } } = getState();
    const id = pastProjectData[deleteIndex].id;
    const dataToPost = {
        gid: aboutUs?.gid,
    }
    dispatch(fetchAPI(`${PROJECT_URL}/${id}`, httpMethod.DELETE, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(dispatch(updatePastProjectData(response.data)));
                dispatch(getAnalytics(aboutUs?.gid));
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.PROJECT_DELETED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}


export const getAnnexureList = () => (dispatch) => {
    dispatch(fetchAPI(ANNEXURE_URL, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addAnnexureList(response.data.annexures))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getAnnexures = (gid) => (dispatch) => {
    dispatch(setTabDataLoading(true));
    const dataToPost = { gid };
    dispatch(fetchAPI(ANNEXURE_UPLOAD, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(addAnnexureData(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    ).finally(() => {
        // You can optionally add a delay here if needed
        setTimeout(() => {
            dispatch(setTabDataLoading(false));
        }, 1000); // 1 second delay
    });
}
export const submitAnnexure = (values, annexure, editFlag, index, fileName, onClose) => (dispatch, getState) => {
    const { profile: { aboutUs, annexureData } } = getState();
    let APIUrl = ANNEXURE_UPLOAD;
    let method = httpMethod.POST;
    let dataToPost = {
        gid: aboutUs?.gid,
        ...values,
    }
    const formData = new FormData();
    if (editFlag) {
        let id = annexureData[index].id;
        APIUrl = `${ANNEXURE_UPLOAD}/${aboutUs?.gid}`;
        method = httpMethod.PUT;
        formData.append('id', id);
    } else {
        formData.append('gid', dataToPost.gid);
        formData.append('annexure_type_id', annexure.id);
    }
    // Assuming dataToPost.file is a File object, if not you'll need to create a File object from the path
    if (dataToPost?.documents) {
        if (isDataURL(dataToPost?.documents)) {
            let file = base64ToFile(dataToPost?.documents, fileName, true)
            formData.append('document', file);
        } else {
            delete dataToPost.documents;
        }
    }
    dispatch(fetchAPI(APIUrl, method, formData, '', true)).then(
        response => {
            if (response.success) {
                dispatch(addAnnexureData(response?.data));
                dispatch(reset('compliancesForm'));
                onClose();
                dispatch(getAnalytics(aboutUs?.gid));
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const uploadAnnexure = (file) => (dispatch, getState) => {
    const { profile: { annexureData, annexureId } } = getState();
    const dataToPost = {
        company_id: dispatch(getCompanyId()),
        annexureId: annexureId.id,
        file
    }
    let formData = new FormData();
    for (const key in dataToPost) {
        formData.append(key, dataToPost[key])
    }
    dispatch(fetchAPI(ANNEXURE_UPLOAD, httpMethod.POST, formData, '', true)).then(
        response => {
            if (response.success) {
                let newArr = [...annexureData];
                newArr.push(response.data);
                dispatch(addAnnexureData(newArr));
                dispatch(change('compliancesForm', 'annexureId', ''));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const deleteAnnexure = (deleteId) => (dispatch, getState) => {
    const { profile: { annexureData, aboutUs } } = getState();
    // console.log('annexureData', annexureData[deleteIndex].annexureId, 'deleteIndex', deleteIndex);
    // return;
    const id = annexureData[deleteId].id;
    const dataToPost = {
        id
    }
    dispatch(fetchAPI(`${ANNEXURE_UPLOAD}/${aboutUs?.gid}`, httpMethod.DELETE, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(addAnnexureData(response?.data));
                dispatch(getAnalytics(aboutUs?.gid));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getCatchmentAreas = (gid) => (dispatch) => {
    dispatch(setTabDataLoading(true));
    const dataToPost = { gid };
    dispatch(fetchAPI(CATCHMENT_AREA, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(updateCatchmentAreas(response?.data?.catchment_area));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    ).finally(() => {
        // You can optionally add a delay here if needed
        setTimeout(() => {
            dispatch(setTabDataLoading(false));
        }, 1000); // 1 second delay
    });
}

export const submitCatchmentAreas = (values, editFlag, onClose) => (dispatch, getState) => {
    const { profile: { catchmentAreas, aboutUs } } = getState();
    let APIUrl = CATCHMENT_AREA;
    let method = httpMethod.POST;
    let dataToPost;
    if (editFlag) {
        APIUrl = `${CATCHMENT_AREA}/${aboutUs?.gid}`;
        method = httpMethod.PUT;
        dataToPost = {
            // district_ids: values?.district,
            // state_id: values?.state
            ...values,
        }
    } else {
        dataToPost = {
            gid: aboutUs?.gid,
            ...values,
        }
    }
    dispatch(fetchAPI(APIUrl, method, dataToPost)).then(
        response => {
            if (response.success) {
                if (editFlag) {
                    dispatch(updateCatchmentAreas(response?.data?.catchment_area));
                } else {
                    dispatch(addCatchmentAreas(response?.data?.catchment_area[0]));
                }
                dispatch(reset('catchmentAreaForm'));
                onClose();
                dispatch(getAnalytics(aboutUs?.gid));
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const deleteCatchmentArea = (deleteId) => (dispatch, getState) => {
    const { profile: { catchmentAreas, aboutUs } } = getState();
    const state_id = catchmentAreas[deleteId].state_id;
    const dataToPost = {
        state_id
    }
    dispatch(fetchAPI(`${CATCHMENT_AREA}/${aboutUs?.gid}`, httpMethod.DELETE, dataToPost)).then(
        response => {
            if (response.success) {
                const newArr = catchmentAreas.filter((data, index) => (
                    index != deleteId
                ));
                dispatch(updateCatchmentAreas(newArr));
                dispatch(getAnalytics(aboutUs?.gid));
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.RECORD_DELETED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getStateName = (stateIds) => (dispatch, getState) => {
    const { master: { state } } = getState();

    if (stateIds?.length > 1 && Array?.isArray(stateIds)) {
        const stateList = stateIds?.map((id) => {
            const stateObj = state?.find((data) => data?.id == id);
            if (stateObj) {
                return stateObj.name;
            }
            return '';
        });
        return stateList.join(', ');
    } else {
        const stateObj = state?.find((data) => data?.id == stateIds);
        return stateObj ? stateObj.name : '';
    }
};

export const getStateNameById = (stateId) => (dispatch, getState) => {
    const { master: { state } } = getState();
    const stateList = state?.find((state) => state?.id == stateId);
    return stateList?.value;
};


export const getDistrictNameById = (id) => (dispatch, getState) => {
    const { master: { district } } = getState();
    const districtList = district?.find((data) => data?.id == id);
    return districtList?.value;
};


export const getDistrictNameByIds = (districtIds) => (dispatch, getState) => {
    const { master: { district } } = getState();
    if (districtIds?.length > 1 && Array.isArray(districtIds)) {
        const districtList = districtIds && districtIds.map((id) => {
            const filterList = district.find((data) => data.id == id);
            if (filterList) {
                return filterList.value;
            }
        });
        const districtString = districtList.join(', ');
        return districtString.toString();
    } else {
        const filterList = district.find((data) => data.id == districtIds);
        if (filterList) {
            return filterList.value;
        }
    }
};

export const getCompliances = (gid) => (dispatch) => {
    dispatch(setTabDataLoading(true));
    const dataToPost = { gid };
    dispatch(fetchAPI(COMPLIANCE_URL, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(addComplianceData(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    ).finally(() => {
        // You can optionally add a delay here if needed
        setTimeout(() => {
            dispatch(setTabDataLoading(false));
        }, 1000); // 1 second delay
    });
}

export const submitCompliances = (values, compliance_type_id, fileName, editFlag, index, onClose) => (dispatch, getState) => {
    const { profile: { aboutUs, complianceData } } = getState();
    let APIUrl = COMPLIANCE_URL;
    let method = httpMethod.POST;
    let dataToPost = {
        gid: aboutUs?.gid,
        ...values,
    }
    const formData = new FormData();
    if (editFlag) {
        let id = complianceData[index].id;
        APIUrl = `${COMPLIANCE_URL}/${id}`;
        method = httpMethod.PUT;
    } else {
        formData.append('compliance[compliance_type_id]', compliance_type_id);
    }

    formData.append('gid', dataToPost.gid);
    if (values?.number) {
        formData.append('compliance[number]', dataToPost.number);
    } else {
        formData.append('compliance[title]', dataToPost.title);
    }
    if (values?.date) {
        formData.append('compliance[date]', dataToPost.date);
    }
    // Assuming dataToPost.file is a File object, if not you'll need to create a File object from the path
    if (dataToPost?.documents) {
        if (isDataURL(dataToPost?.documents)) {
            let file = base64ToFile(dataToPost?.documents, fileName, true)
            formData.append('compliance[file]', file);
        } else {
            delete dataToPost.documents;
        }
    }
    dispatch(fetchAPI(APIUrl, method, formData, '', true)).then(
        response => {
            if (response.success) {
                let newArr = [response.data, ...complianceData];
                dispatch(addComplianceData(newArr));
                // dispatch(setProfileTab('profile'));
                dispatch(reset('compliancesForm'));
                onClose();
                dispatch(getAnalytics(aboutUs?.gid));
                // navigate("/user-profile/public-profile");
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const deleteCompliance = (deleteIndex) => (dispatch, getState) => {
    const { profile: { complianceData, aboutUs } } = getState();
    const id = complianceData[deleteIndex]?.id;
    const dataToPost = {
        gid: aboutUs?.gid,
    }
    dispatch(fetchAPI(`${COMPLIANCE_URL}/${id}`, httpMethod.DELETE, dataToPost)).then(
        response => {
            if (response.success) {
                const newArr = complianceData.filter((data, index) => (
                    index != deleteIndex
                ));
                dispatch(dispatch(addComplianceData(newArr)));
                dispatch(getAnalytics(aboutUs?.gid));
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.ADDRESS_DELETED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const Upgrade_Account = (values, handleClose) => (dispatch, getState) => {
    const dataToSend = {
        user_type_id: values?.usertype?.id,
        organization_name: values?.companyName
    };
    dispatch(fetchAPI(UPGRADE_ACCOUNT, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                window.location.reload();
                handleClose();
                // dispatch(setSnackbar({ flag: true, message: response?.message, type: "success" }))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        },
    );
}

export const getChangeSendOTP = () => (dispatch) => {
    dispatch(fetchAPI(SEND_CHANGE_OTP, httpMethod.GET)).then(
        response => {
            if (response.success) {
                // dispatch(addAnnexureList(response.data.annexures))
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }))
                dispatch(setSendOtp(true));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const changePassword = (values,handleClose) => (dispatch, getState) => {
//console.log("gell",values)
    const dataToSend = {
        otp: values.verification_code,
        // otp: Number(values.verification_code), 
        password: values?.password,
        ...(values.signout && { signout: true })
    };
    dispatch(fetchAPI(CHANGE_PASSWORD, httpMethod.PUT, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }))
                handleClose();
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.error.errorMsg, type: "error" }));
            }
        },
    );
}