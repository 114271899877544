import { Grid, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { change, initialize } from 'redux-form';
import icon_Close from '../../../../../assets/icons/Closebutton.svg';
import icons_editBlue from '../../../../../assets/icons/Edit_Blue.svg';
import icons_publish_Gray from '../../../../../assets/icons/icons_publish_Gray.svg';
import icons_publish_white from '../../../../../assets/icons/icons_publish_white.svg';
import theme from '../../../../../assets/material-ui/theme';
import { getColorCodeById } from '../../../../../common/actions';
import CommonInitials from '../../../../../common/components/CommonInitials';
import { EVENT_DASHBOARD, FILE_HOST } from '../../../../../common/constants';
import { gerCurrentDate, getCurrentTime } from '../../../../../common/utils';
import { setEventInitalValues, setEventPublish, setEventSecondaryTab, setEventTab, setEvent_Approval, } from '../../../../../store/slices/EventSlice';
import EventDatePreview from './EventDetailsSubComponents/EventDatePreview';
import EventFooter from './EventDetailsSubComponents/EventFooter';
import EventsCSS from './EventDetailsCSS/Events.module.css';
import EventNotes from './EventDetailsSubComponents/EventNotes';
import NewEventPreviewCard from './EventDetailsSubComponents/NewEventPreviewCard';
import PointOfContactPreview from './EventDetailsSubComponents/PointOfContactPreview';
import { getEventTypeText, getVolunteerCount, publishEvent } from '../actions';



import DemoLogo from '../../../../../assets/images/DemoLogo.svg';
import DemoCoverImage from '../../../../../assets/images/DemoEventCove.png';





const EventDetailsPreview = ({ _selectedTab, isEventStarted }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const { state } = location;
    const [loading, setLoading] = useState(false);
    const [popupApprovalOpen, setPopupApprovalOpen] = useState(false);
    const [MessageApprovalOpen, setMessageApprovalOpen] = useState(false);
    const [OpenPublish, setOpenPublish] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const selectedTab = _selectedTab || params?.secondaryTab || params?.selectedTab;
    const { socialMedia, users: { userData }, master: { pendingCall }, form: { eventForm } } = useSelector((state) => { return state; });
    const { eventData } = socialMedia;
    const previewEvent = location.pathname === '/events/preview' || location.pathname === `/events/preview/${eventData?.gid || params?.gid}`;
    let uniqueId = eventData?.gid || params?.gid;
    const eventFormValues = useSelector(state => state.form.eventForm?.values);
    const { brand_name, admin_company_logo, admin_company_colour_code, company_logo, colour_code_id } = userData;
    const Role_Id = userData?.sub_user ? 2 : 1;

    const moveToVolunteerManagement = () => {
        dispatch(setEventTab(EVENT_DASHBOARD.VOLUNTEER));
        dispatch(setEventSecondaryTab(EVENT_DASHBOARD.MYVOLUNTEER));
        navigate("/events/listing/volunteer-managenment/my-volunteers")
    }

    const handlePublish = () => {
        dispatch(setEventSecondaryTab(EVENT_DASHBOARD.PUBLISHED));
        dispatch(setEventPublish(EVENT_DASHBOARD.PUBLISHED))
        dispatch(publishEvent(navigate));
    };

    const handleEditVolunteer = () => {
        if (uniqueId) {
            navigate(`/events/create-event/${uniqueId}`);
        } else {
            navigate(`/events/create-event`);
        }
        if (!_.isEmpty(eventData)) {
            dispatch(setEventInitalValues({ ...eventData }));
        }
    };
    useEffect(() => {
    }, [uniqueId])
    const handleClose = () => {
        navigate(`/events/listing`);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
    };
    const handleOpenPublish = () => {
        setOpenPublish(true);
    };
    const handleClosePublish = () => {
        setOpenPublish(false);
    };
    const handleCloseApproval = () => {
        setPopupApprovalOpen(false)
    };
    const handleOpenApproval = () => {
        setPopupApprovalOpen(true)
    };
    const handleMessageApprovalClose = () => {
        setMessageApprovalOpen(false)
    };
    const handleButton2 = () => {
        handleClosePublish();
        handleEditVolunteer();
    };
    const handleApprovalButton1 = () => {
        dispatch(setEvent_Approval("no"));
        handleCloseApproval()
        if (eventFormValues?.management_type_id === 3 || eventFormValues?.management_type_id === 2) {
            setMessageApprovalOpen(true)
        } else {
            handlePublish();
        }
    };
    const handleApprovalButton2 = () => {
        dispatch(setEvent_Approval("yes"))
        handleCloseApproval();
        if (eventFormValues?.management_type_id === 3 || eventFormValues?.management_type_id === 2) {
            setMessageApprovalOpen(true)
        } else {
            handlePublish();
        }
    }
    const handleMessageApprovalButton2 = () => {
        handleMessageApprovalClose();
        handlePublish();
    }
    const handleEdit = () => {
        setIsPrivate(false);
        navigate(eventData?.gid ? `/events/create-event/${eventData?.gid}` : `/events/create-event`);
    }
    const isEventFormFilled = () => {
        const requiredFields = ['description', 'cover_photo', 'event_mode_id', 'beneficiary_count', 'event_type_id', 'name', 'tentative_cost', 'management_type_id', 'thematic_area_ids', 'volunteer_languages', 'volunteer_notes', 'volunteers_count', 'date_schedule', 'end_time', 'start_time'];
        if (eventFormValues?.event_mode_id === 2 || eventFormValues?.event_mode_id === '2') {
            // If event_mode_id is 1 (online),
            requiredFields.push('online_link');
        } else if (eventFormValues?.event_mode_id === 1 || eventFormValues?.event_mode_id === '1') {
            // If event_mode_id is 2 (In-person)
            requiredFields.push('transportation_mode_id', 'pin_code', 'address', 'district_id', 'state_id');
        }
        else if ((eventFormValues?.management_type_id === 3 || eventFormValues?.management_type_id === '3') || (eventFormValues?.management_type_id === 2 || eventFormValues?.management_type_id === '2')) {
            requiredFields.push('partner_id');
        }
        const formFields = Object.keys(eventFormValues);
        return requiredFields.every(field => formFields.includes(field) && eventFormValues[field]);
    };
    const isNewPocNotEmpty = eventData && eventData?.poc?.length > 0;
    const areFormsFilled = () => {
        return isEventFormFilled() && isNewPocNotEmpty;
    };
    const handleOpen = async () => {
        setLoading(true);
        try {
            const volunteersCountData = await dispatch(getVolunteerCount());
            if (!areFormsFilled()) {
                handleOpenPublish();
            } else if (eventData.event_schedule[0]?.event_date === gerCurrentDate() && eventData?.event_schedule[0].start_time < getCurrentTime()) {
                handleOpenPublish();
            } else if (eventData.event_schedule[0]?.event_date < gerCurrentDate()) {
                handleOpenPublish();
            } else if (eventData?.isprivate) {
                if (parseInt(eventForm?.values?.volunteers_count) > volunteersCountData) {
                    setIsPrivate(true);
                } else if (Role_Id === 2) {
                    handleOpenApproval()
                } else {
                    setIsPrivate(false);
                    if (eventFormValues?.management_type_id === 3 || eventFormValues?.management_type_id === 2) {
                        setMessageApprovalOpen(true)
                    } else {
                        handlePublish();
                    }
                }
            } else {
                if (Role_Id === 2) {
                    handleOpenApproval()
                    setIsPrivate(false);
                } else {
                    if (eventFormValues?.management_type_id === 3 || eventFormValues?.management_type_id === 2) {
                        setMessageApprovalOpen(true)
                    } else {
                        handlePublish();
                        setIsPrivate(false);
                    }
                }
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        dispatch(initialize("eventForm", eventData));
        dispatch(change("eventForm", "event_type_id", eventData?.event_type_id));
    }, [eventData])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Grid container spacing={2} sx={{ position: 'relative' }}>
                <Grid item xs={12}>
                    <Grid container sx={{ boxShadow: theme.shadow.softShadow3 }} borderRadius={5} overflow="hidden" backgroundColor="#ffffff">
                        <Grid item xs={12}>
                            <Box>
                                <Grid container className={`${EventsCSS.f} ${EventsCSS.ai_center} ${EventsCSS.jc_space_between}`} sx={{ position: 'relative' }} p={2}>
                                    <Grid item>
                                        <Box className={EventsCSS.newSkelEvCreatorProfile} sx={{ marginLeft: { xs: 0, sm: 3 } }}>

                                            {/* This one is static just for UI perspective and can be removed */}
                                            {Role_Id === undefined && (
                                                <CommonInitials name={eventData?.created_by_initials} divStyle={{ padding: '6px', maxHeight: '70px', maxWidth: '70px', minHeight: '70px', minWidth: '70px', borderRadius: '50%' }} typoStyle={{ fontSize: '29px', lineHeight: '60px' }} bgColor={eventData?.created_by_colour_code} avatarStyle={{ width: '70px', height: '70px' }} src={eventData?.created_by_logo} companyLogo={eventData?.created_by_logo} avatar={true} />
                                            )}
                                            {/* This one is static just for UI perspective and can be removed */}

                                            {Role_Id === 1 && (
                                                <CommonInitials name={eventData?.created_by_initials} divStyle={{ padding: '6px', maxHeight: '70px', maxWidth: '70px', minHeight: '70px', minWidth: '70px', borderRadius: '50%' }} typoStyle={{ fontSize: '29px', lineHeight: '60px' }} bgColor={eventData?.created_by_colour_code} avatarStyle={{ width: '70px', height: '70px' }} src={`${eventData?.created_by_logo}`} companyLogo={eventData?.created_by_logo} avatar={true} />
                                            )}
                                            {Role_Id === 2 && (
                                                <CommonInitials name={eventData?.created_by_initials} divStyle={{ padding: '6px', maxHeight: '70px', maxWidth: '70px', minHeight: '70px', minWidth: '70px', borderRadius: '50%' }} typoStyle={{ fontSize: '29px', lineHeight: '60px' }} bgColor={eventData?.created_by_colour_code} avatarStyle={{ width: '70px', height: '70px' }} src={`${eventData?.created_by_logo}`} companyLogo={eventData?.created_by_logo} avatar={true} />
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} sm={8}>
                                                <Box sx={{ pl: { xs: 0, sm: 6 }, ml: { xs: 0, sm: 6 }, textAlign: { xs: 'right', sm: 'left' } }}>
                                                    <Typography className={EventsCSS.newSkelAvatarTypo}>{eventData?.name}</Typography>
                                                    {/* <Typography className={EventsCSS.newSkelAvatarTypo}>{eventData?.created_by || state?.createdBy || brand_name}</Typography> */}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Box className={`${EventsCSS.f} ${EventsCSS.jc_end} ${EventsCSS.ai_center}`}>
                                                    <Box className={EventsCSS.newSkelBox}>
                                                        <Typography className={EventsCSS.newSkelTips}>
                                                            {eventData?.event_type_id === 1 ? 'Public Event' : eventData?.event_type_id === 2 ? 'Private Event' : eventData?.event_type_id}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={EventsCSS.newSkelBox2}>
                                {eventData?.cover_photo ? (
                                    <>
                                        <img src={eventData?.cover_photo} alt='Event photo' className={EventsCSS.newSkelCoverImage} />
                                        <Box className={EventsCSS.newSkelBox3}>
                                            <img src={eventData?.cover_photo} alt='Event photo' className={EventsCSS.newSkelCoverImageBG} />
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        {/* This one is static just for UI perspective and can be removed */}
                                        {/* <img src={DemoCoverImage} alt='Event photo' className={EventsCSS.newSkelCoverImage} />
                                        <Box className={EventsCSS.newSkelBox3}>
                                            <img src={DemoCoverImage} alt='Event photo' className={EventsCSS.newSkelCoverImageBG} />
                                        </Box> */}
                                        {/* This one is static just for UI perspective and can be removed */}

                                        {/* Below Commented Section Should be uncommented */}
                                        <Skeleton animation="wave" variant="rectangular" className={EventsCSS.newSkelCoverImage} height='310px' />

                                    </>
                                )}
                                {eventData && <NewEventPreviewCard />}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <EventDatePreview data={eventData} selectedTab={selectedTab} getEventTypeText={getEventTypeText} />
                </Grid>
                <Grid item xs={12}>
                    <PointOfContactPreview data={eventData} />
                </Grid>
                <Grid item xs={12}>
                    <EventNotes data={eventData} />
                </Grid>
                <Grid item xs={12}>
                    <EventFooter eventData={eventData} />
                </Grid>
            </Grid>
        </>
    )
}
export default EventDetailsPreview;