import { Box, IconButton, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { useLocation, useNavigate, useParams } from 'react-router';
import Hash from "../../../../assets/icons/Hash.svg";
import CommonButton2 from '../../../../common/components/CommonButton2';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FlagIcon from '../../../../assets/icons/flag_lightGrey.svg';
import EmptyHashtag from './EmptyHashtag';
import { useDispatch } from 'react-redux';
import { followHashtag, unFollowHashtag } from '../../actions';
import { useSelector } from 'react-redux';
import PostMoreItem from './PostMoreItem';
import ReportDrawer from '../Drawer/ReportDrawer';

const HashtagFollow = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const [reportDraweropen, setReportDraweropen] = useState(false);
    const [drawerGid, setDrawerGid] = useState(false);
    const { socialMedia, users, master } = useSelector((state) => { return state; });
    const isNotificationPost = location.pathname.startsWith(`/social-media/posts`);

    const open = Boolean(anchorEl);
    const postMorePopover = open ? 'simple-popover' : undefined;

    const handleFollowClick = () => {
        dispatch(followHashtag(params.hashtag, navigate));
    };
    const handleUnFollowClick = () => {
        dispatch(unFollowHashtag(params.hashtag, navigate));
    };
    const handlePopoverOpen = (event) => {
        event.stopPropagation();
        if (users?.isLoggedIn) {
            setAnchorEl(event.currentTarget);
        } else {
            navigate('/login');
        }
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handleReportDrawerOpen = (gid) => {
        setReportDraweropen(true);
        setDrawerGid(gid);
        handlePopoverClose();
    };

    const handleReportDrawerClose = () => {
        setReportDraweropen(false);
    };
    return (
        <>
            <Box className={SocialMediaCSS.hashtagFollowBox}>
                <Box className={SocialMediaCSS.BoxCon} gap={1}>
                    <img src={Hash} />
                    <Box >
                        <Typography className={SocialMediaCSS.hashtagText}>#{params.hashtag}</Typography>
                        <Typography className={SocialMediaCSS.hashFollow} pt={0.4}>{socialMedia?.postHashtag?.hashtag_follow_count ? socialMedia?.postHashtag?.hashtag_follow_count : 0} Followers</Typography>
                        <Box pt={1} />
                        <Box sx={{ display: "inline-block" }}>
                            {socialMedia?.postHashtag?.hashtag_follow === false &&
                                <Box className={SocialMediaCSS.followButton} onClick={handleFollowClick}>
                                    <Typography className={SocialMediaCSS.followText}>Follow</Typography>
                                </Box>}
                            {socialMedia?.postHashtag?.hashtag_follow === true &&
                                <Box className={SocialMediaCSS.followButton} onClick={handleUnFollowClick}>
                                    <Typography className={SocialMediaCSS.followText}>Following</Typography>
                                </Box>}
                        </Box>
                    </Box>
                </Box>
                <Box className={SocialMediaCSS.cursor} aria-describedby={postMorePopover} onClick={(event) => handlePopoverOpen(event)}>
                    <MoreHorizIcon />
                </Box>
            </Box >
            <Popover
                id={postMorePopover}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                className={SocialMediaCSS.popoverPostMore}
            >
                <Box className={SocialMediaCSS.postMoreOptionBox} onClick={handlePopoverClose}>
                    <Box onClick={() => handleReportDrawerOpen()} >
                        <PostMoreItem icon={FlagIcon} iconAlt='Report Post' title='Report this Hashtag' desc='Seeing something inappropriate' />
                    </Box>
                </Box>
            </Popover>
            <ReportDrawer open={reportDraweropen} gid={drawerGid} onClose={handleReportDrawerClose} socialMedia={socialMedia} master={master} title="Report Hashtag" desc="Report an abusive or inappropriate Hashtag." type="Hashtag" hash={params.hashtag} />
        </>
    )
}
export default HashtagFollow