import React from 'react';
import { Field } from 'redux-form';
import { SelectField as renderSelect } from './SelectField';
import { AutoSelectField as renderAutocompleteSelect } from './AutoSelectField';
import { MenuItem } from '@mui/material';

const CommonSelect = (props) =>{
  let component;
  if (props.type === 'Search') {
    component = renderAutocompleteSelect;
  } else {
    component = renderSelect;
  }
    return (
      <span className="common-select">
        <Field
          name={props.name}
          component={component}
          {...props}
          validate={props.validate}
          radius={props.radius}
          customHeight={props.customHeight}
          label={props.label}
          square={props.square}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          placeholder={props.placeholder}
          dirty={props.dirty}
          placeholderFontSize={props.placeholderFontSize}
          value={props.defaultValue || ''}
          style={props?.style}
          width={props.width}
          PaperPropsStyle={props.PaperPropsStyle}
          clockIcon={props.clockIcon}
        >
          {props.children}
        </Field>
      </span>
    );
}

export default CommonSelect;
