import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import LeftHeader from './LeftHeader';
import RightHeader from './RightHeader';
import { AppBar, Toolbar } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from "lodash-es";

export default function Header1() {
    const { users: { userData } } = useSelector((state) => {
        return state;
    })
    return (
        <Box py='1'>
            <AppBar elevation={0} style={{ borderBottom: '1px solid #dee2e6', background: '#FFF', boxShadow: '-1px 0px 14px 0px rgba(0, 0, 0, 0.25)' }} color="default" >
                <Toolbar variant="dense">
                    <Grid container alignItems="center" padding="8px 0px 8px 0px" display='flex' justifyContent='space-between' >
                        <Grid item >
                            <LeftHeader />
                        </Grid>
                        <Grid item  >
                            {/* {!_.isEmpty(userData) && <RightHeader /> } */}
                            <RightHeader />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

