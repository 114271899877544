import React, { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import theme from '../../../assets/material-ui/theme';
import { placements } from '@popperjs/core';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& $notchedOutline': {
            transition: 'border-color 0.3s ease',
            borderColor: theme.mainPalette.grey.grey400,
            borderRadius: '5px'
        },
        '&:hover $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '&$focused $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '&$disabled $notchedOutline': {
            WebkitTextFillColor: "#98A2B3",
            backgroundColor: '#E0E5FF',
        },
        '& input:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 1000px transparent inset',
            '-webkit-text-fill-color': '#333333 !important',
            'transition': 'background-color 5000s ease-in-out 0s',
        },
        height: '48px',
        // padding: '16px 12px !important',
        fontSize: '12px !important',
        fontWeight: '500 !important',
        lineHeight: '1.5',
    },
    
    focused: {},
    notchedOutline: {},

}));

export default function RenderPasswordNew({
    input,
    label,
    placeholder,
    type,
    fullWidth,
    // maxLength,
    underlineStyle,
    errorStyle,
    style,
    toolTiptext,
    id,
    pattern,
    defaultValue,
    disabled,
    min,
    max,
    value,
    meta: { touched, error, warning },
    inputProps,
    errorMessages,
    custom,
}) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const classes = useStyles();
    return (
        <>
        <FormControl sx={{ width: '100%' }} variant="outlined">
            {/* <InputLabel htmlFor="outlined-adornment-password" >{label}</InputLabel> */}
            <OutlinedInput
                id={input.name}
                type={showPassword ? 'text' : 'password'}
                value={defaultValue}
                min={min}
                max={max}
                fullWidth={fullWidth}
                {...input}
                {...custom}
                className='TextField'
                onKeyDown={(e) => {
                    if (e.key === ' ') {
                    e.preventDefault();  // Prevent typing space
                    }
                }}
                classes={{
                    root: classes.root,
                    focused: classes.focused,
                    disabled: classes.disabled,
                    notchedOutline: classes.notchedOutline,
                }}
                endAdornment={
                    <InputAdornment position="end" >
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                        className="visibility-button"
                        sx={{ color: theme.palette.grey.lightGreyVoilet }}
                    >
                    {showPassword ? <Visibility sx={{ height: '20px', width: '18px' }} /> : <VisibilityOff sx={{ height: '18px', width: '18px' }} />}
                </IconButton>
                </InputAdornment>
            }
            //   label={label}
            placeholder={placeholder}
            />
        </FormControl>
        {touched &&
            (error && (
                <Box className='error' sx={{padding:'0px', margin:'4px 0',textAlign:"left"}}>
                    {error}
                </Box>
            ))}
        </>
    );
}