import React, { useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import SocialMediaCSS from "../../modules/SocialMedia/SocialMediaCSS/SocialMedia.module.css";
import { Box, Typography } from '@mui/material';
import hashtag from "../../assets/icons/Hashtag.svg";
import CommonInitials from './CommonInitials';
import { getHastagList } from '../actions';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/slices/MessageSlice';

const RenderCommentInput = ({
    input,
    meta: { touched, error },
    placeholder,
    users,
    tags,
    maxLength,
    showCount,
    selectedTags,
    unSelectedTag,
    countFontSize,
    rows,
    onChange,
    setNewMessage,
    onExceedLimit,
    comment,
    onKeyDown,
    endAdornment,
    initialValue,
    setClearInput,
    editFlag,
    clearInput
}) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState(initialValue || '');
    const getRegularContent = (value) => {
        // return value.replace(/@\[[^\]]+\]\([^)]+\)|#[^\s]+/g, '');
        return value.replace(/@\[[^\]]+\]\([^)]+\)|#[^\s]+(?:\s+[^\s]+)?/g, '');
    };
    const getMentionsAndTagsLength = (value) => {
        const mentions = value.match(/@\[[^\]]+\]\([^)]+\)/g) || [];
        const hashtags = value.match(/#[^\s]+(?:\s+[^\s]+)?/g) || [];
        return mentions.join('').length + hashtags.join('').length;
    };
    // useEffect(() => {
    //     if (editFlag && initialValue) {
    //         setMessage(initialValue);
    //         setNewMessage(initialValue); // Sync newMessage as well
    //     }
    // }, [initialValue, editFlag]); 

    // console.log(clearInput, "iti")
    // console.log(message, "nit")
    // console.log(initialValue, 'hit')
    useEffect(() => {
        if (clearInput) {
            // console.log("Clearing Input...");
            setMessage('');
            setNewMessage('');
            setClearInput(false);
        }
    }, [clearInput]);
    const handleChange = (event, newValue) => {
        let value = newValue || event.target.value;

        const regularContent = getRegularContent(value);
        const mentionsAndTagsLength = getMentionsAndTagsLength(value);
        const totalLength = regularContent.length + mentionsAndTagsLength;
        // if (input.length <= 1000) {
        //     setMessage(input);
        //     setNewMessage(message)
        // }
        if (totalLength > 1000) {
            dispatch(setSnackbar({ flag: true, message: "You have exceeded the maximum character limit of 1000", type: "error" }));
            return; 
        }
        if (totalLength <= 1000) {
            input.onChange(value);
            setMessage(value);
            setNewMessage(value ? value : message)
        }
        const content = regularContent.trim();
        const mentionsRegex = /@\[[^\]]+\]\([^)]+\)/g; // Match mentions like @[User Two](user2)
        const hashtagsRegex = /#[^\]]+\]/g; // Match hashtags like #[Tag One]
        const mentions = value.match(mentionsRegex) || [];
        const hashtags = value.match(hashtagsRegex) || [];
        if (value.includes('#')) {
            dispatch(getHastagList());
        }

        // console.log('Mentions:', mentions);
        // console.log('Hashtags:', hashtags);
        // console.log('Content:', content);
    };
    const renderSuggestion = (users, search, highlightedIndex) => {
        const { initials, color_code, image } = users;
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CommonInitials
                    name={initials}
                    bgColor={color_code}
                    divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '36px', minWidth: '36px', borderRadius: '50%', cursor: 'pointer' }}
                    typoStyle={{ fontSize: '14px', lineHeight: '36px', }}
                    avatarStyle={{ width: '36px', height: '36px', boxShadow: " 5px 5px 10px 0px rgba(170, 170, 204, 0.50), -5px -5px 10px 0px #FFF" }}
                    src={image}
                    companyLogo={image}
                    avatar={true}
                />
                {/* <img src={users.image} alt="Tag Image" className={SocialMediaCSS.tagImg} style={{ marginRight: '12px', width: '36px', height: '36px' }} /> */}
                <Box ml={"12px"}>
                    <Typography className={SocialMediaCSS.tagHead}>{users.display}</Typography>
                    <Typography className={SocialMediaCSS.tagType}>{users.type}</Typography>
                </Box>
            </Box>
        );
    };
    const rendertagsSuggestion = (tags, search, highlightedIndex) => {

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={hashtag} alt="Tag Image" className={SocialMediaCSS.tagImg} style={{ marginRight: '12px', width: '36px', height: '36px' }} />
                <Box>
                    <Typography className={SocialMediaCSS.tagHead}>{tags.display}</Typography>
                    <Typography className={SocialMediaCSS.tagType}>{tags?.count} Public Post</Typography>
                </Box>
            </Box>
        );
    };
    const formattedUsers = users?.map(user => ({
        id: user.gid,
        display: user.value,
        image: user.image,
        type: user.type,
        initials: user.initials,
        color_code: user.colour_code
    }));
    const formattedTags = tags?.map(tag => ({
        id: tag.key,
        display: tag.value,
        count: tag.count
    }));
    return (
        <>
            <MentionsInput
                //value={input.value}
                value={message}
                onChange={handleChange}
                placeholder={placeholder}

                style={{
                    control: {
                        fontSize: 14,
                        fontWeight: 'normal',
                        borderRadius: '21px',
                        border: '1px solid #cccccc',
                        padding: '10px 35px 10px 18px',  // Adjust padding for a better single-line appearance
                        minHeight: '36px',  
                        overflow: 'hidden',  
                        whiteSpace: 'pre-wrap',  // Keep line breaks as entered by the user
                        wordBreak: 'break-word', 
                        // overflowY: 'auto',
                    },
                    highlighter: {
                        // padding: '10px 18px',
                        // height: '40px',
                    },
                    input: {
                        padding: 9,
                        padding: '10px 45px 10px 18px',
                        minHeight: '36px',
                        outline: 0,
                        border: 0,
                        overflow: 'hidden',  // Hide overflow in single-line mode
                    },
                    suggestions: {
                        marginTop: '40px',
                        maxHeight: 200,
                        overflowY: 'auto',
                        list: {
                            backgroundColor: 'white',
                            border: '1px solid rgba(0,0,0,0.15)',
                            fontSize: 14,
                            width: '300px',
                        },
                        item: {
                            padding: '5px 15px',
                            borderBottom: '1px solid rgba(0,0,0,0.15)',
                            '&focused': {
                                backgroundColor: '#cee4e5',
                            },
                        },
                    },
                }}
                inputProps={{ maxLength, endAdornment }}
                onKeyDown={onKeyDown}
            >
                <Mention
                    trigger="@"
                    data={formattedUsers}
                    style={{ backgroundColor: '#daf4fa' }}
                    renderSuggestion={renderSuggestion}
                    className={SocialMediaCSS.scrollable}
                    displayTransform={(id, display) => `@${display}`}
                />
                <Mention
                    trigger="#"
                    data={formattedTags}
                    style={{ fontStyle: 'italic', backgroundColor: '#daf4fa' }}
                    renderSuggestion={rendertagsSuggestion}
                    markup="#[__display__]"
                    displayTransform={(id, display) => `${display}`}
                />
            </MentionsInput>
            <div style={{ position: 'relative' }}>
                {endAdornment}
            </div>
        </>
    );
};

export default RenderCommentInput;