import React from "react";
import { Box, InputAdornment, OutlinedInput } from "@mui/material";

export const renderField = ({
  input,
  label,
  placeholder,
  type,
  fullWidth,
  maxLength,
  underlineStyle,
  errorStyle,
  style,
  toolTiptext,
  id,
  pattern,
  defaultValue,
  disabled,
  radius,
  min,
  max,
  value,
  onChange,
  errorWidth,
  onKeyPress,
  meta: { touched, error, warning },
  inputProps,
  errorMessages,
  custom,
  placeholderFontSize,
  placeholderFontWeight,
  customHeight,
  classes = {},
  endAdornment
}) => {
  let inputType = type;
  errorStyle = {
    fontSize: "10px",
    color: "rgb(244, 67, 54)",
    position: "static",
  };
  
  return (
    <>
      <OutlinedInput
        inputProps={{
          maxLength,
          disabled,
          min: 0,
          ...inputProps // ensure other inputProps are passed
        }}
        className={`TextField ${classes.root || ""}`}
        sx={{
          height: customHeight ? customHeight : 50,
          borderRadius: radius ? `${radius} !important` : 40,
          backgroundColor: (theme) => theme.palette.secondary.dark,
          "& input::placeholder": {
            fontSize: placeholderFontSize,
            fontWeight: placeholderFontWeight,
          },
          "& .MuiOutlinedInput-input": {
            fontSize: placeholderFontSize,
          },
        }}
        endAdornment={endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        )}
        placeholder={placeholder}
        id={id}
        disabled={disabled}
        autoComplete="off"
        pattern={pattern}
        type={inputType}
        onKeyPress={onKeyPress}
        fullWidth={fullWidth}
        value={defaultValue}
        onChange={input.onChange}
        min={min}
        max={max}
        style={style}
        {...input}
        {...custom}
      />
      {touched && error && (
        <Box
          style={{
            color: "red",
            fontSize: "12px",
            padding: 0,
            paddingLeft: "10px",
            wordBreak: "break-word",
            lineHeight: "14px",
            maxWidth: errorWidth ? errorWidth : "400px",
          }}
        >
          {error}
        </Box>
      )}
    </>
  );
};
