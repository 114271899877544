import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NgoLogo from '../../../../assets/images/Non-profit logo.svg';
import theme from '../../../../assets/material-ui/theme';
import { getCompanyType } from '../../actions';
import _ from 'lodash-es';

const ChooseUserType = ({ RegCSS, handleCompanyTypeClick, dispatch, user }) => {
    // useEffect(()=>{
    //     if(_.isEmpty(user.companyTypes)){
    //         dispatch(getCompanyType());
    //     }
    // }, [])
    const [isHovered, setIsHovered] = useState(false);

    const handleHover = (id) => {
        setIsHovered(id);
    }
    const handleHoverLeave = () => {
        setIsHovered(null);
    }
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = 'Changes you made may not be saved.';
            event.returnValue = message;
            return message;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    return (
        <>
            <Grid item xs={12} textAlign={'center'}>
                <Typography className={RegCSS.signUpMailHeading}>
                    What best describes your organization?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={4}>

                    <Grid item xs={12} className={RegCSS.chooseUTypeGrid}>
                        <Box className={RegCSS.chooseUTypeBox}>
                            {user && !_.isEmpty(user?.companyTypes) && user?.companyTypes?.map((types) => (
                                <Box className={RegCSS.chooseUTypeBox2} key={types.key}
                                    sx={{ background: isHovered === types?.key ? '#06BB70' : '#FFF' }}
                                    onMouseEnter={() => handleHover(types.key)}
                                    onMouseLeave={handleHoverLeave}
                                    onClick={() => { handleCompanyTypeClick(types.key) }}>
                                    <Box className={RegCSS.chooseUTypeImg} sx={{ background: isHovered === types?.key ? '#FFF' : 'transparent', borderRadius: isHovered === types?.key ? '50%' : 0 }}>
                                        <img src={types?.image ? types?.image : NgoLogo} alt='ngoLogo' style={{ width: isHovered === types?.key ? '32px' : '42px', height: isHovered === types?.key ? '40px' : '42px', borderRadius: isHovered === types?.key ? '50%' : '0%' }} />
                                    </Box>
                                    <Box className={RegCSS.chooseAccBox}>
                                        <Typography className={RegCSS.chooseUTypeTypo} sx={{ color: isHovered === types?.key ? '#FFFFFF' : '#333' }}>{types.value}</Typography>
                                    </Box>
                                </Box>))
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center'>
                            <Typography sx={{ fontSize: '14px', color: theme.mainPalette.grey.darkText, fontWeight: theme.typography.fontWeightMedium }}>Already have an account?</Typography>
                            <Link to='/login' underline="always" style={{ marginLeft: 8, color: theme.palette.primary.dark, fontSize: '14px', fontWeight: theme.typography.fontWeightMedium }}>Login</Link>
                        </Box>
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}

export default ChooseUserType
