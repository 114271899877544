import React from 'react';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';

import AddressIcon from '../../../../../assets/icons/Address.svg';
import SkypeIcon from '../../../../../assets/icons/skype.svg';
import ZoomIcon from '../../../../../assets/icons/zoom.svg';
import GoogleMeetIcon from '../../../../../assets/icons/GoogleMeet.svg';
import CiscoWebexIcon from '../../../../../assets/icons/ciscoWebex.svg';
import MicrosoftTeamsIcon from '../../../../../assets/icons/MicrosoftTeams.svg';
import OtherLinkIcon from '../../../../../assets/icons/otherLink.svg';

const EventLisitingLocationIcon = ({ view, eventType, location }) => {
    let iconSrc;
  
    if (eventType === 'In-Person') {
        iconSrc = AddressIcon;
    } else if (eventType === 'Online') {
        iconSrc = location?.image;
    }
    return (
        <>
            <img src={iconSrc} alt="Event Icon" className={ view === 'listView' ? SocialMediaCSS.h16 : SocialMediaCSS.h20} />
        </>
    )
}

export default EventLisitingLocationIcon
