import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    district: [],
    selectedActiveTab: null,
    selectedSection: 1,
    bEngageData: {},
    insightData: {},
    bEngageList: [],
    speakerList: [],
    sponsorList: [],
    awardList: [],
    uploadedData: [],
    partnershipList: [],
    deleglatesList: {},
    nominationLists: {},
    floorPlan: null,
    totalBEngageCount: 0,
    filterData: [],
    category: null,
    gid: null,
    bEngageInitialValues: {},
    isLoad: false,
    bEngageDashboard: [],
    otpDelivered: false,
    regGid: "",
    getNewRegId: "",
    agendaData: [],
    agendaSpeakerData: [],
    updateAgenda: false,
    emailList: [],
    nameList: [],
    awardGid: null,
    partnerGid: null,
    registrationDetails: {},
    conferenceParticiapnts: [],
    conferenceParticiapntsCount: 0,
    conferencesDetail: [],
    userSpeakerList: [],
    newPoc: [],
    newPocFlag: false,
    selectedConferenceMode: '',
    conferenceScheduleDates: [],
    conferenceScheduleTimes: [],
    conferenceBadge: '',
    selectedType: "",
    organisersLogos: [],
    invitations: [],
    addInvitationErrorData: [],
    pageNumber: 1,
    selectedBadgeName: "delegates",
    eventCategory: null,
    directoryList: [],
    totaldirectoryCount: 0,
    historyList: [],
}
const bEngageSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        resetbEngageSlice: () => initialState,
        addBEngageDistrict(state, action) {
            state.district = action.payload;
        },
        addBEngageData(state, action) {
            state.bEngageData = action.payload;
        },
        setGid(state, action) {
            state.gid = action.payload;
        },
        setSelectedBadgeName(state, action) {
            state.selectedBadgeName = action.payload;
        },
        setInsightData(state, action) {
            state.insightData = action.payload;
        },
        setBEngageListData(state, action) {
            state.bEngageList = action.payload;
        },
        setTotalCount(state, action) {
            state.totalBEngageCount = action.payload;
        },
        setCategory(state, action) {
            state.category = action.payload;
        },
        setFilterData(state, action) {
            state.filterData = action.payload;
        },
        setGid(state, action) {
            state.gid = action.payload;
        },
        setBEngageInitalValues(state, action) {
            state.bEngageInitialValues = action.payload;
        },
        setEventIsLoad(state, action) {
            state.isLoad = action.payload;
        },
        setSpeakerList(state, action) {
            state.speakerList = action.payload;
        },
        setSponsorList(state, action) {
            state.sponsorList = action.payload;
        },
        setPartnershipList(state, action) {
            state.partnershipList = action.payload;
        },
        setAwardList(state, action) {
            state.awardList = action.payload;
        },
        setFloorPlan(state, action) {
            state.floorPlan = action.payload;
        },
        setDelegatesList(state, action) {
            state.deleglatesList = action.payload;
        },
        setSelectedActiveTab(state, action) {
            state.selectedActiveTab = action.payload;
        },
        setBEngageDashboard(state, action) {
            state.bEngageDashboard = action.payload;
        },
        setOtpDelivered(state, action) {
            state.otpDelivered = action.payload;
        },
        setRegGid(state, action) {
            state.regGid = action.payload;
        },
        setGetNewRegId(state, action) {
            state.getNewRegId = action.payload;
        },
        updateAgenda(state, action) {
            const index = state.agendaData.findIndex(item => item.schedule_date === action.payload.schedule_date);
            if (index >= 0) {
                state.agendaData[index] = action.payload;
            } else {
                state.agendaData.push(action.payload);
            }
        },
        setAgendaSpeaker(state, action) {
            state.agendaSpeakerData = action.payload;
        },
        setUpdateAgenda(state, action) {
            state.updateAgenda = action.payload;
        },
        setAgendaList(state, action) {
            state.agendaData = action.payload;
        },
        setSelectedSection(state, action) {
            state.selectedSection = action.payload;
        },
        setNominationLists(state, action) {
            state.nominationLists = action.payload;
        },
        setAwardGid(state, action) {
            state.awardGid = action.payload;
        },
        setPartnerGid(state, action) {
            state.partnerGid = action.payload;
        },
        setEmailList(state, action) {
            state.emailList = action.payload;
        },
        setNameList(state, action) {
            state.nameList = action.payload;
        },
        setRegistrationDetails(state, action) {
            state.registrationDetails = action.payload;
        },
        setConferenceParticiapnts(state, action) {
            state.conferenceParticiapnts = action.payload;
        },
        setConferenceParticiapntsCount(state, action) {
            state.conferenceParticiapntsCount = action.payload;
        },
        setConferencesDetail(state, action) {
            state.conferencesDetail = action.payload;
        },
        setUserSpeakerList(state, action) {
            state.userSpeakerList = action.payload;
        },
        addPOC(state, action) {
            state.newPoc.push(action.payload);
        },
        updatePOC(state, action) {
            state.newPoc = action.payload;
        },
        setNewPocFlag(state, action) {
            state.newPocFlag = action.payload;
        },
        updateBengageUploadedData(state, action) {
            state.uploadedData = action.payload;
        },
        addBengageUploadedData(state, action) {
            state.uploadedData.push(action.payload);
        },
        setSelectedConferenceMode(state, action) {
            state.selectedConferenceMode = action.payload;
        },
        setConferenceScheduleDates(state, action) {
            state.conferenceScheduleDates = action.payload;
        },
        setConferenceScheduleTime(state, action) {
            state.conferenceScheduleTimes = action.payload;
        },
        setConferenceBadge(state, action) {
            state.conferenceBadge = action.payload;
        },
        setSelectedType(state, action) {
            state.selectedType = action.payload;
        },
        setOrganiserLogos(state, action) {
            state.organisersLogos = action.payload;
        },
        setInvitations(state, action) {
            state.invitations = action.payload;
        },
        setAddInvitationErrorData(state, action) {
            state.addInvitationErrorData = action.payload;
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        resetbEngageData(state) {
            state.bEngageData = initialState.bEngageData;
        },
        setEventCategory(state, action) {
            state.eventCategory = action.payload
        },
        setDirectoryList(state, action){
            state.directoryList = action.payload;
        },
        setTotalDirectoryCount(state, action){
            state.totaldirectoryCount = action.payload;
        },
        setHistoryList(state, action){
            state.historyList = action.payload;
        },
    }
})

export default bEngageSlice.reducer;
export const {
    setBEngageInitalValues,
    setInsightData,
    setBEngageListData,
    resetbEngageSlice,
    addBEngageDistrict,
    addBEngageData,
    setGid,
    setAwardGid,
    setPartnerGid,
    setBrandListData,
    setTotalCount,
    setFilterData,
    setCategory,
    setEventIsLoad,
    setSpeakerList,
    setSponsorList,
    setPartnershipList,
    setAwardList,
    setFloorPlan,
    setDelegatesList,
    setSelectedActiveTab,
    setBEngageDashboard,
    setOtpDelivered,
    setRegGid,
    setGetNewRegId,
    updateAgenda,
    setAgendaList,
    setUpdateAgenda,
    setAgendaSpeaker,
    setSelectedSection,
    setNominationLists,
    setEmailList,
    setNameList,
    setRegistrationDetails,
    setConferenceParticiapnts,
    setConferenceParticiapntsCount,
    setConferencesDetail,
    setUserSpeakerList,
    updatePOC,
    addPOC,
    setNewPocFlag,
    updateBengageUploadedData,
    addBengageUploadedData,
    setSelectedConferenceMode,
    setConferenceScheduleTime,
    setConferenceScheduleDates,
    setConferenceBadge,
    setSelectedType,
    setOrganiserLogos,
    setInvitations,
    setAddInvitationErrorData,
    setPageNumber,
    resetbEngageData,
    setSelectedBadgeName,
    setEventCategory,
    setDirectoryList,
    setTotalDirectoryCount,
    setHistoryList,

} = bEngageSlice.actions;