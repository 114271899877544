import { Box, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Ginni_Light from "../../assets/images/logo.svg";
import Ginni from '../../assets/icons/Ginni.svg';
import { Link } from 'react-router-dom';
import theme from '../../assets/material-ui/theme';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        height: "65px",
        paddingLeft: '30px',
        paddingRight: '30px',
        // position: 'fixed',
        // bottom: 0,
        width: '100%',
        overflow: 'hidden',
        marginTop: '0px',
        backgroundColor: theme.palette.grey.secondary,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: '130px'
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            left: '0px',
            right: '0px',
            height: '1px',
            background: '#B8B8B8',
            [theme.breakpoints.down('sm')]: {
                left: '0px',
                top: '0%',
                right: '0px',
            },
        },
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '12px',
        paddingRight: '12px',
        width: '100%',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginTop: '15px'
        },
    },
    leftText: {
        color: theme.mainPalette.grey.darkText,
        fontWeight: theme.typography.fontWeightMedium,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            // color: '#FFFFFF',
            marginRight: 0,
            // marginTop:'2',
            // marginBottom: '0',
        },
    },
    rightText: {
        flexGrow: 1,
        textAlign: 'right',
        marginLeft: 'auto',
        fontSize: '12px',
        color: theme.palette.success.light,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginLeft: 0,
        },
    },
    footerLinks: {
        fontSize: '0.65rem !important',
        color: theme.mainPalette.grey.darkText,
        fontWeight: theme.typography.fontWeightMedium,
        textDecoration: 'none'
    },
    logo: {
        height: 38,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            marginTop:'-15px',
            marginBottom:'6px'

        },
    },
}));
export const LightFooterNew = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root} sx={{ paddingLeft: '20px', paddingRight: '20px', }}>
            <div className={classes.borderLine} />
            <div className={classes.content}>
                <Typography style={{ fontSize: '0.75rem', fontWeight:theme.typography.fontWeightMedium }} className={classes.leftText}>
                    Powered by
                </Typography>&nbsp;
                <a href="https://www.ginnitech.com/" target="_blank"><img src={Ginni} alt="Ginni Logo" className={classes.logo} /></a>
                {/* <Typography style={{ fontSize: '12px' }} className={classes.rightText}>
                    User Safety | Privacy Policy | Terms & Conditions
                </Typography> */}
                <Box className={classes.rightText}>
                    <Link to='/restricted-content' className={classes.footerLinks}>Restricted Content |</Link>&nbsp;
                    <Link to='/community-guidelines' className={classes.footerLinks}>Community Guidelines |</Link>&nbsp;
                    <Link to='/user-agreement' className={classes.footerLinks}>User Agreement |</Link>&nbsp;
                    <Link to='/user-content-policy' className={classes.footerLinks}>User Content Policy |</Link>&nbsp;
                    <Link to='/user-data' className={classes.footerLinks}>User Data |</Link>&nbsp;
                    <Link to='/copyright-policy' className={classes.footerLinks}>Copyright Policy |</Link>&nbsp;
                    <Link to='/dispute-resolution' className={classes.footerLinks}>Dispute Resolution |</Link>&nbsp;
                    <Link to='/accessibility' className={classes.footerLinks}>Accessibility |</Link>&nbsp;
                    <Link to='/user-Saftey' className={classes.footerLinks}>User Safety |</Link>&nbsp;
                    <Link to='/privacy-policy' className={classes.footerLinks}>Privacy Policy |</Link>&nbsp;
                    <Link to='/terms-condition' className={classes.footerLinks}>Terms & Conditions</Link>
                </Box>
            </div>
        </Box>
    );
};