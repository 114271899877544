import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import PostHeader from "./PostHeader";
import PostMedia from "./PostMedia";
import PostContent from "./PostContent";
import PostActions from "./PostActions";
import PostComments from "./PostComments";
import EventPostContent from "./EventPostContent";
import { useDispatch } from "react-redux";
import SharedPostHeader from "./SharedPostHeader.jsx";
import { DocumentViewer } from "./DocumentViewer.jsx";
import _ from 'lodash-es';

const SinglePostLayout = ({ socialMedia, post, master, users, form, offset }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sharedLoading, setSharedLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [sharedUrl, setSharedUrl] = useState(null);
  const is_Share = post?.shared_module?.name === null ? false : true;



  // useEffect(() => {
  //   if (post?.primary_post?.post_document?.length > 0 && previewUrl) {
  //     setLoading(true); // Start loading when a document is detected
  //     // Simulate loading time if needed or wait for real file to load.
  //     setTimeout(() => setLoading(false), 1000); // Adjust time as needed
  //   }
  // }, [post, previewUrl]);

  useEffect(() => {
    if (!_.isEmpty(post) && post?.primary_post?.post_document?.length > 0) {
      const uploadedPdf = post?.primary_post?.post_document[0]?.document_url;

      if (uploadedPdf) {
        // console.log('Uploaded PDF URL:', uploadedPdf); 
        fetch(`${process.env.REACT_APP_RAILSURL}/proxy?url=${encodeURIComponent(uploadedPdf)}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          })
          .then(blob => {
            if (blob) {
              const fixedBlob = new Blob([blob], { type: 'application/pdf' });
              const localUrl = URL.createObjectURL(fixedBlob);
              // console.log('Local Blob URL:', localUrl);
              setPreviewUrl(localUrl);
            } else {
              // console.error('No blob returned for the PDF.');
              setPreviewUrl(null);
            }
          })
          .catch(error => {
            // console.error('Error loading file:', error);
            setPreviewUrl(null);
          });
      } else {
        // console.error('No valid PDF URL found.');
        setPreviewUrl(null);
      }
    } else {
      // console.error('Post or document not available.');
      setPreviewUrl(null);
    }
  }, [post]);

  useEffect(() => {
    if (!_.isEmpty(post) && post?.shared_module?.shared_document?.length > 0) {
      const uploadedPdf = post?.shared_module?.shared_document[0]?.document_url;

      if (uploadedPdf) {
        // console.log('Uploaded PDF URL:', uploadedPdf); 
        fetch(`${process.env.REACT_APP_RAILSURL}/proxy?url=${encodeURIComponent(uploadedPdf)}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          })
          .then(blob => {
            if (blob) {
              const fixedBlob = new Blob([blob], { type: 'application/pdf' });
              const localUrl = URL.createObjectURL(fixedBlob);
              // console.log('Local Blob URL:', localUrl);
              setSharedUrl(localUrl);
            } else {
              // console.error('No blob returned for the PDF.');
              setSharedUrl(null);
            }
          })
          .catch(error => {
            // console.error('Error loading file:', error);
            setSharedUrl(null);
          });
      } else {
        // console.error('No valid PDF URL found.');
        setSharedUrl(null);
      }
    } else {
      // console.error('Post or document not available.');
      setSharedUrl(null);
    }
  }, [post]);
  const documentUrl = post?.primary_post?.post_document?.[0]?.document_url;

  // useEffect(() => {
  //   if (post?.primary_post?.post_document?.length > 0 && documentUrl) {
  //     setLoading(true);
  //     // Simulate loading time or wait for the document to be ready
  //     setTimeout(() => setLoading(false), 6000); // Adjust the timeout as needed
  //   }
  // }, [post, previewUrl]);
  useEffect(() => {
    // Check if there is a post document and documentUrl to trigger loading
    if (post?.primary_post?.post_document?.length > 0 && documentUrl) {
      setLoading(true);
    }
    if (post?.shared_module?.shared_document?.length > 0) {
      setSharedLoading(true);
    }
  }, [post]);

  useEffect(() => {
    // Only stop loading when the previewUrl has been set 
    if (previewUrl) {
      setLoading(false);
    }
    if (sharedUrl) {
      setSharedLoading(false);
    }
  }, [previewUrl, sharedUrl]);
  return (
    <>
      <Box className={SocialMediaCSS.postCard}>
        <PostHeader post={post} users={users} master={master} socialMedia={socialMedia} />
        <Divider sx={{ marginTop: '-20px' }} />
        {is_Share === false ? (
          <>
            <PostMedia post={post} />
            {post?.primary_post?.post_document?.length > 0 && documentUrl && (
              loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                  <CircularProgress /> &nbsp; &nbsp;
                  <Typography className={SocialMediaCSS.detailDescription}>Loading document, please wait...</Typography>
                </Box>
              ))}

            {post?.primary_post?.post_document?.length > 0 && previewUrl && (
              !loading && (
                <DocumentViewer
                  pdfFile={previewUrl}
                  // pdfFile={post?.primary_post?.post_document[0]?.document_url} 
                  hidePreview={false} />
              )
            )}
            {/* <div>
            <input type="file" accept="application/pdf" onChange={handleFileChange} />
            {pdfFile && <DocumentViewer pdfFile={pdfFile} />}
        </div> */}
            <PostContent post={post?.body} sharedRFP={post} isLoggedIn={users?.isLoggedIn} />
          </>
        ) : (
          <>
            <PostContent post={post?.body} isLoggedIn={users?.isLoggedIn} />
            <Box className={SocialMediaCSS.postCard}>
              {!post?.shared_module?.certificate_image &&
                <SharedPostHeader post={post} users={users} master={master} socialMedia={socialMedia} isLoggedIn={users?.isLoggedIn} />}
              <PostMedia post={post} />
              {post?.shared_module?.shared_document?.length > 0 && (
                sharedLoading && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                    <CircularProgress /> &nbsp; &nbsp;
                    <Typography className={SocialMediaCSS.detailDescription}>Loading document, please wait...</Typography>
                  </Box>
                ))}
              {post?.shared_module?.shared_document?.length > 0 && sharedUrl && (
                !sharedLoading && (
                  <DocumentViewer
                    pdfFile={sharedUrl}
                    // pdfFile={post?.shared_module?.shared_document[0]?.document_url} 
                    hidePreview={false} />
                ))}
              <PostContent post={post?.shared_module?.body} sharedRFP={post} isLoggedIn={users?.isLoggedIn} />
            </Box>
          </>
        )}

        {/* Incase of Event */}
        {/* <EventPostContent /> */}
        {!post?.multiple_shared_post &&
          <>
            <Divider />
            <PostActions post={post} master={master} users={users} form={form} />
          </>}
        {/* Comment section Show and hide condition will be added here */}
        {/* <PostComments /> */}
      </Box>
    </>
  )
}

export default SinglePostLayout
