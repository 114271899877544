import { Box, Typography } from '@mui/material';
import React from 'react';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { useLocation, useParams } from 'react-router';
import emptyHash from "../../../../assets/images/emptyHashtag.svg";


const EmptyHashtag = () => {
    const location = useLocation();
    const params = useParams();

    return (
        <>
            <Box pt={2}>
                <Box className={SocialMediaCSS.emptyBox}>
                    <img src={emptyHash} />
                </Box>
                <Typography className={SocialMediaCSS.greeting}>Looks like this hashtag’s still</Typography>
                <Typography className={SocialMediaCSS.greeting}>waiting for its first post!</Typography>
                <Typography className={SocialMediaCSS.greeting2}>Follow this hashtag to stay updated.</Typography>
            </Box >
        </>
    )
}
export default EmptyHashtag