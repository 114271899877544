import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { capitalizeFirstLetter } from "../../../../../common/utils";
import { ResharePost } from "./ResharePost";
import ReshareAudience from "./ReshareAudience";
import ReshareActivity from "./ReshareActivity";
import ReshareTag from "./ReshareTag";
import ReshareCategory from "./ReshareCategory";



const ReshareProcess = ({ open, onClose, Title, post, usersImg, isEvent, eventGid, eventThematic,postId }) => {
    const { socialMedia, form, master, users } = useSelector((state) => { return state; });
    const dispatch = useDispatch();
    const postTypeKey = post?.primary_post?.post_type_name || 'public';
    const postType = master?.postTypes?.find(type => type?.key === postTypeKey);
    const postTypeValue = postType?.value;

    const [selectedActivity, setSelectedActivity] = useState(capitalizeFirstLetter());
    const [selectedTag, setSelectedTag] = useState('');
    const [unSelectedTag, setUnSelectedTag] = useState('');
    const [selectedPostTitle, setSelectedPostTitle] = useState('Public');
    const [postCategory, setPostCategory] = useState();
    const Activity = socialMedia.postActivityEdit;
    const tagPost = socialMedia.postTagPeopleEdit;
    const postAudience = socialMedia.postAudienceEdit;
    const showPostThematic = socialMedia.postCategoryEdit;
    // console.log("red",postType)
    const handleActivitySelect = (title) => {
        console.log(`Selected activity: ${title}`);
        setSelectedActivity(title);
    };
    const handleTagSelect = (tag) => {
        console.log(`Selected tags are: ${tag}`);
        setSelectedTag(tag);
    };
    const handleTagUnselect = (tag) => {
        console.log(`Unselected tag: ${tag}`);
        setUnSelectedTag(tag);
    };
    const handlePostSelect = (post) => {
        console.log(`Selected Posts are: ${post}`);
        setSelectedPostTitle(post);
    }
    return (
        <>
            {postAudience === false && showPostThematic === false && Activity === false && tagPost === false &&
                <Grid item xs={12}>
                    <ResharePost open={open} onClose={onClose} postId={postId} selectedActivity={selectedActivity} selectedPostTitle={selectedPostTitle} unSelectedTag={unSelectedTag} setSelectedPostTitle={setSelectedPostTitle} setSelectedActivity1={setSelectedActivity} selectedTag={selectedTag} Title={Title} post={post} socialMedia={socialMedia} master={master} usersImg={users} dispatch={dispatch} form={form} isEvent={isEvent} eventGid={eventGid} eventThematic={eventThematic} user={users} setSelectedTag={setSelectedTag}/>
                </Grid>}
            {postAudience === true &&
                < Grid item xs={12}>
                    <ReshareAudience onPostSelect={handlePostSelect} setSelectedPostTitle={setSelectedPostTitle} selectedPostTitle={selectedPostTitle} open={open} onClose={onClose} Title={Title} post={post} socialMedia={socialMedia} master={master} dispatch={dispatch} setSelectedTag={setSelectedTag}/>
                </Grid >}
            {Activity === true &&
                <Grid item xs={12}>
                    <ReshareActivity onActivitySelect={handleActivitySelect} socialMedia={socialMedia} master={master} open={open} onClose={onClose} Title={Title} post={post} setSelectedTag={setSelectedTag} />
                </Grid>}
            {tagPost === true &&
                <Grid item xs={12}>
                    <ReshareTag onTagSelect={handleTagSelect} socialMedia={socialMedia} onTagUnselect={handleTagUnselect} master={master} opens={open} onClose={onClose} Title={Title} post={post} setSelectedTag={setSelectedTag}/>
                </Grid>}
            {showPostThematic === true &&
                <Grid item xs={12}>
                    <ReshareCategory socialMedia={socialMedia} master={master} open={open} onClose={onClose} Title={Title} post={post} postId={postId}/>
                </Grid>}
        </>
    );
}
export default ReshareProcess;
