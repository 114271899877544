import React, { useEffect } from 'react';
import { Dialog, useMediaQuery, Fade, Typography, Grid, Box, DialogActions } from '@mui/material';
import ReduxForm from './ReduxForm';
import CommonInputNew from './CommonInputNew';
import { Link } from 'react-router-dom';
import CommonButtonNew from './RectangularInputs/CommonButtonNew';
import theme from '../../assets/material-ui/theme';
import { doTempRegister } from '../../modules/Registration/actions';
import { useSelector } from 'react-redux';
import * as clientValidations from '../validations'
import bvokalLogo from '../../assets/images/BvokalNew.svg';
import CreateAccountForm from '../../modules/Registration/common/SignupPages/CreateAccountForm';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} style={{ transitionDelay: ref ? '300ms' : '0ms' }} />;
});

const CreateAccountDialog = ({ open, onClose, navigate, dispatch, location, CommonCss, selectedOption, selectedCompanyType, handleSignUpOpen }) => {
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    const { users } = useSelector((state) => state);

    const handleSubmit = (values) => {
        dispatch(doTempRegister(values, selectedOption, selectedCompanyType, '', handleSignUpOpen));
    }
    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted PaperProps={{ sx: { minWidth: isXsScreen ? '560px' : '300px', borderRadius: '16px', padding: 2, gap: '8px' } }}>
            <Grid container spacing={2} className={CommonCss.createAccRoot}>
                <Grid item xs={12} textAlign={'center'} alignSelf={'stretch'} my={'-10px !important'} className={CommonCss.p0}>
                    <img src={bvokalLogo} height={80} />
                </Grid>
                <Grid item xs={12} textAlign={'center'} className={CommonCss.p0}>
                    <Typography className={CommonCss.signUpMailHeading}>
                        Create an account
                    </Typography>
                </Grid>
                <Grid item xs={12} className={CommonCss.p0}>
                    <CreateAccountForm
                        dispatch={dispatch}
                        navigate={navigate}
                        location={location}
                        RegCSS={CommonCss}
                        selectedOption={selectedOption}
                        selectedCompanyType={selectedCompanyType}
                        handleSubmit={handleSubmit}
                        users={users}
                        isBusinessSuite={true}
                    />
                </Grid>
            </Grid>
            <DialogActions className={CommonCss.createAccAction} sx={{ padding: '8px 0px 0px 0px' }}>
                <Typography className={CommonCss.createAccActionTypo} onClick={onClose}>Close</Typography>
            </DialogActions>
        </Dialog>
    );
};

export default CreateAccountDialog;