import { createSlice } from "@reduxjs/toolkit";
import GlimpseIcon from '../../assets/icons/glimpse.svg';
import GlimpseIconPurple from '../../assets/icons/glimpsePurple.svg';
import DetailsIconWhite from '../../assets/icons/detailsIconWhite.svg'
import DetailsIconBlack from '../../assets/icons/detailsIconBlack.svg'
import DetailsIconPurple from '../../assets/icons/DetailsIconPurple.svg'
import GlimpseIconWhite from '../../assets/icons/glimpseWhite.svg';

import FilterIconWhite from '../../assets/icons/filterWhite.svg'
import FilterIconBlack from '../../assets/icons/filterBlack.svg'
import FilterIconPurple from '../../assets/icons/FilterIconPurple.svg'

import uploadDocument from '../../assets/icons/uploadDocument.svg';
import uploadDocumentWhite from '../../assets/icons/uploadIconWhite.svg';
import uploadPurple from '../../assets/icons/uploadPurple.svg';

const initialState = {
  sideArr: [
    { name: 'Glimpse', completed: false, id: 1, icon: GlimpseIcon, icon2: GlimpseIconPurple, iconWhite: GlimpseIconWhite },
    { name: 'Details', completed: false, id: 2, icon: DetailsIconBlack, icon2: DetailsIconPurple, iconWhite: DetailsIconWhite },
    { name: 'Filter Preference', completed: false, id: 3, icon: FilterIconBlack, icon2: FilterIconPurple, iconWhite: FilterIconWhite }
  ],
  rfpGlimpseData: {},
  rfpDetailsFormData: {},
  filterPreferenceFormData: {},
  glimpseFormData: {},
  locations: [],
  screeningQuestions: [],
  locationFlag: false,
  rfpOrgExperience: [],
  rfpUploadDoc: {},
  rfpDashboardList: [],
  dashboardTab: "inbox",
  insightData: {},
  responseList: [],
  rfpid: "",
  saveRfpData: {},
  saveQuesAnswerResp: [],
  gid: '',
  uniqueId: '',
  reponseFlag: false,
  upload: false,
  responseList: [],
  customQuestion: [],
  totalQuestions: [],
  customAdded: false,
  uploadTab: false,
  lastSubmissionDate: '',
  pageNumber: 1,
  savedRFP: false,
  assigneeList: [],
  assigneeComments: [],
  rfpOffset: null,
  receivedUserId: "",
  userAssigned: "",
  selectedSampleQuestion: [],
  rfpCreatedAt: '',
  dashboardInsight:[],
  viewResponses:[],
  isRFPdataLoading:false,
  starredTabType:"",
};

const rfpSlice = createSlice({
  name: "rfp",
  initialState,
  reducers: {
    setGlimpse(state, action) {
      state.rfpGlimpseData = action.payload;
    },
    setDetails(state, action) {
      state.rfpDetailsFormData = action.payload;
    },
    setFilterPreferenceData(state, action) {
      state.filterPreferenceFormData = action.payload;
    },
    setGlimpseFormData(state, action) {
      state.glimpseFormData = action.payload;
    },
    addLocation(state, action) {
      state.locations.push(action.payload);
    },
    updateLocation(state, action) {
      state.locations = action.payload;
    },
    addScreeningQuestions(state, action) {
      state.screeningQuestions = action.payload;
    },
    setLocationFlag(state, action) {
      state.locationFlag = action.payload;
    },
    addRFPDistrict(state, action) {
      state.district = action.payload;
    },
    addOrgExperience(state, action) {
      state.rfpOrgExperience = action.payload;
    },
    addRfpUploadDoc(state, action) {
      state.rfpUploadDoc = action.payload;
    },
    setRFPList(state, action) {
      state.rfpDashboardList = action.payload;
    },
    setDashboardTab(state, action) {
      state.dashboardTab = action.payload;
    },
    setRFPData(state, action) {
      state.insightData = action.payload;
    },
    setRFPResponse(state, action) {
      state.responseList = action.payload;
    },
    addRFPid(state, action) {
      state.rfpid = action.payload;
    },
    setPreviewData(state, action) {
      state.saveRfpData = action.payload;
    },
    setQuesAnswerResp(state, action) {
      state.saveQuesAnswerResp = action.payload;
    },
    setUniqueId(state, action) {
      state.uniqueId = action.payload;
    },
    setGid(state, action) {
      state.gid = action.payload;
    },
    setResponseFlag(state, action) {
      state.reponseFlag = action.payload;
    },
    setResponseList(state, action) {
      state.responseList = action.payload;
    },
    setSideArr(state, action) {
      state.sideArr = state.sideArr.map(step => {
        if (step.name === action.payload) {
          return { ...step, completed: true };
        }
        return step;
      });
    },
    setUpload(state, action) {
      state.upload = action.payload;
      state.sideArr = [
        { name: 'Glimpse', completed: false, id: 1, icon: GlimpseIcon, icon2: GlimpseIconPurple, iconWhite: GlimpseIconWhite },
        ...(action.payload
          ? [{ name: 'Upload RFP', completed: false, id: 2, icon: uploadDocument, icon2: uploadPurple, iconWhite: uploadDocumentWhite }]
          : [{ name: 'Details', completed: false, id: 2, icon: DetailsIconBlack, icon2: DetailsIconBlack, iconWhite: DetailsIconWhite }]),
        { name: 'Filter Preference', completed: false, id: 3, icon: FilterIconBlack, icon2: FilterIconBlack, iconWhite: FilterIconWhite }
      ];
    },
    setCustomQuestions(state, action) {
      state.customQuestion = action.payload;
    },
    setTotalQuestions(state, action) {
      state.totalQuestions = action.payload;
    },
    setCustomAdded(state, action) {
      state.customAdded = action.payload;
    },
    setUploadTab(state, action) {
      state.uploadTab = action.payload;
    },
    setLastSubmissionDate(state, action) {
      state.lastSubmissionDate = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    setSavedRfp(state, action) {
      state.savedRFP = action.payload;
    },
    setAssigneeList(state, action) {
      state.assigneeList = action.payload;
    },
    setAssigneeComments(state, action) {
      state.assigneeComments = action.payload;
    },
    setrfpOffset(state, action) {
      state.rfpOffset = action.payload;
    },
    setReceivedUserId(state, action) {
      state.receivedUserId = action.payload;
    },
    setUserAssigned(state, action) {
      state.userAssigned = action.payload;
    },
    setSelectedSampleQuestion(state, action) {
      state.selectedSampleQuestion = action.payload;
    },
    resetGlimpseFormData(state) {
      state.glimpseFormData = [];
    },
    setRfpCreatedAt(state, action) {
      state.rfpCreatedAt = action.payload;
    },
    setDashboardInsight(state, action) {
      state.dashboardInsight = action.payload;
    },
    setViewResponses(state, action) {
      state. viewResponses = action.payload;
    },
    setIsRFPdataLoading(state, action) {
        state.isRFPdataLoading = action.payload;
    },
    setStarredTabType(state, action) {
      state.starredTabType = action.payload;
    },
  },
});

export default rfpSlice.reducer;

export const {
  setUploadTab,
  setGlimpse,
  setDetails,
  setFilterPreferenceData,
  setGlimpseFormData,
  addLocation,
  addScreeningQuestions,
  setThematicArea,
  setLocationFlag,
  addRFPDistrict,
  updateLocation,
  addOrgExperience,
  addRfpUploadDoc,
  setRFPList,
  setDashboardTab,
  setRFPData,
  setResponseList,
  setRFPResponse,
  addRFPid,
  setPreviewData,
  setQuesAnswerResp,
  setUniqueId,
  setGid,
  setResponseFlag,
  setSideArr,
  setUpload,
  setTotalQuestions,
  setCustomQuestions,
  setCustomAdded,
  setLastSubmissionDate,
  setPageNumber,
  setSavedRfp,
  setAssigneeList,
  setAssigneeComments,
  setrfpOffset,
  setReceivedUserId,
  setUserAssigned,
  setSelectedSampleQuestion,
  resetGlimpseFormData,
  setRfpCreatedAt,
  setDashboardInsight,
  setViewResponses,
  setIsRFPdataLoading,
  setStarredTabType,
} = rfpSlice.actions;