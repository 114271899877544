import { Box, Typography } from '@mui/material';
import React from 'react';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';
import { useLocation } from 'react-router';

const EmptyPostCard = () => {
    const location = useLocation();
    const isNotificationPost = location.pathname.startsWith(`/social-media/posts`);

    return (
        <>
            <Box className={SocialMediaCSS.emptyPost}>
                {!isNotificationPost &&
                    <Typography className={SocialMediaCSS.emptyTypo}>
                        No Posts yet, all Posts will be shown here.
                    </Typography>}
                {isNotificationPost &&
                    <Typography className={SocialMediaCSS.emptyTypo}>
                        No Posts Found!!
                    </Typography>}
            </Box>
        </>
    )
}
export default EmptyPostCard