import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import invitations from '../../../../assets/icons/invitations.svg';
import invitationWhite from '../../../../assets/icons/invitation_white.svg';
import connection from '../../../../assets/icons/connection.svg';
import connectionWhite from '../../../../assets/icons/connection_white.svg';
import speaker_black from '../../../../assets/icons/speaker_black.svg';
import speaker_white from '../../../../assets/icons/speaker_white.svg';
import sponsor_white from '../../../../assets/icons/sponsor_white.svg';
import sponsor_black from '../../../../assets/icons/sponsor_black.svg';
import theme from '../../../../assets/material-ui/theme';

const CommunitySidecard = ({ selectedCard, handleCardClick, isIndividual, users, socialMedia, master }) => {

    const companyTypes = Array.isArray(master?.connectionTypes) ? master.connectionTypes : [];
    const connectionTypeCount = socialMedia?.myDatabase?.Connection_type_count;
    const keysToCount = ["ngo", "companies", "csr", "agency", "academia", "personal"];
    let totalCount = 0;
    if (Array.isArray(connectionTypeCount)) {
        totalCount = connectionTypeCount.reduce((sum, obj) => {
            return sum + keysToCount?.reduce((acc, key) => {
                return acc + (obj[key] || 0);
            }, 0);
        }, 0);
    }
    return (
        <Box className={`${SocialMediaCSS.smCard} ${SocialMediaCSS.titleCard2}`}>
            <Grid container className={SocialMediaCSS.titleCard2Box} >
                <Grid item xs={12} className={SocialMediaCSS.data}>
                    <Box className={SocialMediaCSS.MyDatabase}>
                        <Typography className={SocialMediaCSS.databaseTypo}>Community</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} className={SocialMediaCSS.SubCardGrid}>
                    <Box className={SocialMediaCSS.SubCardBox} onClick={() => handleCardClick('invite')} sx={{ background: selectedCard === 'invite' ? theme.mainPalette.secondary.secondary110 : theme.mainPalette.grey.white, padding: selectedCard === 'invite' ? '10px 20px' : '12px 20px' }}>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                            <Box className={SocialMediaCSS.SubCardBox2}>
                                <img src={selectedCard === 'invite' ? invitationWhite : invitations} alt='invite' height={selectedCard === 'invite' ? '20px' : '14px'} width={selectedCard === 'invite' ? '20px' : '14px'} />
                                <Typography className={SocialMediaCSS.SubCardTypo} sx={{ color: selectedCard === 'invite' ? theme.mainPalette.grey.white : theme.mainPalette.grey.lightText, fontWeight: selectedCard === 'invite' ? 600 : 400 }}>Invitations</Typography>
                            </Box>
                            <Typography className={`${SocialMediaCSS.inviteCount} ${SocialMediaCSS.SubCarB}`} sx={{color: selectedCard === 'invite' ? theme.mainPalette.grey.white : theme.mainPalette.grey.darkText}} >{users?.connectCount?.invitation_count
                            }</Typography>
                        </Box>
                    </Box>
                    <Box className={SocialMediaCSS.SubCardBox} onClick={() => handleCardClick('connect')} sx={{ background: selectedCard === 'connect' ? theme.mainPalette.secondary.secondary110 : theme.mainPalette.grey.white, padding: selectedCard === 'connect' ? '10px 20px' : '12px 20px' }}>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                            <Box className={SocialMediaCSS.SubCardBox2} >
                                <img src={selectedCard === 'connect' ? connectionWhite : connection} alt='connection' height={selectedCard === 'connect' ? '20px' : '14px'} width={selectedCard === 'connect' ? '20px' : '14px'} />
                                <Typography className={SocialMediaCSS.SubCardTypo} sx={{ color: selectedCard === 'connect' ? theme.mainPalette.grey.white : theme.mainPalette.grey.lightText, fontWeight: selectedCard === 'connect' ? 600 : 400 }}>Connections</Typography>
                            </Box>

                            <Typography className={`${SocialMediaCSS.inviteCount} ${SocialMediaCSS.SubCarB}`} sx={{color: selectedCard === 'connect' ? theme.mainPalette.grey.white : theme.mainPalette.grey.darkText}} >{totalCount}</Typography>
                        </Box>
                    </Box>
                    {isIndividual && <Box className={SocialMediaCSS.SubCardBox} onClick={() => handleCardClick('volinvite')} sx={{ background: selectedCard === 'volinvite' ? theme.mainPalette.secondary.secondary110 : theme.mainPalette.grey.white, padding: selectedCard === 'volinvite' ? '10px 20px' : '12px 20px' }}>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                            <Box className={SocialMediaCSS.SubCardBox2}>
                                <img src={selectedCard === 'volinvite' ? invitationWhite : invitations} alt='vol invite' height={selectedCard === 'volinvite' ? '20px' : '14px'} width={selectedCard === 'volinvite' ? '20px' : '14px'} />
                                <Typography className={SocialMediaCSS.SubCardTypo} sx={{ color: selectedCard === 'volinvite' ? theme.mainPalette.grey.white : theme.mainPalette.grey.lightText, fontWeight: selectedCard === 'volinvite' ? 600 : 400 }}>Volunteering Invitations</Typography>
                            </Box>
                            <Typography className={`${SocialMediaCSS.inviteCount} ${SocialMediaCSS.SubCarB}`} sx={{color: selectedCard === 'volinvite' ? theme.mainPalette.grey.white : theme.mainPalette.grey.darkText}} >{users?.connectCount?.volunteer_invitation_count}</Typography>
                        </Box>
                    </Box>}
                    <Box className={SocialMediaCSS.SubCardBox} onClick={() => handleCardClick('speaker')} sx={{ background: selectedCard === 'speaker' ? theme.mainPalette.secondary.secondary110 : theme.mainPalette.grey.white, padding: selectedCard === 'speaker' ? '10px 20px' : '12px 20px' }}>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                            <Box className={SocialMediaCSS.SubCardBox2}>
                                <img src={selectedCard === 'speaker' ? speaker_white : speaker_black} alt='vol invite' height={selectedCard === 'speaker' ? '20px' : '14px'} width={selectedCard === 'speaker' ? '20px' : '14px'} />
                                <Typography className={SocialMediaCSS.SubCardTypo} sx={{ color: selectedCard === 'speaker' ? theme.mainPalette.grey.white : theme.mainPalette.grey.lightText, fontWeight: selectedCard === 'speaker' ? 600 : 400 }}>Speaker Invitations</Typography>
                            </Box>
                            <Typography className={`${SocialMediaCSS.inviteCount} ${SocialMediaCSS.SubCarB}`} sx={{color: selectedCard === 'speaker' ? theme.mainPalette.grey.white : theme.mainPalette.grey.darkText}} >{users?.connectCount?.speaker_invitation_count}</Typography>
                        </Box>
                    </Box>
                    <Box className={SocialMediaCSS.SubCardBox} onClick={() => handleCardClick('sponsor')} sx={{ background: selectedCard === 'sponsor' ? theme.mainPalette.secondary.secondary110 : theme.mainPalette.grey.white, padding: selectedCard === 'sponsor' ? '10px 20px' : '12px 20px' }}>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                            <Box className={SocialMediaCSS.SubCardBox2}>
                                <img src={selectedCard === 'sponsor' ? sponsor_white : sponsor_black} alt='vol invite' height={selectedCard === 'sponsor' ? '20px' : '14px'} width={selectedCard === 'sponsor' ? '20px' : '14px'} />
                                <Typography className={SocialMediaCSS.SubCardTypo} sx={{ color: selectedCard === 'sponsor' ? theme.mainPalette.grey.white : theme.mainPalette.grey.lightText, fontWeight: selectedCard === 'sponsor' ? 600 : 400 }}>Partner Invitations</Typography>
                            </Box>
                            <Typography className={`${SocialMediaCSS.inviteCount} ${SocialMediaCSS.SubCarB}`} sx={{color: selectedCard === 'sponsor' ? theme.mainPalette.grey.white : theme.mainPalette.grey.darkText}} >{users?.connectCount?.sponsor_invitation_count}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CommunitySidecard