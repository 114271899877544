import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function CommonButtonNew(props) {
  return (
    <div className={props.className}>
      <Button
        color='primary'
        size={props.size || 'medium'}
        fullWidth={!props.smallWidth}
        id={props.name}
        onClick={props.onClick}
        // className={props.classes}
        sx={{
          borderRadius:1.25,
          fontWeight: 600,
          whiteSpace: 'nowrap',
          backgroundColor: '#FFFFFF',
          paddingX: 6,
          ...props.style,
        }}
        className="custom-button"
        startIcon={props.startIcon}
        endIcon={props.endIcon}
        variant={'contained'}
        type={props.type}
        disabled={props.disabled}
        disableRipple
      >
        {props.label}
      </Button>
    </div>
  );
}