import { Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CommonButton2 from "./CommonButton2";
import { useLocation, useNavigate } from "react-router";

const UserData = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path;
    };
    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Grid container xs={12} sx={{ padding: '30px 50px 50px 50px', display: 'flex', justifyContent: 'center' }}>
                <Paper elevation={7} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12} sx={{ padding: '30px 50px 50px 50px' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left' }}>User Data Deletion and Account Closure Policy</Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify' }}>
                            At Bvokal, safeguarding your privacy is our utmost priority. We are fully committed to ensuring
                            transparency and granting you control over your personal data. This policy delineates the
                            procedure for users to terminate their accounts and erase their data. Additionally, it outlines the
                            duration for which user data is retained following account closure.
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}>Account Deletion Process
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}>User-Requested Account Deletion:
                        </Typography>

                        <Typography sx={{ paddingTop: '10px', textAlign: 'justify' }}>
                            To initiate the deletion of your Bvokal account, please log in to your account.
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}>Access Account Settings:
                        </Typography>

                        <Typography sx={{ paddingTop: '10px', textAlign: 'justify' }}> Proceed to your account settings or profile page.
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}>Locate "Delete Account" Option:
                        </Typography>

                        <Typography sx={{ paddingTop: '10px', textAlign: 'justify' }}> Identify and select the "Delete Account" or a similar option within your account settings.</Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}>Confirmation Required:
                        </Typography>
                        <Typography sx={{ paddingTop: '10px', textAlign: 'justify' }}>
                            You may be prompted to confirm your decision. Kindly follow the provided instructions
                            accurately.
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}>Data Erasure Commences:
                        </Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify' }}>
                            Upon confirmation, your account data will be queued for deletion, adhering to the retention periods
                            stipulated below.
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}>Data Retention After Account Closure
                        </Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify' }}>
                            Bvokal retains specific user data even after an account is closed, serving distinct purposes. These
                            retention durations are outlined as follows:
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}>User-Generated Content:
                        </Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify' }}>
                            Any content generated or posted by you on Bvokal may be retained for a period of 30 days for user data and legal 7 financial years remove
                            days subsequent to account closure. This is enacted to sustain a seamless content
                            experience for other users
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left',paddingTop: '10px' }}>Transactional Data:
                        </Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify' }}>
                            Data associated with transactions, including purchase history or subscription particulars, may be
                            maintained for 30 days for user data and legal 7 financial years days for accounting and legal compliance
                            obligations
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left',paddingTop: '10px' }}>Analytics and Usage Data:
                        </Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify' }}>
                            Aggregated usage data, which cannot be attributed to individual users, may be retained indefinitely
                            for analytical purposes
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left',paddingTop: '10px' }}>Legal Mandates:
                        </Typography>

                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify' }}>
                            Certain user data may be preserved in accordance with pertinent laws, regulations, or legal
                            proceedings.
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left',paddingTop: '10px' }}>Contact Information
                        </Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify' }}>
                            Should you have any inquiries or concerns regarding the deletion of your account or data retention,
                            please do not hesitate to contact our team at  support@bvokal.com . Our dedicated team is here to assist
                            you and ensure the protection of your data privacy rights.
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left',paddingTop: '10px' }}>Policy Updates
                        </Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify' }}>
                            Please note that this policy is subject to revisions, and any modifications and this will be
                            communicated to users accordingly. Your continued utilization of Bvokal services signifies your
                            acceptance of this policy
                        </Typography>
                        <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                            <Grid item xs={12} sm={12} md={1.5} gap={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CommonButton2
                                    label="Go Back"
                                    name="Go Back"
                                    type="button"
                                    color="primary"
                                    variant='contained'
                                    // onClick={() => navigate('/login')}
                                    onClick={() => window.history.back()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default UserData;