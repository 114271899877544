import { createConsumer } from "@rails/actioncable";
const cable = createConsumer(process.env.REACT_APP_SOCKET_URL);

// const ws = new WebSocket("wss://staging.ginniimpact.com/social-media/cable");
// console.log('ws', ws);
// ws.onopen = () => {
//     console.log("Connected to websocket server");
//     // ws.onmessage = (e) => {
//     //     console.log('event', e);
//     // };
//     ws.onmessage = (e) => {
//         const data = JSON.parse(e.data);
//         if (data.type === "ping") return;
//         if (data.type === "welcome") return;
//         if (data.type === "confirm_subscription") return;
    
//         const message = data.message;
//         console.log('data', e);
//     };
// };

// console.log('cable', cable);
export default cable;
