import React, { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import BengageGridCard from './BengageGridCard';
import EventDetailsDrawer from '../../Events/Subcomponents/EventDetailsDrawer';
import BengageListCard from './BengageListCard';
import { useNavigate } from 'react-router';
import slugify from 'slugify';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`view-tabpanel-${index}`}
            aria-labelledby={`view-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};



const BengageBody = ({ value, offset, isIndividual }) => {
    const [eventDraweropen, setEventDraweropen] = useState(false);
    const { socialMedia: { bEngageList, eventIsLoad, totalEventCount, engageTotalCount, eventSortBy, eventCategory, eventStatus, myEventList, totalMyEventCount, eventData } } = useSelector((state => state));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleEventDrawerOpen = (event) => {
        // Create slug from title
        const slug = slugify(event?.title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
        const slugUrl = `${slug}-${event?.gid}`;

        navigate(`/volunteer/${slugUrl}`);
    };
    // const handleEventDrawerOpen = (gid) => {
    //     // dispatch(getEventPreview(gid));
    //     setEventDraweropen(true);
    // };

    // const handleEventDrawerClose = () => {
    //     setEventDraweropen(false);
    // };
    return (

        <>
            <TabPanel value={value} index={0}>
                <Grid container spacing={3}>
                    <BengageGridCard bEngageList={bEngageList} handleEventDrawerOpen={handleEventDrawerOpen} eventIsLoad={eventIsLoad} totalEventCount={totalEventCount} dispatch={dispatch} engageTotalCount={engageTotalCount} eventSortBy={eventSortBy} eventCategory={eventCategory} offset={offset} />
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container spacing={3}>
                    <BengageListCard bEngageList={bEngageList} handleEventDrawerOpen={handleEventDrawerOpen} eventIsLoad={eventIsLoad} totalEventCount={totalEventCount} engageTotalCount={engageTotalCount} dispatch={dispatch} eventSortBy={eventSortBy} eventCategory={eventCategory} offset={offset} />
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container spacing={3}>
                    {/* <EventMyEventList bEngageList={myEventList} handleEventDrawerOpen={handleEventDrawerOpen} eventIsLoad={eventIsLoad} totalEventCount={totalMyEventCount} dispatch={dispatch} eventStatus={eventStatus} offset={offset} />  */}
                </Grid>
            </TabPanel>
        </>
    )
}

export default BengageBody
