import React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import theme from '../../../assets/material-ui/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& $notchedOutline': {
            transition: 'border-color 0.3s ease',
            borderColor: theme.mainPalette.grey.grey400,
            borderRadius: '5px'
        },
        '&:hover $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '&$focused $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '&$disabled $notchedOutline': {
            WebkitTextFillColor: "#98A2B3",
            backgroundColor: '#E0E5FF',
        },
        '& input:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 1000px transparent inset',
            '-webkit-text-fill-color': '#333333 !important',
            'transition': 'background-color 5000s ease-in-out 0s',
        },
        height: '48px',
        // padding: '16px 12px !important',
        fontSize: '12px !important',
        fontWeight: '500 !important',
        lineHeight: '1.5',
    },

    focused: {},
    notchedOutline: {},

}));


export default function RenderEmailNew({
    input,
    label,
    placeholder,
    type,
    fullWidth,
    defaultValue,
    disabled,
    min,
    max,
    value,
    customRadius,
    meta: { touched, error, warning },
    inputProps,
    errorMessages,
    custom,
    readOnly
}) {

    const classes = useStyles();
    return (
        <>
            <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                    id={input.name}
                    type='Email'
                    value={defaultValue}
                    min={min}
                    max={max}
                    fullWidth={fullWidth}
                    {...input}
                    {...custom}
                    className='TextField'
                    classes={{
                        root: classes.root,
                        focused: classes.focused,
                        disabled: classes.disabled,
                        notchedOutline: classes.notchedOutline,
                    }}
                    placeholder={placeholder}
                    disabled={disabled}
                    readOnly={readOnly}
                />
            </FormControl>
            {touched && error && <Box className='error' sx={{ padding: '0px', margin: '4px 0' }}>{error}</Box>}
        </>
    );
};
