import React, { useEffect, useState } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Image from "../../../../assets/images/projectTempImage.jpg";
import Location from "../../../../assets/icons/location_grey.svg";
import calender from "../../../../assets/icons/calender.svg"
import suggested from "../../../../assets/icons/Suggested.svg";
import suggested_Icon from "../../../../assets/icons/Suggested_Icon.svg";
import latest from "../../../../assets/icons/latest.svg";
import latest_white from "../../../../assets/icons/latest_white.svg";
import { SOCIAL_TYPE } from "../../../../common/constants";
import { useDispatch } from "react-redux";
import { getDasboardList, getDashboardRfp, getDistrictNameById, getEventsList } from "../../actions";
import { Link } from "react-router-dom";
import { decodeHTMLEntities, formatEventStartDateTime, getResponseDate, getStateNameById, timeAgo } from "../../../../common/utils";
import CommonInitials from "../../../../common/components/CommonInitials";
import PostTime from "./../../../../assets/icons/clock_Solid.svg";
import EventCardSkeleton from "./EventCardSkeleton";
import MasterSlice from "../../../../store/slices/MasterSlice";
import slugify from "slugify";

const EventRFPSection = ({ navigate, socialMedia, master, isLoggedIn }) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(SOCIAL_TYPE.EVENT);
    const [selectedSubTab, setSelectedSubTab] = useState("bVolunteer");
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleTabClick = (tabName) => {
        if (tabName === 'RFP') {
            setSelectedSubTab('Suggested');
        } else {
            setSelectedSubTab('bVolunteer');
        }
        setSelectedTab(tabName);
        console.log("selectedName", tabName)
    };

    const handleSubTabClick = (subTabName) => {
        setSelectedSubTab(subTabName);
        dispatch(getEventsList(subTabName));
        console.log("selectedSubTab", subTabName);
    };

    const handleEventPreview = (gid) => {
        if (isLoggedIn) {
            navigate(`/social-media/events/${gid}`)
        } else {
            navigate('/login');
        }
    }
    const handleRFPPreview = (gid) => {
        if (isLoggedIn) {
            navigate(`/social-media/rfp/${gid}`)
        } else {
            navigate('/login');
        }
    }
    const handleBengageClick = (data) => {
        if (isLoggedIn) {
            const slug = slugify(data?.title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
            const slugUrl = `${slug}-${data?.gid}`;

            navigate(`/volunteer/${slugUrl}`);
        } else {
            navigate('/login');
        }
    }
    console.log(selectedTab, selectedSubTab, selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bVolunteer" ? 'No upcoming and ongoing events yet' : selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bEngage" ? 'No upcoming and ongoing conferences yet' : selectedTab === SOCIAL_TYPE.RFP ? 'No RFP issued yet' : '', "tab")
    const getResourceData = () => {
        if (selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bVolunteer") {
            return socialMedia?.eventList || [];
        } else if (selectedTab === SOCIAL_TYPE.RFP) {
            return socialMedia?.socialDashboardList || [];
        } else if (selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bEngage") {
            return socialMedia?.bengageDashboardList || [];
        }
        return [];
    };
    const handleRFPViewAllClick = (tabName) => {
        navigate("/social-media/rfps")
    };
    const handleEventsViewAllClick = (tabName) => {
        navigate("/social-media/events")
    };
    useEffect(() => {
        dispatch(getEventsList(selectedSubTab))
        dispatch(getDashboardRfp())
        dispatch(getDasboardList())
    }, []);
    return (
        <>
            <Box className={SocialMediaCSS.smCard}>
                <Grid container>
                    {/* Event tab */}
                    <Grid item xs={6} className={`${SocialMediaCSS.EventRfp} ${selectedTab === SOCIAL_TYPE.EVENT ? SocialMediaCSS.selectedCSR : SocialMediaCSS.notSelectedCSR}`} onClick={() => handleTabClick(SOCIAL_TYPE.EVENT)}>
                        <Typography className={selectedTab === SOCIAL_TYPE.EVENT ? SocialMediaCSS.Csrblue : SocialMediaCSS.Csrgrey}>Events</Typography>
                    </Grid>
                    {/* RFP tab */}
                    <Grid item xs={6} className={`${SocialMediaCSS.latestEventRFP} ${selectedTab === SOCIAL_TYPE.RFP ? SocialMediaCSS.selectedCSR : SocialMediaCSS.notSelectedCSR}`} onClick={() => handleTabClick(SOCIAL_TYPE.RFP)}>
                        <Typography className={selectedTab === SOCIAL_TYPE.RFP ? SocialMediaCSS.Csrblue : SocialMediaCSS.Csrgrey}>RFPs</Typography>
                    </Grid>
                </Grid>
                <Box className={`${SocialMediaCSS.gridHeader} ${SocialMediaCSS.eventPadding}`}>
                    <Grid container rowSpacing={1.2} gap={1.2} className={`${SocialMediaCSS.f}`}>
                        {selectedTab === SOCIAL_TYPE.EVENT ?
                            <>
                                <Grid item xs={6} className={`${SocialMediaCSS.commonSelected} ${selectedSubTab === "bVolunteer" ? SocialMediaCSS.suggestedSelected : SocialMediaCSS.latest}`} onClick={() => handleSubTabClick("bVolunteer")} >
                                    <img src={selectedSubTab === "bVolunteer" ? suggested : suggested_Icon} />
                                    <Typography className={selectedSubTab === "bVolunteer" ? SocialMediaCSS.selectTypo : SocialMediaCSS.notSelectedTypo}>b-Volunteer</Typography>
                                </Grid>
                                <Grid item xs={6} className={`${SocialMediaCSS.commonSelected} ${selectedSubTab === "bEngage" ? SocialMediaCSS.latestSelected : SocialMediaCSS.latest}`} onClick={() => handleSubTabClick("bEngage")} >
                                    <img src={selectedSubTab === "bEngage" ? latest_white : latest} />
                                    <Typography className={selectedSubTab === "bEngage" ? SocialMediaCSS.selectTypo : SocialMediaCSS.notSelectedTypo}>b-Engage</Typography>
                                </Grid>
                            </> :
                            <>
                                {/* <Grid item xs={6} className={`${SocialMediaCSS.commonSelected} ${selectedSubTab === "Suggested" ? SocialMediaCSS.suggestedSelected : SocialMediaCSS.latest}`} onClick={() => handleSubTabClick("Suggested")} >
                                    <img src={selectedSubTab === "Suggested" ? suggested : suggested_Icon} />
                                    <Typography className={selectedSubTab === "Suggested" ? SocialMediaCSS.selectTypo : SocialMediaCSS.notSelectedTypo}>Suggested</Typography>
                                </Grid>
                                <Grid item xs={6} className={`${SocialMediaCSS.commonSelected} ${selectedSubTab === "Latest" ? SocialMediaCSS.latestSelected : SocialMediaCSS.latest}`} onClick={() => handleSubTabClick("Latest")} >
                                    <img src={selectedSubTab === "Latest" ? latest_white : latest} />
                                    <Typography className={selectedSubTab === "Latest" ? SocialMediaCSS.selectTypo : SocialMediaCSS.notSelectedTypo}>Latest</Typography>
                                </Grid> */}
                            </>
                        }
                    </Grid>
                </Box>
                <Box className={SocialMediaCSS.gridContainer} >
                    {!socialMedia.isEventCardLoading ? (
                        getResourceData().map((data, index) => (
                            <Box
                                className={`${SocialMediaCSS.CsrDetail}`}
                                key={index}
                                onClick={() => (selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bVolunteer") ? handleEventPreview(data?.gid) : (selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bEngage") ? handleBengageClick(data) : handleRFPPreview(data?.gid)}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                                sx={{
                                    backgroundImage: hoveredIndex === index ? `url(${data?.cover_photo})` : 'none', cursor: 'pointer',
                                }}
                            >
                                {(selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bVolunteer") &&
                                    <Box className={SocialMediaCSS.eventImage}>
                                        <img src={data.cover_photo} className={SocialMediaCSS.eventImage} />
                                    </Box>}
                                {(selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bEngage") &&
                                    <Box className={SocialMediaCSS.eventImage}>
                                        <img src={data?.banner} className={SocialMediaCSS.eventImage} />
                                    </Box>}
                                {selectedTab === SOCIAL_TYPE?.RFP &&
                                    <CommonInitials
                                        name={data?.initials}
                                        bgColor={data?.colour_code}
                                        divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                                        typoStyle={{ fontSize: '18px', lineHeight: '44px', }}
                                        avatarStyle={{ width: '36px', height: '36px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                                        src={data.user_image}
                                        companyLogo={data.user_image}
                                        avatar={true}
                                    />}
                                <Box className={SocialMediaCSS.flexGrow}>
                                    <Typography className={`${SocialMediaCSS.resourcetypo}  ${hoveredIndex === index && SocialMediaCSS.textWhite}`}>{(selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bVolunteer") ? data?.name : (selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bEngage") ? data.title : data.title}</Typography>
                                    <Typography className={`${SocialMediaCSS.eventThematic} ${hoveredIndex === index && SocialMediaCSS.textWhite}`}>{(selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bVolunteer") ? data?.thematic_area : (selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bEngage") ? data?.thematic_area : ""}</Typography>
                                    {selectedTab === SOCIAL_TYPE.EVENT &&
                                        <Box>
                                            <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between} ${SocialMediaCSS.ai_stretch}`}>
                                                <Box className={SocialMediaCSS.f}>
                                                    <img src={Location} className={SocialMediaCSS.imgPad} />
                                                    <Typography className={`${SocialMediaCSS.eventLocation} ${hoveredIndex === index && SocialMediaCSS.textWhite}`}>{(selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bVolunteer") ? dispatch(getDistrictNameById(data?.event_address?.district_id)) : (selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bEngage") ? dispatch(getStateNameById(data?.conference_address_details?.state_id)) : data.location}</Typography>
                                                </Box>
                                                <Box className={SocialMediaCSS.f}  >
                                                    <img src={calender} className={SocialMediaCSS.imgPad} />
                                                    <Typography className={`${SocialMediaCSS.eventLocation} ${hoveredIndex === index && SocialMediaCSS.textWhite}`} >{selectedTab === SOCIAL_TYPE.EVENT ? getResponseDate(data?.start_date) : getResponseDate(data.submission_date)}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>}
                                    {selectedTab === SOCIAL_TYPE.RFP &&
                                        <Box>
                                            {/* {data.body !== 'undefined' && (
                                          <Typography className={`${SocialMediaCSS.eventThematic} ${hoveredIndex === index && SocialMediaCSS.textWhite}`} dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(data.body)}}></Typography>
                                        )} */}
                                            <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between} ${SocialMediaCSS.ai_stretch}`}>
                                                <Box className={SocialMediaCSS.f}  >
                                                    <img src={calender} className={`${SocialMediaCSS.imgPad}`} />
                                                    <Typography className={`${SocialMediaCSS.eventLocation} ${hoveredIndex === index && SocialMediaCSS.textWhite}`} >{getResponseDate(data.submission_date)}</Typography>
                                                </Box>
                                                <Box className={SocialMediaCSS.f}>
                                                    <img src={PostTime} className={`${SocialMediaCSS.imgPad} ${SocialMediaCSS.imd}`} />
                                                    <Typography className={`${SocialMediaCSS.eventLocation} ${hoveredIndex === index && SocialMediaCSS.textWhite}`}>Posted {(timeAgo(data?.created_at))}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        ))) :
                        <>
                            <EventCardSkeleton />
                        </>
                    }
                </Box>
                {getResourceData().length === 0 && !socialMedia.isEventCardLoading && (
                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '100px', marginBottom: '100px', textAlign: 'center'}}>
                        <Typography>{selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bVolunteer" ? 'No upcoming and ongoing events yet' : selectedTab === SOCIAL_TYPE.EVENT && selectedSubTab === "bEngage" ? 'No upcoming and ongoing conferences yet' : selectedTab === SOCIAL_TYPE.RFP ? 'No RFP issued yet' : ''}</Typography>
                    </Box>
                )}
                <Link className={`${SocialMediaCSS.viewAll}`} to={!isLoggedIn ? '/login' : selectedTab === SOCIAL_TYPE.RFP ? "/social-media/rfps" : selectedSubTab === "bEngage" ? "/social-media/bEngage" : "/social-media/events"}>
                    <VisibilityOutlinedIcon className={SocialMediaCSS.viewEye} />
                    <Typography className={SocialMediaCSS.resourceView}>View All</Typography>
                </Link>
                {/* {selectedTab === SOCIAL_TYPE?.EVENT && selectedSubTab === "bVolunteer" ? (
                    <Link className={`${SocialMediaCSS.viewAll}`} to={!isLoggedIn ? '/login' : "/social-media/events"}>
                        <VisibilityOutlinedIcon className={SocialMediaCSS.viewEye} />
                        <Typography className={SocialMediaCSS.resourceView}>View All</Typography>
                    </Link>
                ) : (
                    getResourceData().length > 0 && (
                        <Link className={`${SocialMediaCSS.viewAll}`} to={!isLoggedIn ? '/login' : selectedTab === SOCIAL_TYPE.RFP ? "/social-media/rfps" : selectedSubTab === "bEngage" ? "/social-media/bEngage" : "/social-media/events"}>
                            <VisibilityOutlinedIcon className={SocialMediaCSS.viewEye} />
                            <Typography className={SocialMediaCSS.resourceView}>View All</Typography>
                        </Link>
                    )
                )} */}
            </Box>
        </>
    );
}

export default EventRFPSection;
