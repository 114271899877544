import React from 'react'
import { Typography, Avatar, Tooltip } from '@mui/material';

const CommonInitials = ({ className, avatar, toolTip, toolTitle, divStyle, typoStyle, avatarStyle, imgStyle, imgSrc, name, bgColor, onClick, companyLogo, src }) => {
    // console.log(companyLogo, 'here')
    return (
        <>
            {companyLogo !== 'null' && companyLogo !== null && companyLogo !== undefined ?
                avatar ?
                    toolTip ?
                        <Tooltip title={toolTitle} placement="bottom" arrow>
                            <Avatar alt={name} variant="rounder" style={{ ...avatarStyle }} src={src} />
                        </Tooltip> :
                        <Avatar alt={name} variant="rounder" style={{ ...avatarStyle }} src={src} onClick={onClick} />
                    :
                    <img src={imgSrc} alt='' style={{ ...imgStyle }} loading='lazy' />
                :
                toolTip ?
                    <Tooltip title={toolTitle} placement="bottom" arrow>
                        <div className={className} style={{
                            ...divStyle, background: bgColor
                        }} onClick={onClick}>
                            <Typography style={{ textAlign: 'center', color: '#F5F5F8', fontWeight: '600', fontFamily: 'poppins', ...typoStyle }}>{name}</Typography>
                        </div>
                    </Tooltip> :
                    <div className={className} style={{
                        ...divStyle, background: bgColor
                    }} onClick={onClick}>
                        <Typography style={{ textAlign: 'center', color: '#F5F5F8', fontWeight: '600', fontFamily: 'poppins', ...typoStyle }}>{name}</Typography>
                    </div>
            }
        </>
    )
}

export default CommonInitials
