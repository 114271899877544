import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import theme from '../../assets/material-ui/theme';
import CommonButton2 from "./CommonButton2";

const permissionDialog = ({ open, handleClose, title, message }) => {

    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            // console.log('backdropClicked. Not closing dialog.')
            return;
        }
        handleClose();
    }
    return (
        <>
            <Dialog open={open} onClose={handleDlgClose} PaperProps={{ sx: { minWidth: "600px" } }} disableBackdropClick>
                <DialogTitle sx={{ color: theme.palette.success.dark, textAlign: 'left', fontWeight: '600', fontSize: '18px', py: 1, borderBottom: '1px solid #666' }}>
                    {title}
                    {/* <IconButton sx={{ position: 'absolute', top: 3, right: 0, color: 'red' }} onClick={handleClose}>
                    <CancelOutlinedIcon />
                </IconButton> */}
                </DialogTitle>
                <DialogContent sx={{ textAlign: 'left', fontWeight: '500', fontFamily: 'poppins', color: theme.palette.success.light, fontSize: '16px', mt: '20px', py: 3 }}>
                    <Typography>{message}</Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'right', padding: { md: '10px 20px 10px 120px', xs: '10px 30px 20px 30px' }, alignItems: "center", gap: "15rem", borderTop: '1px solid #666' }}>
                    <CommonButton2
                        label="OK"
                        name="cancel"
                        type="button"
                        color="primary"
                        variant='contained'
                        onClick={() => handleClose()} />
                </DialogActions>
            </Dialog>
        </>
    )
}

export default permissionDialog;