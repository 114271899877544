import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton, Typography, Card, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CommonMultiSelect from '../../../common/components/CommonMultiSelect';
import CommonSelect from '../../../common/components/CommonSelect';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import theme from "../../../assets/material-ui/theme";
import FieldTitle from "./FieldTitle";
import SingleSelect from "../../../common/components/SingleSelect";
import ReduxForm from '../../../common/components/ReduxForm';
import { submitCatchmentAreas, getDistrict, deleteCatchmentArea, getStateNameById, getDistrictNameByIds } from '../actions';
import { submit, reset, change, untouch } from 'redux-form';
import * as clientValidations from '../../../common/validations';
import { setCatchmentFlag } from '../../../store/slices/ProposalSlice';
import CommonDialog from "../../../common/components/CommonDialog";
import CommonButton2 from "../../../common/components/CommonButton2";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    commonHeading: {
        fontSize: '14px !important',
        fontWeight: '600',
        fontFamily: 'poppins',
        color: theme.palette.success.light
    },
    common: {
        fontSize: '12px !important',
        fontWeight: '400',
        fontFamily: 'poppins',
        fontStyle: "italic",
        marginLeft: '5px !important',
        color: theme.palette.success.light,
        textAlign: 'center',
        paddingBottom: '5px'
    }

}));
const CatchmentArea = (props) => {
    const classes = useStyles();
    const [initialValues, setFormValues] = useState({});
    const [editFlag, setEditFlag] = useState(false);
    const [formIndex, setFormIndex] = useState(0);
    const dispatch = useDispatch();
    const { proposal, master } = useSelector((state) => {
        return state;
    });
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = (index) => {
        setFormIndex(index);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleDelete = () => {
        dispatch(deleteCatchmentArea(formIndex));
        handleCloseDialog();
    };

    const handleEditEvent = (index) => {
        const { catchmentAreas } = proposal;
        setFormValues(catchmentAreas[index]);
        setEditFlag(true);
        setFormIndex(index);
        dispatch(setCatchmentFlag(true));
        dispatch(getDistrict(catchmentAreas[index].state));
    }
    useEffect(() => {
        if (!proposal.catchmentFlag) {
            setEditFlag(false);
            setFormValues({});
            dispatch(reset('catchmentAreaForm'));
        }
    }, [dispatch, proposal.catchmentFlag]);
    const handleState = (value) => {
        dispatch(getDistrict(value));
        dispatch(change('catchmentAreaForm', 'district', []));
    }
    return (
        <>
            <Grid item xs={12} sx={{ padding: { md: '0px 40px 40px 40px', xs: '0px 20px 40px 20px' } }}>
                <Card elevation={4} style={{ padding: '20px', borderRadius: 'none' }} >
                    <Grid item xs='12'>
                        <Typography style={{ fontSize: '20px', color: theme.palette.primary.main, fontWeight: '700', paddingBottom: '5px' }}>Catchment Area</Typography>
                        <Box style={{ borderBottom: '1px solid #000000' }}></Box>
                        <Box>
                            {Array.isArray(proposal.catchmentAreas) && proposal.catchmentAreas.length > 0 && proposal.catchmentAreas.map((data, index) => (
                                <Box style={{ display: 'flex', alignItems: 'flex-start' }} key={index}>
                                    <Typography style={{ fontWeight: '700', fontSize: '16px',width: '160px', flexShrink: 0,flexDirection: "column"}}>{dispatch(getStateNameById(data.state))} </Typography>
                                    <Typography style={{ fontWeight: '700', fontSize: '16px'}}>:</Typography>
                                    <Typography style={{ fontSize: '16px', marginLeft: '5px', wordBreak: 'break-word',textAlign:'justify',flexDirection: "row", flex: "1 0 0"}}>{dispatch(getDistrictNameByIds(data.district))}</Typography>
                                    <Box style={{ marginLeft: 'auto', display: 'flex' }}>
                                        <IconButton style={{ color: "#333333" }} onClick={() => handleEditEvent(index)}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        {/* <IconButton size="small" style={{ color: "#D45454" }} onClick={() => dispatch(deleteCatchmentArea(index))}> */}
                                        <IconButton style={{ color: "#D45454" }} onClick={() => handleOpenDialog(index)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        <ReduxForm
                            onSubmit={(values) => dispatch(submitCatchmentAreas(values, editFlag, formIndex))}
                            initialValues={{ ...initialValues }}
                            form="catchmentAreaForm"
                            label="catchmentAreaForm"
                            submitButton="Save"
                        >
                            <Grid container xs='12' style={{ paddingTop: '20px' }}>
                                <Grid item xs={12} md={5.1} sx={{ paddingBottom: { xs: '40px' } }}>
                                    <FieldTitle title={`State*`} className={classes.commonHeading} />
                                    <CommonSelect
                                        name="state"
                                        validate={[clientValidations.required]}
                                        onChange={(e, value) => handleState(value)}
                                    >
                                        <MenuItem value="" > Select a State </MenuItem>
                                        {master.state && master.state.map(obj => (
                                            <MenuItem style={{ borderTop: "1px solid grey" }} value={obj.id} key={obj.name}>
                                                {obj.name}
                                            </MenuItem>
                                        ))}
                                    </CommonSelect>
                                </Grid>

                                <Grid item xs={12} md={5.1} sx={{ marginLeft: { xs: '0px', md: '30px' }, paddingBottom: '15px' }}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <FieldTitle title={`District*`} className={classes.commonHeading} />
                                        <Typography className={classes.common}>(You can select more than one option)</Typography>
                                    </Box>
                                    <CommonMultiSelect
                                        name="district"
                                        options={proposal.district || []}
                                        placeholder={props.values && props.values.district && props.values.district.length > 0 ? "" : "Select an Options"}
                                        validate={[clientValidations.required, clientValidations.requiredList]}
                                        fullWidth={true}
                                        disabled={!props?.values?.state}
                                    />
                                </Grid>
                                {/* <Grid item xs={6.5} md={1} sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: { md: '0px', xs: '5px' }, paddingBottom: '10px' }}>
                                    <IconButton size="small" style={{ color: "#66D454" }} onClick={() => dispatch(submit('catchmentAreaForm'))}>
                                        <AddCircleOutlineIcon />
                                    </IconButton> */}
                                <Grid item xs={12} md={1.2} sx={{ marginLeft: { xs: '0px', md: '10px' }, paddingTop: { md: '24px', xs: '0px' } }}>
                                    <CommonButton2
                                        label="Add"
                                        name="Add"
                                        startIcon={<AddCircleOutlineIcon />}
                                        type="button"
                                        variant="contained"
                                        onClick={() => dispatch(submit('catchmentAreaForm'))}
                                        disabled={!props?.values?.state || !props?.values?.district || props?.values?.district.length === 0}
                                        style={{ borderRadius: '40px', padding: '10px' }}
                                    />
                                </Grid>
                            </Grid>
                        </ReduxForm>
                    </Grid>
                </Card>
            </Grid>
            <CommonDialog
                open={openDialog}
                handleClose={handleCloseDialog}
                handleConfirm={handleDelete}
                title="Delete Confirmation"
                message="Are you sure you want to delete ?"
                confirmText="Yes"
                cancelText="No"
            />
        </>
    )

}
export default CatchmentArea;

