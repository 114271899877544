import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import MainHeader from '../../../../common/components/MainHeader';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { PROFILE_TYPE } from '../../../../common/constants';
import EventMainHeader from '../Events/EventMainHeader';
import EventBody from '../Events/EventBody';
import BengateMainHeader from './SubComponents/BengateMainHeader';
import { getBengageList, getMasterData } from '../../actions';
import BengageBody from './SubComponents/BengageBody';


const SocialBengage = () => {
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { users: { userData },socialMedia: { isPreviewOpen }  } = useSelector(state => state);
    const isIndividual = userData?.profile_type?.toLowerCase() !== PROFILE_TYPE.COMPANY ? true : false;
    let offset = useRef(10);
    const handleTabChange = (event, newValue) => {
        if (newValue === 2 && !isIndividual) {
            navigate('/bEngage/listing');
        }
        setTabValue(newValue);
    };
    useEffect(() => {
        dispatch(getMasterData());
        dispatch(getBengageList(offset.current));
    }, [])

    return (
        <>
            <Box className={SocialMediaCSS.MainHeader} sx={{ zIndex: isPreviewOpen ? 0 : 99 }}>
                <MainHeader />
            </Box>
            <Grid container className={SocialMediaCSS.bgGrey100} height="100vh" >
                <Grid item xs={12} className={SocialMediaCSS.mainContent} sx={{ overflow: 'visible' }}>
                    <Container maxWidth={'xl'}>
                        <Box className={SocialMediaCSS.eventMainContainer}>
                            <BengateMainHeader value={tabValue} handleChange={handleTabChange} offset={offset} isIndividual={isIndividual} />
                            <BengageBody value={tabValue} offset={offset} isIndividual={isIndividual}/>
                        </Box>
                    </Container>
                </Grid>

            </Grid>
        </>
    );
};

export default SocialBengage;
