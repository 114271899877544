import { Box, Container, Grid, useMediaQuery } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import SocialMediaCSS from '../../../SocialMedia/SocialMediaCSS/SocialMedia.module.css';
import MainHeader from '../../../../common/components/MainHeader';
import { getPostTypes } from '../../../../common/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CustomSnackbar from '../../../../common/components/CommonSnackbar';
import { getMasterData, getNotificationData, readAllNotifications } from '../../actions';
import RightSidebar from '../RightSidebar/RightSidebar';
import NotificationScreen from './NotificationScreen';
import { useNavigate } from 'react-router';

const Notification = () => {
    const snackbarProps = useSelector((state) => state.message);
    const { users, socialMedia: { notificationList, totalNotificationCount,isPreviewOpen } } = useSelector((state) => state);
    const navigate = useNavigate();
    const isMdScreen = useMediaQuery('(max-width: 1280px)');
    const dispatch = useDispatch();
    const offset = useRef(10);
    useEffect(() => {
        dispatch(getPostTypes());
        dispatch(getMasterData());
        dispatch(getNotificationData());
        // dispatch(readAllNotifications());
    }, [])
    return (
        <>
            <Box className={SocialMediaCSS.MainHeader} sx={{ zIndex: isPreviewOpen ? 0 : 99 }}>
                <MainHeader />
            </Box>
            {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Grid container className={SocialMediaCSS.bgGrey100} height="100vh" >
                <Grid item xs={12} className={SocialMediaCSS.mainContent} sx={{ overflow: 'visible' }}>
                    <Container>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={9}>
                                <NotificationScreen SocialMediaCSS={SocialMediaCSS} notificationList={notificationList} totalNotificationCount={totalNotificationCount} navigate={navigate} offset={offset} dispatch={dispatch} />
                            </Grid>
                            {!isMdScreen && <Grid item xs={12} lg={3}>
                                <RightSidebar />
                            </Grid>}
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </>
    )
}

export default Notification