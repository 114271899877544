import React, { } from 'react';
import { makeStyles } from '@mui/styles';
import { Popover, List, ListItem, ListItemText, Checkbox } from '@mui/material';
import { setEventCategory } from '../../../../../store/slices/SocialMediaSlice';
import { getCategoryEventsList, getSortByList } from '../actions';

const useStyles = makeStyles((theme) => ({
    popover: {
        boxShadow: '0px 0px 25px 0px #AAAAAA40',
    },
}));

const CategoryPopover = ({ options, anchorEl, open, onClose, checkedItems, setCheckedItems, anchorPosition, dispatch, eventCategory,eventSortBy }) => {
    const classes = useStyles();

    const handleCheckboxChange = (option) => {
        let checked = checkedItems;
        checked = {
            ...checkedItems,
            [option]: !checkedItems[option],
        }
        setCheckedItems({
            ...checkedItems,
            [option]: !checkedItems[option],
        });
        if (checked) {
            const selectedThemes = options
                .filter(item => checked[item.id.toString()])
                .map(item => item.id);
            // Check if category and selectedThemes have the same length
            // and if all items in category are included in selectedThemes
            const isSameLengthAndValues = eventCategory?.length === selectedThemes?.length &&
                eventCategory.every(catItem => selectedThemes.includes(catItem));
            if (isSameLengthAndValues) {
                // setAnchorEl2(null);
                return;
            }
            // console.log(selectedThemes)
            dispatch(setEventCategory(selectedThemes))
            if (eventSortBy) {
                dispatch(getSortByList(10, false, '', eventSortBy, selectedThemes))
                // dispatch(setFIlterData([]));
                // dispatch(reset('filterForm'));
            } else {
                dispatch(getCategoryEventsList(10, false, '', selectedThemes));
            }
        }
        console.log(checked, "checked")
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                style: {
                    width: '280px', height: '368px', scrollbarWidth: 'none', boxShadow: '0px 2px 4px -1px #00000012, 0px 4px 6px -1px #0000001F', border: '1px solid rgb(153 153 153/50%)', borderRadius: '6px',
                }
            }}
            style={{
                ...anchorPosition,
                top: '130px',
                left: '90px',
            }}
            transitionDuration={0}
        >
            <List className={classes.popover}>
                {options.map((option) => (
                    <ListItem key={option.id} sx={{ gap: '10px', pb: 0 }}>
                        <Checkbox
                            checked={checkedItems[option.id] || false}
                            onChange={() => handleCheckboxChange(option.id)}
                            sx={{ padding: 0, '& .MuiSvgIcon-root': { width: '16px', height: '16px' } }}
                        />
                        <ListItemText primary={option.value} sx={{ '& .MuiTypography-root': { fontSize: '14px', lineHeight: '21px' } }} />
                    </ListItem>
                ))}
            </List>
        </Popover>
    );
};

export default CategoryPopover;
