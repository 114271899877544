import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function CommonButton(props) {
  return (
    <div className={props.className}>
      <Button
        color='secondary'
        size={props.size || 'large'}
        fullWidth={!props.smallWidth}
        id={props.name}
        onClick={props.onClick}
        // className={props.classes}
        sx={{
          fontFamily: 'Poppins',
          fontSize: 16,
          borderRadius:10,
          fontWeight: 600,
          whiteSpace: 'nowrap',
          backgroundColor: '#FFFFFF',
          ...props.style,
        }}
        className="custom-button"
        startIcon={props.startIcon}
        endIcon={props.endIcon}
        variant={'contained'}
        type={props.type}
        disabled={props.disabled}
        disableRipple
      >
        {props.label}
      </Button>
    </div>
  );
}