import React from 'react';
import { InputBase, Paper} from '@mui/material';
import SearchIcon from "../../../../../assets/icons/Search.svg"
import SocialMediaCSS from "../../../SocialMediaCSS/SocialMedia.module.css";

const Search = ({ onSearch }) => {
    const handleChange = (e) => {
        const query = e.target.value;
        onSearch(query);
    };

    return (
        <Paper className={SocialMediaCSS.searchContainer}>
            <img src={SearchIcon}  className={SocialMediaCSS.searchTag}/>
            <InputBase
                placeholder="Search here...."
                className={SocialMediaCSS.searchInput}
                onChange={handleChange}
            />
          
        </Paper>
    );
};

export default Search;