import React from 'react';
import { Box, Divider, Typography, Skeleton } from '@mui/material';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';

const PostSkeleton = () => {
    return (
        <>
            <Box className={SocialMediaCSS.postCardSkeleton}>
                <Box className={SocialMediaCSS.postHeader}>
                    <Box className={SocialMediaCSS.postHeaderLogo}>
                        <Skeleton animation="wave" variant="circular" width={46} height={46} />
                    </Box>
                    <Box className={SocialMediaCSS.postHeaderInfoSkeleton}>
                        <Typography variant='body2' className={SocialMediaCSS.postHeaderName}>
                            <Skeleton animation="wave" width="60%" />
                        </Typography>
                        <Typography variant='body4' className={SocialMediaCSS.postHeaderName}>
                            <Skeleton animation="wave" width="40%" />
                        </Typography>
                        <Box className={SocialMediaCSS.postHeaderOtherSkeleton}>
                            <Skeleton animation="wave" variant="circular" width={16} height={16} />
                            <Skeleton animation="wave" width="8%" />
                            <Skeleton animation="wave" variant="circular" width={16} height={16} />
                            <Skeleton animation="wave" width="8%" />
                        </Box>
                    </Box>
                    <Skeleton animation="wave" variant="circular" width={20} height={20} />
                </Box>
                <Divider />
                <Box className={SocialMediaCSS.carouselContainer}>
                    <Box>
                        <Skeleton animation="wave" variant="rounded" width="100%" height={280} />
                    </Box>
                </Box>
                <Box className={SocialMediaCSS.postContentBox}>
                    <Skeleton animation="wave" width="100%" />
                    <Skeleton animation="wave" width="100%" />
                    <Skeleton animation="wave" width="20%" />
                </Box>
                <Divider />
                <Box className={SocialMediaCSS.actionContainer}>
                    <Box className={SocialMediaCSS.actionStats}>
                        <Box className={SocialMediaCSS.actionStatsCountSkeleton}>
                            <Skeleton animation="wave" variant="circular" width={16} height={16} />
                            <Skeleton animation="wave" width={20} height={20} />
                        </Box>
                        <Box className={SocialMediaCSS.actionStatsCountSkeleton}>
                            <Skeleton animation="wave" variant="circular" width={16} height={16} />
                            <Skeleton animation="wave" width={20} height={20} />
                        </Box>
                        <Box className={SocialMediaCSS.actionStatsCountSkeleton}>
                            <Skeleton animation="wave" variant="circular" width={16} height={16} />
                            <Skeleton animation="wave" width={20} height={20} />
                        </Box>
                        <Box className={SocialMediaCSS.thematicTooltipBox}>
                            <Skeleton animation="wave" width="20%" />
                        </Box>
                        <Box className={SocialMediaCSS.thematicCount}>
                            <Skeleton animation="wave" variant="circular" width={16} height={16} />
                            <Skeleton animation="wave" width={20} height={20} />
                        </Box>
                    </Box>
                    <Box className={SocialMediaCSS.actionsContainer}>
                        <Box className={SocialMediaCSS.actions}>
                            <Skeleton animation="wave" variant="circular" width={16} height={16} />
                            <Skeleton animation="wave" variant="circular" width={16} height={16} />
                            <Skeleton animation="wave" variant="circular" width={16} height={16} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PostSkeleton;
