import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import _ from 'lodash-es';
import { Box, Fade, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { reset } from 'redux-form';
import bvokalLogo from '../../../assets/images/BvokalNew.svg';
import theme from '../../../assets/material-ui/theme';
import { PROFILE_TYPE, SINGUP_SCREEN } from '../../../common/constants';
import RegCSS from '../common/RegistrationCSS/RegCSS.module.css';
import ChooseAccount from '../common/SignupPages/ChooseAccount';
import ChooseUserType from '../common/SignupPages/ChooseUserType';
import CreateAccount from '../common/SignupPages/CreateAccount';
import { getCompanyType } from '../actions';
import { setTempUserData } from '../../../store/slices/UserSlice';

const NewSignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedScreen, setSelectedScreen] = useState(SINGUP_SCREEN.CHOOSE);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedCompanyType, setSelectedCompanyType] = useState(null);
    const user = useSelector((state) => {
        return state.users;
    })
    const master = useSelector((state) => {
        return state.master;
    })

    const handleNavigation = (value) => {
        setSelectedScreen(value);
    }
    const changeNavigation = () => {
        dispatch(setTempUserData({}));
        dispatch(reset('signUP'))
        if (selectedOption === PROFILE_TYPE.COMPANY && selectedScreen === SINGUP_SCREEN.CREATE) {
            setSelectedScreen(SINGUP_SCREEN.COMPANY_TYPE);
        } else if (selectedOption === PROFILE_TYPE.COMPANY && selectedScreen === SINGUP_SCREEN.COMPANY_TYPE) {
            setSelectedScreen(SINGUP_SCREEN.CHOOSE);
        } else {
            setSelectedScreen(SINGUP_SCREEN.CHOOSE);
        }
    }

    useEffect(() => {
        if (user?.tempUserData?.name || user?.tempUserData?.email) {
            setSelectedScreen(SINGUP_SCREEN.CREATE);
            // if (location?.state?.user_type_id === 8) {
            //     setSelectedOption(PROFILE_TYPE.PERSONAL);
            // } else {
            //     setSelectedOption(PROFILE_TYPE.COMPANY);
            // }
        }
    }, [user.tempUserData]);

    const handleNavigate = () => {
        if (selectedOption === PROFILE_TYPE.PERSONAL) {
            setSelectedScreen(SINGUP_SCREEN.CREATE)
        } else {
            // if(_.isEmpty(user?.companyTypes?.company)){
            //     dispatch(getCompanyType());
            // }
            dispatch(getCompanyType());
          setSelectedScreen(SINGUP_SCREEN.COMPANY_TYPE);
        }
      }

    const handleCompanyTypeClick = (value) =>{
        setSelectedCompanyType(value);
        setSelectedScreen(SINGUP_SCREEN.CREATE);
    }
    
    useEffect(() => {
        return () => {
            dispatch(reset('signUP'));
        };
    }, []);
    return (
        <Box>
            <Box className={RegCSS.createAccRoot}>
                <Box>
                    <img src={bvokalLogo} height={50} />
                </Box>
                <Fade in={selectedScreen === SINGUP_SCREEN.CHOOSE}>
                    <Box sx={{ display: selectedScreen === SINGUP_SCREEN.CHOOSE ? "block" : "none" }}>
                        <ChooseAccount 
                            RegCSS={RegCSS} 
                            handleNavigation={handleNavigation} 
                            setSelectedOption={setSelectedOption} 
                            selectedOption={selectedOption} 
                            handleNavigate={handleNavigate}
                            dispatch={dispatch}
                            master={master}
                        />
                    </Box>
                </Fade>
                {selectedScreen === SINGUP_SCREEN.COMPANY_TYPE && 
                    <Fade in={selectedScreen === SINGUP_SCREEN.COMPANY_TYPE}>
                        <Box sx={{ display: selectedScreen === SINGUP_SCREEN.COMPANY_TYPE ? "block" : "none" }}>
                            <ChooseUserType 
                                RegCSS={RegCSS} 
                                handleNavigation={handleNavigation}
                                handleCompanyTypeClick={handleCompanyTypeClick}
                                dispatch={dispatch}  
                                user={user}
                            />
                        </Box>
                    </Fade>
                }
                {selectedScreen === SINGUP_SCREEN.CREATE &&
                    <Fade in={selectedScreen === SINGUP_SCREEN.CREATE}>
                        <Box sx={{ display: selectedScreen === SINGUP_SCREEN.CREATE ? "block" : "none" }}>
                            <CreateAccount 
                                dispatch={dispatch} 
                                location={location} 
                                navigate={navigate} 
                                RegCSS={RegCSS} 
                                selectedOption={selectedOption} 
                                selectedCompanyType={selectedCompanyType} 
                            />
                        </Box>
                    </Fade>
                }
                {(selectedScreen === SINGUP_SCREEN.CREATE || selectedScreen === SINGUP_SCREEN.COMPANY_TYPE) && 
                    <IconButton 
                        className={RegCSS.createAccArrowBtn} 
                        onClick={changeNavigation}>
                        <ArrowBackIcon className={RegCSS.createAccArrow} />
                    </IconButton>
                }
            </Box>
        </Box>
        
    )
}
export default NewSignUp;
