import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import CommonInput from "../../../../common/components/CommonInput";
import ReduxForm from "../../../../common/components/ReduxForm";
import SocialSend from "../../../../assets/icons/SocialSend.svg";
import * as clientValidations from '../../../../common/validations';
import { submit } from "redux-form";
import { invitePartner } from "../../actions";
import { useSelector } from "react-redux";

const InvitePartners = ({ socialMedia, users, dispatch, master }) => {
    const { form } = useSelector((state) => state);
    const [errMsg, setErrMsg] = useState('');
    const InviteFormData = form?.Inviteform && form?.Inviteform?.values;
    useEffect(() => {
        if (InviteFormData && InviteFormData?.invite) {
            setErrMsg('');
        }
    }, [InviteFormData]);

    const handleSubmit = () => {
        if (clientValidations.EmailRequired(InviteFormData?.invite) || clientValidations.emailCheck(InviteFormData?.invite)) {
            let error = clientValidations.EmailRequired(InviteFormData?.invite) || clientValidations.emailCheck(InviteFormData?.invite);
             setErrMsg(error);
        } else {
            setErrMsg('');
            dispatch(submit('Inviteform'))
        }
    }

    return (
        <>
            <Grid item xs={12} className={SocialMediaCSS.inviteCard}>
                <ReduxForm
                    onSubmit={(values) => dispatch(invitePartner(values, setErrMsg))}
                    form="Inviteform"
                    label="Invite"
                    submitButton="Save">
                    <Typography className={SocialMediaCSS.inviteTypo}>Invite your partners.</Typography>
                    <Box className={`${SocialMediaCSS.inviteBox}`} >
                        <Box className={SocialMediaCSS.inviteField}>
                            <CommonInput
                                name="invite"
                                placeholder="Email Address"
                                keyboardType="text"
                                type="Invite"
                                fullWidth
                                // validate={[clientValidations.EmailRequired, clientValidations.emailCheck]}
                                maxLength={150}
                            />
                        </Box>
                        <Box className={SocialMediaCSS.inviteButt} onClick={handleSubmit}>
                            <img src={SocialSend}  />
                        </Box>
                    </Box>
                    <Typography className={SocialMediaCSS.inviteError}>{errMsg}</Typography>
                </ReduxForm>
            </Grid>
        </>
    );
}

export default InvitePartners;
