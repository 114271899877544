import { initialize, reset } from "redux-form";
import { fetchAPI } from "../../common/actions";
import { bENGAGE_SECTIONS, COMMON_MSG, FILE_HOST, httpMethod, PAGE_LIMIT, sortBy, UPLOAD_DATA } from "../../common/constants";
import { base64ToFile, base64ToFile2, base64ToFile3, convertJSONparse, convertJsonToQS, convertTime, convertTo24HourFormat, extractTime, getDateRange, isDataURL, isJSON } from "../../common/utils";
import { BOOKMARK_SHOW, CONFERENCE_DETAILS, CONFERENCE_PARTICIPANTS, CONFERENCES, CONFERENCES_DASHBOARD, DIRECTORY, DISTRICT_URL, INVITATION, NOMINATION, PUBLIC_CONFERENCE, SEARCH_EMAIL } from "../../http/apiUrls";
import { setSnackbar } from "../../store/slices/MessageSlice";
import { addBEngageData, addBEngageDistrict, resetbEngageSlice, setAwardList, setBEngageDashboard, setBEngageInitalValues, setBEngageListData, setDelegatesList, setFloorPlan, setGid, setPartnershipList, setSelectedActiveTab, setSpeakerList, setSponsorList, setTotalCount, setOtpDelivered, setGetNewRegId, setAgendaList, setNominationLists, setPartnerGid, setAwardGid, setEmailList, setRegistrationDetails, setConferenceParticiapnts, setConferenceParticiapntsCount, setAgendaSpeaker, setConferencesDetail, setUpdateAgenda, setUserSpeakerList, updatePOC, addPOC, setNewPocFlag, updateBengageUploadedData, addBengageUploadedData, setAddInvitationErrorData, setSelectedConferenceMode, setSelectedType, setOrganiserLogos, setConferenceBadge, setNameList, setSelectedBadgeName, setDirectoryList, setTotalDirectoryCount, setHistoryList } from "../../store/slices/bEngageSlice";
import { useNavigate } from "react-router";
import { setIsLoad } from "../../store/slices/MasterSlice";
import { setBengageDashboardList } from "../../store/slices/SocialMediaSlice";

const updatebEngageInitalValues = (response, eventScheduleDates) => (dispatch) => {
    let selectedTabs = response?.data?.selected_tabs === "" ? [] : response?.data?.selected_tabs;
    let dataToSave = {
        banner: response?.data?.banner || '',
        title: response?.data?.title || '',
        body: response?.data?.body || '',
        payment: response?.data?.autopay_enabled || false,
        thematic_area_ids: response?.data?.thematic_area_id || '',
        event_mode_id: response?.data?.event_mode === 'In-Person' ? 1 : 2 || '',
        // online_link: response?.data?.online_link || '',
        start_date: eventScheduleDates?.length > 0 ? eventScheduleDates[0] : '',
        end_date: eventScheduleDates?.length > 0 ? eventScheduleDates[eventScheduleDates.length - 1] : '',
        start_time: response?.data?.start_date ? extractTime(response?.data?.start_date) : '00:00',
        end_time: response?.data?.end_date ? extractTime(response?.data?.end_date) : '00:00',
        date_schedule: eventScheduleDates || [],
        components: isJSON(selectedTabs) ? JSON.parse(selectedTabs) : convertJSONparse(selectedTabs) || [],
    }
    if (response?.data?.event_mode === 'In-Person') {
        dataToSave = {
            ...dataToSave,
            pincode: response?.data?.conference_address_details?.pincode || '',
            address: response?.data?.conference_address_details?.address || '',
            district_id: response?.data?.conference_address_details?.district_id || '',
            state_id: response?.data?.conference_address_details?.state_id || '',
            city: response?.data?.conference_address_details?.city || '',
        }
    } else {
        dataToSave = {
            ...dataToSave,
            event_link: response?.data?.event_link || '',
        }
    }
    const uploadedData = {
        filename: UPLOAD_DATA.BENGAGE.FIELD_NAME,
        key: UPLOAD_DATA.BENGAGE.FIELD_NAME,
    }
    dispatch(setBEngageInitalValues(dataToSave));
    dispatch(addBengageUploadedData(uploadedData, UPLOAD_DATA.BENGAGE.FIELD_NAME));
    dispatch(setSelectedConferenceMode(dataToSave?.event_mode_id));
    dispatch(updatePOC(response?.data?.conference_contact_details || []));
    dispatch(setSelectedType(response?.data?.private ? 'Private' : 'Public'));
    dispatch(setConferenceBadge(response?.data?.badge_name !== null ? true : ""));
    dispatch(setSelectedBadgeName(response?.data?.badge_name || ""))
}

export const submitBEngage = (values, selectedOption, goTo, navigate, setIsApiCallInProgress) => (dispatch, getState) => {
    const { bEngage: { gid, newPoc, selectedType, conferenceBadge, selectedBadgeName } } = getState();
    let APIUrl = gid ? `${CONFERENCES}/${gid}` : CONFERENCES;
    let method = gid ? httpMethod.PUT : httpMethod.POST;
    const formData = new FormData();
    formData.append('private', selectedType === 'Private' ? true : false);
    formData.append('title', values.title);
    formData.append('body', values.body);
    formData.append('start_date', `${values.date_schedule[0]} ${values?.start_time}:00 +0530`);
    formData.append('end_date', `${values.date_schedule[values.date_schedule.length - 1]} ${values?.end_time}:00 +0530`);
    if (isDataURL(values.banner)) {
        // Convert base64 banner to File object
        const bannerFile = base64ToFile(values.banner, 'banner', true);
        formData.append('banner', bannerFile);
    }

    if (values.event_mode_id !== 2) {
        formData.append('address', values.address);
        formData.append('state_id', values.state_id);
        formData.append('district_id', values.district_id);
        formData.append('city', values.city);
        formData.append('pincode', values.pincode);
    } else {
        formData.append('event_link', values.event_link);
    }
    // conferenceBadge !== '' && formData.append('badge_selected', conferenceBadge);
    selectedBadgeName !== '' && formData.append('badge_name', selectedBadgeName);
    formData.append('event_mode_id', values.event_mode_id);
    formData.append('selected_tabs', JSON.stringify(selectedOption));
    // Append POC
    newPoc.forEach((poc, index) => {
        // Check if the POC object is not empty
        if (poc.name || poc.email || poc.phone || poc.poc_for) {
            formData.append(`poc[${index}][name]`, poc.name);
            formData.append(`poc[${index}][email]`, poc.email);
            formData.append(`poc[${index}][phone]`, poc.phone);
            formData.append(`poc[${index}[nomination_type_id]]`, poc.nomination_type_id);
        }
    });

    if (values?.payment) {
        formData.append('autopay_enabled', values.payment);
    } else {
        formData.append('autopay_enabled', false);
    }

    // Append thematic_area_ids
    values.thematic_area_ids.forEach((id) => {
        formData.append('thematic_area_ids[]', id);
    });
    // formData.append('thematic_area_id', values?.thematic_area_id);

    dispatch(fetchAPI(APIUrl, method, formData, '', true)).then(
        response => {
            if (response.success) {
                dispatch(addBEngageData(response.data));
                dispatch(setGid(response?.data?.gid));
                dispatch(updatebEngageInitalValues(response, getDateRange(response?.data?.start_date, response?.data?.end_date)));
                if (goTo === 'preview') {
                    navigate(`/bEngage/admin-public-preview/${response?.data?.gid}`)
                } else if (goTo === 'listing') {
                    dispatch(resetbEngageSlice());
                    navigate(`/bEngage/listing`);
                }
                if (setIsApiCallInProgress) {
                    setIsApiCallInProgress(false);
                }
                dispatch(setPartnerGid(null));
                dispatch(setAwardGid(null));
            } else if (response.error) {
                if (setIsApiCallInProgress) {
                    setIsApiCallInProgress(false);
                }
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getConference = (gid, navigate) => (dispatch) => {
    dispatch(fetchAPI(`${CONFERENCES}/${gid}`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addBEngageData(response.data));
                dispatch(setGid(response?.data?.gid));
                dispatch(updatebEngageInitalValues(response, getDateRange(response?.data?.start_date, response?.data?.end_date)));
                let activeTab = isJSON(response?.data?.selected_tabs) ? JSON.parse(response?.data?.selected_tabs) : convertJSONparse(response?.data?.selected_tabs);
                dispatch(setSelectedActiveTab(activeTab[0]));
            } else if (response.error) {
                if (response?.error?.errorCode === 422) {
                    navigate('/page-not-found')
                }
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getPublicConference = (gid, admin, navigate) => (dispatch) => {

    const dataToSend = {
        admin_preview: admin ? true : false,
    }
    dispatch(fetchAPI(`${PUBLIC_CONFERENCE}/${gid}`, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(addBEngageData(response.data));
                dispatch(setGid(response?.data?.gid));
                // dispatch(updatebEngageInitalValues(response, getDateRange(response?.data?.start_date, response?.data?.end_date)));
                let activeTab = isJSON(response?.data?.selected_tabs) ? JSON.parse(response?.data?.selected_tabs) : convertJSONparse(response?.data?.selected_tabs);
                dispatch(setSelectedActiveTab(activeTab[0]));
                dispatch(setSpeakerList(response?.data?.speaker_list));
                dispatch(setSponsorList(response?.data?.sponsor_list));
                dispatch(setAwardList(response?.data?.conference_awards));
                dispatch(setAgendaList(response?.data?.conference_agendas));
                dispatch(setFloorPlan(response?.data?.floor_plan));
                dispatch(setPartnershipList(response?.data?.partnership_list));
            } else if (response.error) {
                if (response?.error?.errorCode === 422) {
                    navigate('/page-not-found')
                }
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const publishConference = (gid, navigate) => (dispatch) => {
    const dataToPost = {
        gid,
    }
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/publish`, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                navigate('/bEngage/published');
                dispatch(resetbEngageSlice());
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const cancelConference = (gid, values, handleClose) => (dispatch) => {
    const dataToPost = {
        gid,
        cancel_comment: values?.cancel_comment,
    }
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/cancel`, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
                handleClose();
                dispatch(getAllConferences());
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getAllDistrict = (state_id) => (dispatch) => {
    const APIUrl = state_id ? `${DISTRICT_URL}?state_id=${state_id}` : DISTRICT_URL;
    dispatch(fetchAPI(APIUrl, httpMethod.GET)).then((response) => {
        if (response.success) {
            dispatch(addBEngageDistrict(response?.data?.districts)); // set district for events
        } else if (response.error) {
            dispatch(
                setSnackbar({
                    flag: true,
                    message: response.error.errorMsg,
                    type: "error",
                })
            );
        }
    });
};

export const inviteSpeaker = (values) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    const dataToPost = values;
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/speaker_invitations`, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(reset('inviteSpeakerForm'));
                dispatch(getSpeakerList());
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const searchEmail = (email, setShowStates, showStates, index) => (dispatch) => {
    const dataToPost = { email };
    dispatch(fetchAPI(SEARCH_EMAIL, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                if (response?.data) {
                    dispatch(setEmailList(response?.data));
                    const updatedShowStates = [...showStates];
                    updatedShowStates[index] = true;  // Update the specific `show` state for the current invitation
                    setShowStates(updatedShowStates);
                    // setShow(true);
                } else {
                    dispatch(setEmailList([]));
                    // setShow(false);
                }
                // dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const searchName = (name, setShowNames, showNames, index) => (dispatch) => {
    const dataToPost = { name };
    dispatch(fetchAPI(SEARCH_EMAIL, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                if (response?.data) {
                    dispatch(setNameList(response?.data));
                    const updatedShowStates = [...showNames];
                    updatedShowStates[index] = true;  // Update the specific `show` state for the current invitation
                    setShowNames(updatedShowStates);
                    // setShow(true);
                } else {
                    dispatch(setNameList([]));
                    // setShow(false);
                }
                // dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const reInviteSpeaker = (id) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/speaker_invitations/${id}`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const removeSpeaker = (id) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/speaker_invitations/${id}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                dispatch(getSpeakerList());
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getSpeakerList = () => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/speaker_invitations`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setSpeakerList(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const submitPartnership = (values, setPreviewUrl) => (dispatch, getState) => {
    const { bEngage: { gid, partnerGid } } = getState();
    let APIUrl;
    let method;
    const formData = new FormData();
    if (isDataURL(values?.icon)) {
        // Convert base64 banner to File object
        const iconFile = base64ToFile(values.icon, 'icon', true);
        formData.append('icon', iconFile);
    }
    formData.append('name', values.name);
    formData.append('features', values.features);
    formData.append('fee', values.fee);
    if (partnerGid) {
        APIUrl = `${CONFERENCES}/${gid}/partner_invitations/${partnerGid}`;
        method = httpMethod.PUT;
    } else {
        APIUrl = `${CONFERENCES}/${gid}/partner_invitations`;
        method = httpMethod.POST;
    }
    dispatch(fetchAPI(APIUrl, method, formData, '', true)).then(
        response => {
            if (response.success) {
                dispatch(reset('partnershipForm'));
                dispatch(getPatnershipList());
                setPreviewUrl(null);
                dispatch(setPartnerGid(null));
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getPatnershipList = () => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/partner_invitations`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setPartnershipList(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const removePartnership = (id) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/partner_invitations/${id}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                dispatch(getPatnershipList());
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const inviteSponsor = (values) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    const dataToPost = values;
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/sponsor_invitations`, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(reset('sponsorForm'));
                dispatch(getSponsorList());
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getSponsorList = () => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/sponsor_invitations`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setSponsorList(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const removeSponsor = (id) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/sponsor_invitations/${id}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                dispatch(getSponsorList());
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const submitAward = (values, setPreviewUrl) => (dispatch, getState) => {
    const { bEngage: { gid, awardGid } } = getState();
    let APIUrl;
    let method;
    const formData = new FormData();
    if (isDataURL(values?.icon)) {
        // Convert base64 banner to File object
        const iconFile = base64ToFile2(values.icon, '', true);
        formData.append('icon', iconFile);
    }
    formData.append('name', values.name);
    formData.append('category', values.category);
    formData.append('award_date', values.award_date);
    formData.append('nomination_date', values.nomination_last_date);
    if (awardGid) {
        APIUrl = `${CONFERENCES}/${gid}/conference_awards/${awardGid}`;
        method = httpMethod.PUT;
    } else {
        APIUrl = `${CONFERENCES}/${gid}/conference_awards`;
        method = httpMethod.POST;
    }
    dispatch(fetchAPI(APIUrl, method, formData, '', true)).then(
        response => {
            if (response.success) {
                dispatch(reset('awardForm'));
                dispatch(getAwardList());
                dispatch(setAwardGid(null));
                setPreviewUrl(null);
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getAwardList = () => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/conference_awards`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setAwardList(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const removeAward = (id) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/conference_awards/${id}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                dispatch(getAwardList());
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const submitFloorPlan = (file, setPreviewUrl) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    const formData = new FormData();
    formData.append('plan', file);
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/update_floor_plan`, httpMethod.PUT, formData, '', true)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getFloorPlan = () => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/show_floor_plan`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setFloorPlan(response?.data?.plan));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const uploadAgenda = (file, setPreviewUrl) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    const formData = new FormData();
    formData.append('document', file);
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/conference_agendas`, httpMethod.POST, formData, '', true)).then(
        response => {
            if (response.success) {
                dispatch(setAgendaList(response?.data));
                // dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const removeUploadedAgenda = (id, setPreviewUrl) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/conference_agendas/${id}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                setPreviewUrl(null);
                dispatch(getAgendaList());
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getAgendaList = () => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/conference_agendas`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setAgendaList(response?.data));
                // dispatch(setAgendaSpeaker(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getOrganiserList = () => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/conference_organisers`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setOrganiserLogos(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const addOrganiserLogo = (fileArray) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    const formData = new FormData();
    // Filter out null entries and append each logo to the formData
    fileArray.forEach((fileObj, index) => {
        if (fileObj && fileObj.file) {
            formData.append(`conference_organiser[][logo]`, fileObj.file);
        }
    });
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/conference_organisers`, httpMethod.POST, formData, '', true)).then(
        response => {
            if (response.success) {
                // dispatch(setOrganiserLogos(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

// export const submitAgenda = () => (dispatch, getState) => {
//     const { bEngage: { agendaData, gid, agendaSpeakerData } } = getState();
//     // Helper function to find the speaker object by name in agendaSpeakerData
//     const findSpeakerByName = (name) => {
//         for (let schedule of agendaSpeakerData) {
//             for (let agenda of schedule.agendas) {
//                 for (let speaker of agenda.speakers) {
//                     if (speaker.name === name) {
//                         return speaker;
//                     }
//                 }
//             }
//         }
//         return null; // Return null if no match is found
//     };

//     // Transform the data
//     const transformAgendaData = (agendaData) => {
//         const defaultValues = {
//             topic: "Topic name",
//             location: "Location name",
//             speaker: "Speaker name",
//             start_time: "Time Interval",
//             // end_time: undefined // Adjust based on your application logic
//         };

//         return agendaData.flatMap(({ schedule_date, agendas }) =>
//             agendas
//                 .filter(agenda =>
//                     Object.keys(agenda).some(key =>
//                         key !== 'gid' && key !== 'schedule_date' && agenda[key] !== defaultValues[key]
//                     )
//                 )
//                 .map(agenda => {
//                     const speakerNames = agenda.speaker.split(',').map(name => name.trim()); // Split and trim speaker names
//                     const formattedSpeakers = speakerNames.map(name => {
//                         const matchedSpeaker = findSpeakerByName(name);
//                         if (matchedSpeaker) {
//                             if(matchedSpeaker.user_gid){
//                                 return { user_gid: matchedSpeaker.user_gid };
//                             }else{
//                                 return { name }
//                             }
//                         } else {
//                             return { name }; // If no match, return the name directly
//                         }
//                     });

//                     return {
//                         ...agenda,
//                         schedule_date,
//                         speakers: formattedSpeakers, // Add the formatted speakers array to the agenda
//                     };
//                 })
//             // .map(agenda => ({
//             //     ...agenda,
//             //     schedule_date: schedule_date  // Convert to ISO string
//             // }))
//         );
//     };
//     // Transform the data
//     const transformedData = transformAgendaData(agendaData);
//     console.log(transformedData, "data")

//     // Create the payload
//     const dataToPost = {
//         conference_agendas: transformedData.map(agenda => ({
//             schedule_date: agenda.schedule_date,
//             topic: agenda.topic,
//             location: agenda.location,
//             speaker: agenda.speaker,
//             speakers: agenda.speakers,
//             start_time: agenda.start_time,
//             end_time: agenda.end_time,
//         }))
//     };

//     dispatch(fetchAPI(`${CONFERENCES}/${gid}/conference_agendas`, httpMethod.POST, dataToPost)).then(
//         response => {
//             if (response.success) {
//                 dispatch(getAgendaList());
//                 dispatch(setSnackbar({ flag: true, message: response?.message, type: "success" }));
//             } else if (response.error) {
//                 dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
//             }
//         },
//     );
// }
export const submitAgenda = (values, date, options, addMore, setSelectedOptions, selectedModerators, setSelectedModerators, setFormValues, onClose, startTimeFormat, endTimeFormat) => (dispatch, getState) => {
    const { bEngage: { gid, updateAgenda } } = getState();
    let APIUrl = `${CONFERENCES}/${gid}/conference_agendas`;
    let method = httpMethod.POST;
    const dateValue = new Date(date);
    const formattedDate = dateValue.toUTCString('en-US');
    let userGids;
    let moderatorGid;
    if (Object.keys(options).length !== 0) {
        userGids = options.map(profile => profile.user_gid ? profile.user_gid : profile.gid);
    } else {
        userGids = [];
    }
    if (Object.keys(selectedModerators).length !== 0) {
        moderatorGid = selectedModerators.map(profile => profile.user_gid ? profile.user_gid : profile.gid);
    } else {
        moderatorGid = [];
    }

    const startTime = convertTo24HourFormat(`${values.start_time} ${startTimeFormat}`);
    const endTime = convertTo24HourFormat(`${values.end_time} ${endTimeFormat}`);
    if (startTime > endTime) {
        dispatch(setSnackbar({ flag: true, message: "End time must be after the start time", type: "error" }));
        return;
    }
    // Create the payload
    const dataToPost = {
        schedule_date: formattedDate,
        topic: values.topic,
        location: values.location,
        user_gid: userGids,
        moderator_gid: moderatorGid,
        start_time: startTime,
        end_time: endTime,
    };
    if (values?.gid && updateAgenda) {
        APIUrl = `${CONFERENCES}/${gid}/conference_agendas/${values?.gid}`;
        method = httpMethod.PUT;
    } else {
        APIUrl = `${CONFERENCES}/${gid}/conference_agendas`;
        method = httpMethod.POST;
    }
    // console.log(dataToPost, "data")
    dispatch(fetchAPI(APIUrl, method, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(getAgendaList());
                if (addMore) {
                    dispatch(reset('agendaDataForm'));
                    dispatch(initialize("agendaDataForm", {}));
                    setSelectedOptions([]);
                    setSelectedModerators([]);
                    setFormValues({});
                    dispatch(setUpdateAgenda(false));
                } else {
                    onClose();
                }
                if (response?.data?.message) {
                    dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
                } else {
                    dispatch(setSnackbar({ flag: true, message: response?.message, type: "success" }));
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const deleteAgenda = (id) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/conference_agendas/${id}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                dispatch(getAgendaList());
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getLiked = (gid) => (dispatch, getState) => {
    const { bEngage: { bEngageData } } = getState();
    let dataToPost = {
        conference_gid: gid,
    };
    return dispatch(fetchAPI(`${PUBLIC_CONFERENCE}/${gid}/like`, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                // console.log(response, "response")
                dispatch(addBEngageData({ ...bEngageData, self_like: true, like_count: bEngageData?.like_count + 1 }));
                // dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        }
    );
};

export const deleteLiked = (gid) => (dispatch, getState) => {
    const { bEngage: { bEngageData } } = getState();
    let dataToPost = {
        conference_gid: gid,
    };
    return dispatch(fetchAPI(`${PUBLIC_CONFERENCE}/${gid}/unlike`, httpMethod.DELETE, dataToPost)).then(
        response => {
            if (response.success) {
                // console.log(response, "response")
                dispatch(addBEngageData({ ...bEngageData, self_like: false, like_count: bEngageData?.like_count - 1 }));
                // dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        }
    );
};

export const setBookmark = (gid) => (dispatch, getState) => {
    const { bEngage: { bEngageData } } = getState();
    let dataToPost = {
        bookmarkable_id: gid,
        bookmarkable_type: "Conference",
    };
    return dispatch(fetchAPI(`${BOOKMARK_SHOW}`, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                // console.log(response, "response")    
                dispatch(addBEngageData({ ...bEngageData, is_bookmark: true }));
                // dispatch(setSnackbar({ flag: true, message: response?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        }
    );
};

export const deleteBookmark = (gid) => (dispatch, getState) => {
    const { bengage: { bEngageData } } = getState();
    return dispatch(fetchAPI(`${BOOKMARK_SHOW}/${gid}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                // console.log(response, "response")
                dispatch(addBEngageData({ ...bEngageData, is_bookmark: false }));
                // dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
            }
        }
    );
};

export const downloadDelegates = () => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/download_delegates`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                // dispatch(setDelegatesList(response));
                return response;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const downloadSpeakers = () => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/download_delegates`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                // dispatch(setDelegatesList(response));
                return response;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const downloadSponsor = () => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/download_sponsors`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                // dispatch(setDelegatesList(response));
                return response;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const downloadNominee = () => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/download_award_nominees`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                // dispatch(setDelegatesList(response));
                return response;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getDelegatesList = (gid) => (dispatch) => {
    // const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/delegates_list`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setDelegatesList(response));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getAllNominationList = (gid, type, status, offset, PageLimit = 20, name, navigate) => (dispatch) => {

    const page = Math.floor(offset / PageLimit) + 1;
    const dataToSend = {
        type,
        page: page ? page : 1,
        status: status,
        name: name || '',
    }
    // const { bEngage: { gid } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/nomination_forms`, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                if ((type === "awards" && status === "invited") || (type === "sponsors" && status === "invited")) {
                    dispatch(setNominationLists([]));
                } else {
                    dispatch(setNominationLists(response));
                };
            } else if (response.error) {
                if (response?.error?.errorCode === 422) {
                    navigate('/page-not-found')
                }
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const setAcceptDecline = (gid, data, selectedTab, statusFilter) => (dispatch, getState) => {
    const extractedKey = Object.keys(data)[0];
    // console.log(data[extractedKey], 'data')
    if (data[extractedKey] !== 'New') {
        let dataToSend = {
            status: data[extractedKey] === 'declined' ? 'declined' : 'accepted',
            nomination_form_gid: extractedKey,
            type: selectedTab,
        }
        // console.log(dataToSend, "data")
        return dispatch(fetchAPI(`${CONFERENCES}/${gid}/update_delegate`, httpMethod.PUT, dataToSend)).then(
            response => {
                if (response.success) {
                    dispatch(getAllNominationList(gid, selectedTab, statusFilter));
                    dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
                } else if (response.error) {
                    dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
                }
            }
        );
    }
}

export const getAllConferences = (offset = 10) => (dispatch) => {
    dispatch(setIsLoad(true));
    let convertedPage = Math.ceil(offset / 10);
    let newData = {
        page: convertedPage,
    };
    dispatch(fetchAPI(CONFERENCES, httpMethod.GET, newData)).then(
        response => {
            if (response.success) {
                dispatch(setBEngageListData(response?.data));
                dispatch(setTotalCount(response?.total_count));
                dispatch(setIsLoad(false));
            } else if (response.error) {
                dispatch(setIsLoad(false));
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getFilterList = (offset = 10, loading, setLoading, values, isFecthing) => (dispatch, getState) => {
    const { bEngage: { bEngageList } } = getState();
    loading ? setLoading(true) : dispatch(setIsLoad(true));
    // let APIUrl;
    // Logic to convert multiples of 10 to 1, 2, 3, ...
    let convertedPage = Math.ceil(offset / 10);
    let newData = {
        page: convertedPage,
    };
    if (values) {
        if (values.date_schedule) {
            let start_date = `${values?.date_schedule[0]} 00:00:00 +0530`;
            let end_date = `${values?.date_schedule[values?.date_schedule.length - 1]} 23:59:59 +0530`;
            newData = {
                page: convertedPage,
                status: values?.status ? values?.status : '',
                // event_type: values?.event_type ? values?.event_type : '',
                location: values?.location ? values?.location : '',
                // privacy: values?.privacy ? values?.privacy : '',
                start_date,
                end_date
            }
        } else {
            newData = {
                page: convertedPage,
                ...values,
            };
        }
    }
    // let queryString;
    // let queryArr = [];
    // if (array) {
    //     newData = {
    //         ...newData,
    //         'category[]': array,
    //     }
    //     Object.keys(newData).forEach(key => {
    //         if (newData[key]) {
    //             if (Array.isArray(newData[key])) {
    //                 newData[key].forEach(item => {
    //                     queryArr.push(`${key}=${item}`);
    //                 });
    //             } else {
    //                 queryArr.push(`${key}=${newData[key]}`);
    //             }
    //         }
    //     });
    //     queryString = queryArr.join("&");
    // }
    // APIUrl = array ? `${EVENT_LIST}?${queryString}` : EVENT_LIST;
    return dispatch(fetchAPI(CONFERENCES, httpMethod.GET, newData)).then(
        response => {
            if (response.success) {
                const newBEngageList = response.data || [];
                if (loading) {
                    dispatch(setBEngageListData([...bEngageList, ...newBEngageList]));
                } else {
                    dispatch(setBEngageListData(newBEngageList));
                }
                // !loading ? dispatch(setBEngageListData(response?.data)) : response?.data && dispatch(setBEngageListData([...bEngageList, ...response?.data]));
                dispatch(setTotalCount(response?.total_count));
                loading ? setLoading(false) : dispatch(setIsLoad(false));
                if (loading) {
                    isFecthing.current = false;
                }
            } else if (response?.error) {
                loading ? setLoading(false) : dispatch(setIsLoad(false));
                if (loading) {
                    isFecthing.current = false;
                }
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        }
    );
};

export const deleteConferences = (gid, navigate) => (dispatch, getState) => {
    const { bEngage: { bEngageList } } = getState();
    dispatch(fetchAPI(`${CONFERENCES}/${gid}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                let newArr = bEngageList.filter((data) => data.gid !== gid);
                dispatch(setBEngageListData(newArr));
                if (navigate) {
                    navigate("/bEngage/listing");
                    dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const getConferencesDashboard = (offset = 10) => (dispatch) => {
    dispatch(fetchAPI(CONFERENCES_DASHBOARD, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setBEngageDashboard(response?.data?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const CreateNomination = (id, navigate, handleVerifyOpen) => (dispatch, getState) => {
    const { bEngage: { selectedSection, regGid, bEngageData }, form, users } = getState();
    let values = form?.bEngageConferenceForm.values;
    let dataToPost = {
        nomination_type_id: Number.isInteger(selectedSection) && selectedSection,
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        company_name: values?.company_name,
        designation: values?.designation,
        state_id: values?.state_id,
        city: values?.city,
        remark: values?.remark,
    }
    let email = encodeURIComponent(values?.email);
    let phone = values?.phone;
    let name = values?.name;
    let org_name = values?.company_name;
    let designation = values?.designation;
    if (id) {
        if (Number.isInteger(selectedSection) && selectedSection == 5) {
            dataToPost = {
                ...dataToPost,
                conference_award_gid: id
            }
        } else if (Number.isInteger(selectedSection) && selectedSection == 2) {
            dataToPost = {
                ...dataToPost,
                partner_invitation_gid: id
            }
        }
    }
    if (!users?.isLoggedIn) {
        if (values?.profile_type === 'personal') {
            dataToPost = {
                ...dataToPost,
                profile_type: values?.profile_type,
            }
        } else {
            dataToPost = {
                ...dataToPost,
                profile_type: values?.profile_type,
                company_type: values?.company_type
            }
        }
    }
    let apiUrl = `${NOMINATION}/${regGid}/${'nominate'}`
    console.log(process.env.REACT_APP_PAYMENT);
    dispatch(fetchAPI(apiUrl, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                if (users?.isLoggedIn) {
                    dispatch(reset('bEngageConferenceForm'));
                    dispatch(setOtpDelivered(false));
                    if (selectedSection !== 1) {
                        navigate(`/bEngage/registrationComplete/${regGid}`);
                        dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
                        return;
                    }
                    // Save IDs to local storage
                    if (bEngageData?.autopay_enabled) {
                        // localStorage.setItem('conference_gid', regGid);
                        // if (response?.data?.data?.gid) {
                        //     localStorage.setItem('conference_id', response?.data?.data?.gid);
                        // }    
                        const url = `${process.env.REACT_APP_PAYMENT}?name=${name}&email=${email}&phone=${phone}&conference_gid=${regGid}`;
                        // navigate(url);
                        window.open(url, '_self', 'noopener,noreferrer');
                    } else {
                        navigate(`/bEngage/registrationComplete/${regGid}`);
                        dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
                    }
                } else {
                    dispatch(setGetNewRegId(response.data.data.gid))
                    handleVerifyOpen();
                    // dispatch(setOtpDelivered(true));
                }
                // dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const GetResendOtp = (gid) => (dispatch, getState) => {
    const { bEngage: { getNewRegId }, form } = getState();

    let dataToPost = {
        gid: getNewRegId,
    }
    let apiUrl = `${NOMINATION}/${gid}/${'resend_otp'}`
    dispatch(fetchAPI(apiUrl, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                // dispatch(setBEngageDashboard(response?.data?.data));
                dispatch(setSnackbar({ flag: true, message: response.data.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const GetVerifyNominee = (gid, navigate, handleVerifyClose, setFieldError) => (dispatch, getState) => {
    const { bEngage: { selectedSection, getNewRegId, bEngageData }, form } = getState();
    let values = form?.bEngageConferenceForm.values;
    let dataToPost = {
        // nomination_type_id: selectedActiveTab,
        nomination_type_id: Number.isInteger(selectedSection) && selectedSection,
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        company_name: values?.company_name,
        designation: values?.designation,
        state: values?.state_id,
        city: values?.city,
        remark: values?.remark,
        gid: getNewRegId,
        otp: values?.EnterOtp,
    }
    let email = encodeURIComponent(values?.email);
    let phone = values?.phone;
    let name = values?.name;
    let org_name = values?.company_name;
    let designation = values?.designation;
    if (values?.profile_type === 'personal') {
        dataToPost = {
            ...dataToPost,
            profile_type: values?.profile_type,
        }
    } else {
        dataToPost = {
            ...dataToPost,
            profile_type: values?.profile_type,
            company_type: values?.company_type
        }
    }
    let apiUrl = `${NOMINATION}/${gid}/${'verify'}`
    dispatch(fetchAPI(apiUrl, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                // dispatch(setBEngageDashboard(response?.data?.data));
                dispatch(reset('bEngageConferenceForm'));
                handleVerifyClose();
                // dispatch(setOtpDelivered(false));
                if (selectedSection !== 1) {
                    navigate('/bEngage/registrationComplete');
                    dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
                    return;
                }
                if (bEngageData?.autopay_enabled) {
                    // // Save IDs to local storage
                    // localStorage.setItem('conference_gid', gid);
                    // if (response?.data?.data?.gid) {
                    //     localStorage.setItem('conference_id', response?.data?.data?.gid);
                    // }
                    const url = `${process.env.REACT_APP_PAYMENT}?name=${name}&email=${email}&phone=${phone}&conference_gid=${gid}`;
                    // navigate(url);
                    window.open(url, '_self', 'noopener,noreferrer');
                }
                else {
                    navigate('/bEngage/registrationComplete');
                    dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
                }
                // navigate('/bEngage/registrationComplete');
                // dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                if (response.error.errorCode === 422 && response.error.errorMsg === 'Invalid OTP!!!') {
                    dispatch(setFieldError('Invalid OTP'));
                } else {
                    dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
                }
            }
        },
    );
}

export const paymentUpdate = (gid, id, status) => (dispatch) => {
    const dataToPost = {
        payment_status: status,
    }
    if (id) {
        dataToPost.nomination_gid = id;
    }
    dispatch(fetchAPI(`${NOMINATION}/${gid}/payment_update`, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                localStorage.removeItem('conference_gid');
                localStorage.removeItem('conference_id');
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const submitImportCSV = (file, setIsUploading, setSuccessCount, setFailedCount, selectedTab, statusFilter) => (dispatch, getState) => {
    setIsUploading('uploading');
    const { bEngage: { gid }, master: { nominationTypes } } = getState();
    const formData = new FormData();
    formData.append('file', file);
    let APIUrl;
    if (selectedTab === bENGAGE_SECTIONS.PARTNER) {
        APIUrl = `${CONFERENCES}/${gid}/sponsor_invitations/import_sponsor_csv`;
    } else if (selectedTab === bENGAGE_SECTIONS.SPEAKER) {
        APIUrl = `${CONFERENCES}/${gid}/speaker_invitations/import_speaker_csv`;
    } else if (selectedTab === bENGAGE_SECTIONS.DELEGATES) {
        APIUrl = `${CONFERENCES}/${gid}/nomination_forms/import_csv`;
        formData.append('conference_gid', gid);
        let id = nominationTypes.find(data => data?.key === selectedTab)?.id;
        formData.append('nomination_type_id', id);
    } else if (selectedTab === bENGAGE_SECTIONS.NOMINIE) {
        APIUrl = `${CONFERENCES}/${gid}/nomination_forms/import_csv`;
        formData.append('conference_gid', gid);
        let id = nominationTypes.find(data => data?.key === selectedTab)?.id;
        formData.append('nomination_type_id', id);
    }
    dispatch(fetchAPI(APIUrl, httpMethod.POST, formData, '', true)).then(
        response => {
            if (response.success) {
                setTimeout(() => {
                    setIsUploading('finished');
                    if (response?.data?.data?.failed > 0) {
                        // Create a blob from the CSV data
                        base64ToFile3(response?.data?.data?.csv_data, response?.data?.data?.csv_filename);
                    }
                }, 3000);
                setSuccessCount(response?.data?.data?.success || 0);
                setFailedCount(response?.data?.data?.failed || 0);
                dispatch(getAllNominationList(gid, selectedTab, statusFilter));
                // dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
            } else if (response.error) {
                setIsUploading('file');
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getRegistrationDetails = () => (dispatch) => {
    dispatch(fetchAPI(`${NOMINATION}/registration_details`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setRegistrationDetails(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getConferenceParticipantDetails = (gid, offset = 10, loading, setLoading, values, isFecthing) => (dispatch, getState) => {
    const { bEngage: { conferenceParticiapnts } } = getState();
    let convertedPage = Math.ceil(offset / 10);
    loading && setLoading(true)
    const dataToPost = {
        page: convertedPage,
        ...values
    }
    dispatch(fetchAPI(`${CONFERENCE_PARTICIPANTS}/${gid}/conference_participants`, httpMethod.GET, dataToPost)).then(
        response => {
            if (response.success) {
                loading ? dispatch(setConferenceParticiapnts([...conferenceParticiapnts, ...response?.data])) : dispatch(setConferenceParticiapnts(response?.data));
                // dispatch(setConferenceParticiapnts(response?.data))
                dispatch(setConferenceParticiapntsCount(response?.total_count))
                dispatch(setOrganiserLogos(response?.conference_organisers))
                if (loading) {
                    isFecthing.current = false;
                }
            } else if (response.error) {
                if (loading) {
                    isFecthing.current = false;
                }
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const getConferenceDetails = (gid) => (dispatch, getState) => {
    // const { bEngage: { conferenceParticiapnts } } = getState();
    dispatch(fetchAPI(`${CONFERENCE_DETAILS}/${gid}/participant_deatil`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setConferencesDetail(response?.data))
                dispatch(setOrganiserLogos(response?.conference_organisers))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const ETicketDownload = (gid, gidArray, setIsLoading, setSelect, setSelectedParticipants) => async (dispatch, getState) => {
    const { users: { tokens } } = getState();
    setIsLoading(true);
    // Initialize FormData object
    const formData = new FormData();
    formData.append('conference_gid', gid);
    // Loop through each object in the array and append to formData
    gidArray.forEach(item => {
        formData.append('user_gid[]', item.gid);
    });
    let APIUrl = `${CONFERENCE_DETAILS}/e_ticket`;
    try {
        let accessToken = tokens?.accessToken;
        let refreshToken = tokens?.refreshToken;

        // Fetching binary PDF data
        const response = await fetch(APIUrl, {
            method: 'POST',
            headers: {
                'access-token': accessToken,
                'refresh-token': refreshToken,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        // Check if the response is JSON (indicating an error message)
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            const errorData = await response.json();
            if (errorData?.error?.errorCode === "500" || errorData?.error?.errorCode === "500" || errorData?.error?.errorCode === "401") {
                setIsLoading(false);
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg || COMMON_MSG.GENERIC_ERROR_MSG, type: "error" }));
                return;
            }
            if (errorData?.statusCode === 200) {
                setIsLoading(false);
                setSelect(false);
                setSelectedParticipants([]);
                dispatch(setSnackbar({ flag: true, message: errorData.data.message, type: "success" }));
                return;
            }
        }
        // Handle the binary response as a Blob (PDF)
        const blob = await response.blob();
        // Create a link element
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `e-ticket.pdf`;

        // Append the link to the document body
        document.body.appendChild(a);

        // Trigger the download by simulating a click
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setIsLoading(false);
        setSelect(false);
        setSelectedParticipants([]);
    } catch (error) {
        setIsLoading(false);
        dispatch(setSnackbar({ flag: true, message: 'Error fetching the PDF data', type: "error" }));
    }
}

export const searchUser = (query) => (dispatch, getState) => {
    const { bEngage: { gid } } = getState();
    let dataToSend = {
        name: query,             //Optional || String || Search User by Name
    }
    dispatch(fetchAPI(`${CONFERENCES}/${gid}/search_speaker`, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setUserSpeakerList(response.data?.users_list));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const deleteBengageUploadedFile = (uploadInfo) => (dispatch, getState) => {
    const { bEngage } = getState();
    let bEngageData = {
        // ...bEngage.
        banner: "",
    }
    dispatch(addBEngageData(bEngageData));
    dispatch(removeUploadedData(uploadInfo.FIELD_NAME));
}
export const removeUploadedData = (key) => (dispatch, getState) => {
    //debugger;
    const { bEngage: { uploadedData } } = getState();
    const newArr = uploadedData.filter((data) => (
        data.key !== key
    ));
    dispatch(updateBengageUploadedData(newArr));
}
export const deleteNewPoc = (deleteIndex) => (dispatch, getState) => {
    let { bEngage: { newPoc } } = getState();
    const newArr = newPoc.filter((data, index) => (
        index != deleteIndex
    ));
    dispatch(updatePOC(newArr));
}
export const handleNewPoc = (values, editFlag, index, handleToggleFields) => (dispatch, getState) => {
    dispatch(reset('newPocForm'));
    dispatch(setNewPocFlag(true));
    let { bEngage: { newPoc } } = getState();
    const isDuplicate = newPoc.some((poc, formindex) => poc.phone === values.phone && (!editFlag || formindex !== index));
    if (isDuplicate) {
        dispatch(setSnackbar({ flag: true, message: "POC with this contact already exists.", type: "error" }));
        return;
    }
    if (editFlag) {
        let newArr = [...newPoc];
        newArr[index] = values;
        dispatch(updatePOC(newArr));
    } else {
        dispatch(addPOC(values));
    }
    dispatch(reset('newPocForm'));
    dispatch(setNewPocFlag(false));
    handleToggleFields();
}
// const isDataURL = (str) => {
//     return str.startsWith('data:image/') && str.includes(';base64,');
// }

export const getUploadedDocURL = (uploadInfo) => (dispatch, getState) => {
    const { bEngage: { uploadedData } } = getState();
    const searchObject = uploadedData && uploadedData.find((data) => data?.key === uploadInfo?.FIELD_NAME);
    if (searchObject) {
        let firlURL = `${FILE_HOST}${searchObject.filename}`;
        if (searchObject.filename) {
            return firlURL;
        }
    }
}
export const uploadBengage = (file, uploadInfo) => (dispatch, getState) => {
    const { bengage } = getState();
    let dataToPost = {
        filename: uploadInfo.FIELD_NAME,
        key: uploadInfo.KEY,
        file,
    }
    // if (!isEmpty(events.eventData) && events.eventData.uniqueId) { // for update
    //     dataToPost = {
    //         ...dataToPost,
    //         uniqueId: events.eventData.uniqueId
    //     }
    // }
    const uploadedData = {
        // ...dataToPost,
        filename: file.name,
        key: uploadInfo.FIELD_NAME,
    }
    dispatch(addBengageUploadedData(uploadedData, uploadInfo.FIELD_NAME));
    return uploadedData;
}
export const getEventDistrict = (state) => (dispatch, getState) => {
    // const { master: { district } } = getState();
    // const newArr = district.filter((data) => data.id === parseInt(state));
    // dispatch(addEventDistrict(newArr));
    dispatch(getAllDistrict(state, "event"));
}
export const submitInvitations = (values, setInvitationsAdd) => (dispatch, getState) => {
    const { bEngage: { invitations } } = getState();

    const invitation = { name: values.name, email: values.email, company_name: values.company_name }
    setInvitationsAdd(prev => [...prev, invitation]);
    dispatch(reset('invitationForm'));
}
// export const submitAllInvitations = (values, handleClose, gid, selectedTab, statusFilter) => (dispatch, getState) => {
//     const { bEngage: { volunteers, allVolunteers, addPopupErrorData }, users: { userData }, master: { nominationTypes } } = getState();
//     // let dataToSend = {
//     //     speaker_invitation: values,
//     // };
//     let dataKey, apiUrl, dataToSend = {};

//     if (selectedTab === 'speakers') {
//         dataKey = 'speaker_invitation';
//         apiUrl = `${INVITATION}/${gid}/speaker_invitations`;
//     } else if (selectedTab === 'awards') {
//         dataKey = 'nomination_forms';
//         apiUrl = `${INVITATION}/${gid}/nomination_forms`;
//         const nominationType = nominationTypes.find(type => type.key === selectedTab);
//         if (nominationType) {
//             const nominationId = nominationType.id;
//             dataToSend = {
//                 [dataKey]: values,
//                 nomination_type_id: nominationId // Add nomination_id 
//             };
//         }
//     } else if (selectedTab === 'sponsors') {
//         dataKey = 'sponsor_invitation';
//         apiUrl = `${INVITATION}/${gid}/sponsor_invitations`;
//     } else if (selectedTab === 'delegates') {
//         dataKey = 'delegates';
//         apiUrl = `${INVITATION}/${gid}/nomination_forms`;

//         const nominationType = nominationTypes.find(type => type.key === selectedTab);
//         if (nominationType) {
//             const nominationId = nominationType.id;

//             dataToSend = {
//                 [dataKey]: values,
//                 nomination_type_id: nominationId // Add nomination_id for delegates only
//             };
//         }
//     }

//     if (selectedTab !== 'delegates') {
//         dataToSend = {
//             [dataKey]: values
//         };
//     }

//     // console.log({ dataToSend })
//     console.log({ selectedTab })
//     //let apiUrl = `${INVITATION}/${gid}/speaker_invitations`
//     dispatch(fetchAPI(apiUrl, httpMethod.POST, dataToSend)).then(
//         response => {
//             if (response.success) {
//                 // dispatch(updateAllVolunteers(response.data))
//                 dispatch(getAllNominationList(gid, selectedTab, statusFilter))
//                 dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
//                 handleClose();
//                 dispatch(reset('invitationForm'));
//             } else { //if (response.error) {
//                 // dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
//                 // dispatch(setAddPopupErrorData(response?.data));
//                 if (response?.error?.errorCode === 422) {
//                     const responseData = response?.error?.data;
//                     dispatch(setAddInvitationErrorData(responseData));
//                 } else {
//                     dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
//                 }
//             }
//         },
//     );
//     // dispatch(reset('invitationForm'));
// }
export const submitAllInvitations = (values, handleClose, gid, selectedTab, statusFilter) => (dispatch, getState) => {
    const { bEngage: { volunteers, allVolunteers, addPopupErrorData }, users: { userData }, master: { nominationTypes } } = getState();

    let dataKey, apiUrl, dataToSend = {};

    // Determine dataKey and apiUrl based on selectedTab
    switch (selectedTab) {
        case 'speakers':
            dataKey = 'speaker_invitation';
            apiUrl = `${INVITATION}/${gid}/speaker_invitations`;
            dataToSend = { [dataKey]: values }; // Set values for speakers
            break;

        case 'awards':
            dataKey = 'nomination_forms';
            apiUrl = `${INVITATION}/${gid}/nomination_forms`;
            const nominationTypeForAwards = nominationTypes.find(type => type.key === selectedTab);
            if (nominationTypeForAwards) {
                const nominationId = nominationTypeForAwards.id;
                dataToSend = {
                    [dataKey]: values,
                    nomination_type_id: nominationId // Include nomination_id for awards
                };
            }
            break;

        case 'sponsors':
            dataKey = 'sponsor_invitation';
            apiUrl = `${INVITATION}/${gid}/sponsor_invitations`;
            dataToSend = { [dataKey]: values }; // Set values for sponsors
            break;

        case 'delegates':
            dataKey = 'nomination_forms';
            apiUrl = `${INVITATION}/${gid}/nomination_forms`;
            const nominationTypeForDelegates = nominationTypes.find(type => type.key === selectedTab);
            if (nominationTypeForDelegates) {
                const nominationId = nominationTypeForDelegates.id;
                dataToSend = {
                    [dataKey]: values,
                    nomination_type_id: nominationId // Include nomination_id for delegates
                };
            }
            break;

        default:
            // Handle unexpected selectedTab case if necessary
            console.warn(`Unknown selectedTab: ${selectedTab}`);
            return;
    }

    console.log({ dataToSend }); // Log the payload being sent
    console.log({ selectedTab });

    // Dispatch API request
    dispatch(fetchAPI(apiUrl, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                // Update the state with new nominations
                dispatch(getAllNominationList(gid, selectedTab, statusFilter));
                dispatch(setSnackbar({ flag: true, message: response.data?.message, type: "success" }));
                handleClose();
                dispatch(reset('invitationForm'));
            } else {
                // Handle error responses
                if (response?.error?.errorCode === 422) {
                    const responseData = response?.error?.data;
                    dispatch(setAddInvitationErrorData(responseData));
                } else {
                    dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
                }
            }
        }
    );
};

export const getAllDirectory = (offset = 10, array) => (dispatch) => {
    // dispatch(setIsLoad(true));
    let convertedPage = Math.ceil(offset / 10);
    let newData = {
        page: convertedPage,
    };
    if (array && array.length !== 0) {
        newData = {
            page: convertedPage,
            type: array
        }
    }
    dispatch(fetchAPI(DIRECTORY, httpMethod.GET, newData)).then(
        response => {
            if (response.success) {
                dispatch(setDirectoryList(response?.data));
                dispatch(setTotalDirectoryCount(response?.total_count));
                // dispatch(setIsLoad(false));
            } else if (response.error) {
                // dispatch(setIsLoad(false));
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const searchDirectory = (query) => (dispatch) => {
    let newData = {
        name: query,
    };
    dispatch(fetchAPI(`${DIRECTORY}/search_directries`, httpMethod.GET, newData)).then(
        response => {
            if (response.success) {
                dispatch(setDirectoryList(response?.data));
                dispatch(setTotalDirectoryCount(response?.total_count));
                // dispatch(setIsLoad(false));
            } else if (response.error) {
                // dispatch(setIsLoad(false));
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const deleteDirectory = (gidArray) => (dispatch) => {
    const formData = new FormData();
    gidArray.forEach(item => {
        formData.append('gid[]', item.gid);
    });
    dispatch(fetchAPI(`${DIRECTORY}/delete_directries`, httpMethod.DELETE, formData, '', true)).then(
        response => {
            if (response.success) {
                dispatch(setDirectoryList(response?.data));
                dispatch(setTotalDirectoryCount(response?.total_count));
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                // dispatch(setIsLoad(false));
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getAllHistory = (gid) => (dispatch) => {
    let newData = {
        gid,
    };
    dispatch(fetchAPI(`${DIRECTORY}/view_history`, httpMethod.GET, newData)).then(
        response => {
            if (response.success) {
                dispatch(setHistoryList(response?.data));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const createDirectory = (values, onClose, addMore) => (dispatch) => {
    const formData = new FormData();
    for (const key in values) {
        formData.append(key, values[key]);
    }
    dispatch(fetchAPI(DIRECTORY, httpMethod.POST, formData, '', true)).then(
        response => {
            if (response.success) {
                dispatch(getAllDirectory());
                if (addMore) {
                    dispatch(reset('directoryForm'));
                    dispatch(initialize("directoryForm", {}));
                } else {
                    onClose();
                }
            } else if (response.error) {
                let message = response.error.errorMsg === 'Validation failed: Email already used!' ? 'Record already exists, please delete and add again.' : response.error.errorMsg
                dispatch(setSnackbar({ flag: true, message, type: "error" }));
            }
        },
    );
}