import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, useMediaQuery, Divider, Box, Drawer } from '@mui/material';
import CommonButton2 from '../../../../../common/components/CommonButton2';
import Cancel_sechedule from "../../../../../assets/icons/Cancel_sechedule.svg";
import RFPCSS from "../../../../RFP/RFPcss/RFP.module.css"
import BusinessSuite from '../../../../../common/components/BusinessSuite';
import { useSelector } from 'react-redux';
import { getBusinessSuite, getModuleType } from '../../../../../common/actions';
import { useDispatch } from 'react-redux';

const ApplyRFP = ({ opens, onClosed, onConfirm, title, message, ButtonText1, ButtonText2, handleDrawerOpen }) => {
    const isXsScreen = useMediaQuery('(min-width: 960px)');

    const { users } = useSelector((state) => state);
    const dispatch = useDispatch();

    const handleApply = () => {
        onClosed()
        handleDrawerOpen()
    }

    return (
        <Dialog open={opens} onClose={onClosed} PaperProps={{ sx: { minWidth: isXsScreen ? '600px' : '300px', borderRadius: '24px' } }}>
            <DialogTitle>
                <Box className={`${RFPCSS.f} ${RFPCSS.jc_space_between} ${RFPCSS.ai_center}`}>
                    {title}
                    <img src={Cancel_sechedule} className={RFPCSS.cursor} onClick={onClosed} />
                </Box>

            </DialogTitle>
            <Divider />
            <DialogContent>
                <Typography>{message}</Typography>
            </DialogContent>
            <Box mb={1}>
                <Divider /></Box>
            <DialogActions >
                <CommonButton2
                    label={ButtonText1}
                    name={ButtonText1}
                    type="button"
                    color="primary"
                    variant='outlined'
                    style={{
                        padding: '6px 32px', fontSize: '14px',
                    }}
                    onClick={() => onClosed()}
                />
                <CommonButton2
                    label={ButtonText2}
                    name={ButtonText2}
                    type="button"
                    color="primary"
                    variant='contained'
                    style={{
                        padding: '6px 32px', fontSize: '14px', backgroundColor: "#0775BA"
                    }}
                    onClick={() => handleApply()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ApplyRFP;