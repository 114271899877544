import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, Box, Typography, Fade } from '@mui/material';
import theme from '../../assets/material-ui/theme';
import CommonButton2 from './CommonButton2';
import Cancel_sechedule from "../../assets/icons/CancelledRed.svg";
import tick from '../../assets/icons/tick_green.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} style={{ transitionDelay: ref ? '300ms' : '0ms' }} />;
});

const CommonBusineesDialog = ({ open, handleClose, handleConfirm, message, title, titleStyle, closeIcon, btnText, btnStyle, tickIcon, CommonCss }) => {
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted PaperProps={{ sx: { minWidth: isXsScreen ? '600px' : '300px', borderRadius: '24px' } }}>
            <DialogTitle sx={{ color: theme.palette.success.dark, textAlign: 'center', padding: '16px', fontFamily: 'poppins', borderBottom: '1px solid #CCC' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ ...titleStyle }}>{title}</div>
                    {closeIcon &&
                        <img src={Cancel_sechedule} onClick={handleClose} style={{ cursor: 'pointer', boxShadow: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.07)) drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.12))' }} />}
                </div>
            </DialogTitle>
            {message && <DialogContent sx={{ textAlign: 'left', padding: '32px 16px !important', fontSize: '16px', color: theme.palette.success.light }}>
                {message}
            </DialogContent>}
            {tickIcon &&
                <DialogContent className={CommonCss.trailTickRoot}>
                    <Box className={CommonCss.trailTickBox}>
                        <img src={tick} alt='tick' className={CommonCss.tickAnimation} />
                        <Typography className={CommonCss.trailTickTypo}>Trial is active</Typography>
                    </Box>
                </DialogContent>
            }
            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 16px 10px 16px', borderTop: '1px solid #CCC' }}>
                <CommonButton2
                    label={btnText}
                    name={btnText}
                    type={btnText}
                    color="primary"
                    variant='contained'
                    style={{ ...btnStyle }}
                    onClick={() => handleConfirm()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default CommonBusineesDialog;