import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Grid, IconButton, Skeleton, Tooltip, Typography, Zoom } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from "react-router-dom";
import Address from '../../../../../../assets/icons/Address.svg';
import Area from '../../../../../../assets/icons/Area.svg';
import Copy from '../../../../../../assets/icons/Copy.svg';
import Date from '../../../../../../assets/icons/Date.svg';
import EventBookMark from '../../../../../../assets/icons/EventBookMark.svg';
import EventBookMarked from '../../../../../../assets/icons/eventBookmarked.svg';
import Join from '../../../../../../assets/icons/Join.svg';
import Thematic from '../../../../../../assets/icons/Thematic.svg';
import chain from '../../../../../../assets/icons/chain.svg';
import theme from '../../../../../../assets/material-ui/theme';
import CommonButton2 from '../../../../../../common/components/CommonButton2';
import { calculateTimeDifference, formatEventDateTime } from '../../../../../../common/utils';
import { getDistrictNameById, getStateNameById } from '../../../../../Profile/actions';
import { DownloadCertificate, deleteBookmark, deleteLiked, getBeneficiaryNameByIds, getDownloadCertificate, getEventAreaNameByIds, getJoinEvent, getLiked, getOptOut, setBookmark } from '../../actions';
import EventsCSS from '../EventDetailsCSS/Events.module.css';
import SocialMediaCSS from '../../../../SocialMediaCSS/SocialMedia.module.css';
import FeedbackPopup from '../../../Popup/FeedbackPopup';
import RecoverIcon from '../../../../../../assets/icons/recover_white.svg';
import TickIcon from '../../../../../../assets/icons/tick_darkGreen.svg';
import OptOutIcon from '../../../../../../assets/icons/logout_white.svg';
import DownloadIcon from '../../../../../../assets/icons/download.svg';
import CertificateIcon from '../../../../../../assets/icons/certificate-icon.svg';
import CertificateIconGrey from '../../../../../../assets/icons/certificate-icon-grey.svg';
import InfoIcon from '../../../../../../assets/icons/info-warning.svg';
import _ from 'lodash-es';
import PostEditProcess from '../../../Posts/PostEditPopup/PostEditProcess';
import ZoomIcon from '../../../../../../assets/icons/zoom.svg';
import colors from 'react-multi-date-picker/plugins/colors';
import NewSelectedDateRange from './NewSelectedDateRange';
import { STATUS_CODE } from '../../../../../../common/constants';
import { setDownloadCertificate } from '../../../../../../store/slices/SocialMediaSlice';
// import FeedbackPopup from '../../../Popup/FeedbackPopup';

const NewEventPreviewCard = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const typographyRef = useRef(null);
    const [isFavorite, setIsFavorite] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [editPostOpen, setEditPostOpen] = useState(false);
    const [openBookTooltip, setOpenBookTooltip] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [secondDialogOpen, setSecondDialogOpen] = useState(false);
    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [isFeedbackDone, setFeedbackComplete] = useState(false);
    const iscreate = location.pathname.startsWith('/events/create-event');
    const isPreview = location.pathname === '/events/preview' || location.pathname === `/events/preview/${params?.uniqueId}`;
    const { socialMedia, form, users, master } = useSelector((state) => { return state; })
    const eventFormValues = useSelector(state => state.form.eventForm?.values);
    const data = socialMedia.eventData;
    let EventStartDate;
    let EventStartTime;
    let EventEndTime;
    let combinedDateTime;
    let timeDiff;
    if (!_.isEmpty(data?.event_schedule)) {
        EventStartDate = data?.event_schedule[0]?.event_date;
        EventStartTime = data?.event_schedule[0]?.start_time
        EventEndTime = data?.event_schedule[0]?.end_time

        combinedDateTime = formatEventDateTime(EventStartDate, EventStartTime, EventEndTime);

        timeDiff = calculateTimeDifference(EventStartTime, EventEndTime);
    }
    // const EventCreate = location.pathname.startsWith('/events/create-event')
    const handleCopyClick = () => {
        if (typographyRef.current) {
            const textToCopy = typographyRef.current.innerText;
            navigator.clipboard.writeText(textToCopy).then(() => {
                setOpenTooltip(true);
            });
        }
    };

    const handleFeedbackDialog = () => {
        if (!isFeedbackDone) {
            setFeedbackDialog(true)
        } else {
            // if(!data?.rating){
            dispatch(DownloadCertificate(data?.gid));
            // }
        }
    }

    const handleFeedbackClose = () => {
        setFeedbackDialog(false);
        setFeedbackComplete(true);
    }

    const handleDownload = () => {
        dispatch(DownloadCertificate(data?.gid));
    }
    const handleButtonClick = () => {
        setIsTooltipOpen(true);
        setTimeout(() => {
            setIsTooltipOpen(false);
        }, 1000);
    };
    const handleLikeClick = () => {
        dispatch(getLiked(data?.gid));
        // setIsFavorite(true);
        // setTimeout(() => {
        //     setIsFavorite(false);
        // }, 1000);
    };
    const handleDeleteLike = () => {
        dispatch(deleteLiked(data?.gid));
    }
    const handleBookClick = () => {
        dispatch(setBookmark(data?.gid));
        // setOpenBookTooltip(true);
        // setTimeout(() => {
        //     setOpenBookTooltip(false);
        // }, 1000);
    };
    const handleDeleteBookmark = (gid) => {
        dispatch(deleteBookmark(gid));
    }
    const isEventOnline = eventFormValues?.event_mode_id === "2" || data?.event_mode_id === (2 || '2');
    const isEventInPerson = eventFormValues?.event_mode_id == "1" || data?.event_mode_id == (1 || '1');

    const handleJoinEvent = () => {
        dispatch(getJoinEvent(data?.gid));
    }
    const handleOptOutEvent = () => {
        dispatch(getOptOut(data?.gid));
    }
    const handleEditDialogOpen = () => {
        dispatch(getDownloadCertificate(data?.gid));
        // setPostEditActivity()
        // setPostEditBody(body)
        // setPostEditAudience()
        // setPostEditThematic()
        setEditPostOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditPostOpen(false);
        dispatch(setDownloadCertificate(null));
    };
    {/* This one is static just for UI perspective and can be removed */ }
    // const isEventStatic = 3;
    {/* This one is static just for UI perspective and can be removed */ }

    // const leftFlag = events.leftFlag
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setFeedbackComplete(data?.rating);
    }, [data?.rating]);
    const totalThematics = data?.thematic_area_ids?.length || 0;
    return (
        <>
            <Grid container padding={2} className={EventsCSS.newPreCard}>
                <Grid item xs={12} md={6} lg={4} xl={4} className={EventsCSS.newSkelGlassmorphicCard} sx={{ flexDirection: 'column' }}>
                    <Box className={EventsCSS.newSkelGcHeader} py={2} mx={2} >
                        <Typography className={EventsCSS.newPreCardTypo}>
                            {data?.event_mode_id ? (
                                data?.event_mode_id === 1 ? "In-Person" : "Online"
                            ) : (
                                <>
                                    {/* This one is static just for UI perspective and can be removed */}
                                    {/* In-Person */}
                                    {/* This one is static just for UI perspective and can be removed */}

                                    {/* Below Commented Section Should be uncommented */}
                                    <Skeleton animation="wave" variant="rounded" height='24px' width='120px' />
                                </>

                            )}
                        </Typography>
                        <Box className={`${EventsCSS.f} ${EventsCSS.ai_center}`} gap={'16px'}>
                            {/* <Box className={EventsCSS.newPreCardShadow}> */}
                            <Box className={EventsCSS.likeBox}>
                                <Typography>{data?.like_count !== 0 ? data?.like_count : 0}</Typography>
                                <IconButton sx={{ padding: '6px' }} onClick={() => { data?.self_like ? handleDeleteLike() : handleLikeClick() }}>
                                    {data?.thematic_area_ids ? (
                                        // <Tooltip open={isFavorite} placement="top" title="This is for preview Purpose only" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, 0], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }}>
                                        <FavoriteIcon sx={{ fontSize: '20px', color: data?.self_like ? '#D45454' : 'unset' }} />
                                        // </ToolTip>
                                    ) : (
                                        <>
                                            {/* This one is static just for UI perspective and can be removed */}
                                            {/* <Tooltip open={isFavorite} placement="top" title="This is for preview Purpose only" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, 0], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }}>
                                                <FavoriteIcon sx={{ fontSize: '20px', color: '#D45454' }} />
                                            </Tooltip> */}
                                            {/* This one is static just for UI perspective and can be removed */}

                                            {/* Below Commented Section Should be uncommented */}
                                            <Skeleton variant="circular" width={22} height={22} animation="wave" />
                                        </>
                                    )}
                                </IconButton>
                            </Box>
                            {/* </Box> */}
                            <Box className={EventsCSS.newPreCardShadow}>
                                <IconButton sx={{ padding: '5px' }} onClick={() => { data?.bookrmark?.is_bookrmark ? handleDeleteBookmark(data?.bookrmark?.gid) : handleBookClick() }}>
                                    {data?.thematic_area_ids ? (
                                        // <Tooltip open={openBookTooltip} placement="top" title="This is for preview Purpose only" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, 0], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }}>
                                        <img src={data?.bookrmark?.is_bookrmark ? EventBookMarked : EventBookMark} />
                                        // </Tooltip>
                                    ) : (
                                        <>
                                            {/* This one is static just for UI perspective and can be removed */}
                                            {/* <Tooltip open={openBookTooltip} placement="top" title="This is for fcsfs   Purpose only" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, 0], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }}>
                                                <img src={EventBookMark} />
                                            </Tooltip> */}

                                            {/* Below Commented Section Should be uncommented */}
                                            <Skeleton variant="circular" width={22} height={22} animation="wave" />
                                        </>
                                    )}
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={EventsCSS.newPreCardBox}>
                        <Box className={EventsCSS.newSkelGcBody} p={2}>
                            <Box className={EventsCSS.newPreCardBox2}>
                                {data?.thematic_area_ids ? (
                                    <Tooltip title='Event Area' placement="top" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -10], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }}>
                                        <img src={Thematic} className={EventsCSS.h20} />
                                    </Tooltip>
                                ) : (
                                    <>
                                        {/* This one is static just for UI perspective and can be removed */}
                                        {/* <img src={Thematic} className={EventsCSS.h20} /> */}
                                        {/* This one is static just for UI perspective and can be removed */}

                                        {/* Below Commented Section Should be uncommented */}
                                        <Skeleton animation="wave" variant="circular" height='1.2rem' width='1.2rem' sx={{ flexShrink: 0 }} />
                                    </>
                                )}

                                {/* <Typography className={EventsCSS.newSkelEhDetails} sx={{ flexGrow: '1' }}>
                                    {data?.thematic_area_ids ? (
                        
                                        dispatch(getEventAreaNameByIds(data.thematic_area_ids[0])) */}
                                <Box sx={{ flexGrow: '1' }}>
                                    {data?.thematic_area_ids ? (
                                        <>
                                            {totalThematics > 0 && (
                                                <Tooltip
                                                    title={
                                                        <Box>
                                                            {data?.thematic_area_ids?.map((thematic, index) => (
                                                                <Box key={index} display="flex" alignItems="center">
                                                                    <img src={Thematic} alt="Thematic" style={{ width: '13px', height: '13px', marginRight: '5px' }} />
                                                                    <Typography component="span" sx={{ marginLeft: '5px', fontSize: "11px" }}>
                                                                        {dispatch(getEventAreaNameByIds(thematic))}
                                                                    </Typography>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    }
                                                    placement="top"
                                                    arrow
                                                >
                                                    <Box className={EventsCSS.f} sx={{ cursor: "pointer" }}>
                                                        <Typography className={EventsCSS.newSkelEhDetails}>
                                                            {dispatch(getEventAreaNameByIds(data?.thematic_area_ids[0]))}
                                                        </Typography>
                                                        {totalThematics > 1 && (
                                                            <Box className={EventsCSS.plustheme}>
                                                                <Typography className={EventsCSS.plusText}>+{totalThematics - 1}</Typography>
                                                            </Box>

                                                        )}
                                                    </Box>
                                                </Tooltip>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {/* This one is static just for UI perspective and can be removed */}
                                            {/* Employment Livelihood enhancement projects enhancement projects */}
                                            {/* This one is static just for UI perspective and can be removed */}

                                            {/* Below Commented Section Should be uncommented */}
                                            <Skeleton animation="wave" variant="rounded" height='1.2rem' width='100%' />
                                        </>

                                    )}
                                </Box>
                            </Box>
                            <Box className={EventsCSS.newPreCardBox3}>
                                {!_.isEmpty(data?.event_schedule) && data.event_schedule[0] ? (
                                    <Tooltip title='Start Date' placement="top" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -10], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }}>
                                        <img src={Date} className={EventsCSS.h20} />
                                    </Tooltip>
                                ) : (
                                    <>
                                        {/* This one is static just for UI perspective and can be removed */}
                                        {/* <img src={Date} className={EventsCSS.h20} /> */}
                                        {/* This one is static just for UI perspective and can be removed */}

                                        {/* Below Commented Section Should be uncommented */}
                                        <Skeleton animation="wave" variant="circular" height='1.2rem' width='1.2rem' />
                                    </>
                                )
                                }
                                <Typography className={EventsCSS.newSkelEhDetails} >
                                    {!_.isEmpty(data?.event_schedule) && data.event_schedule[0] ? (
                                        <>
                                            {combinedDateTime}
                                        </>
                                    ) : (
                                        <>
                                            {/* This one is static just for UI perspective and can be removed */}
                                            {/* 20th Aug 2024 10:00AM */}
                                            {/* This one is static just for UI perspective and can be removed */}

                                            {/* Below Commented Section Should be uncommented */}
                                            <Skeleton animation="wave" variant="rounded" height='1.2rem' width='100%' />
                                        </>

                                    )}
                                </Typography>
                                <Box className={EventsCSS.newPreCardBox3} sx={{ marginLeft: 'auto' }}>
                                    {data?.event_schedule?.length !== 0 ? <Typography className={EventsCSS.previewSchedule} onClick={() => setSecondDialogOpen(true)}>View Schedule</Typography> :
                                        <Typography className={EventsCSS.newSkelEhDetails} >
                                            <>
                                                {/* Below Commented Section Should be uncommented */}
                                                <Skeleton animation="wave" variant="rounded" height='1.2rem' width='100%' />
                                            </>
                                        </Typography>
                                    }
                                </Box>
                            </Box>
                            {/* {EventCreate && (
                                <>
                                    <Box className={EventsCSS.newPreCardBox3}>
                                        {EventStartTime && EventEndTime ? (
                                            <AccessTimeIcon sx={{ fontSize: '20px', color: theme.palette.success.light }} />
                                        ) : (
                                            <Skeleton animation="wave" variant="circular" height='1.2rem' width='1.2rem' />
                                        )}
                                        <Typography className={EventsCSS.newSkelEhDetails} >
                                            {EventStartTime && EventEndTime ? (
                                                <>
                                                    {timeDiff}
                                                </>
                                            ) : (
                                                <Skeleton animation="wave" variant="rounded" height='1.2rem' width='100%' />
                                            )}
                                        </Typography>
                                    </Box>
                                </>
                            )} */}
                            <Box className={`${EventsCSS.f} ${EventsCSS.ai_center}`} gap={1}>
                                {data?.beneficiary_type ? (
                                    <Tooltip title='Beneficiary Type' placement="top" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -10], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }}>
                                        <img src={Area} className={EventsCSS.h20} />
                                    </Tooltip>
                                ) : (
                                    <>
                                        {/* This one is static just for UI perspective and can be removed */}
                                        {/* <img src={Area} className={EventsCSS.h20} /> */}
                                        {/* This one is static just for UI perspective and can be removed */}

                                        {/* Below Commented Section Should be uncommented */}
                                        <Skeleton animation="wave" variant="circular" height='1.2rem' width='1.2rem' />
                                    </>

                                )}
                                <Typography className={`${EventsCSS.newSkelEhDetails}`}>
                                    {data?.beneficiary_type ? (
                                        data?.beneficiary_type && data?.beneficiary_type.length !== 0 ?
                                            <Tooltip title={dispatch(getBeneficiaryNameByIds(data?.beneficiary_type))} arrow>
                                                <span>{dispatch(getBeneficiaryNameByIds(data?.beneficiary_type))}</span>
                                            </Tooltip> : <Skeleton animation="wave" variant="rounded" height='1.2rem' width='100%' />
                                    ) : (
                                        <>
                                            {/* This one is static just for UI perspective and can be removed */}
                                            {/* <Tooltip title="College Students, Adults" arrow>
                                                College Students, Adults
                                            </Tooltip> */}
                                            {/* This one is static just for UI perspective and can be removed */}

                                            {/* Below Commented Section Should be uncommented */}
                                            <Skeleton animation="wave" variant="rounded" height='1.2rem' width='100%' />
                                        </>

                                    )}
                                </Typography>
                            </Box>
                            {isEventOnline && (
                                <Box className={`${EventsCSS.f} ${EventsCSS.ai_center}`} gap={1}>
                                    {data?.online_link ? (
                                        <img src={chain} className={EventsCSS.h20} />
                                    ) : (
                                        <Skeleton animation="wave" variant="circular" height='1.2rem' width='1.2rem' />
                                    )}
                                    <Typography ref={typographyRef} className={`${EventsCSS.newSkelEhDetails} ${EventsCSS.newPreCardTypo2}`}>
                                        {data?.online_link ? (
                                            <a href={data && data?.online_link && data.online_link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: '#0A97D9' }}>
                                                {data && data?.online_link && data.online_link}
                                            </a>
                                        ) : (
                                            <Skeleton animation="wave" variant="rounded" height='1.2rem' width='100%' />
                                        )}
                                    </Typography>
                                    <Box sx={{ marginLeft: 'auto' }}>
                                        {data?.online_link ? (
                                            <Tooltip title="Copied!" placement="top" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -10], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }} open={openTooltip} onClose={() => setOpenTooltip(false)}>
                                                <img src={Copy} style={{ cursor: 'pointer' }} onClick={handleCopyClick} />
                                            </Tooltip>
                                        ) : (
                                            <Skeleton variant="circular" width={22} height={22} animation="wave" />
                                        )}
                                    </Box>
                                </Box>)}
                            {isEventInPerson && (
                                <Box className={`${EventsCSS.f} ${EventsCSS.ai_center}`} gap={1}>
                                    {data?.district_id && data?.address ? (
                                        <Tooltip title="Location" placement="top" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -10], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }} >
                                            <img src={Address} className={EventsCSS.h20} />
                                        </Tooltip>
                                    ) : (
                                        <Skeleton animation="wave" variant="circular" height='1.2rem' width='1.2rem' />
                                    )}
                                    {data?.district_id ? (
                                        <Tooltip title={`${data.address}, ${dispatch(getDistrictNameById(data?.district_id))}, ${dispatch(getStateNameById(data?.state_id))},  ${data.pin_code}`} arrow>
                                            <Typography className={`${EventsCSS.newSkelEhDetails}`}>
                                                {`${data.address}, ${dispatch(getDistrictNameById(data?.district_id))}, ${dispatch(getStateNameById(data?.state_id))} ${data.pin_code}`}
                                            </Typography>
                                        </Tooltip>
                                    ) : (
                                        <Skeleton animation="wave" variant="rounded" height='1.2rem' width='100%' />)}
                                </Box>
                            )}
                            {/* This one is static just for UI perspective and can be removed */}
                            {/* {isEventStatic && (
                                <>
                                    <Box className={`${EventsCSS.f} ${EventsCSS.ai_center}`} gap={1}>
                                        <img src={Address} className={EventsCSS.h20}/>
                                        <Tooltip title='234, D-block, District Center, Janakpuri, New Delhi - 110056' arrow>
                                            <Typography className={`${EventsCSS.newSkelEhDetails}`}>
                                                234, D-block, District Center, Janakpuri, New Delhi - 110056
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                    <Box className={`${EventsCSS.f} ${EventsCSS.ai_center}`} gap={1}>
                                        <img src={ZoomIcon} className={EventsCSS.h20}/>
                                        <Tooltip title='https://zoom.us/j/2937g52385gc2385?pwd=a187xg18o3rx18rpb2C3' arrow>
                                            <Typography className={`${EventsCSS.newSkelEhDetails}`}>
                                                https://zoom.us/j/2937g52385gc2385?pwd=a187xg18o3rx18rpb2C3
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                </>
                            )} */}
                            {/* This one is static just for UI perspective and can be removed */}
                        </Box>
                        <Box className={EventsCSS.newSkelGcFooter} p={2} gap={2}>
                            {/* {(iscreate || isPreview) && ( */}
                            {/* <Tooltip
                                open={isTooltipOpen}
                                title="This is for preview purposes only"
                                placement="top"
                                arrow
                                slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -8], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }}
                            > */}
                            {data?.is_join === 'Join' && data?.status_code === STATUS_CODE.PUBLISH.CODE ? (
                                <CommonButton2
                                    label="Join"
                                    name="Join"
                                    endIcon={<img src={Join} />}
                                    type="button"
                                    color="primary"
                                    variant='contained'
                                    classes={{
                                        root: EventsCSS.eventActionBtn,
                                    }}
                                    onClick={() => handleJoinEvent()}
                                    disabled={!data?.join_allowed}
                                />
                            ) : (
                                <>
                                    {data?.is_join === "Requested" && data?.status_code === STATUS_CODE.PUBLISH.CODE && <CommonButton2
                                        label="Request Pending"
                                        name="RequestPending"
                                        endIcon={<img src={RecoverIcon} />}
                                        type="button"
                                        color="primary"
                                        variant='contained'
                                        classes={{
                                            root: EventsCSS.eventActionBtn,
                                            disabled: EventsCSS.disabledBtn
                                        }}
                                        disabled
                                    />}

                                    {data?.is_join === "Accepted" && data?.status_code === STATUS_CODE.PUBLISH.CODE && <Box className={EventsCSS.iconAndButton}>
                                        <img src={TickIcon} alt='Tick' className={EventsCSS.h32} />
                                        <CommonButton2
                                            label="Opt Out"
                                            name="OptOut"
                                            endIcon={<img src={OptOutIcon} />}
                                            type="button"
                                            color="warning"
                                            variant='contained'
                                            classes={{
                                                root: `${EventsCSS.eventActionBtn} ${EventsCSS.eventActionBtnWarning}`,
                                            }}
                                            onClick={() => handleOptOutEvent()}
                                        />
                                    </Box>}


                                    {data?.is_join === "Accepted" && data?.status_code === STATUS_CODE.ONGOING.CODE && <Box className={EventsCSS.iconAndButton}>
                                        <img src={TickIcon} alt='Tick' className={EventsCSS.h32} />
                                        <Tooltip title="You cannot opt-out at this stage" placement="top" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -10], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }}>
                                            <span>
                                                <CommonButton2
                                                    label="Opt Out"
                                                    name="OptOut"
                                                    endIcon={<img src={OptOutIcon} />}
                                                    type="button"
                                                    color="warning"
                                                    variant='contained'
                                                    classes={{
                                                        root: `${EventsCSS.eventActionBtn} ${EventsCSS.eventActionBtnWarning}`,
                                                        disabled: EventsCSS.disabledBtn
                                                    }}
                                                    disabled
                                                />
                                            </span>
                                        </Tooltip>
                                    </Box>}


                                    {!data?.certificate_issued && data?.is_join === "Accepted" && (data?.status_code === STATUS_CODE.CLOSED.CODE || data?.status_code === STATUS_CODE.COMPLETE.CODE) && <Box className={EventsCSS.twoButtons}>
                                        <Tooltip title="Certificate issued to be soon" placement="bottom" slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [4, -10], }, },], }, tooltip: { style: { backgroundColor: 'black', fontSize: '14px', lineHeight: '21px', fontWeight: 500 } } }}>
                                            <span>
                                                <CommonButton2
                                                    label="Certificate"
                                                    name="Certificate"
                                                    startIcon={<img src={InfoIcon} />}
                                                    endIcon={<img src={DownloadIcon} />}
                                                    type="button"
                                                    color="primary"
                                                    variant='contained'
                                                    classes={{
                                                        root: `${EventsCSS.eventActionBtn} ${EventsCSS.eventActionBtnSuccess}`,
                                                        disabled: EventsCSS.disabledBtn
                                                    }}
                                                    disabled
                                                />
                                            </span>
                                        </Tooltip>
                                        <CommonButton2
                                            label="Share"
                                            name="Share"
                                            endIcon={<img src={CertificateIconGrey} />}
                                            type="button"
                                            color="primary"
                                            variant='outlined'
                                            classes={{
                                                root: EventsCSS.eventActionBtn,
                                                disabled: EventsCSS.disabledOutlinedBtn
                                            }}
                                            disabled
                                        />
                                    </Box>}

                                    {data?.certificate_issued && data?.status_code === STATUS_CODE.COMPLETE.CODE && data?.is_join === "Accepted" && <Box className={EventsCSS.twoButtons}>
                                        <CommonButton2
                                            label="Certificate"
                                            name="Certificate"
                                            endIcon={<img src={DownloadIcon} />}
                                            type="button"
                                            color="primary"
                                            variant='contained'
                                            classes={{
                                                root: `${EventsCSS.eventActionBtn} ${EventsCSS.eventActionBtnSuccess}`,
                                            }}
                                            onClick={() => handleFeedbackDialog()}
                                        />
                                        <CommonButton2
                                            label="Share"
                                            name="Share"
                                            endIcon={<img src={CertificateIcon} />}
                                            type="button"
                                            color="primary"
                                            variant='outlined'
                                            classes={{
                                                root: EventsCSS.eventActionBtn,
                                            }}
                                            onClick={() => handleEditDialogOpen()}
                                        />
                                    </Box>}
                                    {/* This one is static just for UI perspective and can be removed */}
                                    <Box></Box>
                                    {/* Below Commented Section Should be uncommented */}
                                    {/* <Skeleton variant="rectangular" width={160} height={36} animation="wave" sx={{ borderRadius: '20px' }} /> */}
                                </>
                            )}
                            {/* </Tooltip> */}
                            {/* )} */}
                        </Box>
                    </Box>
                </Grid>
            </Grid >
            {secondDialogOpen &&
                <NewSelectedDateRange
                    open={secondDialogOpen}
                    onClose={() => setSecondDialogOpen(false)}
                    title='Event Schedule'
                    events={data}
                    preview={true}
                />
            }
            {
                feedbackDialog &&
                <FeedbackPopup
                    open={feedbackDialog}
                    onClose={handleFeedbackClose}
                    dispatch={dispatch}
                    form={form}
                    gid={data?.gid}
                    setFeedbackComplete={setFeedbackComplete}
                />
            }
            {
                editPostOpen && <PostEditProcess
                    open={editPostOpen}
                    onClose={handleEditDialogClose}
                    usersImg={users}
                    master={master}
                    SocialMediaCSS={SocialMediaCSS}
                    socialMedia={socialMedia}
                    isEvent={true}
                    eventGid={data?.certificate_gid}
                    eventThematic={data?.thematic_area_ids}
                // post={post}
                // postEditBody={postEditBody}
                />
            }
        </>
    )
}
export default NewEventPreviewCard;