import { createSlice } from "@reduxjs/toolkit";
const initialState  = {
    isLoggedIn: false,
    isProfileSkiped: false,
    isUploaded: null,
    userData : {},
    myNetworks:[],
    userType: {},
    companyTypes:[],
    redeemApiFlag: false,
    userList:[],
    notifications: 0,
    messageCount: 0,
    tokens: {},
    businessSuite: [],
    userDetail:[],
    connectCount:[],
}
const userSlice = createSlice({
    name:"user",
    initialState,
    reducers: {
        resetUserSlice: () => initialState,
        setLogin(state, action){
            state.isLoggedIn = action.payload;
        },
        setCompanyType(state, action){
            state.companyTypes = action.payload;
        },
        setVerifyEmail(state, action){
            state.verifyEmail = action.payload;
        },
        setUserData(state, action){
            state.userData = action.payload;
        },
        setBusineesSuite(state, action){
            state.businessSuite = action.payload;
        },
        setTempUserData(state, action){
            state.tempUserData = action.payload;
        },
        setUserEmailVerify(state, action){
            state.emailVerification = action.payload;
        },
        setForgotPassData(state, action){
            state.forgotPassData = action.payload;
        },
        setForgotUserData(state, action){
            state.forgotUserData = action.payload;
        },
        setMyNetWorks(state, action){
            state.myNetworks = action.payload
        },
        setRedeepApiFlag(state, action){
            state.redeemApiFlag = action.payload
        },
        setApplied(state, action) {
            if (!state.userData.data) {
                state.userData.data = {};
            }
            state.userData.data = action.payload;
        },
        setUserList(state, action) {
            state.userList.push(action.payload);
        },
        updateUserListData(state, action) {
            state.userList = action.payload;
        },
        setNotificationCount(state, action) {
            state.notifications = action.payload
        },
        setMessageCount(state, action) {
            state.messageCount = action.payload
        },
        setLoginTokens(state, action) {
            state.tokens = action.payload
        },
        setProfileSkip(state, action) {
            state.isProfileSkiped = action.payload
        },
        setIsUploaded(state, action) {
            state.isUploaded = action.payload
        },
        setUserDetail(state, action) {
            state.userDetail = action.payload
        },
        setConnectCount(state, action) {
            state.connectCount = action.payload
        },
    }
})

export default userSlice.reducer;
export const { 
    setLoginTokens,
    addUser, 
    setLogin, 
    setCompanyType, 
    setVerifyEmail, 
    setUserData, 
    setTempUserData, 
    setUserEmailVerify, 
    setForgotPassData,
    setForgotUserData,
    resetUserSlice,
    setMyNetWorks,
    setRedeepApiFlag,
    setApplied,
    setUserList,
    updateUserListData,
    setMessageCount,
    setNotificationCount,
    setBusineesSuite,
    setProfileSkip,
    setIsUploaded,
    setUserDetail,
    setConnectCount,
}  = userSlice.actions;