import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import cancel from "../../../../assets/icons/Cancel.svg"
import { useDispatch } from "react-redux";
import { resetPostTags, setCreatePost, setPostAudience, setPrevAudience, setSelectedActivity, setSelectedPostAudience, setSelectedThematic, setUploadedFiles } from "../../../../store/slices/SocialMediaSlice";
import arrow from "../../../../assets/icons/arrow-back.svg"
import CommonButton2 from "../../../../common/components/CommonButton2";
import { reset } from "redux-form";
import DiscardPost from "../Popup/DiscardPost";
import { deletePost } from "../../actions";
import { useSelector } from "react-redux";


const PostAudience = ({ socialMedia, selectedPostTitle, form, onPostSelect, setSelectedPostTitle, master }) => {
    const dispatch = useDispatch();
    const draft = socialMedia?.draftPost;
    const [postDiscardOpen, setPostDiscardOpen] = useState(false);
    const [audienceChanged, setAudienceChanged] = useState(false);
    const uploadedFiles = useSelector((state) => state.socialMedia.uploadedFiles);
    const [postAud, setPostAud] = useState(null);
    const [selectedValue, setSelectedValue] = useState(selectedPostTitle ? selectedPostTitle : socialMedia.selectedPostAudience);
    const postTypeKey = draft?.post_type_name;
    const postType = master.postTypes.find(type => type.key === postTypeKey);
    const postTypeValue = postType?.value;
    const tempSelectedValueRef = useRef(null);
    // const handleProfileDialogOpen = () => {
    //     if(!form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description && uploadedFiles.length === 0) {
    //         handleCreateCancel();
    //     } else {
    //         setPostDiscardOpen(true);
    //     }
    // };
    const handleProfileDialogOpen = () => {
        if (postAud === null && !form?.CreatePostform?.values?.post_description ||audienceChanged) {
            handleCreateCancel();
        }
        if ((!form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description && uploadedFiles.length > 0) && uploadedFiles.length === 0) {
            handleCreateCancel();
        } else {
            setPostDiscardOpen(true);
        }
    };

    const handleCreateCancel = () => {
        dispatch(setPostAudience(false))
        dispatch(resetPostTags())
        dispatch(setCreatePost(false))
        setSelectedPostTitle(postTypeValue ? postTypeValue : "Public")
        // dispatch(setSelectedPostAudience('Public'))
        dispatch(reset('CreatePostform'))
        dispatch(setUploadedFiles([]));
        dispatch(setSelectedActivity(""));
        dispatch(setSelectedThematic([]));
        if (draft.gid) {
            dispatch(deletePost(draft.gid));
        }
        handleProfileDialogClose();
    };
    const handleProfileDialogClose = () => {
        setPostDiscardOpen(false);
    };
    const handleDoneClick = () => {
        // window.scrollTo(0, 0);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(setPostAudience(false))
        if (tempSelectedValueRef.current) {
            dispatch(setPrevAudience(tempSelectedValueRef.current))
        }
        
    }
    const handleBackClick = () => {
        dispatch(setPostAudience(false))
        setSelectedPostTitle(postTypeValue ? postTypeValue : socialMedia?.prevAudience ? socialMedia?.prevAudience : "Public")
    }
    console.log("hey",audienceChanged)
    const handleCardClick = (title) => {
        console.log(`Clicked on: ${title}`);
        onPostSelect(title);
        setSelectedPostTitle(title);
        setPostAud(title);
        setAudienceChanged(true); 
        tempSelectedValueRef.current = title;
        // dispatch(setSelectedPostAudience(title));
        // dispatch(setPostAudience(false))
    }
    //console.log("ddd", socialMedia?.prevAudience)

    return (
        <>
            <Box className={SocialMediaCSS.postCard}>
                <Box className={SocialMediaCSS.activityHeader}>
                    <Box className={`${SocialMediaCSS.postGap}`}>
                        <img src={arrow} onClick={handleBackClick} />
                        <Typography className={SocialMediaCSS.selectActivity} >
                            Post Audience
                        </Typography>
                    </Box>
                    <Box className={SocialMediaCSS.cancelIcon} onClick={() => handleProfileDialogOpen()}>
                        <img src={cancel} className={SocialMediaCSS.createPost} />
                    </Box>
                </Box>
                <Box>
                    <Typography className={SocialMediaCSS.audiencePost}>Who can view your post?</Typography>
                    <Typography className={SocialMediaCSS.audienceText}>Your default audience is set to connections, but you can change the audience of this specific post.</Typography>
                </Box>
                <Typography className={SocialMediaCSS.ChooseAudi}>Choose audience</Typography>
                <Box className={SocialMediaCSS.scrollable}>
                    <RadioGroup value={selectedValue} onChange={(event) => {
                        setSelectedValue(event.target.value);
                        handleCardClick(event.target.value);
                    }}>
                        {master?.postTypes?.map((data, index) => (
                            <Box key={index} mb={index !== master?.postTypes.length - 1 ? 0 : 0}>
                                <FormControlLabel
                                    value={data.value}
                                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20, padding: '0px' } }} />}
                                    label={
                                        <Box className={SocialMediaCSS.audiCard} onClick={() => handleCardClick(data.value)}>
                                            <img src={data.image} className={SocialMediaCSS.postAudienceIMG} />
                                            <Box>
                                                <Typography className={SocialMediaCSS.autiTitle}>{data.value}</Typography>
                                                <Typography className={SocialMediaCSS.audiDescrip}>{data.title}</Typography>
                                            </Box>
                                        </Box>
                                    }
                                />
                            </Box>
                        ))}
                    </RadioGroup></Box>

                <Box className={`${SocialMediaCSS.nextPost} ${SocialMediaCSS.margin}`} >
                    <CommonButton2
                        label="Done"
                        name="Done"
                        variant="contained"
                        type="button"
                        onClick={handleDoneClick}
                        style={{
                            padding: '6px 24px',
                            fontSize: '14px',
                            height: '32px',
                            fontWeight: '600',
                            background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%',
                            boxShadow: 'none'
                        }}
                    />
                </Box>
            </Box>
            {postDiscardOpen &&
                <DiscardPost
                    open={postDiscardOpen}
                    onClose={handleProfileDialogClose}
                    ButtonText1="Save as Draft"
                    ButtonText2="Discard"
                    title="Save as Draft"
                    Content1="Do you want to save this post as Draft?"
                    Content2="The content of this post will be here whenever you come back."
                    handleDiscard={handleCreateCancel}
                />}
        </>
    )
}

export default PostAudience;

