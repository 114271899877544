import React from 'react';
import TextField from '@mui/material/TextField';
import theme from '../../assets/material-ui/theme';
import { Box, OutlinedInput } from '@mui/material';
import { useState } from 'react';

export const renderMultilinetext = ({
    input,
    label,
    placeholder,
    type,
    fullWidth,
    maxLength,
    underlineStyle,
    errorStyle,
    style,
    toolTiptext,
    id,
    pattern,
    defaultValue,
    disabled,
    min,
    max,
    rows,
    multiline,
    maxWords,
    value,
    meta: { touched, error, warning },
    inputProps,
    errorMessages,
    custom,
}) => {
    let inputType = type;
    errorStyle = {
        fontSize: '10px',
        color: 'rgb(244, 67, 54)',
        position: 'static',
    };
    return (
        <>
            <OutlinedInput
                multiline={multiline}
                rows={rows}
                placeholder={placeholder}
                id={id}
                disabled={disabled}
                autoComplete="off"
                pattern={pattern}
                type={inputType}
                fullWidth={fullWidth}
                value={defaultValue}
                min={min}
                inputProps={{ maxLength: maxLength }}
                //InputProps={{ sx: {  border: '0.1px solid #333333',borderRadius: 4,background:theme.palette.secondary.dark,"&:hover": {borderColor:theme.palette.success.dark} ,
                sx={{
                    border: 1,
                    borderColor: theme.palette.secondary.dark,
                    borderRadius: 4,
                    fontSize: 16,
                    backgroundColor: theme.palette.secondary.dark,
                    fontWeight: 400,
                    color: theme.palette.success.dark,
                    
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.success.dark,
                    },
                    "&:hover  .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.success.dark
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.primary.main
                    },
                    "& input:-webkit-autofill": {
                        height: 12,
                        '-webkit-box-shadow': '0 0 0 1000px transparent inset',
                        '-webkit-text-fill-color': '#333333 !important',
                        'transition': 'background-color 5000s ease-in-out 0s',
                    },
                }}
            
                max={max}
                {...input}
                {...custom}
            />
            {touched && (error && (
                <Box className='error'>
                    {error}
                </Box>
            ))}
        </>
    );
};
export default renderMultilinetext;