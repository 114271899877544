import React from 'react';
import { Box, Dialog, IconButton, useMediaQuery } from '@mui/material';
import theme from '../../../../assets/material-ui/theme';
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import CloseIcon from "../../../../assets/icons/closeDialogue.svg"

import LinkedInIcon from '../../../../assets/icons/socialIcons/linkedIn.svg';
import InstaIcon from '../../../../assets/icons/socialIcons/instagram.svg';
import FBIcon from '../../../../assets/icons//facebook.svg';
import XIcon from '../../../../assets/icons/socialIcons/x.svg';
import EmailIcon from '../../../../assets/icons/socialIcons/email.svg';
import WAIcon from '../../../../assets/icons/socialIcons/whatsapp.svg';
import SocialShareToRow from './SocialShareToRow';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, WhatsappShareButton } from "react-share";
import { useLocation } from 'react-router';
export const ShareOtherPopup = ({ open, onClose, Title, post }) => {
    const location = useLocation();
    const convertToHttps = (url) => {
        if (url.startsWith('http://')) {
            return url.replace('http://', 'https://');
        }
        return url;
    };
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }
    const isPostURL = location?.pathname?.startsWith('/social-media/posts');
    const shareUrl = isPostURL ? convertToHttps(window.location.href) : `${window.location.origin}/social-media/posts/${post?.gid}`;
    const postTitle = post?.primary_post?.body || "Check out this post!";
    const postDescription = post?.description || "This is an amazing post!";
    const postImageUrl = post?.primary_post?.post_images?.[0]?.images_url || "";
    const postVideoUrl = post?.primary_post?.post_video?.[0]?.video_url || '';
    const post_gid = post?.gid
    // const postUrl = `${shareUrl}/posts/${post_gid}`;
    const postUrl = shareUrl.includes(`/posts/${post_gid}`)
        ? shareUrl
        : `${shareUrl}/posts/${post_gid}`;
    //console.log("reer", postUrl)

    // const handleLinkedInShare = () => {
    //     let linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(postUrl)}&text=${encodeURIComponent(postTitle)}`;
    //     if (postImageUrl) {
    //         linkedInUrl += `&media=${encodeURIComponent(postImageUrl)}`;
    //     }
    //     if (postVideoUrl) {
    //         linkedInUrl += `&media=${encodeURIComponent(postVideoUrl)}`;
    //     }
    //     window.open(linkedInUrl, '_blank');
    // };

    const handleLinkedInShare = () => {
        const linkedInUrl = `https://www.linkedin.com/shareArticle/?mini=true&url=${encodeURIComponent(postUrl)}&title=${encodeURIComponent(postTitle)}&source=LinkedIn`;
        window.open(linkedInUrl, '_blank');
    };


    const handleInstagramShare = () => {
        alert("Instagram does not support direct sharing via URL. Please copy the post link and share manually.");
    };

    const handleXShare = () => {
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(postUrl)}&text=${encodeURIComponent(postTitle)}`;
        window.open(twitterUrl, '_blank');
    };
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleDlgClose}
            aria-labelledby="Share Post"
            PaperProps={{ classes: { root: SocialMediaCSS.shareToPopup } }}
        >
            <Box className={SocialMediaCSS.shareToHeader}>
                <IconButton onClick={() => onClose()} sx={{ padding: 0 }}>
                    <img src={CloseIcon} alt='Close Dialog' className={SocialMediaCSS.h15} />
                </IconButton>
            </Box>

            <Box className={SocialMediaCSS.smContainer}>
                {/* <LinkedinShareButton url={shareUrl} title={postTitle} summary={postDescription} source="LinkedIn" image={postImageUrl}>
                        <SocialShareToRow icon={LinkedInIcon} iconAlt='LinkedIn' title='Share to Linkedin' />
                    </LinkedinShareButton> */}
                <Box onClick={handleLinkedInShare}>
                    <SocialShareToRow icon={LinkedInIcon} iconAlt='LinkedIn' title='Share to Linkedin' />
                </Box>

                {/* <Box onClick={handleInstagramShare}>
                    <SocialShareToRow icon={InstaIcon} iconAlt='Instagram' title='Share to Instagram' />
                </Box> */}
                <FacebookShareButton url={shareUrl} quote={postTitle} image={postImageUrl}>
                    <SocialShareToRow icon={FBIcon} iconAlt='Facebook' title='Share to Facebook' />
                </FacebookShareButton>
                <Box onClick={handleXShare}>
                    <SocialShareToRow icon={XIcon} iconAlt='X' title='Share to X' />
                </Box>
                {/* <EmailShareButton url={shareUrl} subject={postTitle} body={postDescription} image={postImageUrl}>
                    <SocialShareToRow icon={EmailIcon} iconAlt='Email' title='Share via Email' />
                </EmailShareButton> */}
                {/* <WhatsappShareButton url={shareUrl} title={postTitle} separator=":" image={postImageUrl} >
                    <SocialShareToRow icon={WAIcon} iconAlt='Whatsapp' title='Share via Whatsapp' />
                </WhatsappShareButton> */}
            </Box>
        </Dialog>
    );
};