import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, MenuItem, Popover, Typography } from "@mui/material";
import filter from "../../../../../assets/icons/filterIcon.svg"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CategoryHeader from "../../Popup/CategoryHeader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setCategory, setSortBy } from "../../../../../store/slices/SocialMediaSlice";

const RfpListHeader = ({ SocialMediaCSS }) => {
    const dispatch = useDispatch();
    const { master, socialMedia: { category }, form } = useSelector((state) => state);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorPosition, setAnchorPosition] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [checkedItems, setCheckedItems] = useState({});
    const [sortBy, setSort] = useState(null);


    const open = Boolean(anchorEl2);
    const handleClick = (event) => {
        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();
        setAnchorPosition({
            top: rect.bottom,
            // left: rect.right ,
        });
        setAnchorEl2(event.currentTarget);

    };
    const handleClose = () => {
        if (Object.keys(checkedItems).length === 0 || // Check if checkedItems is empty
            Object.keys(checkedItems).every(key => !checkedItems[key]) // Check if all values are falsy
        ) {
            setAnchorEl2(null);
            return;
        }
        if (checkedItems) {
            const selectedThemes = master?.thematicAreas
                .filter(item => checkedItems[item.id.toString()])
                .map(item => item.id);
            // Check if category and selectedThemes have the same length
            // and if all items in category are included in selectedThemes
            const isSameLengthAndValues = category?.length === selectedThemes?.length &&
                category.every(catItem => selectedThemes.includes(catItem));
            if (isSameLengthAndValues) {
                setAnchorEl2(null);
                return;
            }
            dispatch(setCategory(selectedThemes))
            // if (filterData) {
            //     dispatch(getFilterList(10, false, '', filterData, selectedThemes))

            // } else {
            //     dispatch(getCategoryEventsList(10, false, '', selectedThemes));
            // }
        }
        setAnchorEl2(null);
    };

    useEffect(() => {
        if (category === null) {
            setCheckedItems({});
        }
    }, [category])
    const handleSortByClick = (event) => {
        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();
        setAnchorPosition({
            top: rect.bottom,
            left: rect.left,
        });
        setAnchorEl(event.currentTarget);
    };
    // const [anchorEl, setAnchorEl] = React.useState(null);

    const openSortByPopover = Boolean(anchorEl);

    const handleCloseSortByPopover = () => {
        setAnchorEl(null);
    };

    const handleSortOptionClick = (option) => {
        setSort(option);
        // console.log("option",option)
        dispatch(setSortBy(option));
        setAnchorEl(null);
        // Perform sorting logic based on the selected option
        // For example: dispatch action to fetch sorted data
    };
    return (
        <>
            <Grid xs={12} className={SocialMediaCSS.rfpHeader}>
                <Typography className={SocialMediaCSS.rfpTypo}>RFP</Typography>
                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`} gap={3}>
                    <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`} style={{ cursor: 'pointer' }} onClick={handleSortByClick} gap={1}>
                        <Typography className={SocialMediaCSS.category}>Sort By</Typography>
                        <ExpandMoreIcon />
                    </Box>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        style={{ fontSize: '16px', fontWeight: 500, lineHeight: '16px', textAlign: 'left', color: '#333333', }}
                    >
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`} gap={1}>
                            <Typography className={SocialMediaCSS.category}>Category</Typography>
                            <ExpandMoreIcon />
                        </Box>
                    </Button>
                </Box>
            </Grid>
            {/* {anchorPosition && (
            <Popover
                id="sort-by-menu"
                anchorEl={anchorEl}
                open={openSortByPopover}
                onClose={handleCloseSortByPopover}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition}
                anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                style={{ ...anchorPosition, top: '15px', right: '0px', }}
                transitionDuration={0}
            >
                <MenuItem onClick={() => handleSortOptionClick('latest')}>Latest</MenuItem>
                <MenuItem onClick={() => handleSortOptionClick('oldest')}>Oldest</MenuItem>
            </Popover>)} */}
             <Popover
                id="sort-by-menu"
                anchorEl={anchorEl}
                open={openSortByPopover}
                onClose={handleCloseSortByPopover}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <MenuItem onClick={() => handleSortOptionClick('latest')}>Latest</MenuItem>
                <MenuItem onClick={() => handleSortOptionClick('oldest')}>Oldest</MenuItem>
            </Popover>
            {open &&
                <CategoryHeader
                    options={master?.thematicAreas}
                    anchorEl={anchorEl}
                    open={open}
                    // anchorEl={anchorEl2}
                    onClose={handleClose}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    anchorPosition={anchorPosition}
                />}
        </>
    )
}

export default RfpListHeader;