import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CommonButton2 from "./CommonButton2";
import { useLocation, useNavigate } from "react-router";

const AboutUs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path;
    };
    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Grid container xs={12} sx={{ padding: '30px 50px 50px 50px', display: 'flex', justifyContent: 'center' }}>
                <Paper elevation={7} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12} sx={{ padding: '30px 50px 50px 50px' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left' }}><b>About Us - bVokal</b></Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify' }}>
                            Welcome to bVokal, the premier social media platform dedicated to empowering the CSR
                            (Corporate Social Responsibility) and ESG (Environmental, Social, and Governance)
                            community. At bVokal, we believe in the transformative power of collaboration, innovation,
                            and shared purpose to drive meaningful social and environmental impact.
                        </Typography>
                        <Typography sx={{ paddingTop: '10px', textAlign: 'justify' }}> <b>Our Mission: </b>Our mission is to create a dynamic and engaging platform where professionals,
                            organizations, and stakeholders in the CSR and ESG sectors can connect, collaborate, and
                            create lasting change. We are committed to providing the tools and resources necessary to
                            support impactful initiatives and foster a community dedicated to sustainability and social
                            responsibility.</Typography>
                        <Typography sx={{ paddingTop: '10px', textAlign: 'justify' }}> <b>What We Offer: </b>At bVokal, we have integrated a comprehensive suite of ERP (Enterprise
                            Resource Planning) modules to streamline and enhance your CSR and ESG activities:</Typography>
                        <Box sx={{ paddingLeft: '40px' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}><b>1. Request for Proposal (RFP) Management:</b></Typography>
                            <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                                <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '2px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                                Simplify the process of creating, managing, and publishing RFPs with our
                                intuitive tools. Ensure transparency, efficiency, and effective collaboration
                                with stakeholders.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '0px' }}><b>2. Proposal Creation and Sharing:</b></Typography>
                            <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                                <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '2px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                                Create compelling proposals with ease using our advanced templates and
                                customization options. Share your proposals seamlessly with partners and
                                stakeholders for quick feedback and approval.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '700 !important', textAlign: 'left', paddingTop: '0px' }}><b>3. Event Management:</b></Typography>
                            <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                                <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '2px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                                Plan, organize, and manage impactful events with our robust event
                                management module. From conferences and seminars to workshops and
                                webinars, we provide the tools to ensure your events are successful and
                                engaging.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '0px' }}><b>4. Conference Management:</b></Typography>
                            <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                                <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '2px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                                Streamline every aspect of your conference planning with features for
                                speaker registration, agenda creation, sponsor management, and attendee
                                engagement. Foster an environment of learning and networking for all
                                participants.
                            </Typography>
                        </Box>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}><b>Why Choose bVokal?</b></Typography>
                        <Typography sx={{ paddingTop: '20px', textAlign: 'justify', paddingLeft: '40px' }}>
                            <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '0px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                            <b>Dedicated to CSR and ESG: </b>Our platform is specifically designed for the unique
                            needs of the CSR and ESG sectors, ensuring that every feature and tool is tailored to
                            support your mission-driven activities.
                        </Typography>
                        <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                            <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '0px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                            <b> Comprehensive Tools: </b>With our integrated ERP modules, you can manage your
                            projects, proposals, and events from a single platform, reducing complexity and
                            enhancing productivity.
                        </Typography>
                        <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                            <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '0px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                            <b>  Collaboration and Networking: </b>Connect with like-minded professionals,
                            organizations, and stakeholders. Share knowledge, best practices, and innovative
                            solutions to drive collective impact.
                        </Typography>
                        <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                            <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '2px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                            <b> User-Friendly Interface: </b>Our platform is designed to be intuitive and easy to use,
                            allowing you to focus on what matters most making a difference.
                        </Typography>
                        <Typography sx={{ paddingTop: '0px', textAlign: 'justify', paddingLeft: '40px' }}>
                            <span style={{ display: 'inline-block', marginRight: '8px', marginBottom: '2px', borderRadius: '50%', width: '6px', height: '6px', backgroundColor: 'black' }}></span>
                            <b> Security and Privacy: </b>We prioritize the security and privacy of your data,
                            implementing robust measures to protect your information and ensure compliance
                            with industry standards.
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}><b>Join Our Community:</b></Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify', paddingTop: '20px' }}>
                            bVokal is more than just a platform; it’s a community of changemakers dedicated to
                            creating a sustainable and equitable world. Join us today and be a part of a movement that
                            is shaping the future of CSR and ESG. Together, we can amplify our impact and drive positive
                            change.
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}><b>Contact Us:</b></Typography>
                        <Typography sx={{ fontSize: '16px', paddingTop: '10px', textAlign: 'justify', paddingTop: '20px' }}>
                            For more information or to get started with bVokal, visit our website at  <a href="https://www.bvokal.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}> www.bvokal.com</a> or
                            reach out to our support team at info@bvokal.com. Connect with us on social media to stay
                            updated on the latest news, events, and insights in the CSR and ESG community
                        </Typography>
                        <Divider sx={{ height: '20px', paddingTop: '20px' }} />
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: '10px' }}><b>bVokal - Empowering Change, together.</b></Typography>

                        <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                            <Grid item xs={12} sm={12} md={1.5} gap={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CommonButton2
                                    label="Go Back"
                                    name="Go Back"
                                    type="button"
                                    color="primary"
                                    variant='contained'
                                    // onClick={() => navigate('/login')}
                                    onClick={() => window.history.back()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default AboutUs;