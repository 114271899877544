// ChatHeader.js
import React from 'react';
import { Box, Avatar, Typography, Skeleton, Card } from '@mui/material';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { UserProfile } from './ChatScreen';

const ChatHeader = ({ brandData, userStatus, socialMedia }) => (
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
        {
            socialMedia.isChatMessagesLoading ? (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap : 2, width:'50%'}}>
                        <Skeleton animation="wave" variant="circular" width={48} height={48} sx={{ flexShrink: 0 }} />
                        <Box sx={{display:'flex', flexDirection:'column', gap:'1px', width:'40%'}}>
                            <Skeleton animation="wave" variant='text' width="100%" height={18} />
                            <Skeleton animation="wave" variant='text' width="80%" height={18} />
                            <Skeleton animation="wave" variant='text' width="40%" height={14} />
                        </Box>
                    </Box>
                </>) : (
                    <UserProfile 
                        src={brandData?.profile_image} 
                        name={brandData?.name} 
                        subtitle={brandData?.company_type} 
                        status={userStatus} 
                        initials={brandData?.initials} 
                        color_code={brandData?.color_code} 
                        gid={brandData?.gid}
                        availability_status={brandData?.availability_status}
                    />
            )
        }
        
    </Box>
);

export default ChatHeader;
