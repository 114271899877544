import React, { useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import PostIcon from '../../../../assets/icons/Post.svg';
import ArticleIcon from '../../../../assets/icons/article.svg';
import EventIcon from '../../../../assets/icons/eventNew.svg';
import { useDispatch } from "react-redux";
import { setArticleCreation, setCreatePost } from "../../../../store/slices/SocialMediaSlice";
import { useNavigate } from "react-router";
import { getDraftPost} from "../../actions";
import { getHastagList, getMentionsList } from "../../../../common/actions";

const CreateSection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCreatePost = () => {
        dispatch(setCreatePost(true));
        dispatch(getDraftPost());
        dispatch(getMentionsList());
        dispatch(getHastagList());
    };
    const handleCreateEvent = () => {
        navigate("/events/create-event")
    };
    const handleCreateArticle = () => {
        dispatch(setCreatePost(true));
        dispatch(setArticleCreation(true));
        dispatch(getDraftPost());
    };
    return (
        <>
            <Box className={SocialMediaCSS.createCard}>
                <Grid container className={SocialMediaCSS.createCardContainer}>
                    <Grid xs className={SocialMediaCSS.createCardItem}>
                        <Box className={SocialMediaCSS.createBtn} onClick={handleCreatePost}>
                            <img src={PostIcon} className={SocialMediaCSS.h20} alt="Create Post" />
                            <Typography variant="body4" className={SocialMediaCSS.createText}>
                                Create Post
                            </Typography>
                        </Box>
                    </Grid>
                    {/* <Grid xs className={SocialMediaCSS.createCardItem}>
                        <Box className={SocialMediaCSS.createBtn} onClick={handleCreateArticle}>
                            <img src={ArticleIcon} className={SocialMediaCSS.h20} alt="Create Article" />
                            <Typography variant="body4" className={SocialMediaCSS.createText}>
                                Create Article
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs className={SocialMediaCSS.createCardItem}>
                        <Box className={SocialMediaCSS.createBtn} onClick={handleCreateEvent}>
                            <img src={EventIcon} className={SocialMediaCSS.h20} alt="Create Event" />
                            <Typography variant="body4" className={SocialMediaCSS.createText}>
                                Create Event
                            </Typography>
                        </Box>
                    </Grid> */}
                </Grid>
            </Box>
        </>
    )
}

export default CreateSection