import React, { useEffect, useState } from 'react';
import { Box, OutlinedInput, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      transition: 'border-color 0.3s ease',
      borderColor: theme.mainPalette.grey.grey400,
      borderRadius: '5px'
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
    '&$disabled $notchedOutline': {
      WebkitTextFillColor: "#98A2B3",
      backgroundColor: '#E0E5FF',
    },
    '& input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 1000px transparent inset',
      '-webkit-text-fill-color': '#333333 !important',
      'transition': 'background-color 5000s ease-in-out 0s',
    },
    // height: '48px',
    // padding: '16px 12px !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
    lineHeight: '1.5',
  },

  focused: {},
  notchedOutline: {},
}));

export const RenderInputNew = ({
  input,
  label,
  placeholder,
  type,
  fullWidth,
  maxLength,
  underlineStyle,
  countFontSize,
  errorStyle,
  style,
  toolTiptext,
  id,
  pattern,
  showCount,
  defaultValue,
  disabled,
  min,
  max,
  value,
  onChange,
  errorWidth,
  onKeyPress,
  meta: { touched, error, warning },
  inputProps,
  errorMessages,
  custom,
  rows,
  multiline,
  placeholderFontSize,
  customHeight
}) => {
  const classes = useStyles();

  let inputType = type;
  errorStyle = {
    fontSize: '10px',
    color: 'rgb(244, 67, 54)',
    position: 'static',
  };
  const dynamicStyle = {
    ...style,
    height: multiline ? undefined : '48px',
    padding: multiline ? '8px 12px ' : '16px 4px ',
  };
  // const placeholderStyle = placeholderFontSize ? { '&::placeholder': { fontSize: placeholderFontSize } } : {};

  const [charCount, setCharCount] = useState(input.value ? input.value.length : 0);

  useEffect(() => {
    setCharCount(input.value ? input.value.length : 0);
  }, [input.value]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const currentLength = inputValue.length;
    setCharCount(currentLength); // Update charCount with the current length of input value
    input.onChange(inputValue); // Call the onChange function passed through props to update the form value
  };
  return (
    <>
      <OutlinedInput
        inputProps={{
          maxLength,
          disabled,
          min: 0
        }}
        className="TextField"
        classes={{
          root: classes.root,
          focused: classes.focused,
          disabled: classes.disabled,
          notchedOutline: classes.notchedOutline,
        }}
        multiline={multiline}
        rows={multiline ? rows : 1}
        placeholder={placeholder}
        id={id}
        disabled={disabled}
        autoComplete="off"
        pattern={pattern}
        type={inputType}
        onKeyPress={onKeyPress}
        fullWidth={fullWidth}
        value={defaultValue}
        // value={input.value || defaultValue || ''} 
        // onChange={input.onChange}
        onChange={handleChange}
        min={min}
        max={max}
        // style={style}
        style={dynamicStyle}

        {...input}
        {...custom}
      />
      <Box sx={{ display: "flex", paddingTop: '6px' }}>
        {touched && (error && (
          <Box style={{ color: 'red', fontSize: '12px', padding: 0, wordBreak: 'break-word', marginRight: 'auto', maxWidth: errorWidth ? errorWidth : '400px' }} >
            {error}
          </Box>
        ))}
        {showCount && (
          <Typography sx={{ fontSize: countFontSize ? countFontSize : '10px !important', color: '#666', fontWeight: "500", marginLeft: 'auto !important', fontStyle: 'italic' }}>{`${charCount}/${maxLength}`}</Typography>
        )}</Box>
    </>
  );
};