import { Box, Grid, Typography } from '@mui/material'
import React from 'react';
import SocialMediaCSS from '../../../../SocialMediaCSS/SocialMedia.module.css';



const InsightCard = ({icon, title, count}) => {
    return (
        <Grid item xs={12} sm={6} lg={3}>
            <Box className={SocialMediaCSS.insightCard}>
                <img src={icon} alt={title} className={SocialMediaCSS.h32} />
                <Box className={SocialMediaCSS.insightData}>
                    <Typography variant='h6' className={SocialMediaCSS.insightCount}>
                        {count}
                    </Typography>
                    <Typography variant='body' className={SocialMediaCSS.insightTitle}>
                        {title}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    )
}

export default InsightCard
