import React from 'react'
import ReduxForm from '../../../../common/components/ReduxForm'
import { Box, Grid, Typography } from '@mui/material'
import theme from '../../../../assets/material-ui/theme'
import CommonInputNew from '../../../../common/components/CommonInputNew'
import CommonButton2 from '../../../../common/components/CommonButton2'
import CommonButtonNew from '../../../../common/components/RectangularInputs/CommonButtonNew'
import * as clientValidations from '../../../../common/validations'

const SignUpMailSentForm = ({ RegCSS, handleSubmit, handleResendClick, isResendDisabled, remainingTime, isBusinessSuite }) => {
    return (
        <ReduxForm
            onSubmit={(values) => handleSubmit(values)}
            form="signUPMailSent"
            label="Log in to your account"
            submitButton="SignUp"
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Grid container spacing={3} px={isBusinessSuite ? 0 : 6}>
                        <Grid item xs={12}>
                            <Typography className={RegCSS.signUpMailTypo4}>Verification Code <span style={{ color: theme.palette.error.main }}>*</span></Typography>
                            <CommonInputNew
                                name="verification_code"
                                placeholder="Enter OTP"
                                type="otp"
                                keyboardType="number"
                                normalize={clientValidations.normalizeNumericInput}
                                fullWidth
                                validate={[clientValidations.required, clientValidations.number, clientValidations.maxLength6, clientValidations.onlyTextandNumber]}
                                maxLength={6}
                            />
                            <Box className={RegCSS.signUpMailBox2}>
                                <CommonButton2
                                    label="Resend Code"
                                    name="Resend Code"
                                    style={{
                                        color: isResendDisabled ? '#CCCCCC' : theme.palette.primary.dark,
                                        fontSize: '14px',
                                        textDecoration: 'underline',
                                        background: 'transparent',
                                        lineHeight: '21px',
                                        '&:disabled': {
                                            background: '#CCCCCC',
                                        },
                                        fontWeight: '500',
                                        padding: 0,
                                        margin: 0,
                                    }}
                                    onClick={() => handleResendClick()}
                                    disabled={isResendDisabled}
                                />
                                <Typography className={RegCSS.signUpMailTypo5}>
                                    {isResendDisabled ? `${remainingTime} seconds` : ''}
                                </Typography>
                            </Box>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={RegCSS.signUpMailTypo4}>
                                Password <span style={{ color: theme.palette.error.main }}>*</span>
                            </Typography>
                            <CommonInputNew
                                name="password"
                                placeholder="Choose your password"
                                type="password"
                                keyboardType="default"
                                fullWidth
                                validate={[clientValidations.PasswordRequired, clientValidations.minLength8, clientValidations.noWhitespaces]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box className={`${RegCSS.f} ${RegCSS.jc_center}`}>
                        <CommonButtonNew
                            label="SignUp"
                            name="SignUp"
                            type="submit"
                            style={{ background: theme.palette.primary.dark, color: 'white', boxShadow: theme.shadow.softShadow4 }}
                            smallWidth
                        />
                    </Box>
                </Grid>
            </Grid>
        </ReduxForm>
    )
}

export default SignUpMailSentForm