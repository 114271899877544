import React from "react";
import { Route, Routes } from "react-router-dom";
import NewRegistrationLayout from "./common/NewRegistrationLayout";
import NewSignUp from "./components/NewSignUp";
import SignUpMailSentNew from "./components/SignUpMailSentNew";
import ForgotPasswordNew from "./components/ForgotPasswordNew";
import ResetPasswordNew from "./components/ResetPasswordNew";

const NewRegistrationComponent = () => {
    return (
        <>
            <NewRegistrationLayout>
                <Routes>
                    <Route path="/signup" element={<NewSignUp />} />
                    <Route path="/signup-mail-sent" element={<SignUpMailSentNew />} />
                    <Route path="/forgot-password" element={<ForgotPasswordNew />} />
                    <Route path="/reset-password" element={<ResetPasswordNew />} />
                </Routes>
            </NewRegistrationLayout>
        </>
    );
};
export default NewRegistrationComponent;
