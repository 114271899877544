import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles";
import { Box, OutlinedInput, Tooltip, Typography } from "@mui/material";
import theme from "../../assets/material-ui/theme";
const useStyles = makeStyles((theme) => ({
    menuItem: {
        borderTop: "1px solid grey",
        paddingLeft: '3px'
    },
    formControl: {
        "& label.Mui-focused": {
            color: "grey",
        },
    },
    menuList: {
        padding: 0,
    },
    checkbox: {
        "& .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
        },
        "& .Mui-checked": {
            color: "#ffffff",
            backgroundColor: "transparent",
        },
    },
}));
export const RenderMultiSelect = ({
    options =[],
    disabled,
    placeholder,
    square,
    fullWidth,
    form,
    input,
    label,
    children,
    meta,
    showcount,
    selectTitle,
    customHeight,
    placeholderFontSize,
    marginSize,
    isProfile,
    customClasses,
    ...props

}) => {

    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [expanded, setExpanded] = useState(false)
    const [maxSelectionTooltipOpen, setMaxSelectionTooltipOpen] = useState(false);
    const isAllSelected = options.length > 0 && selected.length === options.length;

    const handleChange = (event) => {
        const value = event.target.value;
        if (value.length < 1) {
            input.onChange("")
            setSelected("");
            return;
        }
        if (props.maxSelections && value.length > props.maxSelections) {
            setMaxSelectionTooltipOpen(true);
            return;
        } else {
            setMaxSelectionTooltipOpen(false);
        }
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === options.length ? [] : options);
            input.onChange(selected.length === options.length ? [] : options);
            return;
        }
        input.onChange(value)
        setSelected(value);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            'data-expanded': expanded ? 'true' : 'false'
        },
        style: {
            maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
            width: 240,
        },
    };
    return (
        <FormControl fullWidth={fullWidth} classes={{ root: classes.formControl }} >
            <InputLabel shrink={false} sx={{ fontSize: placeholderFontSize, marginTop: marginSize }} >{placeholder}</InputLabel>
            {/* <InputLabel shrink={false} >{!expanded && selected?.length == 0 && placeholder}</InputLabel> */}
            <Select
                multiple
                value={selected}
                // value={input.value}
                onOpen={() => setExpanded(true)}
                onClose={() => { setExpanded(false); setMaxSelectionTooltipOpen(false) }}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selectedOption) =>
                    <div >
                        <Typography sx={{ fontSize: `${placeholderFontSize} !important` }}>
                            {selectedOption.length > 0 && (showcount === true ? `${selectedOption.length} Thematic Area Selected` : (input.name === "district" ? `${selectedOption.length} District Selected` : `${selectedOption.length} ${selectTitle} Selected`))}
                        </Typography>
                    </div>
                }
                MenuProps={MenuProps}
                inputProps={{
                    'aria-label': 'Without label',
                    name: input.name,
                    value: input.value || [],
                }}
                disabled={disabled}
                sx={{
                    height: customHeight ? customHeight : 50,
                    borderRadius: square ? '5px' : 40,
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: square ? theme.palette.primary.dark : theme.palette.success.dark, 
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: square ?  theme.palette.primary.dark : theme.palette.primary.main, 
                    }
                }}
                className={customClasses?.select}
            >
                {/* <MenuItem
                    value="all"
                    classes={{
                        root: isAllSelected ? "selectedAll" : ""
                    }}
                >
                    <ListItemIcon className={classes.checkbox}>
                        <Checkbox
                            checked={isAllSelected}
                            className={classes.checkbox}
                            indeterminate={
                                selected.length > 0 && selected.length < options.length
                            }
                           
                        />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                </MenuItem> */}

                {/* {options?.map((option) => ( */}
                {options && Array.isArray(options) && options.map((option) =>(
                    isProfile ? (<MenuItem key={option.id} value={input.value === "district" ? option.id : option.id} classes={{ root: classes.menuItem }} className={classes.checkbox} style={{ borderTop: "1px solid grey" }} >
                        <ListItemIcon >
                            <Checkbox checked={input.value.indexOf(input.value === "district" ? option.id : option.id) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option.value} primaryTypographyProps={{ sx: { whiteSpace: 'break-spaces', fontSize: `${placeholderFontSize} !important` } }} />
                    </MenuItem>) :
                    (<MenuItem key={option.id} value={input.name === "district" ? option.id : option.id} classes={{ root: classes.menuItem }} className={classes.checkbox} style={{ borderTop: "1px solid grey" }} >
                        <ListItemIcon >
                            <Checkbox checked={input.value.indexOf(input.name === "district" ? option.id : option.id) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option.name || option.value} primaryTypographyProps={{ sx: { whiteSpace: 'break-spaces', fontSize: `${placeholderFontSize} !important` } }} />
                    </MenuItem>)
                ))}
            </Select>
            {meta.touched &&
                (meta.error && (
                    <Box className='error'>
                        {meta.error}
                    </Box>
                ))}
            <Tooltip
                title="Maximum selections reached"
                placement="top"
                // arrow
                open={maxSelectionTooltipOpen}
            >
            </Tooltip>
        </FormControl>
    );
};

RenderMultiSelect.propTypes = {
    form: PropTypes.string,
    input: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    meta: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    maxSelections: PropTypes.number,
    placeholderFontSize: PropTypes.string,
    marginSize: PropTypes.string,
    customClasses: PropTypes.object,
};
