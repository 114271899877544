import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Radio, RadioGroup, FormControlLabel, Grid, Tooltip } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import cancel from "../../../../assets/icons/Cancel.svg"
import { useDispatch } from "react-redux";
import { resetSelectedActivity, setCreatePost, setDraftPost, setPostAudience, setSelectedActivity, setSelectedThematic, setShowPostThematic, setUploadedFiles } from "../../../../store/slices/SocialMediaSlice";
import arrow from "../../../../assets/icons/arrow-back.svg"
import tick from '../../../../assets/icons/charm_tick.svg';
import CommonButton2 from "../../../../common/components/CommonButton2";
import Search from "./PostSubComponents/Search";
import Post from "../../../../assets/icons/Post_send.svg"
import { useSelector } from "react-redux";
import { createPost, deletePost } from "../../actions";
import { reset } from "redux-form";
import DiscardPost from "../Popup/DiscardPost";
import { useNavigate } from "react-router";

const PostCategory = ({ form, onPostSelect, setSelectedActivity, master, socialMedia }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const draft = socialMedia?.draftPost
    const [selectedThematicAreas, setSelectedThematicAreas] = useState([]);
    const [thematicSelections, setThematicSelections] = useState(draft?.post_thematic_area_ids);
    const [postDiscardOpen, setPostDiscardOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const maxSelections = 7;

    const handleSearchChange = (search) => {
        setSearchQuery(search);
    };
    const handleProfileDialogOpen = () => {
        setPostDiscardOpen(true);
    };
    const handleCreateCancel = () => {
        dispatch(setShowPostThematic(false))
        dispatch(setCreatePost(false))
        dispatch(reset('CreatePostform'))
        dispatch(setUploadedFiles([]));
        // dispatch(setSelectedActivity(""));
        dispatch(resetSelectedActivity());
        dispatch(setSelectedThematic([]));
        handleProfileDialogClose();
        if (draft.gid) {
            dispatch(deletePost(draft.gid));
        }
        setSelectedActivity('')
        dispatch(setDraftPost([]));
    };
    //console.log("delhi",master.pendingCall)
    useEffect(() => {
        const timer = setTimeout(() => {
            if (draft && draft.draft_post) {
                setThematicSelections(draft?.post_thematic_area_ids);
                // dispatch(setSelectedThematic(draft?.post_thematic_area_ids)); 
                // console.log("Thematic Selections Length:", thematicSelections.length);
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [draft]);
    // console.log("df",thematicSelections)
    const handleProfileDialogClose = () => {
        setPostDiscardOpen(false);
    };
    console.log("yyy", draft?.post_thematic_area_ids)
    useEffect(() => {

        setThematicSelections(draft?.post_thematic_area_ids || socialMedia?.selectedThematic);
        if (draft?.post_thematic_area_ids) {
            dispatch(setSelectedThematic(draft?.post_thematic_area_ids))
        }
    }, []);
    const handleBackClick = () => {
        dispatch(setShowPostThematic(false))
        dispatch(setSelectedThematic(thematicSelections));
    }
    const publish = 'true';
    const handlePostClick = () => {
        dispatch(setSelectedThematic(thematicSelections));
        dispatch(createPost(publish,navigate));
        // dispatch(setShowPostThematic(false));
        dispatch(setDraftPost([]));
        setSelectedActivity("");
    }
    // const handleThematicSelection = (thematicId) => {
    //     const updatedSelections = thematicSelections?.includes(thematicId)
    //         ? thematicSelections.filter((id) => id !== thematicId)
    //         : [...thematicSelections, thematicId];
    //     setThematicSelections(updatedSelections);
    //     dispatch(setSelectedThematic(updatedSelections));
    // };
    const handleThematicSelection = (thematicId) => {
        const updatedSelections = thematicSelections.includes(thematicId)
            ? thematicSelections.filter((id) => id !== thematicId)
            : thematicSelections.length < maxSelections
                ? [...thematicSelections, thematicId]
                : thematicSelections;

        setThematicSelections(updatedSelections);
        dispatch(setSelectedThematic(updatedSelections));
    };
    const nonPreselectedThematicAreas = master.thematicAreas.filter(thematic => !selectedThematicAreas.includes(thematic.id));
    const filteredNonPreselectedThematicAreas = nonPreselectedThematicAreas.filter(thematic => thematic.value?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);
    return (
        <>
            <Box ref={topRef} className={SocialMediaCSS.postCard}>
                <Box className={SocialMediaCSS.activityHeader}>
                    <Box className={`${SocialMediaCSS.postGap}`}>
                        <img src={arrow} onClick={handleBackClick} />
                        <Typography className={SocialMediaCSS.selectActivity} >
                            Select post category
                        </Typography>
                    </Box>
                    <Box className={SocialMediaCSS.cancelIcon} onClick={() => handleProfileDialogOpen()} >
                        <img src={cancel} className={SocialMediaCSS.createPost} />
                    </Box>
                </Box>
                <Search onSearch={handleSearchChange} />
                <Grid container spacing={2} className={`${SocialMediaCSS.PostThematic} ${SocialMediaCSS.scrollable}`}>
                    <Grid item xs={12} className={SocialMediaCSS.PostThematic}>
                        {filteredNonPreselectedThematicAreas.map((thematic) => (
                            <Tooltip
                                key={thematic.id}
                                title={thematicSelections?.length >= maxSelections && !thematicSelections.includes(thematic?.id) ? 'Max selection reached' : ''}
                                placement="top"
                                arrow
                            >
                                <Box
                                    key={thematic.id}
                                    className={SocialMediaCSS.PostThematicBox}
                                    sx={{
                                        color: thematicSelections?.includes(thematic.id) ? '#fff' : '#333',
                                        fontWeight: thematicSelections?.includes(thematic.id) ? 500 : 400,
                                        borderRadius: '30px',
                                        border: thematicSelections?.includes(thematic.id) ? 'none' : '1px solid var(--BG-Secondary, #0DC366)',
                                        background: thematicSelections?.includes(thematic.id)
                                            ? 'var(--BG-Secondary, linear-gradient(180deg, #0DC366 0%, #00A651 100%))'
                                            : 'var(--Primary-10, #EAF8FF)',
                                    }}
                                    onClick={() => handleThematicSelection(thematic.id)}
                                >
                                    <Typography className={SocialMediaCSS.PostThematicTypo}>
                                        {thematic?.value}
                                    </Typography>
                                    {thematicSelections?.includes(thematic.id) && (
                                        <Box className={SocialMediaCSS.PostTickBox}>
                                            <img src={tick} alt="tick" />
                                        </Box>
                                    )}
                                </Box>
                            </Tooltip>
                        ))}
                    </Grid>
                </Grid>
                <Box className={`${SocialMediaCSS.nextPost} ${SocialMediaCSS.margin}`} >
                    <CommonButton2
                        label="| Post"
                        name="Post"
                        variant="contained"
                        startIcon={<img src={Post} />}
                        type="button"
                        onClick={handlePostClick}

                        style={{
                            padding: '6px 24px',
                            fontSize: '14px',
                            height: '32px',
                            fontWeight: '600',
                            background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%',
                            color: "#FFF",
                            boxShadow: 'none',
                            // opacity: socialMedia?.selectedThematic?.length === 0 ? 0.5 : 1
                            opacity: thematicSelections?.length === 0 || master.pendingCall ? 0.5 : 1

                        }}
                        // disabled={socialMedia?.selectedThematic?.length === 0}
                        disabled={thematicSelections?.length === 0 || master.pendingCall}
                        loading={master.pendingCall}
                    />
                </Box>
            </Box>
            {postDiscardOpen &&
                <DiscardPost
                    open={postDiscardOpen}
                    onClose={handleProfileDialogClose}
                    ButtonText1="Save as Draft"
                    ButtonText2="Discard"
                    title="Save as Draft"
                    Content1="Do you want to save this post as Draft?"
                    Content2="The content of this post will be here whenever you come back."
                    handleDiscard={handleCreateCancel}
                />}
        </>
    )
}

export default PostCategory;