import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CommonButton2 from "./CommonButton2";
import { useLocation, useNavigate } from "react-router";

const Contact = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path;
    };
    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Grid container xs={12} sx={{ padding: '30px 50px 50px 50px', display: 'flex', justifyContent: 'center' }}>
                <Paper elevation={7} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12} sx={{ padding: '30px 50px 50px 50px' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', }}><b>Contact us</b></Typography>

                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: "20px" }}><b>3RD FLOOR, UNIT NO 315A D-21, Corporate Park,</b></Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: "20px" }}><b>DWARKA, Sector 21 Dwarka, New Delhi, South West Delhi,</b></Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: "20px" }}><b>Delhi, 110077</b></Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: "20px" }}><b>Email: <a href="mailto:info@bvokal.com" style={{ textDecoration: 'underline', }}>
                            info@bvokal.com
                        </a></b></Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', paddingTop: "20px" }}><b>Customer Support Number: 9999880378</b></Typography>

                        <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                            <Grid item xs={12} sm={12} md={1.5} gap={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CommonButton2
                                    label="Go Back"
                                    name="Go Back"
                                    type="button"
                                    color="primary"
                                    variant='contained'
                                    // onClick={() => navigate('/login')}
                                    onClick={() => window.history.back()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default Contact;