// LeftChatSideBar.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, List, MenuItem, Select, InputBase } from '@mui/material';
import { UserCard, UserProfile } from './ChatScreen';
import Search from '../../../../assets/icons/searchThin.svg';
import { getConnectionList } from '../../actions';
import ChatConnectionsSkeletion from './ChatConnectionsSkeletion';

const contacts = [
  { name: 'John Doe', online: true },
  { name: 'Jane Smith', online: false },
  { name: 'Alice Johnson', online: true },
  // Add more contacts as needed
];

const LeftChatSideBar = ({ onSelectContact, SocialMediaCSS, onChangeStatus, userStatus, connectionTypes, dispatch, socialMedia, userData, postTypes, setBrandData, brandData,setActiveChatChannel }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [connectionListValue, setConnectionValue] = useState()
    const [open, setOpen] = useState(false);

    const searchInputRef = useRef(null);

    const filteredContacts = contacts.filter(contact =>
        contact.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleListChange = (e) => {
        let type = e.target.value;
        setSelectedType(type);
        setSearchTerm('');  // Reset the search term
        if (searchInputRef.current) {
            searchInputRef.current.value = '';  // Clear the search input value
        }
        dispatch(getConnectionList(type));
    }
    const handleSearch = (e) => {
        
        let name = e.target.value;
        setSearchTerm(name);
        let updatedVal = [];
        socialMedia?.connectionList.map((data)=>{
            if(data?.name?.indexOf(name) > -1 || data?.email?.indexOf(name) > -1){
                updatedVal.push(data);
            }
        })
        setConnectionValue(updatedVal)
    }
    useEffect(() => {
        dispatch(getConnectionList());
    }, [dispatch])

    useEffect(() => {
        setConnectionValue(socialMedia?.connectionList);
    }, [socialMedia?.connectionList])

    return (
        <Box className={SocialMediaCSS.chatLeftSide}>
            <Box className={SocialMediaCSS.headerBox}>
                <UserProfile
                    src={userData?.profile_pic}
                    name={userData?.name}
                    subtitle={userData?.profile_type}
                    status={userStatus}
                    onChangeStatus={onChangeStatus}
                    ownwer={true}
                    initials={userData?.initials}
                    color_code={userData?.color_code}
                    gid={userData?.gid}
                />
                <Select defaultValue="My Connections" variant="outlined" fullWidth sx={{
                    bgcolor: 'background.default',
                    '.MuiOutlinedInput-input': {
                        padding: 0,
                    },
                    '.MuiSelect-select': {
                        padding: '16px',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent', // Optional: if you want to remove the border as well
                    }
                    }} className={SocialMediaCSS.selectLeftChat} onChange={(e) => handleListChange(e)}
                >
                <MenuItem value="My Connections">My Connections</MenuItem>
                    {connectionTypes?.map((type) =>
                        <MenuItem value={type.key} key={type.key}>{type.value}</MenuItem>
                    )}
                </Select>
            </Box>
            <List sx={{ flexGrow:'1', overflow: 'auto', scrollbarWidth: 'thin'}}>
                {
                    !socialMedia.isConnectionListLoading ? (
                        connectionListValue && connectionListValue.map((list, index) =>
                    <UserCard
                      list={list}
                      key={index}
                      dispatch={dispatch}
                      setBrandData={setBrandData}
                      brandData={brandData}
                    />)) : <ChatConnectionsSkeletion />
                }
            </List>
            <Box>
                <InputBase
                    placeholder="Search Connection"
                    onChange={(e) => handleSearch(e)}
                    inputRef={searchInputRef}
                    fullWidth
                    endAdornment={<img src={Search} 
                    alt="" style={{ mr: 1 }} />}
                    sx={{ bgcolor: 'background.default', borderRadius: '50rem', pl: 2, pr: 1, py: 1, border: "1px solid #dee2e6", 
                    transition: 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s'}}
                />
            </Box>
        </Box>
    );
};

export default LeftChatSideBar;
