import React, { useEffect } from 'react'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import CustomSnackbar from '../../../common/components/CommonSnackbar'
import { useSelector } from 'react-redux'
import theme from "../../../assets/material-ui/theme";
import { useLocation, useNavigate } from 'react-router'
import { LightFooterNew } from '../../../common/components/LightFooterNew'
import MainCarousel from '../components/MainCarousel'
import RegCSS from './RegistrationCSS/RegCSS.module.css';

const NewRegistrationLayout = (props) =>
{
    const snackbarProps = useSelector((state) => state.message);
    const users = useSelector((state) => state.users);
    const { leftComponent } = props
    const navigate = useNavigate();
    const location = useLocation();
    const isLogin = location.pathname === '/login';
    const isDemo = location.pathname === '/demo';
    const isForgot = location.pathname === '/forgot-password';
    const isAllSet = location.pathname === '/password-changed-success';
    const isSetPass = location.pathname === '/set-account-password';
    const isMailSent = location.pathname === '/signup-mail-sent';
    const isSignup = location.pathname === '/signup';
    const isReset = location.pathname.startsWith('/reset-password');
    const isLocalhost = window.location.href === 'http://localhost:3000';
    const isMobile = useMediaQuery('(max-width: 960px)');
    useEffect(() => {
        if (users.isLoggedIn) {
            // navigate("/user-dashboard");
        }
    }, [])
    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Grid container rowSpacing={3} className={RegCSS.newRegLayoutRoot} sx={{ paddingBottom:{xs:'120px', sm:'48px', md:'0px'}}}>
                <Grid item xs={12} md={5} lg={6} className={RegCSS.newRegLayoutGrid}>
                    <Typography className={RegCSS.newRegLayoutTypo}>Empowering impact,<br />connecting communties</Typography>
                    <MainCarousel />
                </Grid>
                <Grid item xs={12} md={7} lg={6} className={RegCSS.newRegLayoutGrid2} sx={{ justifyContent: {xs: 'center', md: 'start'}, }} >
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Box sx={{maxWidth:'550px'}}>
                            <Box className={RegCSS.newRegLayoutBox} >
                                {props.children}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Box className={RegCSS.newRegLayoutGrid3}>
                <LightFooterNew />
            </Box>
        </>
    )
}

export default NewRegistrationLayout

