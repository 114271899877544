import { reset } from "redux-form";
import _ from "lodash-es";
import {
  setBeneficiariesData,
  setCoverLetterData,
  setExpectedImpactData,
  setGlimpse,
  setGoalsData,
  setMileageData,
  setProposalBriefData,
  setSuggestedByData,
  setSustainabilityData,
  setTable,
  setTextEditor,
  changeSideArr,
  addProposalDistrict,
  setCatchmentFlag,
  updateCatchmentAreas,
  addCatchmentAreas,
  updateSdgGuideLines,
  removeTab,
  addProposalUploadedData,
  updateTableData,
  updateEditorData,
  setSideArr,
  setDashboardList,
  setDashboardTab,
  setUniqueId,
  setProposalResponse,
  setProfileData,
  setProposalResponseFlag,
  setIsResponded,
  setIsRead,
  setInsightData,
  setUploadTab,
  setMainArr,
  setChatBrandList,
  setChatMessages,
  setIs_message_send,
  setRfpId,
  setRfp_unq_id,
  setProposalUserId,
  setIs_message_received,
  setSentInsight,
  setSentTotalCount,
  setAssigneeList,
  setAssigneeComments,
  setProfileList,
  setAdminProfile,
  setUserAssigned,
} from "../../store/slices/ProposalSlice";
import {
  addBeneficiaryTypes,
  addSdgGuideLines,
  setIsLoad,
  addSchedule7,
} from "../../store/slices/MasterSlice";
import {
  BENEFICIARY_TYPES,
  SDGGUIDELINESMAPPING,
  SDGGUIDELINES_URL,
  ADD_COVER_LETTER,
  ADD_TABLE,
  UPDATE_TABLE,
  DELETE_SECTION,
  UPLOAD_PROPOSAL,
  DELETE_PROPOSAL_FILE,
  SUGGESTED_BY,
  UPDATE_PROPOSAL,
  ADD_SIDEBAR,
  SEND_PROPOSAL,
  PROPOSAL_LIST,
  PROPOSAL_PREVIEW,
  PROPOSAL_RESPONSE,
  PROPOSAL_RESPONSE_LIST,
  MARK_FAVOURITE,
  MARK_READ,
  PROPOSAL_INSIGHTS,
  DELETE_PROPOSAL,
  GET_PROPOSAL_MENU,
  SCHEDULE7,
  SEND_MESSAGES,
  CHAT_BRAND_LIST,
  FETCH_CHAT_MSG,
  DELETE_TABLE,
  PROPOSAL_SENT_INSIGHTS,
  FETCH_ASSIGNEE_LIST,
  ASSIGN_USER,
  GET_UNASSIGN_USER_COMMENTS,
  UNASSIGN_USER,
  ASSIGN_TO_ME,
  COMPLETE_PROFILE,
} from "../../http/apiUrls";
import { ADD_SECTION, CREATE_PROPOSAL } from "../../http/apiUrls";
import { setSnackbar } from "../../store/slices/MessageSlice";
import {
  fetchAPI,
  getMasterData,
  getCompanyId,
  getNotificationCount,
  getOutBoxData,
  getProfileDataForPreview,
  resetAllForms,
  resetSingleForm,
  getAllState,
  getAllDistrict,
  getThematicAreas,
  getModuleType,
} from "../../common/actions";
import {
  httpMethod,
  COMMON_MSG,
  PROPOSAL_SECTIONS,
  STATUS_CODE,
  DASHBOARD_SECTIONS,
  PAGE_LIMIT,
} from "../../common/constants";
import {
  encodeHTMLEntities,
  decodeHTMLEntities,
  editorTextLength,
} from "../../common/utils";
import { getCompanyType } from "../Registration/actions";
import { useNavigate } from "react-router";
import { getAllProfile, getAnnexureList, getUserProfile } from "../Profile/actions";

export const getMasterInfo = () => (dispatch, getState) => {
  const { users: { userData: { gid } } } = getState();
  dispatch(getAllState());
  dispatch(getSchedule7());
  dispatch(getAllDistrict());
  dispatch(getAnnexureList());
  dispatch(getThematicAreas());
  dispatch(getCompanyType());
  dispatch(getModuleType());
  dispatch(getUserProfile(gid));
  // dispatch(getAllProfile(gid));
  dispatch(getSdgGuideLines());
};

export const getSchedule7 = () => (dispatch) => {
  dispatch(fetchAPI(SCHEDULE7, httpMethod.GET)).then(
    response => {
      if (response.success) {
        dispatch(addSchedule7(response?.data?.schedule_seven));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
      }
    },
  );
}

export const fetchSdgGuidelines = (id) => (dispatch, getState) => {
  dispatch(fetchAPI(`${SDGGUIDELINESMAPPING}/${id}`, httpMethod.GET)).then(
    (response) => {
      if (response.success) {
        const { proposal: { sdgGuidelines } } = getState();
        const updatedData = [...sdgGuidelines, ...response.data];
        // dispatch(addSdgGuideLinesMapping(updatedData));
        dispatch(updateSdgGuideLine(updatedData));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const removeSvgByParentId = (id) => (dispatch, getState) => {
  dispatch(fetchAPI(`${SDGGUIDELINESMAPPING}/${id}`, httpMethod.GET)).then(
    (response) => {
      if (response.success) {
        const { proposal: { sdgGuidelines } } = getState();
        const updatedGuidlines = [...response.data];
        const updatedMapping = sdgGuidelines.filter(item => !updatedGuidlines.includes(item));
        console.log(updatedMapping, "mapping")
        // dispatch(addSdgGuideLinesMapping(updatedMapping));
        dispatch(updateSdgGuideLine(updatedMapping));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const getProposalUniqueId = () => (dispatch, getState) => {
  const {
    proposal: { uniqueId },
  } = getState();
  return uniqueId;
};

export const submitGlimpse = (values, navigate) => (dispatch, getState) => {
  const {
    proposal: { catchmentAreas, sdgGuidelines, uploadTab, uniqueId, rfpId },
  } = getState();
  if (catchmentAreas.length < 1) {
    // for null catchement Area
    dispatch(
      setSnackbar({
        flag: true,
        message: COMMON_MSG.CATCHMENT_AREA_ERROR,
        type: "error",
      })
    );
    return;
  }
  let apiURL = CREATE_PROPOSAL;
  delete values.uniqueId;
  if (uniqueId) {
    delete values.sdgGuidelines;
    delete values.catchmentArea;
    delete values.status_code;
    apiURL = UPDATE_PROPOSAL;
  }
  let dataToSend = {
    ...values,
    sdgGuidelines: sdgGuidelines,
    catchmentArea: catchmentAreas,
    upload_proposal: uploadTab ? "Y" : "N",
    uniqueId,
    rfpId,
  };
  dispatch(fetchAPI(apiURL, httpMethod.POST, dataToSend)).then((response) => {
    if (response.success) {
      dispatch(setGlimpse(response.data));
      dispatch(setUniqueId(response.data.uniqueId));
      dispatch(formSubmittedSuccessfully());
      dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
      navigate(`/proposal/new/${response.data.uniqueId}`);
      // dispatch(reset('glimpseForm'));
      // dispatch(setRfpId(null))
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const submitTable = (values, selectedTab) => (dispatch, getState) => {
  const {
    proposal: { tableData },
  } = getState();
  let updatedData = tableData.filter(
    (data) => data.sideBarId === selectedTab.sideBarId
  );
  let dataToPost = {
    tableName: values.tableName,
    uniqueId: dispatch(getProposalUniqueId()),
    tableData: JSON.stringify(updatedData[0]),
  };
  let apiURL = ADD_TABLE;
  if (!_.isEmpty(updatedData) && updatedData[0].id) {
    // for update case
    apiURL = `${UPDATE_TABLE}/${updatedData[0].id}`
  }
  dispatch(fetchAPI(apiURL, httpMethod.POST, dataToPost)).then((response) => {
    if (response.success) {
      let id = response.data.id;
      dispatch(setTable({ ...values, ...selectedTab, id }));
      dispatch(changeSideArr({ ...values, ...selectedTab }));
      dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
      dispatch(formSubmittedSuccessfully());
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const submitTextEditor =
  (values, selectedTab) => (dispatch, getState) => {
    if (_.isEmpty(values.textName)) {
      dispatch(
        setSnackbar({
          flag: true,
          message: "Please enter editor name",
          type: "error",
        })
      );
      return;
    }
    if (editorTextLength(values.textData)) {
      dispatch(
        setSnackbar({
          flag: true,
          message: COMMON_MSG.TEXT_EDITOR_LENGTH,
          type: "error",
        })
      );
      return;
    }
    const {
      proposal: { textEditorData },
    } = getState();
    let updatedData = textEditorData.filter(
      (data) => data.sideBarId === selectedTab.sideBarId
    );
    let dataToPost = {
      uniqueId: dispatch(getProposalUniqueId()),
      textName: values.textName,
      textData: encodeHTMLEntities(values.textData), //JSON.stringify({ html: dataToSave }),
      isCustom: true,
      sideBarId: selectedTab.sideBarId,
    };
    if (!_.isEmpty(updatedData) && updatedData[0].id) {
      // for update case
      dataToPost = {
        ...dataToPost,
        id: updatedData[0].id,
      };
    }
    dispatch(fetchAPI(ADD_SECTION, httpMethod.POST, dataToPost)).then(
      (response) => {
        if (response.success) {
          let id = response.data.id;
          dispatch(setTextEditor({ ...values, ...selectedTab, id }));
          dispatch(changeSideArr({ ...values, ...selectedTab }));
          dispatch(formSubmittedSuccessfully());
          dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const submitProposalBrief =
  (values, sectionName) => (dispatch, getState) => {
    if (editorTextLength(values.textData)) {
      dispatch(
        setSnackbar({
          flag: true,
          message: COMMON_MSG.TEXT_EDITOR_LENGTH,
          type: "error",
        })
      );
      return;
    }
    const {
      proposal: { propsalBriefData },
    } = getState();
    let dataToSend = {
      uniqueId: dispatch(getProposalUniqueId()),
      textName: sectionName,
      textData: encodeHTMLEntities(values.textData),
    };
    if (!_.isEmpty(propsalBriefData) && propsalBriefData.id) {
      dataToSend = {
        ...dataToSend,
        id: propsalBriefData.id,
      };
    }
    dispatch(fetchAPI(ADD_SECTION, httpMethod.POST, dataToSend)).then(
      (response) => {
        if (response.success) {
          let updatedData = {
            ...response.data,
            textData: decodeHTMLEntities(response.data.textData),
          };
          dispatch(setProposalBriefData(updatedData));
          dispatch(formSubmittedSuccessfully());
          dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
          // dispatch(reset('proposalBriefForm'));
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const submitBeneficiaries =
  (values, sectionName) => (dispatch, getState) => {
    if (editorTextLength(values.textData)) {
      dispatch(
        setSnackbar({
          flag: true,
          message: COMMON_MSG.TEXT_EDITOR_LENGTH,
          type: "error",
        })
      );
      return;
    }
    const {
      proposal: { beneficiariesData },
    } = getState();
    let dataToSend = {
      uniqueId: dispatch(getProposalUniqueId()),
      textName: sectionName,
      textData: encodeHTMLEntities(values.textData),
    };
    if (!_.isEmpty(beneficiariesData) && beneficiariesData.id) {
      dataToSend = {
        ...dataToSend,
        id: beneficiariesData.id,
      };
    }
    dispatch(fetchAPI(ADD_SECTION, httpMethod.POST, dataToSend)).then(
      (response) => {
        if (response.success) {
          let updatedData = {
            ...response.data,
            textData: decodeHTMLEntities(response.data.textData),
          };
          dispatch(setBeneficiariesData(updatedData));
          dispatch(formSubmittedSuccessfully());
          dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const submitGoals = (values, sectionName) => (dispatch, getState) => {
  if (editorTextLength(values.textData)) {
    dispatch(
      setSnackbar({
        flag: true,
        message: COMMON_MSG.TEXT_EDITOR_LENGTH,
        type: "error",
      })
    );
    return;
  }
  const {
    proposal: { goalsData },
  } = getState();
  let dataToSend = {
    uniqueId: dispatch(getProposalUniqueId()),
    textName: sectionName,
    textData: encodeHTMLEntities(values.textData),
  };
  if (!_.isEmpty(goalsData) && goalsData.id) {
    dataToSend = {
      ...dataToSend,
      id: goalsData.id,
    };
  }
  dispatch(fetchAPI(ADD_SECTION, httpMethod.POST, dataToSend)).then(
    (response) => {
      if (response.success) {
        let updatedData = {
          ...response.data,
          textData: decodeHTMLEntities(response.data.textData),
        };
        dispatch(setGoalsData(updatedData));
        dispatch(formSubmittedSuccessfully());
        dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const submitSustainability =
  (values, sectionName) => (dispatch, getState) => {
    if (editorTextLength(values.textData)) {
      dispatch(
        setSnackbar({
          flag: true,
          message: COMMON_MSG.TEXT_EDITOR_LENGTH,
          type: "error",
        })
      );
      return;
    }
    const {
      proposal: { sustainabilityData },
    } = getState();
    let dataToSend = {
      uniqueId: dispatch(getProposalUniqueId()),
      textName: sectionName,
      textData: encodeHTMLEntities(values.textData),
    };
    if (!_.isEmpty(sustainabilityData) && sustainabilityData.id) {
      dataToSend = {
        ...dataToSend,
        id: sustainabilityData.id,
      };
    }
    dispatch(fetchAPI(ADD_SECTION, httpMethod.POST, dataToSend)).then(
      (response) => {
        if (response.success) {
          let updatedData = {
            ...response.data,
            textData: decodeHTMLEntities(response.data.textData),
          };
          dispatch(setSustainabilityData(updatedData));
          dispatch(formSubmittedSuccessfully());
          dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const submitMileage = (values, sectionName) => (dispatch, getState) => {
  if (editorTextLength(values.textData)) {
    dispatch(
      setSnackbar({
        flag: true,
        message: COMMON_MSG.TEXT_EDITOR_LENGTH,
        type: "error",
      })
    );
    return;
  }
  const {
    proposal: { mileageData },
  } = getState();
  let dataToSend = {
    uniqueId: dispatch(getProposalUniqueId()),
    textName: sectionName,
    textData: encodeHTMLEntities(values.textData),
  };
  if (!_.isEmpty(mileageData) && mileageData.id) {
    dataToSend = {
      ...dataToSend,
      id: mileageData.id,
    };
  }
  dispatch(fetchAPI(ADD_SECTION, httpMethod.POST, dataToSend)).then(
    (response) => {
      if (response.success) {
        let updatedData = {
          ...response.data,
          textData: decodeHTMLEntities(response.data.textData),
        };
        dispatch(setMileageData(updatedData));
        dispatch(formSubmittedSuccessfully());
        dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const submitExpectedImpact =
  (values, sectionName) => (dispatch, getState) => {
    if (editorTextLength(values.textData)) {
      dispatch(
        setSnackbar({
          flag: true,
          message: COMMON_MSG.TEXT_EDITOR_LENGTH,
          type: "error",
        })
      );
      return;
    }
    const {
      proposal: { expectedImpactData },
    } = getState();
    let dataToSend = {
      uniqueId: dispatch(getProposalUniqueId()),
      textName: sectionName,
      textData: encodeHTMLEntities(values.textData),
    };
    if (!_.isEmpty(expectedImpactData) && expectedImpactData.id) {
      dataToSend = {
        ...dataToSend,
        id: expectedImpactData.id,
      };
    }
    dispatch(fetchAPI(ADD_SECTION, httpMethod.POST, dataToSend)).then(
      (response) => {
        if (response.success) {
          let updatedData = {
            ...response.data,
            textData: decodeHTMLEntities(response.data.textData),
          };
          dispatch(setExpectedImpactData(updatedData));
          dispatch(formSubmittedSuccessfully());
          dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const submitSuggestedBy = (values, isDelete) => (dispatch, getState) => {
  const { proposal } = getState();
  if (isDelete && (!proposal.suggestedbyData || !proposal.suggestedbyData.fullname)) { // remove side tab when there is no data
    dispatch(removeTab());
    dispatch(addSideBar());
    return;
  }
  let dataToPost = {
    fullname: values.fullname,
    uniqueId: dispatch(getProposalUniqueId()),
  };
  dispatch(fetchAPI(SUGGESTED_BY, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        if (isDelete) {
          dispatch(removeTab());
          dispatch(addSideBar());
          dispatch(setSuggestedByData([]));
        } else {
          dispatch(setSuggestedByData(response.data));
          dispatch(formSubmittedSuccessfully());
        }
        dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const submitCoverLetter = (values, isDelete) => (dispatch, getState) => {
  const { proposal } = getState();
  if (isDelete && (!proposal.coverLetterData || !proposal.coverLetterData.coverLetterSub)) { // remove side tab when there is no data
    dispatch(removeTab());
    dispatch(addSideBar());
    return;
  }
  let dataToSend = {
    uniqueId: dispatch(getProposalUniqueId()),
    coverLetterSub: values.coverLetterSub,
    coverLetterDesc: values.coverLetterDesc,
  };
  dispatch(fetchAPI(ADD_COVER_LETTER, httpMethod.POST, dataToSend)).then(
    (response) => {
      if (response.success) {
        dispatch(setCoverLetterData(response.data));
        if (isDelete) {
          dispatch(removeTab());
          dispatch(addSideBar());
          dispatch(setCoverLetterData([]));
        } else {
          dispatch(setCoverLetterData(response.data));
          dispatch(formSubmittedSuccessfully());
        }
        dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const submitCatchmentAreas =
  (values, editFlag, index) => (dispatch, getState) => {
    let {
      proposal: { catchmentAreas },
    } = getState();
    dispatch(setCatchmentFlag(true));
    dispatch(reset("catchmentAreaForm"));
    if (editFlag) {
      let newArr = [...catchmentAreas];
      newArr[index] = values;
      dispatch(updateCatchmentAreas(newArr));
    } else {
      const stateExists = catchmentAreas.some(
        (area) => area.state === values.state
      );
      if (!stateExists) {
        dispatch(addCatchmentAreas(values));
      } else {
        dispatch(
          setSnackbar({
            flag: true,
            message: COMMON_MSG.SAME_STATE,
            type: "error",
          })
        );
      }
    }
    dispatch(setCatchmentFlag(false));
    dispatch(reset("catchmentAreaForm"));
  };

export const deleteCatchmentArea = (deleteIndex) => (dispatch, getState) => {
  const {
    proposal: { catchmentAreas },
  } = getState();
  const newArr = catchmentAreas.filter((data, index) => index != deleteIndex);
  dispatch(updateCatchmentAreas(newArr));
};

export const getBeneficiaryTypes = () => (dispatch) => {
  dispatch(fetchAPI(BENEFICIARY_TYPES, httpMethod.GET)).then((response) => {
    if (response.success) {
      // response.data.sort((a, b) => a.name.localeCompare(b.name));
      dispatch(addBeneficiaryTypes(response.data?.beneficiary_type));
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const getSdgGuideLines = () => (dispatch) => {
  dispatch(fetchAPI(SDGGUIDELINES_URL, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(addSdgGuideLines(response?.data?.sdg_guidelines));
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const getDistrict = (state) => (dispatch, getState) => {
  const {
    master: { district },
  } = getState();
  const newArr = district.filter((data) => data.state_id == state);
  dispatch(addProposalDistrict(newArr));
};

export const getDistrictNameByIds = (districtIds) => (dispatch, getState) => {
  const {
    master: { district },
  } = getState();
  if (districtIds.length > 1 && Array.isArray(districtIds)) {
    const districtList =
      districtIds &&
      districtIds.map((id) => {
        const filterList = district.find((data) => data.id == id);
        if (filterList) {
          return filterList.name;
        }
      });
    // return districtList.toString(' ');
    return districtList.join(', ');
  } else {
    const filterList = district.find((data) => data.id == districtIds);
    if (filterList) {
      return filterList.name;
    }
  }
};

export const getStateNameById = (stateId) => (dispatch, getState) => {
  const {
    master: { state },
  } = getState();
  const stateList = state.find((state) => state.id == stateId);
  if (stateList) return stateList.name;
};

export const updateSdgGuideLine = (values) => (dispatch) => {
  dispatch(updateSdgGuideLines(values));
};

export const deleteSections =
  (sectionName, sideBarId, type) => (dispatch, getState) => {
    const {
      proposal: { textEditorData, tableData },
    } = getState();
    let dataToSend = {
      uniqueId: dispatch(getProposalUniqueId()),
      textName: sectionName,
    };
    dispatch(fetchAPI(DELETE_SECTION, httpMethod.POST, dataToSend)).then(
      (response) => {
        if (response.success) {
          let emptyArr = new Array();
          if (sectionName === PROPOSAL_SECTIONS.BRIEF) {
            dispatch(setProposalBriefData(emptyArr));
          } else if (sectionName === PROPOSAL_SECTIONS.BENEFICIARIES) {
            dispatch(setBeneficiariesData(emptyArr));
          } else if (sectionName === PROPOSAL_SECTIONS.BENEFICIARIES) {
            dispatch(setGoalsData(emptyArr));
          } else if (sectionName === PROPOSAL_SECTIONS.GOALS) {
            dispatch(setSustainabilityData(emptyArr));
          } else if (sectionName === PROPOSAL_SECTIONS.SUSTAINIBILITY) {
            dispatch(setMileageData(emptyArr));
          } else if (sectionName === PROPOSAL_SECTIONS.EXPECTED_IMPACT) {
            dispatch(setExpectedImpactData(emptyArr));
          } else if (type === PROPOSAL_SECTIONS.TEXT_EDITOR) {
            const filteredData = textEditorData.filter(
              (item) => item.sideBarId !== sideBarId
            );
            dispatch(updateEditorData(filteredData));
          } else if (type === PROPOSAL_SECTIONS.TABLE) {
            const filteredData = tableData.filter(
              (item) => item.sideBarId !== sideBarId
            );
            dispatch(updateTableData(filteredData));
          }
          dispatch(removeTab());
          dispatch(addSideBar());
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const uploadProposal = (file, uploadInfo) => (dispatch, getState) => {
  let dataToPost = {
    filename: uploadInfo.FIELD_NAME,
    key: uploadInfo.KEY,
    file,
    uniqueId: dispatch(getProposalUniqueId()),
  };

  let formData = new FormData();
  for (const key in dataToPost) {
    formData.append(key, dataToPost[key]);
  }
  return dispatch(
    fetchAPI(UPLOAD_PROPOSAL, httpMethod.POST, formData, "", true)
  ).then((response) => {
    if (response.success) {
      const uploadedData = {
        ...response.data,
        key: uploadInfo.FIELD_NAME,
      };
      dispatch(addProposalUploadedData(uploadedData, uploadInfo.FIELD_NAME));
      return response.data;
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
      return false;
    }
  });
};

export const deleteProposalUploadedFile =
  (uploadInfo) => (dispatch, getState) => {
    let dataToPost = {
      filename: uploadInfo.FIELD_NAME,
      key: uploadInfo.KEY,
      uniqueId: dispatch(getProposalUniqueId()),
    };
    dispatch(fetchAPI(DELETE_PROPOSAL_FILE, httpMethod.POST, dataToPost)).then(
      (response) => {
        if (response.success) {
          dispatch(addProposalUploadedData({}));
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const getProposalPreview = (uniqueId) => (dispatch, getState) => {
  let apiURL = `${PROPOSAL_PREVIEW}/${uniqueId}`;
  return dispatch(fetchAPI(apiURL, httpMethod.GET)).then((response) => {
    if (response.success) {
      dispatch(setPreviewData(response.data));
      return response.data;
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const setPreviewData = (data) => (dispatch, getState) => {
  // console.log("data", data);
  const {
    custom_texteditors,
    fileHost,
    glimpseData,
    tableData,
    texteditors,
    uniqueId,
    uploadData,
    sideBarInfo,
    rfp_unq_id,
    is_message_send,
    is_message_received,
    upload_proposal,
  } = data;
  let profileData = dispatch(getProfileDataForPreview(data));
  dispatch(setIsResponded(data?.proposalRespons?.is_responded));
  dispatch(setIsRead(data?.proposalRespons?.is_read));
  dispatch(setProfileData(profileData));
  dispatch(setIs_message_send(data?.is_message_send));
  dispatch(setIs_message_received(data?.is_message_received));
  dispatch(setUniqueId(uniqueId));
  dispatch(setRfp_unq_id(data?.rfp_unq_id))
  dispatch(setProposalUserId(data?.user_id));
  dispatch(setUserAssigned(data.assigned_to_me))
  // console.log('rfp',data?.rfp_unq_id)
  if (glimpseData) {
    // glimpse
    dispatch(setGlimpse(glimpseData));
  }
  if (glimpseData && glimpseData.catchmentArea) {
    // catchment area
    if (_.isArray(glimpseData.catchmentArea)) {
      dispatch(updateCatchmentAreas(glimpseData.catchmentArea));
    } else {
      dispatch(updateCatchmentAreas(JSON.parse(glimpseData.catchmentArea)));
    }
  }

  if (glimpseData && glimpseData.coverLetterDesc) {
    // for cover leeter data
    let coverData = {
      coverLetterDesc: glimpseData.coverLetterDesc,
      coverLetterSub: glimpseData.coverLetterSub,
    };
    dispatch(setCoverLetterData(coverData));
  }

  if (uploadData) {
    // upload data
    let uploadedData = {
      filename: uploadData.proposaldoc || uploadData.recommendDocument,
      fileHost,
    };
    dispatch(addProposalUploadedData(uploadedData));
  }

  if (tableData && tableData.length > 0) {
    // table data
    let updatedTableData = tableData.map((data) => {
      let updatedData = {
        id: data.id,
        ...JSON.parse(data.tabledata),
        tableName: data.tablename,
      }

      return updatedData;
    });
    // console.log('updatedTableData', updatedTableData);
    dispatch(updateTableData(updatedTableData));
  }

  if (custom_texteditors && custom_texteditors.length > 0) {
    // text editor data
    let editorData = custom_texteditors.map((data) => {
      let returnData = decodeHTMLEntities(data.textData);
      // returnData = {
      //   ...returnData,
      //   textData: decodeHTMLEntities(returnData.textData),
      // }
      return { ...data, textData: returnData };
    });
    dispatch(updateEditorData(editorData));
  }

  if (sideBarInfo && sideBarInfo.sideBar) {
    // set sidebar
    dispatch(setSideArr(JSON.parse(sideBarInfo.sideBar)));
    // console.log(JSON.parse(sideBarInfo.sideBar), "BAR");
  }

  if (texteditors && texteditors.length > 0) {
    // sections data
    // eslint-disable-next-line array-callback-return
    texteditors.map((data) => {
      let updatedData = {
        ...data,
        textData: decodeHTMLEntities(data.textData),
      };
      dispatch(setEditorsData(updatedData));
    });
  }

  if (glimpseData && glimpseData.suggestedbyData) {
    dispatch(setSuggestedByData({ fullname: glimpseData.suggestedbyData }));
  }
  if (
    _.isArray(glimpseData.sdgGuidelines) &&
    glimpseData.sdgGuidelines.length > 0
  ) {
    dispatch(updateSdgGuideLines(glimpseData.sdgGuidelines));
  }

  if (glimpseData.upload_proposal) {
    let value = glimpseData.upload_proposal === "Y" ? true : false;
    dispatch(setUploadTab(value));
  }
};

export const setEditorsData = (data) => (dispatch, getState) => {
  switch (data.textName) {
    case PROPOSAL_SECTIONS.BRIEF:
      return dispatch(setProposalBriefData(data));
    case PROPOSAL_SECTIONS.GOALS:
      return dispatch(setGoalsData(data));
    case PROPOSAL_SECTIONS.BENEFICIARIES:
      return dispatch(setBeneficiariesData(data));
    case PROPOSAL_SECTIONS.SUSTAINIBILITY:
      return dispatch(setSustainabilityData(data));
    case PROPOSAL_SECTIONS.MILEAGE:
      return dispatch(setMileageData(data));
    case PROPOSAL_SECTIONS.EXPECTED_IMPACT:
      return dispatch(setExpectedImpactData(data));
    default:
      return {};
  }
};

export const addTableDataFromPreview = (tableData) => (dispatch, getState) => {
  let filterData = tableData.map((data) => {
    return JSON.parse(data.tableData);
  });
  dispatch(updateTableData(filterData));
};

export const addEditorDataFromPreview =
  (editorData) => (dispatch, getState) => {
    let filterData = editorData.map((data) => {
      return JSON.parse(data.textData);
    });
    dispatch(updateEditorData(filterData));
  };

export const addSideBar = () => (dispatch, getState) => {
  const {
    proposal: { sideArr },
  } = getState();
  let dataToPost = {
    uniqueId: dispatch(getProposalUniqueId()),
    sideBar: JSON.stringify(sideArr),
  };
  dispatch(fetchAPI(ADD_SIDEBAR, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const formSubmittedSuccessfully = () => (dispatch, getState) => {
  // Dispatch an action to mark the current selected sideArr item as completed
  const { sideArr } = getState().proposal;
  const currentSelectedItem = sideArr?.find((item) => item.selected);
  const updatedSideArr = sideArr?.map((item) =>
    item.sideBarId === currentSelectedItem.sideBarId
      ? { ...item, completed: true }
      : item
  );
  // console.log(updatedSideArr, "updatedsideArr");
  dispatch(setSideArr(updatedSideArr));
  dispatch(addSideBar());

  // // Find the index of the next sideArr item
  const currentIndex = sideArr.findIndex(
    (item) => item.sideBarId === currentSelectedItem.sideBarId
  );
  // const nextIndex = currentIndex + 1;
  // console.log(currentIndex, "index");

  // If there is a next sideArr item, select it
  if (currentIndex < sideArr.length) {
    // dispatch(changeSideArr(sideArr[currentIndex]));
  } else {
    // If nextIndex is equal to sideArr.length, navigate to the "Preview Proposal" section
    let updatedSideArr = sideArr.map((item) =>
      item.sideBarId === currentIndex ? { ...item, completed: true } : item
    );
    dispatch(setSideArr(updatedSideArr));
  }
};

export const sendProposal =
  (emails, extraEmails = [], navigate, uniqueId) =>
    (dispatch, getState) => {
      const { proposal: { glimpseData: { rfpId, }, rfp_unq_id } } = getState();
      // const { proposal:  { rfpId }  } =  getState();
      if (!rfpId && !rfp_unq_id && (emails.length < 1 && extraEmails.length < 1)) {
        dispatch(
          setSnackbar({
            flag: true,
            message: COMMON_MSG.PROPOSAL_SEND_EMAIL,
            type: "error",
          })
        );
        return;
      }
      let outBoxData = dispatch(getOutBoxData());
      let dataToPost = {
        ...outBoxData,
        emails,
        extraEmails,
        uniqueId: uniqueId || dispatch(getProposalUniqueId()),
      };
      dispatch(fetchAPI(SEND_PROPOSAL, httpMethod.POST, dataToPost)).then(
        (response) => {
          if (response.success) {
            navigate("/proposal/sent");
            dispatch(setRfpId(null))
            dispatch(setRfp_unq_id(null))
          } else if (response.error) {
            dispatch(
              setSnackbar({
                flag: true,
                message: response.error.errorMsg,
                type: "error",
              })
            );
          }
        }
      );
    };

export const clearProposalData = () => (dispatch, getState) => {
  const {
    form,
    proposal: { tempSideArr },
  } = getState();
  const formArr = _.keys(form);
  dispatch(setGlimpse([]));
  dispatch(setBeneficiariesData([]));
  dispatch(setCoverLetterData([]));
  dispatch(setExpectedImpactData([]));
  dispatch(setGoalsData([]));
  dispatch(setMileageData([]));
  dispatch(setProposalBriefData([]));
  dispatch(setSuggestedByData([]));
  dispatch(setSustainabilityData([]));
  dispatch(addTableDataFromPreview([]));
  dispatch(addEditorDataFromPreview([]));
  dispatch(addProposalUploadedData([]));
  dispatch(updateCatchmentAreas([]));
  dispatch(setSideArr(tempSideArr));
  dispatch(updateSdgGuideLines([]));
  dispatch(setProfileData({}));
  dispatch(setInsightData({}));
  dispatch(setUniqueId());
  dispatch(setUploadTab(false));
  dispatch(setIsResponded(false));
  dispatch(setIsRead(false));
  dispatch(setProfileData(false));
  dispatch(resetAllForms());

};

export const getProposalList =
  (section, offset = 0, PageLimit = PAGE_LIMIT) =>
    (dispatch, getState) => {
      dispatch(setIsLoad(true));
      let dataToPost = dispatch(getProposalListPayload(section));
      dataToPost = {
        ...dataToPost,
        offset,
        PageLimit,
      };
      dispatch(fetchAPI(PROPOSAL_LIST, httpMethod.POST, dataToPost)).then(
        (response) => {
          dispatch(setIsLoad(false));
          if (response.success) {
            dispatch(setDashboardTab(section));
            dispatch(setDashboardList(response.data));
            dispatch(getNotificationCount());
            // if (section !== DASHBOARD_SECTIONS.DRAFT) {
            //   dispatch(getProposalInsights("", section)); // first parameter is uniqueId incase of single proposal insights
            // }
          } else if (response.error) {
            dispatch(setDashboardList([]));
            dispatch(
              setSnackbar({
                flag: true,
                message: response.error.errorMsg,
                type: "error",
              })
            );
          }
        }
      );
    };

export const getProposalListPayload = (section) => (dispatch, getState) => {
  switch (section) {
    case DASHBOARD_SECTIONS.INBOX:
      return {
        isInbox: 'inbox',
        statusCode: STATUS_CODE.SENT.CODE,
      };
    case DASHBOARD_SECTIONS.SENT:
      return {
        isInbox: 'sent',
        statusCode: STATUS_CODE.SENT.CODE,
      };
    case DASHBOARD_SECTIONS.DRAFT:
      return {
        isInbox: 'draft',
        statusCode: STATUS_CODE.DRAFT.CODE,
      };
    default:
      return {
        isInbox: 'draft',
        statusCode: STATUS_CODE.DRAFT.CODE,
      };
  }
};

export const sendProposalResponse =
  (values, uniqueId, inbox) => (dispatch, getState) => {
    // if(!values.userRating){
    //   dispatch(setSnackbar({ flag: true, message:COMMON_MSG , type: "error" }));
    //   return;
    // }
    let dataToPost = {
      ...values,
      uniqueId,
      userRating: values.userRating || 0,
    };
    dispatch(fetchAPI(PROPOSAL_RESPONSE, httpMethod.POST, dataToPost)).then(
      (response) => {
        if (response.success) {
          dispatch(setProposalResponseFlag(true));
          dispatch(getProposalInsights(uniqueId, inbox))
          dispatch(reset("responseForm"));
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const getProposalResponse = (uniqueId, sender_id) => (dispatch, getState) => {
  // let apiURL = `${PROPOSAL_RESPONSE_LIST}/${uniqueId}`;
  const dataToSend = {
    uniqueId,
    receiverId: sender_id,
  }
  dispatch(fetchAPI(PROPOSAL_RESPONSE_LIST, httpMethod.POST, dataToSend)).then((response) => {
    if (response.success) {
      dispatch(setProposalResponse(response.data));
      dispatch(getNotificationCount());
    } else if (response.error) {
      dispatch(setProposalResponse([]));
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};

export const setProposalFavourite =
  (uniqueId, favourite, tab) => (dispatch, getState) => {
    let dataToPost = {
      uniqueId,
      favourite,
      statusType: tab,
    };
    dispatch(fetchAPI(MARK_FAVOURITE, httpMethod.POST, dataToPost)).then(
      (response) => {
        if (response.success) {
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const setProposalRead = (uniqueId, isRead) => (dispatch, getState) => {
  const { proposal:{dashboardTab} } = getState();
  let dataToPost = {
    uniqueId,
    isRead,
  };
  if (dashboardTab === 'inbox') {
    dataToPost.type = 'inbox';
  }
  dispatch(fetchAPI(MARK_READ, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(getNotificationCount());
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const getProposalInsights =
  (uniqueId, statusType) => (dispatch, getState) => {
    let dataToPost = {
      uniqueId,
      statusType,
    };
    dispatch(fetchAPI(PROPOSAL_INSIGHTS, httpMethod.POST, dataToPost)).then(
      (response) => {
        if (response.success) {
          dispatch(setInsightData(response.data));
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const getProposalSentInsights =
  (uniqueId, offset) => (dispatch, getState) => {
    let dataToPost = {
      uniqueId,
      offset,
      // statusType,
    };
    dispatch(fetchAPI(PROPOSAL_SENT_INSIGHTS, httpMethod.POST, dataToPost)).then(
      (response) => {
        if (response.success) {
          dispatch(setSentInsight([...response.data.data]));
          dispatch(setSentTotalCount(response?.data?.totalCount))
          dispatch(getNotificationCount());
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const deleteProposal =
  (uniqueId, statusType) => (dispatch, getState) => {
    let dataToPost = {
      uniqueId,
      statusType,
    };

    dispatch(fetchAPI(DELETE_PROPOSAL, httpMethod.POST, dataToPost)).then(
      (response) => {
        if (response.success) {
          dispatch(getProposalList(statusType));
          dispatch(setDashboardTab(statusType));
        } else if (response.error) {
          dispatch(
            setSnackbar({
              flag: true,
              message: response.error.errorMsg,
              type: "error",
            })
          );
        }
      }
    );
  };

export const submitUploadProposal = () => (dispatch) => {
  dispatch(setSnackbar({ flag: true, message: COMMON_MSG.DATA_CREATED, type: "success" }));
  dispatch(formSubmittedSuccessfully());
};


export const getProposalMenu = () => (dispatch, getState) => {
  return;
  dispatch(fetchAPI(GET_PROPOSAL_MENU, httpMethod.GET)).then(
    (response) => {
      if (response.success) {
        // console.log('proposal menu list -->',JSON.parse(response.data.mainArr));
        dispatch(setMainArr(JSON.parse(response.data.mainArr)));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const sendChatMessages = (uniqueId, receiverId, message) => (dispatch, getState) => {
  // const {users: {userData: {data: {company_id}}}} = getState();
  let dataToPost = {
    uniqueId,
    receiverId,
    message
  }
  dispatch(fetchAPI(SEND_MESSAGES, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const getChatBrandList = (uniqueId, search) => (dispatch, getState) => {
  let dataToPost = {
    uniqueId,
    search: search || "",
  }
  dispatch(fetchAPI(CHAT_BRAND_LIST, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setChatBrandList(response.data));
        return response.data;
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const fetchChatMessages = (uniqueId, receiverId) => (dispatch, getState) => {
  let dataToPost = {
    uniqueId,
    receiverId
  }
  dispatch(fetchAPI(FETCH_CHAT_MSG, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setChatMessages(response.data));
        dispatch(getNotificationCount());
        return response.data;
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};

export const deleteTabledata = (sideBarId, formName) => (dispatch, getState) => {
  const { proposal: { tableData } } = getState();
  const filteredData = tableData.find((data) => data.sideBarId === sideBarId);
  const dataToUpdate = tableData.find((data) => data.sideBarId !== sideBarId);
  if (!filteredData) {
    dispatch(removeTab());
    dispatch(addSideBar());
    return;
  }
  let apiURL = `${DELETE_TABLE}/${filteredData.id}`;
  return dispatch(fetchAPI(apiURL, httpMethod.DELETE)).then((response) => {
    if (response.success) {
      dispatch(setSnackbar({ flag: true, message: COMMON_MSG.TABLE_DELETED, type: "success" }));
      dispatch(updateTableData(dataToUpdate || []));
      dispatch(resetSingleForm(formName));
      dispatch(removeTab());
      dispatch(addSideBar());
    } else if (response.error) {
      dispatch(
        setSnackbar({
          flag: true,
          message: response.error.errorMsg,
          type: "error",
        })
      );
    }
  });
};
export const getAssigneeList = () => (dispatch) => {
  let dataToPost = {
    type:'proposal'
  }

  dispatch(fetchAPI(FETCH_ASSIGNEE_LIST, httpMethod.POST, dataToPost)).then(
    response => {
      if (response.success) {
        dispatch(setAssigneeList(response?.data))
        // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
      }
    },
  );
}
export const updateAssignUser = (uniqueId, assignee_id,offset,selectedTab) => (dispatch, getState) => {
  // console.log("set",selectedTab)
  let dataToPost = {
    uniqueId,
    assignee_id,
    offset,
    PageLimit: PAGE_LIMIT,
    isInbox: selectedTab
  }
  dispatch(fetchAPI(ASSIGN_USER, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
         dispatch(setDashboardList(response.data));
        // return response.data;
        dispatch(setSnackbar({ flag: true, message:"User Assigned Successfully!!!", type: "success" }));
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};
export const unAssignUser = (values, selectedRowId, selectedAssigneeId, handleClose) => (dispatch, getState) => {
  const { users: { userData }, proposal: { offset, dashboardTab } } = getState();
  // console.log("set3",handleClose)
  const { role_id } = userData?.data;
  let dataToPost = {
    comment: values?.comment,
    uniqueId: selectedRowId,
    PageLimit: PAGE_LIMIT,
    offset,
    isInbox: dashboardTab,
  }
  if (role_id !== 2) {
    dataToPost.assigneeId = selectedAssigneeId;
  }
  dispatch(fetchAPI(UNASSIGN_USER, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setDashboardList(response.data));
        dispatch(setSnackbar({ flag: true, message: "User Unassigned Successfully!!!", type: "success" }));
        dispatch(reset("UnassinCommentForm"))
        // handleClose();
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error", }));
      }
    }
  );
};
export const unAssignUserComments = (proposalId) => (dispatch, getState) => {
  let dataToPost = {
    uniqueId: proposalId,
  }
  dispatch(fetchAPI(GET_UNASSIGN_USER_COMMENTS, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setAssigneeComments(response?.data))
      } else if (response.error) {
        dispatch(
          setSnackbar({
            flag: true,
            message: response.error.errorMsg,
            type: "error",
          })
        );
      }
    }
  );
};
export const assignToMe = (proposalId, offset, selectedTab) => (dispatch, getState) => {
  let dataToPost = {
    uniqueId: proposalId,
    PageLimit: PAGE_LIMIT,
    offset,
    isInbox: selectedTab
  }
  dispatch(fetchAPI(ASSIGN_TO_ME, httpMethod.POST, dataToPost)).then(
    (response) => {
      if (response.success) {
        dispatch(setDashboardList(response.data));
        dispatch(setSnackbar({ flag: true, message: "User Assigned Successfully!!!", type: "success" }));
      } else if (response.error) {
        dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error", }));
      }
    }
  );
};
export const getAdminCompanyId = () => (dispatch, getState) => {
  const { users } = getState();
  return users?.userData?.data?.admin_company_id;
}
export const getCompleteProfile = (showLoad = false) => (dispatch, getState) => {
  // const { users } = getState();
  // const Role_Id = users.userData?.data?.role_id;
  //  console.log(Role_Id,"adminProfile")
    let company_id = dispatch(getAdminCompanyId());
    let APIUrl = `${COMPLETE_PROFILE}/${company_id}`;
    if (!company_id) return;
    dispatch(fetchAPI(APIUrl, httpMethod.GET, '', '', '', '')).then(
      response => {
        if (response.success) {
          dispatch(setAdminProfile(response.data));
        }
      }
    );
}
export const getAdminProfileFromOutBox = () => (dispatch, getState) => {
  let outBoxData = dispatch(getOutBoxData());
  const { proposal:{adminProfile} } = getState();
  // console.log(adminProfile,"profile proposals")
  let previewFromOutBox = {
    complianceDoc: outBoxData.complianceDoc,
    annexureDocIds: dispatch(getAnnexureTitleList(outBoxData.annexureDocIds)), // _.keys(OutboxForm?.values?.annexure),
  };
  if (outBoxData.aboutUs) {
    previewFromOutBox = {
      ...previewFromOutBox,
      aboutUs: adminProfile.aboutUs,
    };
  }
  if (outBoxData.pastProjects.length > 0) {
    previewFromOutBox = {
      ...previewFromOutBox,
      pastProjectData: dispatch(
        getPastProjectsFromOutBox(outBoxData.pastProjects)
      ),
    };
  }
  return previewFromOutBox;
};

export const getPastProjectsFromOutBox = (pastProjects) => (dispatch, getState) => {
    const {proposal: {adminProfile }} = getState();
    let newProjectArr = [];
    pastProjects.map((data) => {
      adminProfile.pastProjectData.find((project) => {
        if (project.id == data) {
          newProjectArr.push(project);
        }
      });
    });
    return newProjectArr;
  };

export const getAnnexureTitleList = (ids) => (dispatch, getState) => {
  const {
    master: { annexureList },
  } = getState();
  let data = annexureList.filter((data) => ids.indexOf(data.id) > -1);
return data;
}