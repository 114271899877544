import { createSlice } from "@reduxjs/toolkit";

import GlimpseIcon from "../../assets/icons/glimpse.svg";
import GlimpseIconPurple from "../../assets/icons/glimpsePurple.svg";
import GlimpseIconWhite from "../../assets/icons/glimpseWhite.svg";

import uploadIcon from "../../assets/icons/upload.svg";
import uploadIconPurple from "../../assets/icons/uploadPurple.svg";
import uploadIconWhite from "../../assets/icons/uploadIconWhite.svg";

import proposalBrief from "../../assets/icons/proposalBrief.svg";
import proposalBriefPurple from "../../assets/icons/proposalBriefPurple.svg";
import proposalBriefWhite from "../../assets/icons/proposalBriefWhite.svg";

import goals from "../../assets/icons/goals.svg";
import goalsPurple from "../../assets/icons/goalsPurple.svg";
import goalsWhite from "../../assets/icons/goalsWhite.svg";

import beneficiaries from "../../assets/icons/beneficiaries.svg";
import beneficiariesPurple from "../../assets/icons/beneficiariesPurple.svg";
import beneficiariesWhite from "../../assets/icons/beneficiariesWhite.svg";

import proposalSustainability from "../../assets/icons/proposalSustainability.svg";
import proposalSustainabilityPurple from "../../assets/icons/proposalSustainabilityPurple.svg";
import proposalSustainabilityWhite from "../../assets/icons/proposalSustainabilityWhite.svg";

import mileage from "../../assets/icons/mileage.svg";
import mileagePurple from "../../assets/icons/mileagePurple.svg";
import mileageWhite from "../../assets/icons/mileageWhite.svg";

import expectedImpact from "../../assets/icons/expectedImpact.svg";
import expectedImpactPurple from "../../assets/icons/expectedImpactPurple.svg";
import expectedImpactWhite from "../../assets/icons/expectedImpactWhite.svg";

import suggestedBy from "../../assets/icons/suggestedBy.svg";
import suggestedByPurple from "../../assets/icons/suggestedByPurple.svg";
import suggestedByWhite from "../../assets/icons/suggestedByWhite.svg";

import coverLetter from "../../assets/icons/coverLetter.svg";
import coverLetterPurple from "../../assets/icons/coverLetterPurple.svg";
import coverLetterWhite from "../../assets/icons/coverLetterWhite.svg";

import table from "../../assets/icons/table.svg";
import tablePurple from "../../assets/icons/tablePurple.svg";
import tableWhite from "../../assets/icons/tableWhite.svg";

import textEditor from "../../assets/icons/textEditor.svg";
import textEditorPurple from "../../assets/icons/textEditorPurple.svg";
import textEditorWhite from "../../assets/icons/textEditorWhite.svg";
import DetailsIconWhite from '../../assets/icons/detailsIconWhite.svg'
import DetailsIconBlack from '../../assets/icons/detailsIconBlack.svg'
import DetailsIconPurple from '../../assets/icons/DetailsIconPurple.svg'

const initialState = {
  mainArr: [],
  sideArr: [],
  tempSideArr: [
    {
      name: "Glimpse",
      completed: false,
      selected: true,
      sideBarId: 1,
      type: "Glimpse",
      icon: "proposal-icons/glimpse.svg",
      icon2: "proposal-icons/glimpsePurple.svg",
      iconWhite: "proposal-icons/glimpseWhite.svg",
    },
    {
      name: "Detail",
      completed: false,
      selected: true,
      sideBarId: 2,
      type: "Detail",
      icon: "proposal-icons/glimpse.svg",
      icon2: "proposal-icons/glimpsePurple.svg",
      iconWhite: "proposal-icons/glimpseWhite.svg",
    },
  ],
  glimpseData: [],
  formSubmitted: false,
  sdgGuidelines: [],
  tableData: [],
  textEditorData: [],
  catchmentAreas: [],
  catchmentFlag: false,
  propsalBriefData: [],
  beneficiariesData: [],
  goalsData: [],
  sustainabilityData: [],
  mileageData: [],
  expectedImpactData: [],
  suggestedbyData: [],
  coverLetterData: [],
  uploadedData:{},
  dashboardTab: 'inbox',
  dashboardList:[],
  isEdit: false,
  uniqueId: null,
  rfpId:null,
  profileData: {},
  adminProfile:{},
  uploadTab: false,
  responseFlag: false,
  isResponded: false,
  isRead: false,
  rfp_unq_id:'',
  insightData: {},
  is_message_send:null,
  is_message_received:null,
  pageNumber: 1,
  chatMessages:[],
  sentInsight: [],
  sentTotalCount: 0,
  assigneeList:[],
  assigneeComments:[],
  offset:null,
  userAssigned:"",
};

const proposalSlice = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    setSideArr(state, action) {
      state.sideArr = action.payload;
    },
    setMainArr(state, action) {
      state.mainArr = action.payload;
    },
    addProposalUploadedData(state, action){
      state.uploadedData = action.payload;
    },
    removeTab(state, action) {
      let index = state.sideArr.findIndex(data => data.selected === true)
      if (index > -1) {
        state.sideArr = state.sideArr.filter((item, i) => {
          if (item.selected === false) {
            return item
          }
        }).map((item, i) => {
          if (i === index - 1) {
            return { ...item, selected: true }
          }
          else if (i != index - 1) {
            return item
          }
        })
        const names = state.sideArr.map(data => data.name)
        state.mainArr = state.mainArr.map((data) => {
          if (!names.includes(data.name)) {
            return { ...data, selected: false }
          }
          else {
            return { ...data }
          }
        })
      }
    },
    changeSideArr(state, action) {
      state.sideArr = state.sideArr.map(data => {
        if (data.sideBarId === action.payload.sideBarId) {
          // console.log(action.payload, "payload")
          return { ...data, name: action.payload.tableName || action.payload.textName || action.payload.name, type: action.payload.type, selected: true }
        }
        else
        return data
      })
      // console.log(state.sideArr, "changeArr")
    },
    setGlimpse(state, action) {
      state.glimpseData = action.payload;
    },
    setForm(state, action) {
      const { type } = action.payload;
      switch (type) {
        case "SUBMIT_FORM_SUCCESS":
          return {
            ...state,
            formSubmitted: true,
          };
        default:
          return state;
      }
    },
    setTable(state, action) {
      const { tableName, sideBarId, id } = action.payload;
      const existingTable = state.tableData.find((table) => table.sideBarId === sideBarId);

      if (existingTable) {
        state.tableData = state.tableData.map((table) =>
          table.sideBarId === sideBarId
            ? {
              ...table,
              tableName, id
            }
            : table
        );
      } else {
        state.tableData.push({
          sideBarId,
          tableName,
          id
        });
      }
    },
    setTextEditor(state, action) {
      const { textData, sideBarId, textName, id } = action.payload;
      const existingEditor = state.textEditorData.find((table) => table.sideBarId === sideBarId);

      if (existingEditor) {
        state.textEditorData = state.textEditorData.map((table) =>
          table.sideBarId === sideBarId
            ? {
              ...table,
              textData, textName, id
            }
            : table
        );
      } else {
        state.textEditorData.push({
          textData, sideBarId, textName, id
        });
      }
    },
    setProposalBriefData(state, action) {
      state.propsalBriefData = action.payload;
    },
    setBeneficiariesData(state, action) {
      state.beneficiariesData = action.payload;
    },
    setGoalsData(state, action) {
      state.goalsData = action.payload;
    },
    setSustainabilityData(state, action) {
      state.sustainabilityData = action.payload;
    },
    setMileageData(state, action) {
      state.mileageData = action.payload;
    },
    setExpectedImpactData(state, action) {
      state.expectedImpactData = action.payload;
    },
    setSuggestedByData(state, action) {
      state.suggestedbyData = action.payload;
    },
    setCoverLetterData(state, action) {
      state.coverLetterData = action.payload;
    },
    addProposalDistrict(state, action) {
      state.district = action.payload;
    },
    addCatchmentAreas(state, action) {
      state.catchmentAreas.push(action.payload);
    },
    updateCatchmentAreas(state, action) {
      state.catchmentAreas = action.payload;
    },
    setCatchmentFlag(state, action) {
      state.catchmentFlag = action.payload;
    },
    setTableData(state, action) {
      const { columns, data, sideBarId } = action.payload;
      const existingTable = state.tableData.find((table) => table.sideBarId === sideBarId);

      if (existingTable) {
        state.tableData = state.tableData.map((table) =>
          table.sideBarId === sideBarId
            ? {
              ...table,
              columns,
              data
            }
            : table
        );
      } else {
        state.tableData.push({
          sideBarId,
          columns,
          data
        });
      }
    },
    updateSdgGuideLines(state, action) {
      state.sdgGuidelines = action.payload
    },
    updateTableData(state, action){
      state.tableData = action.payload;
    },
    updateEditorData(state, action){
      state.textEditorData = action.payload;
    },
    setDashboardTab(state, action){
      state.dashboardTab = action.payload;
    },
    setDashboardList(state, action){
      state.dashboardList = action.payload;
    },
    setUniqueId(state, action){
      state.uniqueId = action.payload;
    },
    setRfpId(state, action){
      state.rfpId = action.payload;
    },
    setProfileData(state, action){
      state.profileData = action.payload;
    },
    setUploadTab(state, action){
      state.uploadTab = action.payload;
    },
    setProposalResponseFlag(state, action){
      state.responseFlag = action.payload;
    },
    setProposalResponse(state, action){
      state.responseList = action.payload;
    },
    setIsResponded(state, action){
      state.isResponded = action.payload;
    },
    setIsRead(state, action){
      state.isRead = action.payload;
    },
    setInsightData(state, action){
      state.insightData = action.payload;
    },
    setPageNumber(state, action){
      state.pageNumber = action.payload;
    },
    setChatBrandList(state, action){
      state.chatBrandList = action.payload;
    },
    setChatMessages(state, action){
      state.chatMessages = action.payload;
    },
    clearChatMessages(state, action){
      state.chatMessages = [];
    },
    setIs_message_send(state, action){
      state.is_message_send = action.payload;
    },
    setIs_message_received(state, action){
      state.is_message_received = action.payload;
    },
    setRfp_unq_id(state, action){
      state.rfp_unq_id = action.payload;
    },
    setProposalUserId(state, action){
      state.user_id = action.payload;
    },
    setSentInsight(state, action){
      state.sentInsight = action.payload;
    },
    setSentTotalCount(state, action){
      state.sentTotalCount = action.payload;
    },
    setAssigneeList(state, action){
      state.assigneeList = action.payload;
    },
    setAssigneeComments(state, action){
      state.assigneeComments = action.payload;
    },
    setOffset(state, action){
      state.offset = action.payload;
    },
    setAdminProfile(state, action){
      state.adminProfile = action.payload;
    },
    setUserAssigned(state, action){
      state.userAssigned = action.payload;
    },
  },
});

export default proposalSlice.reducer;
export { initialState };
export const {
  setGlimpse,
  setForm,
  setTable,
  setTextEditor,
  setProposalBriefData,
  setBeneficiariesData,
  setGoalsData,
  setSustainabilityData,
  setMileageData,
  setExpectedImpactData,
  setSuggestedByData,
  setCoverLetterData,
  setSideArr,
  changeSideArr,
  setMainArr,
  removeTab,
  addProposalDistrict,
  addCatchmentAreas,
  updateCatchmentAreas,
  setCatchmentFlag,
  setTableData,
  updateSdgGuideLines,
  addProposalUploadedData,
  updateTableData,
  updateEditorData,
  setDashboardTab,
  setDashboardList,
  setUniqueId,
  setRfpId,
  setProfileData,
  setUploadTab,
  setProposalResponseFlag,
  setProposalResponse,
  setIsResponded,
  setIsRead,
  setInsightData,
  setPageNumber,
  setChatBrandList,
  setChatMessages,
  setRfp_unq_id,
  setIs_message_send,
  setIs_message_received,
  clearChatMessages,
  setProposalUserId,
  setSentInsight,
  setSentTotalCount,
  setAssigneeList,
  setAssigneeComments,
  setOffset,
  setAdminProfile,
  setUserAssigned,
} = proposalSlice.actions;
