import React from 'react';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import theme from '../../../../assets/material-ui/theme';
import { Box, Typography } from '@mui/material';

const PostMoreItem = ({icon, iconAlt, title, desc}) => {
    return (
        <>
            <Box className={SocialMediaCSS.postMoreOptionItem}>
                <img src={icon} alt={iconAlt} className={SocialMediaCSS.h14} />
                <Box className={SocialMediaCSS.postMoreOptionItemDetail}>
                    <Typography variant='body2' className={SocialMediaCSS.postMoreOptionItemName}>
                        {title}
                    </Typography>
                    <Typography variant='body5' className={SocialMediaCSS.postMoreOptionItemDesc}>
                        {desc}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default PostMoreItem
