import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import calender from "../../../../assets/icons/calender.svg";
import social_event from "../../../../assets/icons/social_event.svg";
import Location from "../../../../assets/icons/location_grey.svg";
import Nature from "../../../../assets/images/Bovokal.svg"
import CommonInitials from "../../../../common/components/CommonInitials";
import { getResponseDate } from "../../../../common/utils";
import { useNavigate } from "react-router";
import slugify from "slugify";

const EventShare = ({ post }) => {
    const navigate = useNavigate();
    const handleEventClick = () => {
        navigate(`/social-media/events/${post?.gid}`);
    }
    const handleConferenceClick = () => {
        // Create slug from title
        const slug = slugify(post?.title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
        const slugUrl = `${slug}-${post?.gid}`;
        navigate(`/volunteer/${slugUrl}`);
        // navigate(`/bEngage/public/${post?.gid}`);
    }

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = post?.body;
    const description = tempDiv.textContent || tempDiv.innerText || "";

    return (
        <>
            <Box className={`${SocialMediaCSS.smCard} ${SocialMediaCSS.noTitleCard} ${SocialMediaCSS.EventShare} ${SocialMediaCSS.cursor}`} onClick={post?.post_type === 'SocialEvent' ? handleEventClick : post?.post_type === 'Conference' ? handleConferenceClick : ""}>
                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`}>
                    <Box className={`${SocialMediaCSS.social_rfp}`}>
                        {/* <img src={Nature} className={SocialMediaCSS.social_rfpImg} /> */}
                        <CommonInitials
                            name={post?.user_details?.initials}
                            bgColor={post?.user_details?.colour_code}
                            divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', cursor: 'pointer' }}
                            typoStyle={{ fontSize: '18px', lineHeight: '44px' }}
                            avatarStyle={{ width: '46px', height: '46px' }}
                            src={post?.user_details?.image}
                            companyLogo={post?.user_details?.image}
                            avatar={true}
                        />
                    </Box>
                    <Box className={SocialMediaCSS.social_Box}>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                            <Typography className={SocialMediaCSS.EventTitle}>{post?.title}</Typography>
                            <img src={social_event} />
                        </Box>

                        {/* <Typography className={SocialMediaCSS.EventTitle2}>{post?.body}</Typography> */}
                        <Typography className={SocialMediaCSS.EventTitle2}>
                            {description}
                        </Typography>

                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                            {post?.mode_type === "inperson" &&
                                <Box className={SocialMediaCSS.f}>
                                    <img src={Location} className={SocialMediaCSS.imgPad} />
                                    <Typography className={SocialMediaCSS.eventLocation}>{post?.address}</Typography>
                                </Box>}
                            {post?.mode_type === "online" &&
                                <Box className={SocialMediaCSS.f}>
                                    <img src={post?.conference_platform?.image} className={SocialMediaCSS.confImg} />&nbsp;
                                    <Typography className={`${SocialMediaCSS.eventLocation}`}>{post?.conference_platform?.title}</Typography>
                                </Box>}
                            <Box className={SocialMediaCSS.f}  >
                                <img src={calender} className={`${SocialMediaCSS.imgPad} ${SocialMediaCSS.cdateImg}`} />
                                <Typography className={SocialMediaCSS.confDate}>{getResponseDate(post?.date)}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </>
    );
}
export default EventShare;
