import React from 'react';
import { Field } from 'redux-form';
import RenderImageUploader from './RenderImageUploader';
import PropTypes from 'prop-types';

const CommonImageUploader = (props) => {
  return (
    <span className="common-image-uploader">
      <Field
        {...props}
        name={props.name}
        component={RenderImageUploader}
        validate={props.validate}
        label={props.label}
        style={props.style}
        showEdit={props.showEdit}
        showUpload={props.showUpload}
        className={props.className}
        // onChange={props.onChange}
        dirty={props.dirty}
        value={props.defaultValue || ''}
        sizeLimit={props.sizeLimit}
        uploadFormat= {props.uploadFormat}
        uploadInfo = {props.uploadInfo}
      />
    </span>
  );
};

CommonImageUploader.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default CommonImageUploader;
