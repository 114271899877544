import { Grid, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { change } from "redux-form";
import EditIcon from '../../../assets/icons/EditBlue.svg';
import bvokalLogo from '../../../assets/images/BvokalNew.svg';
import theme from "../../../assets/material-ui/theme";
import CommonButton2 from "../../../common/components/CommonButton2";
import CommonInputNew from "../../../common/components/CommonInputNew";
import CommonButtonNew from "../../../common/components/RectangularInputs/CommonButtonNew";
import ReduxForm from '../../../common/components/ReduxForm';
import * as clientValidations from '../../../common/validations';
import { resendOTP, resetPassword } from '../actions';
import RegCSS from '../common/RegistrationCSS/RegCSS.module.css';


export default function ResetPasswordNew() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => {
        return state.users;
    })
    const [remainingTime, setRemainingTime] = useState(0);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const handleResendClick = () => {
        // Disable the button and set a timer to re-enable it after 1 minute.
        setIsResendDisabled(true);
        setRemainingTime(60); // Set initial remaining time to 60 seconds (1 minute).
        const timerInterval = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime === 1) {
                    clearInterval(timerInterval); // Stop the timer when it reaches 0.
                    setIsResendDisabled(false); // Re-enable the button.
                }
                return prevTime - 1;
            });
        }, 1000); // Update the remaining time every 1 second.

        dispatch(resendOTP(email));
        dispatch(change('resetPassword', 'resetPasswordOtp', ''));
    };
    const handleEditClick = () => {
        navigate('/forgot-password', { state: { email: user.forgotPassData.email } });
    };
    useEffect(() => {
        setRemainingTime(60); // Set initial remaining time to 60 seconds (1 minute).
        const timerInterval = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime === 1) {
                    clearInterval(timerInterval); // Stop the timer when it reaches 0.
                    setIsResendDisabled(false); // Re-enable the button.
                }
                return prevTime - 1;
            });
        }, 1000);
    }, []);
    useEffect(() => {
        dispatch(change('resetPassword', 'resetPasswordOtp', ''));
        dispatch(change('resetPassword', 'password', ''));
    }, []);
    let email = '';
    if (user && user.forgotPassData && user.forgotPassData.email) {
        email = user.forgotPassData.email;
    }
    return (
        <Grid container className={RegCSS.signUpMailRoot}>
            <Grid item xs={12}>
                <Grid container spacing={5}>
                    <Grid item xs={12} textAlign={'center'}>
                        <img src={bvokalLogo} height={50} />
                    </Grid>
                    <Grid item xs={12} className={RegCSS.signUpMailGrid}>
                        <Typography className={RegCSS.signUpMailTypo}>
                            We have emailed a code to:
                        </Typography>
                        <Box className={RegCSS.signUpMailBox}>
                            <Typography className={RegCSS.signUpMailTypo2}>
                                {user.forgotPassData && user.forgotPassData.email}
                            </Typography>
                            <img src={EditIcon} height={24} style={{ cursor: 'pointer' }} onClick={handleEditClick} />
                        </Box>
                        <Typography className={RegCSS.signUpMailTypo3}>
                            If you haven't received the account verification Code, please click on the 'Resend Code’ button.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '30px !important' }}>
                        <ReduxForm
                            onSubmit={(values) => dispatch(resetPassword(values, navigate))}
                            form="resetPassword"
                            label="Log in to your account"
                            submitButton="Login"
                        >
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} px={6}>
                                        <Grid item xs={12}>
                                            <Typography className={RegCSS.signUpMailTypo4}>Verification Code <span style={{ color: theme.palette.error.main }}>*</span></Typography>
                                            <CommonInputNew
                                                name="resetPasswordOtp"
                                                placeholder="Enter verification code"
                                                type="otp"
                                                keyboardType="number"
                                                fullWidth
                                                normalize={clientValidations.normalizeNumericInput}
                                                validate={[clientValidations.required, clientValidations.number, clientValidations.maxLength6, clientValidations.onlyTextandNumber]}
                                                maxLength={6}
                                            />
                                            <Box className={RegCSS.signUpMailBox2}>
                                                <CommonButton2
                                                    label="Resend Code"
                                                    name="Resend Code"
                                                    style={{
                                                        color: isResendDisabled ? '#CCCCCC' : theme.palette.primary.dark, fontSize: '14px', textDecoration: 'underline', background: 'transparent', lineHeight: '21px',
                                                        '&:disabled': {
                                                            background: '#CCCCCC',
                                                        },
                                                        fontWeight: '500',
                                                        padding: 0,
                                                        margin: 0,
                                                    }}
                                                    onClick={() => handleResendClick()}
                                                    disabled={isResendDisabled}
                                                />
                                                <Typography className={RegCSS.signUpMailTypo5}>
                                                    {isResendDisabled ? `${remainingTime} seconds` : ''}
                                                </Typography>
                                            </Box>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={RegCSS.signUpMailTypo4}>Password <span style={{ color: theme.palette.error.main }}>*</span></Typography>
                                            <CommonInputNew
                                                name="password"
                                                placeholder="Choose your password"
                                                type="password"
                                                keyboardType="default"
                                                fullWidth
                                                validate={[clientValidations.PasswordRequired, clientValidations.minLength8, clientValidations.noWhitespaces]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box className={`${RegCSS.f} ${RegCSS.jc_center}`}>
                                        <CommonButtonNew
                                            label="Confirm"
                                            name="Confirm"
                                            type="submit"
                                            style={{ background: theme.palette.primary.dark, color: 'white', boxShadow: theme.shadow.softShadow4 }}
                                            smallWidth
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </ReduxForm>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}  
