import React, { useState, useRef, useEffect } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { Box, CircularProgress, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import SocialMediaCSS from '../../../SocialMedia/SocialMediaCSS/SocialMedia.module.css';
import full_screen from '../../../../assets/icons/full_screen.svg';
import white_cross from '../../../../assets/icons/white_cross.svg';
import white_right_arrow from '../../../../assets/icons/white_right_arrow.svg';
import white_left_arrow from '../../../../assets/icons/white_left_arrow.svg';
import zoom_in_white from '../../../../assets/icons/zoom_in_white.svg';
import zoom_out_white from '../../../../assets/icons/zoom_out_white.svg';

export const DocumentViewer = ({ pdfFile, handleCancle, hidePreview }) => {
    const [page, setPage] = useState(1);
    const [scale, setScale] = useState(0.4); // Initialize scale for zooming
    const canvasRef = useRef(null);
    const isXsScreen = useMediaQuery('(min-width: 1024px)');
    const isMdScreen = useMediaQuery('(min-width: 1440px)');

    const { pdfDocument } = usePdf({
        file: pdfFile,
        page,
        canvasRef,
        scale,
    });

    const handleFullScreen = useFullScreenHandle();

    useEffect(() => {
        if (handleFullScreen.active) {
            setScale(1);
        } else {
            setScale(0.55);
        }
    }, [handleFullScreen])
  
    const previousDisabled = page === 1;
    const nextDisabled = Boolean(page === pdfDocument?.numPages);

    return (
        <Box className={SocialMediaCSS.agendaPdfRoot}>
            <Box className={SocialMediaCSS.agendaPdfMain}>
                <FullScreen handle={handleFullScreen} className={SocialMediaCSS.full_screen}>
                    <Box className={SocialMediaCSS.agendaPdfHeaderBox} sx={{ position: handleFullScreen.active && 'fixed' }}>
                        <Typography className={SocialMediaCSS.agendaPdfHeaderTypo}>Document - {pdfDocument?.numPages || 0} pages</Typography>
                        {/* Full Screen Toggle */}
                        <Grid item textAlign="center" className={handleFullScreen.active ? SocialMediaCSS.agendaPdfHeaderBox3 : SocialMediaCSS.agendaPdfHeaderBox2}>
                            {!handleFullScreen.active && pdfDocument && !hidePreview && <img src={full_screen} alt='full' onClick={handleFullScreen.active ? handleFullScreen.exit : handleFullScreen.enter} style={{ cursor: 'pointer' }} />}
                            {handleFullScreen.active && <Box className={SocialMediaCSS.agendaPdfHeaderBoxIn}>
                                <img src={zoom_out_white} alt='zoomOut' style={{ cursor: scale !== 1 ? 'pointer' : 'not-allowed' }} onClick={() => scale !== 1 && setScale((prev) => Math.max(0.1, prev - 0.1))} />
                                <Box className={SocialMediaCSS.agendaPdfHeaderLine}></Box>
                                <img src={zoom_in_white} alt='zoomIn' style={{ cursor: 'pointer' }} onClick={() => setScale((prev) => prev + 0.1)} />
                                {/* onClick={() => setScale((prev) => prev + 0.1)} */}
                            </Box>}
                            {handleCancle && pdfDocument ? <img src={white_cross} alt='cross' style={{ cursor: 'pointer' }} onClick={handleFullScreen.active ? handleFullScreen.exit : handleCancle} /> : handleFullScreen.active && <img src={white_cross} alt='cross' style={{ cursor: 'pointer' }} onClick={handleFullScreen.exit} />}
                        </Grid>
                    </Box>
                    <Box sx={{ backgroundColor: handleFullScreen.active && '#f3f4f6' }}>
                        <Container maxWidth="md">
                            <Grid container spacing={2} alignItems="center" justifyContent={'center'} marginTop={!handleFullScreen.active && '10px'}>

                                {/* Left Arrow */}
                                {!handleFullScreen.active && (
                                    <>
                                        {pdfDocument && <Grid item xs={3} disabled={previousDisabled}
                                            onClick={() => !previousDisabled && setPage((prev) => prev - 1)}
                                            // sx={{
                                            //     color: previousDisabled ? 'gray' : 'inherit',
                                            //     cursor: previousDisabled ? 'not-allowed' : 'pointer',
                                            //     left: isMdScreen ? handleFullScreen.active ? '192px' : '25px' : isXsScreen ? '152px' : '92px',
                                            //     top: handleFullScreen.active ? '462.999px' : '282.999px',
                                            //     height: handleFullScreen.active ? '54.001px' : '34.001px',
                                            //     width: handleFullScreen.active ? '54.001px' : '34.001px',
                                            //     position: handleFullScreen.active && 'fixed'
                                            // }}
                                            sx={{
                                                color: previousDisabled ? 'gray' : 'inherit',
                                                cursor: previousDisabled ? 'not-allowed' : 'pointer',
                                                left: isMdScreen ? handleFullScreen.active ? `${192 * scale}px` : `25px` : isXsScreen ? `${152 * scale}px` : `92px`,
                                                top: handleFullScreen.active ? `${462.999 * scale}px` : `282.999px`,
                                                height: handleFullScreen.active ? `${54.001 * scale}px` : `34.001px`,
                                                width: handleFullScreen.active ? `${54.001 * scale}px` : `34.001px`,
                                                position: handleFullScreen.active && 'fixed',
                                                transform: handleFullScreen.active ? `scale(${scale})` : "",
                                            }}
                                            className={SocialMediaCSS.agendaPdfLeftArrow}
                                        >
                                            <img src={white_left_arrow} alt="left" />
                                        </Grid>}
                                    </>
                                )}
                                {handleFullScreen.active && (
                                    <>
                                        {pdfDocument &&
                                            <Grid item xs={3} disabled={previousDisabled}
                                                onClick={() => !previousDisabled && setPage((prev) => prev - 1)}
                                                sx={{
                                                    color: previousDisabled ? 'gray' : 'inherit',
                                                    cursor: previousDisabled ? 'not-allowed' : 'pointer',
                                                    left: isMdScreen ? handleFullScreen.active ? '12vw' : '3vw' : isXsScreen ? '5vw' : '8vw',
                                                    // left: handleFullScreen.active ? isMdScreen ? '192px' : `25px` : isXsScreen ? '152px' : '92px',
                                                    top: handleFullScreen.active ? '50%' : '282.999px',
                                                    height: handleFullScreen.active ? '54px' : '34px',
                                                    width: handleFullScreen.active ? '54px' : '34px',
                                                    position: handleFullScreen.active ? 'fixed' : 'relative',
                                                }}
                                                className={SocialMediaCSS.agendaPdfLeftArrow}
                                            >
                                                <img src={white_left_arrow} alt="left" />
                                            </Grid>}
                                    </>
                                )}

                                {/* PDF Viewer */}
                                <Grid item xs={12} className={SocialMediaCSS.agendaPdfContentGrid}>
                                    {!pdfDocument ? (
                                        <CircularProgress size={40} />
                                    ) : (
                                        <canvas ref={canvasRef} style={{ border: '1px solid #e0e0e0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }} />
                                    )}
                                </Grid>

                                {/* Right Arrow */}
                                {!handleFullScreen.active && (
                                    <>
                                        {pdfDocument &&
                                            <Grid item xs={3} disabled={nextDisabled}
                                                onClick={() => !nextDisabled && setPage((prev) => prev + 1)}
                                                // sx={{
                                                //     color: nextDisabled ? 'gray' : 'inherit',
                                                //     cursor: nextDisabled ? 'not-allowed' : 'pointer',
                                                //     right: isMdScreen ? handleFullScreen.active ? '152px' : '25px' : isXsScreen ? '152px' : '92px',
                                                //     bottom: handleFullScreen.active ? '440.999px' : '282.999px',
                                                //     height: handleFullScreen.active ? '54.001px' : '34.001px',
                                                //     width: handleFullScreen.active ? '54.001px' : '34.001px',
                                                //     position: handleFullScreen.active && 'fixed'
                                                // }}
                                                sx={{
                                                    color: nextDisabled ? 'gray' : 'inherit',
                                                    cursor: nextDisabled ? 'not-allowed' : 'pointer',
                                                    right: isMdScreen ? handleFullScreen.active ? `${192 * scale}px` : `25px` : isXsScreen ? `${152 * scale}px` : `92px`,
                                                    top: handleFullScreen.active ? `${462.999 * scale}px` : `282.999px`,
                                                    height: handleFullScreen.active ? `${54.001 * scale}px` : `34.001px`,
                                                    width: handleFullScreen.active ? `${54.001 * scale}px` : `34.001px`,
                                                    position: handleFullScreen.active && 'fixed',
                                                    // transform: `scale(${scale})`,
                                                    transform: handleFullScreen.active ? `scale(${scale})` : "",
                                                }}
                                                className={SocialMediaCSS.agendaPdfRightArrow}
                                            >
                                                <img src={white_right_arrow} alt="right" />
                                            </Grid>}
                                    </>
                                )}
                                {handleFullScreen.active && (
                                    <>
                                        {pdfDocument && <Grid item xs={3} disabled={nextDisabled}
                                            onClick={() => !nextDisabled && setPage((prev) => prev + 1)}
                                            sx={{
                                                color: nextDisabled ? 'gray' : 'inherit',
                                                cursor: nextDisabled ? 'not-allowed' : 'pointer',
                                                // right: isMdScreen ? handleFullScreen.active ? '192px' : "25px" : isXsScreen ? '12px' : '92px',
                                                right: isMdScreen ? handleFullScreen.active ? '12vw' : '3vw' : isXsScreen ? '5vw' : '8vw',
                                                top: handleFullScreen.active ? '50%' : '282.999px',
                                                height: handleFullScreen.active ? '54px' : '34px',
                                                width: handleFullScreen.active ? '54px' : '34px',
                                                position: handleFullScreen.active ? 'fixed' : 'relative',
                                            }}
                                            className={SocialMediaCSS.agendaPdfRightArrow}
                                        >
                                            <img src={white_right_arrow} alt="right" />
                                        </Grid>}
                                    </>
                                )}
                            </Grid>
                        </Container>
                    </Box>
                </FullScreen>
            </Box>
        </Box>
    );
};