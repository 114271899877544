import React from 'react';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { Box, Typography } from '@mui/material';

const SocialShareToRow = ({icon, iconAlt, title}) => {
    return (
        <>
            <Box className={SocialMediaCSS.smItem}>
                <img src={icon} alt={iconAlt} className={SocialMediaCSS.h32} />
                <Typography variant='body1' className={SocialMediaCSS.smItemName}>
                    {title}
                </Typography>
            </Box>
        </>
    )
}

export default SocialShareToRow
