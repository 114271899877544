import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css'
import SocialMediaEventCSS from '../../SocialMediaCSS/SocialMediaEvent.module.css'
import userImage2 from '../../../../assets/images/user_profile_image.jpeg';
import { FollowUser, sendConnectionReq } from '../../../Profile/actions';
import theme from '../../../../assets/material-ui/theme';
import CommonButton2 from '../../../../common/components/CommonButton2';
import InvitationHeaderTabs from './Invitation/InvitationHeaderTabs';
import InvitationList from './Invitation/InvitationList';
import ConnectionHeaderTabs from './Connection/ConnectionHeaderTabs';
import ConnectionList from './Connection/ConnectionList';
import VolinvitationHeaderTabs from './Vol Invitation/VolinvitationHeaderTabs';
import VolinvitationList from './Vol Invitation/VolinvitationList';
import NgoMain from './Connection/NgoList/NgoMain';
import CompanyMain from './Connection/CompanyList/CompanyMain';
import CommonInitials from '../../../../common/components/CommonInitials';
import SuggestionsMain from './SuggestionsMain';
import _ from 'lodash-es';
import { getCompanyTypeByKey } from '../../../../common/utils';
import { invitationAction } from '../../actions';
import IndependentNgoList from './Connection/NgoList/IndependentNgoList';
import Section8List from './Connection/NgoList/Section8List';
import FoundationList from './Connection/NgoList/FoundationList';
import PublicList from './Connection/CompanyList/PublicList';
import PrivateList from './Connection/CompanyList/PrivateList';
import SpeakerInvitations from './SpeakerInvitations';
import SponsorInvitations from './SponsorInvitations';
import SponsorHeaderTabs from './SponsorHeaderTabs';
import SpeakerHeaderTabs from './SpeakerHeaderTabs';

const CommunityMain = ({ dispatch, navigate, selectedCard, handleCardClick, connectionCount, selectedConnTab, myInvitations, mySuggestions, recommendationList, offset, totalRecommendationCount, invitationList, totalInvitationCount, connectionUserList, totalConnectionListCount, volInvitationList, totalVolInvitationCount, containerRef, totalRequestedCount, totalAcceptedCount, speakerList, totalSpeakers, sponsorList, totalSponsors, totalMissedCount, totalDeclinedCount }) => {
    const [followStatus, setFollowStatus] = useState({});

    // Initialize follow status based on suggested users data
    useEffect(() => {
        const initialStatus = {};
        mySuggestions?.data && mySuggestions?.data.forEach(user => {
            initialStatus[user.gid] = user.followed;
        });
        setFollowStatus(initialStatus);
    }, [mySuggestions]);

    const handleSendConnect = (value) => {
        dispatch(sendConnectionReq(value, 'community'));
    }
    const handleFollow = (gid) => {
        dispatch(FollowUser(gid, followStatus[gid]));
        setFollowStatus(prevState => ({
            ...prevState,
            [gid]: !prevState[gid]
        }));
    }

    const handleInvitationAction = (gid, value) => {
        dispatch(invitationAction(gid, value));
    }

    const goToProfile = (gid) => {
        navigate(`/profile/${gid}`)
    }

    // console.log("se;ected", selectedCard);

    return (
        <Box className={SocialMediaCSS.communityRoot}>
            {/* Invitations starts here */}
            {selectedCard === '' && (
                <>
                    {!_.isEmpty(myInvitations) && <Box className={SocialMediaCSS.communityMain}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box className={SocialMediaCSS.communityMainTitleGrid}>
                                    <Box className={SocialMediaCSS.communityMainTitleBox}>
                                        <Typography className={SocialMediaCSS.communityMainTitleTypo}>Invitations</Typography>
                                    </Box>
                                    {totalInvitationCount > 4 && <Box className={SocialMediaCSS.communityMainViewBox} onClick={() => handleCardClick('invite')}>
                                        <Typography className={SocialMediaCSS.communityMainViewTypo}>View All</Typography>
                                    </Box>}
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box className={SocialMediaCSS.communityMainCardGrid}>
                                    <Grid container spacing={2}>
                                        {myInvitations.map((invite) => (
                                            <Grid item xs={12} sm={6} md={6} xl={3} key={invite?.gid}>
                                                <Box className={SocialMediaCSS.communityMainCardBox}>
                                                    <Box className={SocialMediaCSS.communityMainCardBox2}>
                                                        <CommonInitials
                                                            name={invite?.initials}
                                                            bgColor={invite?.colour_code}
                                                            divStyle={{ padding: '3px', maxHeight: '70px', maxWidth: '70px', minHeight: '70px', minWidth: '70px', borderRadius: '10px', boxShadow: theme.shadow.softShadow2, flex: '0 0 70px', cursor: 'pointer', }}
                                                            typoStyle={{ fontSize: '26px', lineHeight: '60px', }}
                                                            avatarStyle={{ width: '70px', height: '70px', boxShadow: theme.shadow.softShadow2, cursor: 'pointer', borderRadius: '10px', }}
                                                            src={invite?.profile_pic}
                                                            companyLogo={invite?.profile_pic}
                                                            avatar={true}
                                                            onClick={() => goToProfile(invite?.user_gid)}
                                                        />
                                                        <Box className={SocialMediaCSS.communityMainCardBox4}>
                                                            <Typography className={SocialMediaCSS.communityMainCardTypo}>{invite?.profile_name}</Typography>
                                                            {/* <Typography className={SocialMediaCSS.communityMainCardTypo2}>{dispatch(getCompanyTypeByKey(invite?.profile_type))}</Typography> */}
                                                            {invite?.profile_tagline && <Typography className={SocialMediaCSS.communityMainCardTypo2}>{invite?.profile_tagline}</Typography>}
                                                        </Box>
                                                    </Box>
                                                    <Box className={SocialMediaCSS.communityMainCardBox3}>
                                                        <CommonButton2
                                                            label="Accept"
                                                            name="Accept"
                                                            type="Accept"
                                                            variant="contained"
                                                            // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                                            style={{ padding: '2px 16px', backgroundColor: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, boxShadow: 'none', width: '100px', borderRadius: '20px' }}
                                                            onClick={() => { handleInvitationAction(invite?.gid, 'Accepted') }}
                                                        />
                                                        <CommonButton2
                                                            label='Decline'
                                                            name='Decline'
                                                            type='Decline'
                                                            variant="outlined"
                                                            // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                                            style={{ padding: '2px 16px', color: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, borderColor: theme.mainPalette.primary.primary110, width: '100px', borderRadius: '20px' }}
                                                            onClick={() => handleInvitationAction(invite?.gid, 'Decline')}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>}
                    {/* Suggestions starts here */}
                    <Box className={SocialMediaCSS.communityMain}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box className={SocialMediaCSS.communityMainTitleGrid}>
                                    <Box className={SocialMediaCSS.communityMainTitleBox}>
                                        <Typography className={SocialMediaCSS.communityMainTitleTypo}>Suggestions</Typography>
                                    </Box>
                                    {mySuggestions?.view_all && <Box className={SocialMediaCSS.communityMainViewBox} onClick={() => handleCardClick('suggested')}>
                                        <Typography className={SocialMediaCSS.communityMainViewTypo}>View All</Typography>
                                    </Box>}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className={SocialMediaCSS.communityMainCardGrid}>
                                    <Grid container spacing={2}>
                                        {!_.isEmpty(mySuggestions?.data) && mySuggestions?.data.map((suggestion) => (
                                            <Grid item xs={12} sm={6} md={6} xl={3} key={suggestion?.gid}>
                                                <Box className={SocialMediaCSS.communityMainCardBox}>
                                                    <Box className={SocialMediaCSS.communityMainCardBox2}>
                                                        <CommonInitials
                                                            name={suggestion?.initials}
                                                            bgColor={suggestion?.colour_code}
                                                            divStyle={{ padding: '3px', maxHeight: '70px', maxWidth: '70px', minHeight: '70px', minWidth: '70px', borderRadius: '10px', boxShadow: theme.shadow.softShadow2, flex: '0 0 70px', cursor: 'pointer', }}
                                                            typoStyle={{ fontSize: '26px', lineHeight: '60px', }}
                                                            avatarStyle={{ width: '70px', height: '70px', boxShadow: theme.shadow.softShadow2, cursor: 'pointer', borderRadius: '10px', }}
                                                            src={suggestion?.profile_pic}
                                                            companyLogo={suggestion?.profile_pic}
                                                            avatar={true}
                                                            onClick={() => goToProfile(suggestion?.gid)}
                                                        />
                                                        <Box className={SocialMediaCSS.communityMainCardBox4}>
                                                            <Typography className={SocialMediaCSS.communityMainCardTypo}>{suggestion?.name}</Typography>
                                                            {/* <Typography className={SocialMediaCSS.communityMainCardTypo2}>{dispatch(getCompanyTypeByKey(suggestion?.profile_type))}</Typography> */}
                                                            {suggestion?.profile_tagline && <Typography className={SocialMediaCSS.communityMainCardTypo2}>{suggestion?.profile_tagline}</Typography>}
                                                        </Box>
                                                    </Box>
                                                    <Box className={SocialMediaCSS.communityMainCardBox3}>
                                                        <CommonButton2
                                                            label="Connect"
                                                            name="Connect"
                                                            type="Connect"
                                                            variant="contained"
                                                            // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                                            style={{ padding: '2px 16px', backgroundColor: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, boxShadow: 'none', width: '100px', borderRadius: '20px' }}
                                                            onClick={() => { handleSendConnect(suggestion?.email) }}
                                                        />
                                                        <CommonButton2
                                                            label={followStatus[suggestion.gid] ? "Unfollow" : "Follow"}
                                                            name={followStatus[suggestion.gid] ? "Unfollow" : "Follow"}
                                                            type={followStatus[suggestion.gid] ? "Unfollow" : "Follow"}
                                                            variant="outlined"
                                                            // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                                                            style={{ padding: '2px 16px', color: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, borderColor: theme.mainPalette.primary.primary110, width: '100px', borderRadius: '20px' }}
                                                            onClick={() => handleFollow(suggestion?.gid)}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    {_.isEmpty(mySuggestions?.data) && <Box className={`${SocialMediaEventCSS.f} ${SocialMediaEventCSS.jc_center}`} sx={{ m: '230px 0px', width: '100%' }}>
                                        <Typography sx={{ lineHeight: '36px', fontSize: '30px', fontWeight: 500, color: '#666666' }}>All Caught up!!</Typography>
                                    </Box>}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
            {selectedCard === 'invite' &&
                <Box className={SocialMediaCSS.communityMain2}>
                    <Grid container spacing={2}>
                        <InvitationHeaderTabs dispatch={dispatch} totalInvitationCount={totalInvitationCount} totalAcceptedCount={totalAcceptedCount} totalRequestedCount={totalRequestedCount} />
                        <InvitationList dispatch={dispatch} navigate={navigate} selectedConnTab={selectedConnTab} invitationList={invitationList} totalInvitationCount={totalInvitationCount} offset={offset} containerRef={containerRef} />
                    </Grid>
                </Box>
            }
            {selectedCard === 'suggested' &&
                <Box className={SocialMediaCSS.communityMain2}>
                    <Grid container spacing={2}>
                        <SuggestionsMain dispatch={dispatch} navigate={navigate} recommendationList={recommendationList} offset={offset} totalRecommendationCount={totalRecommendationCount} containerRef={containerRef} />
                    </Grid>
                </Box>
            }
            {selectedCard === 'connect' &&
                <Box className={SocialMediaCSS.communityMain2}>
                    <Grid container spacing={2}>
                        <ConnectionHeaderTabs dispatch={dispatch} connectionCount={connectionCount} />
                        {selectedConnTab === 'ngo' ?
                            <NgoMain dispatch={dispatch} navigate={navigate} handleCardClick={handleCardClick} connectionUserList={connectionUserList} />
                            :
                            selectedConnTab === 'companies' ?
                                <CompanyMain dispatch={dispatch} navigate={navigate} handleCardClick={handleCardClick} connectionUserList={connectionUserList} />
                                :
                                <ConnectionList dispatch={dispatch} navigate={navigate} connectionUserList={connectionUserList} totalConnectionListCount={totalConnectionListCount} offset={offset} containerRef={containerRef} selectedConnTab={selectedConnTab} />
                        }
                    </Grid>
                </Box>

            }
            {selectedCard === 'independent_ngo' &&
                <Box className={SocialMediaCSS.communityMain2}>
                    <Grid container spacing={2}>
                        <IndependentNgoList dispatch={dispatch} navigate={navigate} connectionUserList={connectionUserList} totalConnectionListCount={totalConnectionListCount} offset={offset} containerRef={containerRef} />
                    </Grid>
                </Box>
            }
            {selectedCard === 'section8' &&
                <Box className={SocialMediaCSS.communityMain2}>
                    <Grid container spacing={2}>
                        <Section8List dispatch={dispatch} navigate={navigate} connectionUserList={connectionUserList} totalConnectionListCount={totalConnectionListCount} offset={offset} containerRef={containerRef} />
                    </Grid>
                </Box>
            }
            {selectedCard === 'foundation' &&
                <Box className={SocialMediaCSS.communityMain2}>
                    <Grid container spacing={2}>
                        <FoundationList dispatch={dispatch} navigate={navigate} connectionUserList={connectionUserList} totalConnectionListCount={totalConnectionListCount} offset={offset} containerRef={containerRef} />
                    </Grid>
                </Box>
            }
            {selectedCard === 'public_company' &&
                <Box className={SocialMediaCSS.communityMain2}>
                    <Grid container spacing={2}>
                        <PublicList dispatch={dispatch} connectionUserList={connectionUserList} totalConnectionListCount={totalConnectionListCount} offset={offset} containerRef={containerRef} />
                    </Grid>
                </Box>

            }
            {selectedCard === 'private' &&
                <Box className={SocialMediaCSS.communityMain2}>
                    <Grid container spacing={2}>
                        <PrivateList dispatch={dispatch} connectionUserList={connectionUserList} totalConnectionListCount={totalConnectionListCount} offset={offset} containerRef={containerRef} />
                    </Grid>
                </Box>

            }
            {selectedCard === 'volinvite' &&
                <Box className={SocialMediaCSS.communityMain2}>
                    <Grid container spacing={2}>
                        <VolinvitationHeaderTabs dispatch={dispatch} totalRequestedCount={totalRequestedCount} totalAcceptedCount={totalAcceptedCount} />
                        <VolinvitationList dispatch={dispatch} navigate={navigate} selectedConnTab={selectedConnTab} volInvitationList={volInvitationList} totalVolInvitationCount={totalVolInvitationCount} offset={offset} containerRef={containerRef} />
                    </Grid>
                </Box>

            }
            {selectedCard === 'speaker' &&
                <Box className={SocialMediaCSS.communityMain2}>
                    <Grid container spacing={2}>
                        <SpeakerHeaderTabs dispatch={dispatch} totalInvitationCount={totalSpeakers} totalAcceptedCount={totalAcceptedCount} totalRequestedCount={totalRequestedCount} totalMissedCount={totalMissedCount} totalDeclinedCount={totalDeclinedCount} />
                        <SpeakerInvitations dispatch={dispatch} navigate={navigate} speakerList={speakerList} totalSpeakers={totalSpeakers} offset={offset} containerRef={containerRef} selectedConnTab={selectedConnTab} />
                    </Grid>
                </Box>

            }
            {selectedCard === 'sponsor' &&
                <Box className={SocialMediaCSS.communityMain2}>
                    <Grid container spacing={2}>
                        <SponsorHeaderTabs dispatch={dispatch} totalInvitationCount={totalSponsors} totalAcceptedCount={totalAcceptedCount} totalRequestedCount={totalRequestedCount} totalMissedCount={totalMissedCount} totalDeclinedCount={totalDeclinedCount} />
                        <SponsorInvitations dispatch={dispatch} navigate={navigate} sponsorList={sponsorList} totalSponsors={totalSponsors} offset={offset} containerRef={containerRef} selectedConnTab={selectedConnTab} />
                    </Grid>
                </Box>

            }
        </Box>
    )
}

export default CommunityMain