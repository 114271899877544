import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { renderField as textField } from './RenderInput';
import renderPassword from './RenderPassword';
import RenderEmail from './RenderEmail';
import RenderOrganization from './RenderOrganization';
import RenderTextArea from './RenderTextArea';
import { renderLoginText } from './renderLoginText';
import { renderMultilinetext } from './renderMultilinetext';
import RenderChat from '../RenderChat';
import { RenderText } from '../RenderText';
import { RenderMultipleEmails } from './RenderMultipleEmails';
import RenderTooltipInput from './RenderTooltipInput.js';
import RenderSocialInput from './RenderSocialInput.js';
import RenderInvite from './RenderInvite.js';
import RenderCommentInput from './RenderCommentInput.js';


const CommonInput = ({ keyboardType, showCount, toolTiptext, placeholder, customHeight, onKeyPress, placeholderFontSize, placeholderFontWeight, validate, classes ={}, ...otherProps }) => {
  let component;
  if (otherProps.type === 'password') {
    component = renderPassword;
  } else if (otherProps.type === 'Email') {
    component = RenderEmail;
  } else if (otherProps.type === 'organization') {
    component = RenderOrganization;
  } else if (otherProps.type === 'TextArea') {
    component = RenderTextArea;
  } else if (otherProps.type === 'LoginText') {
    component = renderLoginText;
  } else if (otherProps.type === 'multiline') {
    component = renderMultilinetext;
  } else if (otherProps.type === 'chat') {
    component = RenderChat;
  } else if (otherProps.type === 'singleTextLine') {
    component = RenderText;
  } else if (otherProps.type === 'multipleEmails') {
    component = RenderMultipleEmails;
  } else if (otherProps.type === 'tooltipInput') {
    component = RenderTooltipInput;
  } else if (otherProps.type === 'socialInput') {
    component = RenderSocialInput;
  } else if (otherProps.type === 'commentInput') {
    component = RenderCommentInput;
  } else if (otherProps.type === 'Invite') {
    component = RenderInvite;
  } else {
    component = textField;
  }
  return (
    <div className={`mt-10 ${classes.root || ''}`}>
      <Field
        inputProps={{ ...otherProps }}
        name={otherProps.name}
        // component={otherProps.type === 'password' ? renderPassword : RenderEmail}
        component={component}
        id={otherProps.name}
        label={otherProps.label}
        placeholder={placeholder}
        type={otherProps.type}
        rows={otherProps.rows}
        keyboardType={keyboardType}
        placeholderFontSize={placeholderFontSize}
        placeholderFontWeight={placeholderFontWeight}
        customHeight={customHeight}
        fullWidth
        onKeyPress={onKeyPress}
        users={otherProps.users}
        tags={otherProps.tags}
        toolTiptext={toolTiptext}
        validate={validate}
        multiline={otherProps.multiline}
        maxLength={otherProps.maxLength}
        onChange={otherProps.onChange}
        onExceedLimit={otherProps.onExceedLimit}
        defaultValue={otherProps.defaultValue || ''}
        onBlur={otherProps.onBlur}
        disabled={otherProps.disabled}
        clearInput={otherProps.clearInput}
        setClearInput={otherProps.setClearInput}
        min={otherProps.min}
        max={otherProps.max}
        selectedTags={otherProps.selectedTags}
        unSelectedTag={otherProps.unSelectedTag}
        radius={otherProps.radius}
        showCount={showCount}
        comment={otherProps.comment}
        initialValue={otherProps.initialValue}
        editFlag={otherProps.editFlag}
        countFontSize={otherProps.countFontSize}
        setNewMessage={otherProps.setNewMessage}
        errorWidth={otherProps.errorWidth}
        normalize={otherProps.normalize}
        borderBottom={otherProps.borderBottom}
        maxWords={otherProps.maxWords}
        handleSubmit={otherProps.handleSubmit}
        classes={classes}
        onKeyDown={otherProps.onKeyDown}
        endAdornment={otherProps.endAdornment}
      />
    </div>
  )
};


CommonInput.propTypes = {
    name: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    keyboardType: PropTypes.string,
    validate: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.func),
        PropTypes.func,
    ]),
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    toolTiptext: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    normalize: PropTypes.func,
    borderBottom:PropTypes.string,
    placeholderFontSize: PropTypes.string,
    maxWords: PropTypes.number,
    customHeight: PropTypes.string,
    radius: PropTypes.string,
    countFontSize :PropTypes.string,
    classes: PropTypes.object,
};

export default CommonInput;

