import React, { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import theme from '../../assets/material-ui/theme';
import { placements } from '@popperjs/core';

export default function RenderPassword({
  input,
  label,
  placeholder,
  type,
  fullWidth,
  // maxLength,
  underlineStyle,
  errorStyle,
  style,
  toolTiptext,
  id,
  pattern,
  defaultValue,
  disabled,
  min,
  max,
  value,
  meta: { touched, error, warning },
  inputProps,
  errorMessages,
  custom,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <FormControl sx={{ width: '100%' }} variant="outlined">
        {/* <InputLabel htmlFor="outlined-adornment-password" >{label}</InputLabel> */}
        <OutlinedInput
          id={input.name}
          type={showPassword ? 'text' : 'password'}
          value={defaultValue}
          min={min}
          max={max}
          fullWidth={fullWidth}
          {...input}
          {...custom}
          className='TextField'
          onKeyDown={(e) => {
            if (e.key === ' ') {
              e.preventDefault();  // Prevent typing space
            }
          }}
          sx={(theme) => {
            return {
              border: 1,
              borderColor: theme.palette.success.contrastText,
              backgroundColor: theme.palette.secondary.dark,
              borderRadius: 10,
              fontSize: 16,
              height: 48,
              fontWeight: 400,
              color: theme.palette.success.dark,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.success.dark,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.success.dark
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.main
              },
              "& input:-webkit-autofill": {
                height: 12,
                '-webkit-box-shadow': '0 0 0 1000px transparent inset',
                '-webkit-text-fill-color': '#333333 !important',
                'transition': 'background-color 5000s ease-in-out 0s',
            },
            }
          }}
          endAdornment={
            <InputAdornment position="end" >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                className="visibility-button"
                sx={{ color: theme.palette.grey.lightGreyVoilet }}
              >
                {showPassword ? <Visibility sx={{ height: '20px', width: '18px' }} /> : <VisibilityOff sx={{ height: '18px', width: '18px' }} />}
              </IconButton>
            </InputAdornment>
          }
          //   label={label}
          placeholder={placeholder}
        />
      </FormControl>
      {touched &&
        (error && (
          <Box className='error'>
            {error}
          </Box>
        ))}
    </>
  );
}