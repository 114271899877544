import React, { useEffect, useState } from 'react';
import { Grid, Tabs, Tab, Divider, Typography, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import SocialMediaCSS from "../../../SocialMediaCSS/SocialMedia.module.css";

import PostLikeIcon from '../../../../../assets/icons/postReactions/01_like.svg';
import PostClapIcon from '../../../../../assets/icons/postReactions/02_clap.svg';
import PostGratitudeIcon from '../../../../../assets/icons/postReactions/03_gratitude.svg';
import PostInspiringIcon from '../../../../../assets/icons/postReactions/04_inspiring.svg';
import PostImpactfulIcon from '../../../../../assets/icons/postReactions/05_impactful.svg';
import PostSupportIcon from '../../../../../assets/icons/postReactions/06_support.svg';
import PostThoughtfulIcon from '../../../../../assets/icons/postReactions/07_thoughtful.svg';
import { REACTION_LIST } from '../../../../../common/constants';
import theme from '../../../../../assets/material-ui/theme';
import { getReactionCount, getReactionList } from '../../../actions';
import { setSelectedReactionTab } from '../../../../../store/slices/SocialMediaSlice';

const ConnectionTabs = ({ isIndividual, master, setTabId, gid, tabId, socialMedia ,commentId,replyId,commentsGid}) => {
    const [value, setValue] = useState(REACTION_LIST.ALL);
    const dispatch = useDispatch();
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        setTabId(newValue);
        dispatch(setSelectedReactionTab(newValue));
        dispatch(getReactionList(gid, newValue,commentId,replyId));
        //console.log("Tab", newValue);
    };
    //console.log('red',replyId)

    const tabStyle = (tabValue) => ({
        color: theme.mainPalette.grey.lightText,
        fontSize: '14px',
        fontWeight: '500',
        padding: '8px',
        height: '26px',
        minHeight: '26px',
        minWidth: 'unset',
        transition: 'all 0.3s ease',
        '&.Mui-selected': {
            height: '26px',
            minHeight: '26px',
            paddingTop: '7px',
            color: theme.mainPalette.primary.primary110,
            transition: 'all 0.3s ease',
        },
        '&:hover': {
            color: theme.mainPalette.secondary.secondary110,
            transition: 'all 0.3s ease',
            opacity: 1,
        },
    });
    const tabLabels = Array.isArray(socialMedia?.reactionCount?.reaction_counts ) ? socialMedia?.reactionCount?.reaction_counts : [];
    // console.log("reaction", count)
    const CustomLabel = ({ mainLabel, count }) => (
        <Box className={SocialMediaCSS.all}>
            <Typography className={SocialMediaCSS.mainLabel}>{mainLabel}</Typography>
            <Typography className={SocialMediaCSS.countLabel} >{count}</Typography>
        </Box>
    );
    const reactionCounts = socialMedia?.reactionCount?.reaction_counts || [];
    useEffect(() => {
        // if (gid) {
            dispatch(getReactionList(gid,value, commentId, replyId));
        // }
    }, [gid,value, commentId, replyId]);
    return (
        <Grid item xs={12} className={SocialMediaCSS.connectionTabs}>
            <Tabs
                value={value}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="none"
                variant="scrollable"
                scrollButtons="auto"
                sx={{ "& .MuiTabs-indicator": { backgroundColor: theme.palette.primary.dark, height: '5px', borderRadius: '0px' }, minHeight: '36px', height: '36px' }}
                aria-label="Profile Tabs"
            >
                <Tab value={REACTION_LIST.ALL}
                    label={<CustomLabel mainLabel="All" count={reactionCounts.find(label => label.key === 'all')?.value || 0} />}
                    sx={tabStyle(REACTION_LIST.ALL)}
                />
                {/* <Tab value={REACTION_LIST.LIKE}
                    icon={<img src={PostLikeIcon} className={SocialMediaCSS.iconStyle} />}
                    label="0"
                    sx={tabStyle(REACTION_LIST.LIKE)}
                    iconPosition="start"
                />
                <Tab value={REACTION_LIST.CLAP}
                    icon={<img src={PostClapIcon} className={SocialMediaCSS.iconStyle} />}
                    label="2"
                    sx={tabStyle(REACTION_LIST.CLAP)}
                    iconPosition="start"
                />
                <Tab value={REACTION_LIST.PRAISE}
                    icon={<img src={PostGratitudeIcon} className={SocialMediaCSS.iconStyle} />}
                    label="5"
                    sx={tabStyle(REACTION_LIST.PRAISE)}
                    iconPosition="start"
                />
                <Tab value={REACTION_LIST.LETGO}
                    icon={<img src={PostInspiringIcon} className={SocialMediaCSS.iconStyle} />}
                    label="0"
                    sx={tabStyle(REACTION_LIST.LETGO)}
                    iconPosition="start"
                />
                <Tab value={REACTION_LIST.LIT}
                    icon={<img src={PostImpactfulIcon} className={SocialMediaCSS.iconStyle} />}
                    label="79"
                    sx={tabStyle(REACTION_LIST.LIT)}
                    iconPosition="start"
                />
                <Tab value={REACTION_LIST.HEART}
                    icon={<img src={PostSupportIcon} className={SocialMediaCSS.iconStyle} />}
                    label="7"
                    sx={tabStyle(REACTION_LIST.HEART)}
                    iconPosition="start"
                />
                <Tab value={REACTION_LIST.BULB}
                    icon={<img src={PostThoughtfulIcon} className={SocialMediaCSS.iconStyle} />}
                    label="89"
                    sx={tabStyle(REACTION_LIST.BULB)}
                    iconPosition="start"
                /> */}
                {master?.postReaction.map(reaction => (
                    <Tab
                        key={reaction.id}
                        value={reaction.key}
                        icon={<img src={reaction.image} className={SocialMediaCSS.iconStyle} alt={reaction.key} />}
                        label={tabLabels.find(label => label.key === reaction.key)?.value}
                        sx={tabStyle(reaction.id)}
                        iconPosition="start"
                    />
                ))}
            </Tabs>
            <Divider />
        </Grid>
    );
};

export default ConnectionTabs;

