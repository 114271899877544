import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Dialog, DialogContent, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import Cancel_sechedule from "../../../../../../assets/icons/Cancel_sechedule.svg";
import theme from '../../../../../../assets/material-ui/theme';
import CommonButton2 from '../../../../../../common/components/CommonButton2';
import ReduxForm from '../../../../../../common/components/ReduxForm';
import SingleSelect from '../../../../../../common/components/SingleSelect';
import { formatDateString, formatInterger, formatTimeData, gerCurrentDate, getCurrentTime } from '../../../../../../common/utils';
import { setEventScheduleTime } from '../../../../../../store/slices/EventSlice';
import EventsCSS from '../EventDetailsCSS/Events.module.css';

const NewSelectedDateRange = ({ open, onClose, title, events, handleClose, preview, memoizedValue }) => {
    const dispatch = useDispatch();
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    const [secondDialogOpen, setSecondDialogOpen] = useState(false);
    const [mainHours, setTotalHours] = useState(false);
    const selectedDateForm = useSelector((state) => state.form.selectedDateForm);

    const handleDlgClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        onClose();
    }
    const handleSubmit = (values) => {
        onClose();
        handleClose();
    }
    const handleOpenPreviousDialog = () => {
        onClose();
        setSecondDialogOpen(false);
    }
    const calculateTotalHours = (fromTime, toTime) => {
        if (!fromTime || !toTime) {
            return 0; // Return 0 if either fromTime or toTime is undefined
        }
        const [fromHours, fromMinutes] = fromTime.split(':').map(Number);
        const [toHours, toMinutes] = toTime.split(':').map(Number);

        const totalHours = toHours - fromHours;
        const totalMinutes = toMinutes - fromMinutes;
        return totalHours + totalMinutes / 60;
    };

    useEffect(() => {
        let totalHours = 0;
        if (events?.event_schedule.length > 0) {
            events?.event_schedule.map((data, index) => {
                dispatch(change('selectedDateForm', `start_time_${index}`, data.start_time));
                dispatch(change('selectedDateForm', `end_time_${index}`, data.end_time));
                totalHours += calculateTotalHours(data.start_time, data.end_time);
            })
        }
        setTotalHours(formatInterger(totalHours));
    }, []);

    const handleStartEndTime = (value, index, field) => {
        let timer = [...events?.eventScheduleTimes];
        let newObj = { ...timer[index], [field]: value };
        timer[index] = newObj;
        dispatch(setEventScheduleTime(timer));
        if(index === 0){
            dispatch(change('eventForm', field, value));
        }
    }

    useEffect(() => {
        let totalHours = 0;
        if (events?.event_schedule.length > 0) {
            events?.event_schedule.map((data, index) => {
                totalHours += calculateTotalHours(data.start_time, data.end_time);
            })
        }
        setTotalHours(formatInterger(totalHours));;
    }, [events?.event_schedule]);
    return (
        <>
            <ReduxForm
                form="selectedDateForm"
                label="SelectedDate"
                submitButton="Done"
            >
                <Dialog open={open} onClose={handleDlgClose} PaperProps={{ sx: { minWidth: isXsScreen ? '850px' : '300px', minHeight: '561px', borderRadius: '15px !important', padding: '0px 16px 16px 16px'} }} className={EventsCSS.dateRangeRoot}>
                    <Box className={EventsCSS.dateRangebox}>
                        <Box display="flex" alignItems="center">
                            {!preview && <IconButton sx={{ padding: '0px' }} onClick={handleOpenPreviousDialog}>
                                <ChevronLeftIcon sx={{ fontSize: '28px', color: theme.palette.success.dark }} />
                            </IconButton>}
                            <Typography className={EventsCSS.dateRangeTitle}>{title}</Typography>
                        </Box>
                        {!preview && <img src={Cancel_sechedule} onClick={handleSubmit} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '-6px' }} />}
                    </Box>
                    <Box sx={{ borderTop: '1px solid #CCC', paddingBottom: '12px' }}> </Box>
                    <DialogContent className={EventsCSS.dateRangecontent}>
                        <Grid container xs='12' className={EventsCSS.dateRangedisplay} >
                            <Grid item xs={4} sx={{ padding: '0px !important', }}>
                                <Typography className={EventsCSS.dateRangeTypography}>Event Date</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ padding: '0px !important', }}>
                                <Typography className={EventsCSS.dateRangeTypography}>Timings</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ padding: '0px !important', display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography className={EventsCSS.dateRangeTypography}>Total hours:</Typography>
                                <Typography className={EventsCSS.dateRangeTypography}> &nbsp;{mainHours} hrs</Typography>
                            </Grid>
                        </Grid>
                        {events?.event_schedule?.map((date, index) => {
                            const parsedDate = new Date(date.event_date);
                            const formattedDate = `${parsedDate.getDate()}, ${parsedDate.toLocaleString('default', { month: 'long' })} ${parsedDate.getFullYear()}`;
                            const startTime = preview && events?.event_schedule[index].start_time;
                            const endTime = preview && events?.event_schedule[index].end_time;
                            return (
                                <Grid container xs='12' className={EventsCSS.dateRangecontentTable} >
                                    <Grid item xs={4} sx={{ padding: '0px !important', display: 'flex', }}>
                                        <Typography className={EventsCSS.dateRangetype2}>{formatDateString(formattedDate)}</Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={{ padding: '0px !important', display: 'flex' }}>
                                        <Typography className={EventsCSS.dateRangetype1}>From:</Typography>
                                        <Box>
                                            {preview ? <Typography className={EventsCSS.dateRangetype2} sx={{ paddingLeft: '4px' }}>{formatTimeData(startTime)}</Typography> : <SingleSelect
                                                options={memoizedValue}
                                                name={`start_time_${index}`}
                                                placeholder='Select'
                                                customHeight={"22px"}
                                                placeholderFontSize={"12px"}
                                                onChange={(value) => handleStartEndTime(value, index, 'start_time')}
                                                checkValue={date === gerCurrentDate() ? getCurrentTime() : ""}
                                            />}
                                        </Box>&nbsp;
                                        <Typography className={EventsCSS.dateRangetype1}>,</Typography>
                                        <Typography className={EventsCSS.dateRangetype1}>To:</Typography>
                                        <Box minWidth="39%">
                                            {preview ? <Typography className={EventsCSS.dateRangetype2} sx={{ paddingLeft: '4px' }}>{formatTimeData(endTime)}</Typography> : <SingleSelect
                                                options={memoizedValue}
                                                name={`end_time_${index}`}
                                                placeholder='Select'
                                                customHeight={"22px"}
                                                placeholderFontSize={"12px"}
                                                onChange={(value) => handleStartEndTime(value, index, 'end_time')}
                                                checkValue={events?.event_schedule[index]['start_time']}
                                            />}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} className={EventsCSS.dateRangeButtonGrid} gap={0.4}>
                                        <AccessTimeIcon sx={{ fontSize: '15px', color: theme.palette.success.light }} />
                                        <Typography className={EventsCSS.dateRangetype2}>
                                            {selectedDateForm && selectedDateForm.values && selectedDateForm.values[`start_time_${index}`] && selectedDateForm.values[`end_time_${index}`] ?
                                                `${formatInterger(calculateTotalHours(
                                                    selectedDateForm.values[`start_time_${index}`],
                                                    selectedDateForm.values[`end_time_${index}`]
                                                ))} hrs` : "00"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </DialogContent>
                    <Box className={EventsCSS.dateRangeButton} gap={'60px'}>
                        {preview ? <CommonButton2
                            label="Close"
                            name="Close"
                            type="button"
                            color="primary"
                            variant='contained'
                            style={{ padding: '2px 24px', fontSize: '16px', backgroundColor: theme.palette.primary.dark }}
                            onClick={() => onClose()}
                        /> : <CommonButton2
                            label="Done"
                            name="Done"
                            type="button"
                            color="primary"
                            variant='contained'
                            style={{ padding: '2px 24px', fontSize: '16px', backgroundColor: theme.palette.primary.dark }}
                            onClick={() => handleSubmit()}
                        />}
                    </Box>
                </Dialog >
            </ReduxForm>
        </>
    );
};

export default NewSelectedDateRange;
