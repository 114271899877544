import React from 'react';
import { func, string, node, elementType } from 'prop-types';
import { reduxForm } from 'redux-form';
import { flattenError } from '../utils';

const ReduxForm = (props) => (
  <div>
    <form
      onSubmit={props.handleSubmit((values) => props.onSubmit(values, props))}
      className={props.className}
    >
      {props.content ? props.content(props) : props.children}
    </form>
  </div>
);

const formFeilds = reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  forceUnregisterOnUnmount: true,
  onSubmitFail: errors => {
    const query = Object.keys(flattenError(errors))
      .map(fieldName => `[name="${fieldName}"]`)
      .join(',');

    if (query) {
      const errorEl = document.querySelector(query);
      if (errorEl && errorEl.focus) {
        errorEl.focus(); 
      }
    }
  },
});

ReduxForm.propTypes = {
  onSubmit: func.isRequired,
  handleSubmit: func.isRequired,
  content: elementType,
  className: string,
  children: node,
};

export default formFeilds(ReduxForm);