import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import calender from "../../../../assets/icons/calender.svg";
import social_rfp from "../../../../assets/icons/social_rfp.svg";
import time from "../../../../assets/icons/clock_Solid.svg";
import Nature from "../../../../assets/images/Bovokal.svg"
import { decodeHTMLEntities, formatTime, getDate, getResponseDate } from "../../../../common/utils";
import CommonInitials from "../../../../common/components/CommonInitials";
import { useNavigate } from "react-router";
import CommonButton2 from "../../../../common/components/CommonButton2";
import PostHeader from "./PostHeader";
import EventBookmarkHeader from "./EventBookMarkHeader";
import slugify from "slugify";
import ConferenceBookmarkHeader from "./PostSubComponents/ConferenceBookMarkHeader";

const ConferenceBookMark = ({ post, socialMedia, users, master }) => {
    const navigate = useNavigate();
    const [showFullText, setShowFullText] = useState(false);
    const [showViewMore, setShowViewMore] = useState(false);
    const postContentRef = useRef(null);
    const viewMoreButtonRef = useRef(null);

    const handlerConferenceBookMark = (title, gid) => {
        const slug = slugify(title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
        const slugUrl = `${slug}-${gid}`;
        navigate(`/volunteer/${slugUrl}`);
    };

    useEffect(() => {
        if (postContentRef.current) {
            const { scrollHeight, clientHeight } = postContentRef.current;
            setShowViewMore(scrollHeight > clientHeight);
        }
    }, [post]);
    const decodeHTMLEntities = (text) => {
        let decodedString = text;
        const parser = new DOMParser();
        while (decodedString.includes('&')) {
            decodedString = parser.parseFromString(decodedString, 'text/html').documentElement.textContent;
        }
        return decodedString;
    };

    const toggleFullText = (e) => {
        e.stopPropagation();
        setShowFullText((prevShowFullText) => !prevShowFullText);
    };
    // Function to strip HTML tags using regular expressions
    const stripHtmlTags = (html) => {
        return html.replace(/<\/?[^>]+(>|$)/g, "");
    };
    const getTextFromHtml = (html) => {
        const decodedHtml = decodeHTMLEntities(html);
        return stripHtmlTags(decodedHtml);
    };
    return (
        <>
            <Box className={SocialMediaCSS.postCard}>
                {post?.post_type === "bookmark_conference" &&
                    <ConferenceBookmarkHeader post={post} users={users} master={master} socialMedia={socialMedia} />
                }
                {post?.post_type === 'bookmark_event' &&
                    <EventBookmarkHeader post={post} users={users} master={master} socialMedia={socialMedia} />
                }
                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.fd_column}`} pt={0} width="100%">
                    <Box>
                        <img src={post?.conference_image} className={SocialMediaCSS.eventSharImage} />
                    </Box>

                    <Box pt={1}>
                        <Typography className={SocialMediaCSS.textContent} >{post?.conference_title}</Typography>
                    </Box>
                    <Box pt={1}>
                        <Typography className={SocialMediaCSS.rfpEvents} ref={postContentRef} style={{ maxHeight: showFullText ? 'none' : '53px', overflow: 'hidden' }} > {getTextFromHtml(post?.conference_body)}</Typography>
                        {showViewMore && (
                            <Button onClick={(e) => toggleFullText(e)} className={SocialMediaCSS.viewMoreButton} ref={viewMoreButtonRef} style={{ display: 'block', marginTop: '15px' }}>
                                {showFullText ? 'View Less' : 'View More'}
                            </Button>
                        )}
                    </Box>

                    <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`} pt={2}>
                        <Box className={`${SocialMediaCSS.f}`}>
                            <Typography className={SocialMediaCSS.rfpsEvent} sx={{ whiteSpace: 'nowrap' }}>{post?.conference_mode}</Typography>
                            {/* <Typography className={SocialMediaCSS.rfpsEvent}>{post?.event_attendance} attendance</Typography> */}
                        </Box>
                        < CommonButton2
                            label="View Conference"
                            name="viewConference"
                            variant="outlined"
                            type="button"
                            onClick={() => handlerConferenceBookMark(post?.conference_title, post?.gid)}
                            style={{ padding: '6px 16px', fontSize: '14px', height: '32px', fontWeight: '600' }}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default ConferenceBookMark;
