import React from 'react'
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css'
import { Box, Grid, Typography } from '@mui/material'
import CommonButton2 from '../../../../common/components/CommonButton2'
import theme from '../../../../assets/material-ui/theme'
import { useNavigate } from 'react-router'

const LeftSideCardNew = () => {
    const navigate = useNavigate();
    return (
        <Box className={`${SocialMediaCSS.smCard} ${SocialMediaCSS.titleCard}`}>
            <Grid container sx={{ padding: '16px 32px' }} spacing={2}>
                <Grid item xs={12}>
                    <Typography className={SocialMediaCSS.LeftCardTitle}>New to Bvokal?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={SocialMediaCSS.LeftCardSubtitle}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, distinctio omnis.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <CommonButton2
                        label="Create account"
                        name="Create account"
                        type="Create account"
                        variant="contained"
                        // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                        style={{ padding: '6px 32px', backgroundColor: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, boxShadow: 'none', borderRadius: '20px' }}
                        onClick={() => navigate('/signup')}
                    />
                    <CommonButton2
                        label='Log in'
                        name='Log in'
                        type='Log in'
                        variant="outlined"
                        // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                        style={{ padding: '6px 32px', color: theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, borderColor: theme.mainPalette.primary.primary110, borderRadius: '20px', marginTop: '10px' }}
                        onClick={() => navigate('/login')}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default LeftSideCardNew