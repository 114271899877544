import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import theme from '../../assets/material-ui/theme';
import { Box, OutlinedInput, Typography } from '@mui/material';
import { useState } from 'react';

export const RenderTextArea = ({
    input,
    label,
    placeholder,
    type,
    fullWidth,
    maxLength,
    underlineStyle,
    errorStyle,
    style,
    toolTiptext,
    id,
    pattern,
    defaultValue,
    disabled,
    min,
    max,
    rows,
    multiline,
    maxWords,
    value,
    meta: { touched, error, warning },
    inputProps,
    errorMessages,
    placeholderFontSize,
    placeholderFontWeight,
    custom,
    showCount,
    customHeight,
    radius,
    countFontSize,
}) => {
    let inputType = type;
    const [charCount, setCharCount] = useState(input.value ? input.value.length : 0);

    useEffect(() => {
        // Update charCount when input value changes
        setCharCount(input.value ? input.value.length : 0);
    }, [input.value]);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const currentLength = inputValue.length;
        setCharCount(currentLength); // Update charCount with the current length of input value
        input.onChange(inputValue); // Call the onChange function passed through props to update the form value
    };
    errorStyle = {
        fontSize: '10px',
        color: 'rgb(244, 67, 54)',
        position: 'static',
    };
    return (
        <>
            <TextField
                multiline={multiline}
                rows={rows}
                placeholder={placeholder}
                id={id}
                disabled={disabled}
                autoComplete="off"
                pattern={pattern}
                type={inputType}
                fullWidth={fullWidth}
                // value={defaultValue}
                value={input.value}
                onChange={handleChange}
                min={min}
                inputProps={{ maxLength: maxLength }}
                InputProps={{ sx: { borderRadius: radius ? radius : 4, background: theme.palette.secondary.dark, } }}
                sx={{
                    '& .MuiOutlinedInput-input': {
                        fontSize: placeholderFontSize, // Set placeholder font size
                        fontWeight: placeholderFontWeight,
                    },
                    '& .MuiFormLabel-root': {
                        fontSize: '16px', // Set label font size (optional)
                    },
                }}

                max={max}
                {...input}
                {...custom}
            />
            <Box sx={{ display: "flex", paddingTop: '6px' }}>
                {touched && (error && (
                    <Box className='error' sx={{ marginRight: 'auto' }}>
                        {error}
                    </Box>
                ))}
                {showCount && (
                    // <Box sx={{ display: 'flex', justifyContent: 'flex-end',alignItems:'end' }}>
                    <Typography sx={{ fontSize: countFontSize ? countFontSize : '10px', color: '#666', fontWeight: "500", marginLeft: 'auto', fontStyle: 'italic' }}>{`${charCount}/${maxLength}`}</Typography>
                    // </Box>
                )}</Box>
        </>
    );
};
export default RenderTextArea;

