// MessageList.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Paper, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import _ from 'lodash-es';
import { timeAgo } from '../../../../common/utils';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { CSSTransition } from 'react-transition-group';
import CommonInitials from '../../../../common/components/CommonInitials';
import theme from '../../../../assets/material-ui/theme';
import ChatSkeleton from './ChatSkeleton';

const MessageList = ({ messages, userData, brandData, socialMedia }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState('');
    const open = Boolean(anchorEl);
    const messagesEndRef = useRef(null);


    const handleClick = (event, message) => {
        setAnchorEl(event.currentTarget);
        setSelectedMessage(message);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedMessage('');
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(selectedMessage);
        handleClose();
    };


    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView();
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <Box className={SocialMediaCSS.chatContainer} >
            {/* <CSSTransition
                in={socialMedia.isChatMessagesLoading}
                timeout={0}
                classNames={{
                    enter: SocialMediaCSS['fade-enter'],
                    enterActive: SocialMediaCSS['fade-enter-active'],
                    exit: SocialMediaCSS['fade-exit'],
                    exitActive: SocialMediaCSS['fade-exit-active']
                }}
                unmountOnExit
            >
                <ChatSkeleton />
            </CSSTransition> */}
            {/* {!socialMedia.isChatMessagesLoading && ( */}

                    <>
                        {!_.isEmpty(messages) ? (
                            messages.map((message, index) => (
                            <Box className={`${SocialMediaCSS.chatCardContainer} ${message.sender_gid === userData?.gid ? SocialMediaCSS.senderChatCardContainer : SocialMediaCSS.receiverChatCardContainer}`} key={index} ref={messagesEndRef}>
                                <CommonInitials
                                    name={message.sender_gid === userData?.gid ? userData?.initials : brandData?.initials}
                                    bgColor={message.sender_gid === userData?.gid ? userData?.color_code : brandData?.color_code}
                                    divStyle={{ padding: '2px', maxHeight: '30px', maxWidth: '30px', minHeight: '30px', minWidth: '30px', borderRadius: '50%', boxShadow: theme.shadow.softShadow4, flex: '0 0 30px', borderColor: '#e9ecef', border: '1px solid #dee2e6' }}
                                    typoStyle={{ fontSize: '11px', lineHeight: '25px', }}
                                    avatarStyle={{ width: '30px', height: '30px', boxShadow: theme.shadow.softShadow4, }}
                                    src={`${message.sender_gid === userData?.gid ? userData?.profile_pic : brandData?.profile_image}`}
                                    companyLogo={`${message.sender_gid === userData?.gid ? userData?.profile_pic : brandData?.profile_image}`}
                                    avatar={true}
                                />
                                <Box className={`${SocialMediaCSS.chatCard} ${message.sender_gid === userData?.gid ? SocialMediaCSS.senderChatCard : SocialMediaCSS.receiverChatCard}`}>
                                    <Box className={SocialMediaCSS.chatCardBody}>
                                        <Typography variant="body2" className={SocialMediaCSS.chatMsg}>{message.message}</Typography>
                                        <IconButton sx={{ padding: 0 }} onClick={(e) => handleClick(e, message.message)}>
                                            <MoreVertIcon sx={{ color: message.sender_gid === userData?.gid ? '#fff' : '#0000008a', rotate: '90deg' }} />
                                        </IconButton>
                                    </Box>

                                    <Typography 
                                        variant="body5" 
                                        color={message.sender_gid === userData?.gid ? "#FFF" : "text.secondary"} 
                                        sx={{ display: 'block', fontWeight:500, textAlign: 'right', mt: 1 }}
                                    >
                                            {timeAgo(message.sent_at)}
                                    </Typography>
                                </Box>
                            </Box>))
                        ) : (<Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center', flexGrow:'1'}} >
                                <Typography variant='subtitle2'>{socialMedia.isChatMessagesLoading ? "Loading..." : "No messages here."}</Typography>
                            </Box>
                        )}
                    </>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} >
                <MenuItem onClick={handleCopy} sx={{ gap: '10px', p: '4px 8px', minWidth: '150px' }} >
                    Copy
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default MessageList;