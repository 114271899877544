import { Dialog, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Cancel_sechedule from "../../../assets/icons/Cancel_sechedule.svg";
import theme from '../../../assets/material-ui/theme';
import CommonButton2 from '../../../common/components/CommonButton2';
import { gerCurrentDate, getCurrentTime } from '../../../common/utils';
import EventsCSS from './EventsCSS/Events.module.css';

const PublishPopup = ({ events,open, onClose, Content, title, ButtonText1,text1, handleButton2 }) => {
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }

    return (
        <>
            <Dialog open={open} onClose={handleDlgClose} PaperProps={{ sx: { minWidth: isXsScreen ? '600px' : '300px', borderRadius: '24px' } }}>
                <Box className={EventsCSS.commentTitleBox}>
                    <Typography className={EventsCSS.approvalPopupTitle}>{title}</Typography>
                    <Box>
                        <img src={Cancel_sechedule} onClick={onClose} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '-6px' }} />
                    </Box>
                </Box>
                <Box className={EventsCSS.approvalBorderBox}> </Box>
                <Box padding="32px">
                    <Typography sx={{ color: '#666', fontWeight: '400' }}>
                        {Content}
                    </Typography>
                    {events && ((events?.eventScheduleDates[0] === gerCurrentDate() && events?.eventScheduleTimes[0]?.start_time < getCurrentTime()) || events.eventScheduleDates[0] < gerCurrentDate()) &&(
                    <Typography sx={{ color: '#666', fontWeight: '400' }}>
                        {text1}
                    </Typography>
                    )}
                </Box>
                <Box className={EventsCSS.approvalBorderBox}> </Box>
                <Box className={EventsCSS.approvalBtnBox}>
                    <CommonButton2
                        label={ButtonText1}
                        name={ButtonText1}
                        type="button"
                        color="primary"
                        variant='contained'
                        style={{
                            padding: '6px 32px', fontSize: '14px', backgroundColor: theme.palette.primary.dark,
                        }}
                        onClick={() => handleButton2()}
                    />
                </Box>

            </Dialog >
        </>
    )
}

export default PublishPopup;