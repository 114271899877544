import React, { useEffect, useRef, useState } from 'react';
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';
import SocialMediaEventCSS from '../../../SocialMediaCSS/SocialMediaEvent.module.css';
import { Box, CircularProgress, Divider, Fade, Grid, Typography } from '@mui/material';

import InpersonIcon from '../../../../../assets/icons/inPerson.svg';
import OnlineIcon from '../../../../../assets/icons/online.svg';
import ThematicIcon from '../../../../../assets/icons/Thematic.svg';

import EventTypeLabel from './EventTypeLabel';
import EventLisitingActionBtn from './EventLisitingActionBtn';
import EventLisitingLocationIcon from './EventLisitingLocationIcon';

import TotalEventsIcon from '../../../../../assets/icons/totalEventsIcon.svg';
import MyEventsIcon from '../../../../../assets/icons/myEventsIcon.svg';
import CompletedEventsIcon from '../../../../../assets/icons/completedEventsIcon.svg';
import ContriHoursIcon from '../../../../../assets/icons/contributedHoursIcon.svg';

import InsightCard from './EventDetailsSubComponents/InsightCard';
import { getMyEventsList } from '../actions';
import { EVENTS, status, STATUS_CODE } from '../../../../../common/constants';
import _ from 'lodash-es';
import { getDayFromDate, getMonthFromDate } from '../../../../../common/utils';
import CommonInitials from '../../../../../common/components/CommonInitials';

// const parseDate = (dateString) => {
//     const [day, month, year] = dateString.split('-');
//     const date = new Date(`${year}-${month}-${day}`);
//     const monthName = date.toLocaleString('default', { month: 'long' });
//     return { day, month: monthName };
// };

const EventMyEventList = ({ eventList, handleEventDrawerOpen, eventIsLoad, totalEventCount, dispatch, eventStatus, offset }) => {
    const [loading, setLoading] = useState(false);
    const eventStatusValues = useRef(null);
    let totalCount = useRef(0);
    let totalEvents = useRef(0);
    let isFecthing = false;

    useEffect(() => {
        // Add event listener when component mounts to detect scroll
        window.addEventListener('scroll', handleScroll);

        return () => {
            // Remove event listener when component unmounts
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        if (eventList?.list) {
            totalCount.current = eventList?.list.length;
            totalEvents.current = totalEventCount;
        }
    }, [eventList?.list, totalEventCount])
    useEffect(() => {
        if (eventStatus) {
            eventStatusValues.current = eventStatus;
            offset.current = 10;
        }
    }, [eventStatus])

    const handleScroll = () => {
        // Detect if user has scrolled to the bottom of the page
        if (isFecthing) return;
        if (totalCount.current < totalEvents.current) {
            console.log(totalCount.current, 'running')
            if (Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.scrollHeight) {
                // Fetch more data when user scrolls to the bottom
                try {
                    isFecthing = true;
                    offset.current = offset.current + 10;
                    dispatch(getMyEventsList(offset.current, true, setLoading, eventStatusValues.current))
                } catch (error) {
                    isFecthing = false;
                }
                setTimeout(() => {
                    isFecthing = false;
                }, 2000);
            }
        } else {
            setLoading(false);
        }
    };
    useEffect(() => {
        dispatch(getMyEventsList(offset.current, false, '', eventStatus));
    }, [])

    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <InsightCard icon={TotalEventsIcon} title='Total Events' count={eventList?.total_event_count} />
                    <InsightCard icon={MyEventsIcon} title='My Events' count={eventList?.my_events_count} />
                    <InsightCard icon={CompletedEventsIcon} title='Completed Events' count={eventList?.completed_events_count} />
                    <InsightCard icon={ContriHoursIcon} title='Contributed Hours' count={eventList?.contributed_hours} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} justifyContent={'center'}>
                    {!eventIsLoad && !_.isEmpty(eventList?.list) && eventList?.list.map((event) => {
                        const day = getDayFromDate(event?.created_at) || getDayFromDate(event?.start_date);
                        const month = getMonthFromDate(event?.created_at) || getMonthFromDate(event?.start_date);
                        const status = Object.values(STATUS_CODE).find(status => status.CODE === event?.status_code).STATUS_TEXT;
                        return (
                            <Grid item xs={12} key={event?.gid}>
                                <Box className={SocialMediaCSS.EventListCard} onClick={() => handleEventDrawerOpen(event?.gid)}>
                                    <Grid container>
                                        <Grid item xs={12} md='auto'>
                                            <Box className={SocialMediaCSS.leftEventDate}>
                                                <Box className={SocialMediaCSS.leftEvDetails}>
                                                    <Typography variant="h6" className={SocialMediaCSS.evDate}>{day}</Typography>
                                                    <Typography variant="body1" className={SocialMediaCSS.evMonth}>{month}</Typography>
                                                </Box>
                                                <Box className={SocialMediaCSS.leftEvlabel}>
                                                    <Typography variant='body4' className={SocialMediaCSS.leftEvlabelText}>{event?.event_status}</Typography>
                                                </Box>
                                                <EventTypeLabel type={event.event_type} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md className={SocialMediaCSS.eventListR} zeroMinWidth>
                                            <Grid container spacing={2}>
                                                <Grid item md={12} lg zeroMinWidth>
                                                    <Box className={SocialMediaCSS.eventListRL}>
                                                        <Box className={SocialMediaCSS.eventListMidRLT}>
                                                            <CommonInitials name={event?.initials} bgColor={event?.colour_code} divStyle={{ padding: '3px', maxHeight: '32px', maxWidth: '32px', minHeight: '32px', minWidth: '32px', borderRadius: '50%', boxShadow: '5px 5px 10px 0px rgba(170, 170, 204, 0.5), -5px -5px 10px 0px rgba(255, 255, 255, 1)', }} typoStyle={{ fontSize: '12px', lineHeight: '25px', }} avatarStyle={{ width: '32px', height: '32px', boxShadow: '5px 5px 10px 0px rgba(170, 170, 204, 0.5), -5px -5px 10px 0px rgba(255, 255, 255, 1)' }} src={`${event?.logo}`} companyLogo={event?.logo} avatar={true} />
                                                            {/* <Box className={SocialMediaCSS.eventCreatorLogo}>
                                                                <img src={event.companyLogo} alt='Event Creator Logo' className={SocialMediaCSS.h32} />
                                                            </Box> */}
                                                            <Typography variant="h6" className={SocialMediaCSS.eventListTitle}>
                                                                {event.event_name}
                                                            </Typography>
                                                        </Box>
                                                        <Box className={SocialMediaCSS.eventListMidRLB}>
                                                            <img src={event.event_mode === "Online" ? OnlineIcon : InpersonIcon} alt='Event Type' className={SocialMediaCSS.h20} />
                                                            <Typography variant="body2" className={SocialMediaCSS.eventType}>{event.event_mode}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={12} lg='auto' className={SocialMediaCSS.eventListRR}>
                                                    <Box className={SocialMediaCSS.eventListingOther}>
                                                        <Box className={SocialMediaCSS.eventListingIconText}>
                                                            <img src={ThematicIcon} alt='Thematic Area' className={SocialMediaCSS.h16} />
                                                            <Typography variant="body2" className={SocialMediaCSS.eventListingThematicText}>
                                                                {event.thematic_area}
                                                            </Typography>
                                                        </Box>
                                                        <Box className={SocialMediaCSS.eventListingIconText}>
                                                            <EventLisitingLocationIcon view='listView' eventType={event.event_mode} location={event.conference_platform} />
                                                            <Typography variant="body2" className={SocialMediaCSS.eventListingLocationText}>
                                                                {event?.conference_platform?.title || `${event.district}, ${event.state}`}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box className={SocialMediaCSS.eventListingActionBox}>
                                                        <EventLisitingActionBtn view='listView' status={event.event_status} certificateIssued={event.certificate_issued} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        );
                    })}
                    {eventIsLoad && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                        <Fade
                            in={eventIsLoad}
                            style={{
                                transitionDelay: eventIsLoad ? '100ms' : '0ms',
                            }}
                            unmountOnExit
                        >
                            <Box className={SocialMediaEventCSS.loadingBox}>
                                <CircularProgress />
                                <Typography className={SocialMediaEventCSS.loadingText}>Loading...</Typography>
                            </Box>
                        </Fade>
                    </Box>}
                    {loading && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                        <Fade
                            in={loading}
                            style={{
                                transitionDelay: loading ? '100ms' : '0ms',
                            }}
                            unmountOnExit
                        >
                            <Box className={SocialMediaEventCSS.loadingBox}>
                                <CircularProgress />
                                <Typography className={SocialMediaEventCSS.loadingText}>Loading...</Typography>
                            </Box>
                        </Fade>
                    </Box>}
                    {_.isEmpty(eventList?.list) && !eventIsLoad && <Box className={`${SocialMediaEventCSS.f} ${SocialMediaEventCSS.jc_center}`} sx={{ m: '230px 0px', width: '100%' }}>
                        <Typography sx={{ lineHeight: '36px', fontSize: '30px', fontWeight: 500, color: '#666666' }}>No Events yet, all Events will be shown here.</Typography>
                    </Box>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default EventMyEventList
