import React, { useEffect, useState } from 'react';
import { Dialog, useMediaQuery, Fade, Typography, Grid, Box, DialogActions } from '@mui/material';
import ReduxForm from './ReduxForm';
import CommonInputNew from './CommonInputNew';
import { Link } from 'react-router-dom';
import CommonButtonNew from './RectangularInputs/CommonButtonNew';
import theme from '../../assets/material-ui/theme';
import { completeRegistration, doTempRegister, resendOTP } from '../../modules/Registration/actions';
import { useSelector } from 'react-redux';
import * as clientValidations from '../validations'
import bvokalLogo from '../../assets/images/BvokalNew.svg';
import EditIcon from '../../assets/icons/EditBlue.svg';
import { change, initialize, reset } from 'redux-form';
import CommonButton2 from './CommonButton2';
import SignUpMailSentForm from '../../modules/Registration/common/SignupPages/SignUpMailSentForm';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} style={{ transitionDelay: ref ? '300ms' : '0ms' }} />;
});

const SignUpDialog = ({ open, onClose, dispatch, navigate, CommonCss, handleSignUpClose, selectedOption }) => {
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    const user = useSelector((state) => {
        return state.users;
    })
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [orgLabel, setOrgLabel] = useState(true);
    const [remainingTime, setRemainingTime] = useState(0);

    const handleEditClick = () => {
        dispatch(reset('signUPMailSent'));
        handleSignUpClose();
    };
    const handleSubmit = (values) => {
        dispatch(completeRegistration(values, navigate, onClose));
    }
    const handleResendClick = () => {
        // Disable the button and set a timer to re-enable it after 1 minute.
        setIsResendDisabled(true);
        setRemainingTime(60); // Set initial remaining time to 60 seconds (1 minute).
        const timerInterval = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime === 1) {
                    clearInterval(timerInterval); // Stop the timer when it reaches 0.
                    setIsResendDisabled(false); // Re-enable the button.
                }
                return prevTime - 1;
            });
        }, 1000); // Update the remaining time every 1 second.

        // Dispatch the resendActivationEmail action.
        // dispatch(resendActivationEmail(user.tempUserData.email));
        dispatch(resendOTP(user.tempUserData.email))
        dispatch(change('signUPMailSent', 'signUpOtp', ''));
    };

    useEffect(() => {
        setRemainingTime(60); // Set initial remaining time to 60 seconds (1 minute).
        const timerInterval = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime === 1) {
                    clearInterval(timerInterval); // Stop the timer when it reaches 0.
                    setIsResendDisabled(false); // Re-enable the button.
                }
                return prevTime - 1;
            });
        }, 1000);
        dispatch(initialize("signUPMailSent", {}, false, { updateUnregisteredFields: false }));
        // if (user?.tempUserData && Array.isArray(user.userType)) {
        //     const searchUserType = user?.tempUserData && user?.userType.find((data) => data.id === user?.tempUserData?.user_type_id).user_type;
        //     if (searchUserType) {
        //         setOrgLabel(searchUserType);
        //     }
        // }
    }, []);

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted PaperProps={{ sx: { minWidth: isXsScreen ? '560px' : '300px', borderRadius: '16px', padding: 2, gap: '8px' } }}>
            <Grid container spacing={2} className={CommonCss.signUpRoot}>
                <Grid item xs={12} textAlign={'center'} alignSelf={'stretch'} my={'-10px !important'} className={CommonCss.p0}>
                    <img src={bvokalLogo} height={80} />
                </Grid>
                <Grid item xs={12} className={`${CommonCss.signUpMailGrid} ${CommonCss.p0}`}>
                    <Typography className={CommonCss.signUpMailTypo}>
                        We have emailed a code to
                    </Typography>
                    <Box className={CommonCss.signUpMailBox}>
                        <Typography className={CommonCss.signUpMailTypo2}>
                            {user.tempUserData && user.tempUserData.email}
                        </Typography>
                        <img src={EditIcon} height={24} style={{ cursor: 'pointer' }} onClick={handleEditClick} />
                    </Box>
                    <Typography className={CommonCss.signUpMailTypo3}>
                        If you haven't received the account verification Code, please click on the 'Resend Code’ button.
                    </Typography>
                </Grid>
                <Grid item xs={12} className={CommonCss.p0}>
                    <SignUpMailSentForm 
                        RegCSS={CommonCss}
                        handleSubmit={handleSubmit}
                        handleResendClick={handleResendClick}
                        isResendDisabled={isResendDisabled}
                        remainingTime={remainingTime}
                        isBusinessSuite={true}
                    />
                </Grid>
            </Grid>
            <DialogActions className={CommonCss.createAccAction} sx={{ padding: '8px 0px 0px 0px' }}>
                <Typography className={CommonCss.createAccActionTypo} onClick={onClose}>Close</Typography>
            </DialogActions>
        </Dialog>
    );
};

export default SignUpDialog;