import { Box, Typography } from '@mui/material';
import React from 'react';
import Lock_Password from "../../../../../assets/icons/Lock_Password.svg"
import SocialMediaCSS from '../../../SocialMediaCSS/SocialMedia.module.css';

const ResharePostDelCard = () => {

    return (
        <>
            <Box className={SocialMediaCSS.reshareDelCard} >
                <img src={Lock_Password} />
                <Box>
                    <Typography className={SocialMediaCSS.delCardTexthead}>This content isn’t available at the moment</Typography>
                    <Typography className={SocialMediaCSS.delCardtext}>This usually happens when owner shared it with limited group of people, changed the visibility settings or it's been deleted.</Typography>
                </Box>
            </Box>
        </>
    )
}
export default ResharePostDelCard;