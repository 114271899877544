import { Box, FormControl } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

export const RenderProgress = ({
    val,
    input,
    min,
    max,
    backgroundColor,
    meta: { touched, error, warning },
    ...props
}) => {
    const [value, setValue] = useState(val);

    const normalise = (value, MIN, MAX) => ((value - MIN) * 100) / (MAX - MIN);

    const normalisedValue = normalise(value, min, max);

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: "6px",
        borderRadius: "36px",
        [`&.${linearProgressClasses.colorPrimary}`]: {
            // backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
            backgroundColor: backgroundColor || theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: "36px",
            backgroundColor: theme.palette.mode === 'light' ? '#0775BA' : '#308fe8',
        },
    }));

    useEffect(() => {
        setValue(val);
    }, [val]);

    return (
        <FormControl fullWidth>
            <Box sx={{ width: '100%', marginTop: '-5px !important' }}>
                <BorderLinearProgress
                    variant="determinate"
                    value={normalisedValue}
                />
            </Box>
            {touched && (error && (
                <Box className='error'>
                    {error}
                </Box>
            ))}
        </FormControl>
    );
};

RenderProgress.propTypes = {
    meta: PropTypes.object.isRequired,
    val: PropTypes.number.isRequired,
    input: PropTypes.object.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    backgroundColor: PropTypes.string,
};
