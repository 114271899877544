import React, { useEffect, useState, useRef } from 'react'
import MainHeader from './MainHeader';
import CustomSnackbar from './CommonSnackbar';
import { useSelector } from 'react-redux';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import CommonCSS from '../Common.module.css';
import { useDispatch } from 'react-redux';
import RightChatSideBar from '../../modules/SocialMedia/Components/Chats/RightChatSideBar';
import { getPostTypes, submitSupport } from '../actions';
import { getMasterData } from '../../modules/SocialMedia/actions';
import ReduxForm from './ReduxForm';
import SingleSelect from './SingleSelect';
import * as clientValidations from '../validations';
import CommonInput from './CommonInput';
import theme from '../../assets/material-ui/theme';
import CommonButton2 from './CommonButton2';
import { submit } from 'redux-form';
import { setSnackbar } from '../../store/slices/MessageSlice';

const HelpAndSupport = () => {
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState('');
    const snackbarProps = useSelector((state) => state.message);
    const { master } = useSelector(state => state);
    const isMdScreen = useMediaQuery('(max-width: 1280px)');
    const [file, setFile] = useState(null);
    const [disable, setDisableButton] = useState(false);
    const dispatch = useDispatch();
    const handleSubmitForms = () => {
        dispatch(submit('supportForm'));
        fileInputRef.current.value = '';
        setFileName('');
    }
    const handleFile = (e) => {
        let doc = e.target.files[0];
        const maxFileSizeInBytes = 2 * 1024 * 1024; // 2MB
        if (doc?.size > maxFileSizeInBytes) {
            setDisableButton(true);
            dispatch(setSnackbar({ flag: true, message: 'File size must be 2MB', type: "error" }));
        } else if (doc?.size < maxFileSizeInBytes) {
            setDisableButton(false);
            setFile(doc);
        } else {
            setFile(null);
        }
    }
    useEffect(() => {
        dispatch(getPostTypes());
        dispatch(getMasterData());
    }, [])
    return (
        <>
            <Box className={CommonCSS.MainHeader}>
                <MainHeader />
            </Box>
            {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Grid container className={CommonCSS.bgGrey100} height="100vh" >
                <Grid item xs={12} className={CommonCSS.mainContent}>
                    <Container>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={9}>
                                <Grid container className={CommonCSS.helpCard}>
                                    <Grid container spacing={2} className={`${CommonCSS.helpGrid} ${CommonCSS.offset_lg_3} ${CommonCSS.col_lg_6} ${CommonCSS.p_lg_5}`}>
                                        <ReduxForm
                                            onSubmit={(values) => dispatch(submitSupport(values, file))}
                                            form="supportForm"
                                            label="Support">
                                            <Grid item xs={12} className={CommonCSS.helpGrid2}>
                                                <h3 className={CommonCSS.helph3}>Message Us
                                                </h3>
                                                <Typography className={CommonCSS.helpTypography}>What we can help you with?*</Typography>
                                                <SingleSelect
                                                    options={master?.requestTypes || []}
                                                    fullWidth={true}
                                                    name='user_query'
                                                    placeholder="Please select a reason."
                                                    radius="50px"
                                                    square={true}
                                                    customHeight={"58px"}
                                                    placeholderFontSize={"16px"}
                                                    isSupport={true}
                                                    validate={[clientValidations.required]}
                                                />
                                                <Typography className={CommonCSS.helpTypography} mt={2}>What do you have in your mind?*</Typography>
                                                <CommonInput
                                                    name="description"
                                                    placeholder={''}
                                                    type="TextArea"
                                                    keyboardType="default"
                                                    fullWidth
                                                    rows={5}
                                                    multiline
                                                    radius={'10px'}
                                                    placeholderFontSize={'12px'}
                                                    countFontSize={'14px'}
                                                    // showCount={showCount}
                                                    custom={true}
                                                    // maxLength={100}
                                                    validate={[clientValidations.required, clientValidations.noHtmlTags, clientValidations.onlyTextandNumber]}
                                                />
                                                <Typography className={CommonCSS.helpTypography} mt={2}>Attach File (if any)</Typography>
                                                <input 
                                                    type='file' 
                                                    name='file' 
                                                    className={CommonCSS.helpInput} 
                                                    onChange={(e) => handleFile(e)} 
                                                    ref={fileInputRef}
                                                />
                                                <CommonButton2
                                                    label="Send"
                                                    name="save"
                                                    type="button"
                                                    color="primary"
                                                    variant='contained'
                                                    style={{ padding: '0.5rem 1rem', fontSize: '1rem', backgroundColor: theme.palette.primary.main, maxWidth: '150px', margin: '20px 0' }}
                                                    onClick={() => handleSubmitForms()}
                                                    disabled = {disable}
                                                />
                                            </Grid>
                                        </ReduxForm>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {!isMdScreen && <Grid item xs={12} lg={3}>
                                <RightChatSideBar />
                            </Grid>}
                        </Grid>
                    </Container>
                </Grid >
            </Grid >
        </>
    )
}

export default HelpAndSupport