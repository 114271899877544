import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Grid, Radio, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import chooseAcc from '../../../../assets/images/chooseAcc.svg'
import company from '../../../../assets/images/company.svg'
import personal from '../../../../assets/images/personal.svg'
import theme from '../../../../assets/material-ui/theme'
import { PROFILE_TYPE } from '../../../../common/constants'
import { getProfileTypes } from '../../actions'

const ChooseAccount = ({ RegCSS, setSelectedOption, selectedOption, handleNavigate, dispatch, master }) => {
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        dispatch(getProfileTypes());
    }, [])


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={RegCSS.signUpMailHeading} textAlign={'center'}>
                        Choose your account type!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sx={{ paddingLeft: '22px !important' }}>
                            <Grid item xs={12} className={RegCSS.chooseAccGrid}>
                                {master?.profileTypes && master?.profileTypes.map((profile) => (
                                    <Box className={RegCSS.chooseAccCard} key={profile.key}>
                                        <Radio
                                            checked={selectedOption === profile.key}
                                            onChange={handleChange}
                                            value={profile.key}
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                            sx={{ width: '18px', height: '18px', flexShrink: 0, }}

                                        />
                                        <img src={profile.image.url} alt='logo' className={RegCSS.chooseAccImg} />
                                        <Box className={RegCSS.chooseAccBox}>
                                            <Typography className={RegCSS.chooseAccTypo}>{profile.value}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                                {/* <Box className={RegCSS.chooseAccCard}>
                                    <Radio
                                        checked={selectedOption === PROFILE_TYPE.COMPANY}
                                        onChange={handleChange}
                                        value={PROFILE_TYPE.COMPANY}
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                        sx={{ width: '18px', height: '18px', flexShrink: 0, }}
                                    />
                                    <img src={company} alt='logo' className={RegCSS.chooseAccImg} />
                                    <Box className={RegCSS.chooseAccBox}>
                                        <Typography className={RegCSS.chooseAccTypo}>Company</Typography>
                                    </Box>
                                </Box> */}
                            </Grid>
                            <Grid item xs={12} className={RegCSS.chooseAccGrid2}>
                                <Box className={RegCSS.chooseAccArrow}
                                    sx={{
                                        background: selectedOption ? 'var(--Secondary-110, #06BB70)' : 'var(--Gray-100, #F5F5F8)',
                                        filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.12))'
                                    }} disabled={selectedOption === ''}
                                    onClick={() => selectedOption ? handleNavigate() : null}>
                                    <ArrowForwardIcon sx={{ color: selectedOption ? theme.mainPalette.grey.white : theme.mainPalette.grey.grey400 }} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display='flex' justifyContent='center'>
                                    <Typography sx={{ fontSize: '14px', color: theme.mainPalette.grey.darkText, fontWeight: theme.typography.fontWeightMedium }}>Already have an account?</Typography>
                                    <Link to='/login' underline="always" style={{ marginLeft: 8, color: theme.palette.primary.dark, fontSize: '14px', fontWeight: theme.typography.fontWeightMedium }}>Login</Link>
                                </Box>
                            </Grid>
                            <Grid item xs={12} className={RegCSS.chooseAccGrid3}>
                                <img src={chooseAcc} alt="acc" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChooseAccount
