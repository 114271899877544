import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import { useDispatch } from "react-redux";
import MainHeader from '../../../../common/components/MainHeader';
import RfpListHeader from "./ListingSubComponents/RfpListHeader";
import RfpListingCard from "./ListingSubComponents/RfpListingCard";

import { useNavigate } from "react-router";
import { getMasterData } from "../../actions";
import { getThematicAreas } from "../../../../common/actions";
import CustomSnackbar from "../../../../common/components/CommonSnackbar";
import { useSelector } from "react-redux";

const RfpsListing = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const snackbarProps = useSelector((state) => state.message);
    const { users: { isLoggedIn }, socialMedia: { isPreviewOpen } } = useSelector(state => state);

    useEffect(() => {
        dispatch(getMasterData());
        //dispatch(getThematicAreas());
    }, [])
    return (
        <>
            <Box className={SocialMediaCSS.MainHeader} sx={{ zIndex: isPreviewOpen ? 0 : 99 }}>
                <MainHeader />
            </Box>
            {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Grid container className={SocialMediaCSS.rfpContainer}>
                <Grid item xs={12} className={SocialMediaCSS.rfpPaper}>
                    <RfpListHeader SocialMediaCSS={SocialMediaCSS} />
                    <RfpListingCard SocialMediaCSS={SocialMediaCSS} navigate={navigate} />
                </Grid>
            </Grid>
        </>
    )
}

export default RfpsListing;