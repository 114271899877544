import { IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import * as Icons from '@mui/icons-material';
import RFPCSS from '../../../RFPcss/RFP.module.css';

import { ReactComponent as CustomShareIcon } from '../../../../../assets/icons/share_white.svg';
import { ReactComponent as InviteProposalIcon } from '../../../../../assets/icons/inviteProposal.svg';
import { ReactComponent as Back } from "../../../../../assets/icons/ep_back.svg";
import { ReactComponent as Respond } from "../../../../../assets/icons/bi_reply-all.svg";
import { ReactComponent as preview } from "../../../../../assets/icons/preview_respond.svg";
import { ReactComponent as Cancel } from "../../../../../assets/icons/iconoir_cancel.svg";
import { ReactComponent as Status } from "../../../../../assets/icons/StatusSend.svg";
import { ReactComponent as Edit } from "../../../../../assets/icons/tabler.svg";

const IconStack = ({ iconName, title }) => {
    const iconMap = {
        VisibilityOutlinedIcon: Icons.VisibilityOutlined,
        InsertDriveFileOutlinedIcon: Icons.InsertDriveFileOutlined,
        SendIcon: CustomShareIcon,
        InviteProposalIcon: InviteProposalIcon,
        DriveFileRenameOutlineIcon: Icons.DriveFileRenameOutline,
        BackArrowIcon: Icons.ArrowBackRounded,
        Back: Back,
        Respond:Respond,
        preview:preview,
        cancel:Cancel,
        Send:Status,
        ResponseEdit:Edit,
    };
    const IconComponent = iconMap[iconName];
    return (
        <>
            <Stack direction="column" alignItems="center" spacing={0.5}>
                <IconButton classes={{ root: RFPCSS.pageActionIconButton }}>
                    {IconComponent ? <IconComponent className={RFPCSS.pageActionIcon} /> : null}
                </IconButton>
                <Typography variant="body4" className={RFPCSS.ibText}>
                    {title}
                </Typography>
            </Stack>
        </>
    )
}

export default IconStack
