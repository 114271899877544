// MessageInput.js
import React, { useState } from 'react';
import { Box, TextField, IconButton, InputBase } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import Chat from '../../../../assets/icons/Chat.svg';

const MessageInput = ({ onSend }) => {
  const [message, setMessage] = useState('');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSend();
    }
};

  const handleSend = () => {
    if (message.trim()) {
      onSend(message);
      setMessage('');
    }
  };

  return (
    <Box className={SocialMediaCSS.messageInput} display="flex" alignItems="center" p={2}>
      <InputBase
        placeholder="Type a message..."
        fullWidth
        variant="outlined"
        multiline
        minRows={1}
        maxRows={1}
        inputProps={{maxLength: 1000}}
        classes={{
          root: SocialMediaCSS.commentTextInput,
          input: SocialMediaCSS.commentTextInputInput,
          focused: SocialMediaCSS.commentTextInputFocused,
        }}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        endAdornment={(
          <IconButton
            color="primary"
            aria-label="Send"
            className={SocialMediaCSS.sendCommentIcon}
            onClick={handleSend}
            disabled={!message.trim()}
          >
            <img src={Chat} alt='Send' className={SocialMediaCSS.h18} />
          </IconButton>
        )}
      />
    </Box>
    // <Box className={SocialMediaCSS.messageInput} display="flex" alignItems="center" p={2}>
    //   <TextField
    //     fullWidth
    //     variant="outlined"
    //     placeholder="Type a message..."
    //     value={message}
    //     onChange={(e) => setMessage(e.target.value)}
    //   />
    //   <IconButton color="primary" onClick={handleSend}>
    //     <SendIcon />
    //   </IconButton>
    // </Box>
  );
};

export default MessageInput;
