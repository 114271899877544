import { Box, Divider, IconButton, ListItemIcon, MenuItem, Popover, Tooltip, tooltipClasses, Typography, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import LikeIcon from '../../../../assets/icons/like_lightGrey.svg';
import CommentIcon from '../../../../assets/icons/comment_lightGrey.svg';
import ShareIcon from '../../../../assets/icons/share_lightGrey.svg';
import ReshareIcon from '../../../../assets/icons/postReshare.svg';
import ThematicIcon from '../../../../assets/icons/Thematic.svg';
import theme from '../../../../assets/material-ui/theme';
import { SharePopup } from '../Popup/SharePopup';
import AllReactions from './AllReactions';
import { ConnnectionList } from '../Popup/ConnectionList';
import { useSelector } from 'react-redux';
import PostComments from './PostComments';
import { useDispatch } from 'react-redux';
import { fetchPostComments, reshareWithoutTextPost } from '../../actions';
import { setPostMode } from '../../../../store/slices/SocialMediaSlice';
import reshared from '../../../../assets/icons/reshared.svg';
import thoughts from '../../../../assets/icons/message-edit.svg';
import { reset } from 'redux-form';
import { ResharePost } from '../Popup/PostReshareProcess.jsx/ResharePost';
import ReshareProcess from '../Popup/PostReshareProcess.jsx/ReshareProcess';
import { useNavigate } from 'react-router';
import { SharedList } from '../Popup/SubComponents/SharedList';

const ThematicTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.mainPalette.grey.white,
        padding: '8px',
        boxShadow: theme.shadow.softShadow3
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.mainPalette.grey.white,
    },
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.mainPalette.grey.white,
        color: theme.mainPalette.grey.lightText,
        boxShadow: theme.shadow.softShadow3,
        padding: '8px'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.mainPalette.grey.white,
    },
}));

const PostActions = ({ post, master, users, form }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { socialMedia } = useSelector((state) => { return state; });
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [sharedDialogOpen, setSharedDialogOpen] = useState(false);
    const [connectionDialogOpen, setConnectionDialogOpen] = useState(false);
    const [commentsVisible, setCommentsVisible] = useState(false);
    const [postGid, setPostGid] = useState(false);
    const [popupProps, setPopupProps] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    const handleShareDialogOpen = (gid) => {
        if (users?.isLoggedIn) {
            setShareDialogOpen(true);
            dispatch(reset('postReshareform'));
            setPostGid(gid);
        } else {
            navigate('/login');
        }
        handlePopoverClose();

    };
    const handleSharedDialogOpen = () => {
        if (users?.isLoggedIn) {
            setSharedDialogOpen(true);
        } else {
            navigate('/login');
        }
    };

    const handleSharedDialogClose = () => {
        setSharedDialogOpen(false);
    };
    const handlePostResharewithoutText = (gid) => {
        dispatch(reshareWithoutTextPost(gid, navigate))
        handlePopoverClose();
    }
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open2 = Boolean(anchorEl);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleShareDialogClose = () => {
        setShareDialogOpen(false);
    };

    const handleProfileDialogOpen = (props) => {
        if (users?.isLoggedIn) {
            setPopupProps(props);
            setConnectionDialogOpen(true);
            dispatch(setPostMode('Post'));
        } else {
            navigate('/login');
        }
    };

    const handleProfileDialogClose = () => {
        setConnectionDialogOpen(false);
    };
    const handleToggleComments = (gid) => {
        if (users?.isLoggedIn) {
            dispatch(fetchPostComments(gid))
            setCommentsVisible(!commentsVisible);
        } else {
            navigate('/login');
        }
        // console.log(`Post GID: ${gid}`);
    };
    const getThematicAreaNames = (ids) => {
        return ids?.map(id => {
            const area = master?.thematicAreas?.find(area => area?.id === id);
            return area ? area?.value : 'Unknown';
        });
    };
    // const reactionIds = post?.primary_post?.total_reactions?.map(reaction => reaction?.key);
    // const reactionList = master?.reactionList?.find(react => react?.key === reactionIds);
    // const selectedReaction = reactionList ? reactionList.image : null;
    // const uniqueReactions = new Set(reactionIds);
    // console.log("frg", reactionIds)
    const reactionIds = post?.primary_post?.total_reactions?.map(reaction => reaction?.key);
    const uniqueReactions = new Set(reactionIds);

    // console.log("Unique Reaction IDs:", [...uniqueReactions]);
    return (
        <>
            <Box className={SocialMediaCSS.actionContainer}>
                <Box className={SocialMediaCSS.actionStats}>
                    <Box className={SocialMediaCSS.actionStatsCount}>
                        {/* {reactionIds?.map((reaction) => ( */}
                        {[...uniqueReactions].map((reactionKey) => {
                            const reaction = master?.postReaction?.find(react => react?.key === reactionKey);
                            const selectedReactionImage = reaction ? reaction.image : null;
                            // console.log("Reaction Key:", reactionKey); 
                            // console.log("Reaction Object:", reaction);
                            // console.log("Selected Reaction Image:", selectedReactionImage);
                            return (
                                <Box className={SocialMediaCSS.postReacted} onClick={() => handleProfileDialogOpen({ title: "Reaction", reaction: "true", type: "Post" })}>
                                    <img src={selectedReactionImage} className={SocialMediaCSS.h12} alt='Like' />
                                </Box>)
                        })}
                        {reactionIds?.length === 0 &&
                            <img src={LikeIcon} className={SocialMediaCSS.h12} alt='Like' />
                        }
                        <Typography variant='body4' className={SocialMediaCSS.statsText} ml={0.6}>
                            {post?.primary_post?.total_reaction_count}
                        </Typography>
                    </Box>
                    <LightTooltip title='Comments' placement='top' arrow>
                        <Box className={SocialMediaCSS.actionStatsCount}>
                            <img src={CommentIcon} className={SocialMediaCSS.h12} alt='Comment' />
                            <Typography variant='body4' className={SocialMediaCSS.statsText}>
                                {post?.primary_post?.comment_count}
                            </Typography>
                        </Box>
                    </LightTooltip>
                    <LightTooltip title='Share' placement='top' arrow >
                        <Box className={SocialMediaCSS.actionStatsCount} onClick={() => post?.multiple_shared_post ? handleSharedDialogOpen() : handleProfileDialogOpen({ title: "Share", share: 'true' })}>
                            <img src={ShareIcon} className={SocialMediaCSS.h12} alt='Share' />
                            <Typography variant='body4' className={SocialMediaCSS.statsText}>
                                {post?.primary_post?.share_count}
                            </Typography>
                        </Box>
                    </LightTooltip>

                    <ThematicTooltip
                        placement="top"
                        title={(
                            <Box className={SocialMediaCSS.thematicTooltipBox}>
                                {getThematicAreaNames(post?.primary_post?.post_thematic_area_ids || []).map((name, index) => (
                                    <Box className={SocialMediaCSS.thematicTooltipRow} key={index}>
                                        <img src={ThematicIcon} className={SocialMediaCSS.h12} alt='Thematic Area' />
                                        <Typography className={SocialMediaCSS.thematicName}>
                                            {name}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                        className={SocialMediaCSS.thematicTooltip}
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <Box className={SocialMediaCSS.thematicCount}>
                            <img src={ThematicIcon} className={SocialMediaCSS.h12} alt='Thematic Area' />
                            <Typography variant='body4' className={SocialMediaCSS.statsText}>
                                {post?.primary_post?.post_thematic_areas_count}
                            </Typography>
                        </Box>
                    </ThematicTooltip>
                </Box>

                <Box className={SocialMediaCSS.actionsContainer}>

                    <AllReactions master={master} postId={post?.gid} dispatch={dispatch} post={post} isLoggedIn={users?.isLoggedIn} navigate={navigate} />
                    <Box className={SocialMediaCSS.actions}>
                        <LightTooltip title='Comment' placement='top' arrow>
                            <IconButton size='small' onClick={() => handleToggleComments(post.gid)}>
                                <img src={CommentIcon} alt='Comment' className={SocialMediaCSS.h16} />
                            </IconButton>
                        </LightTooltip>
                        <LightTooltip title='Share' placement='top' arrow>
                            <IconButton size='small'
                                // onClick={() => handleShareDialogOpen(post.gid)}
                                onClick={handlePopoverOpen}
                            >
                                <img src={ReshareIcon} alt='Share' className={SocialMediaCSS.h16} />
                            </IconButton>
                        </LightTooltip>
                    </Box>
                </Box>
            </Box>
            <Popover
                open={open2}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                className={SocialMediaCSS.threeDotsPopover}
            >
                <MenuItem className={SocialMediaCSS.threeDotsItem} sx={{
                    padding: '12px 0px',
                    borderBottom: '1px solid #CCC',
                    margin: '0 10px',
                    '&:last-of-type': {
                        borderBottom: 'none',
                    },
                    '& .MuiListItemIcon-root': {
                        minWidth: 'unset',
                        marginRight: '8px',
                    },
                }} onClick={() => handlePostResharewithoutText(post.gid)}>
                    <ListItemIcon>
                        <img src={reshared} />
                    </ListItemIcon>
                    Reshare
                </MenuItem>
                <MenuItem className={SocialMediaCSS.threeDotsItem} sx={{
                    padding: '12px 2px',
                    margin: '0 10px',
                    '& .MuiListItemIcon-root': {
                        minWidth: 'unset',
                        marginRight: '8px',
                    },

                }} onClick={() => handleShareDialogOpen(post.gid)}>
                    <ListItemIcon>
                        <img src={thoughts} />
                    </ListItemIcon>
                    Reshare with thoughts
                </MenuItem>
            </Popover>
            {/* <SharePopup */}
            <ReshareProcess
                open={shareDialogOpen}
                SocialMediaCSS={SocialMediaCSS}
                onClose={handleShareDialogClose}
                Title="Share to"
                postId={postGid}
                post={post}
                master={master}
                form={form}
                user={users}
            />
            {connectionDialogOpen && <ConnnectionList
                open={connectionDialogOpen}
                onClose={handleProfileDialogClose}
                // Title="Reaction"
                // reaction="true"
                Title={popupProps.title}
                reaction={popupProps.reaction}
                share={popupProps.share}
                type={popupProps.type}
                socialMedia={socialMedia}
                gid={post?.gid}
                master={master}
            />}
            {sharedDialogOpen &&
                <SharedList
                    open={sharedDialogOpen}
                    onClose={handleSharedDialogClose}
                    Title="Share"
                    socialMedia={socialMedia}
                    gid={post?.gid}
                />}
        
            {commentsVisible && <PostComments gid={post.gid} socialMedia={socialMedia} dispatch={dispatch} master={master} users={users} type={'comment'} />}
        </>
    )
}

export default PostActions
