import React from 'react';
import { Popover, List, ListItem, ListItemText } from '@mui/material';
import { SORTBY } from '../../../../../common/constants';

const SortByPopover = ({ anchorEl, open, onClose, onSelectOption }) => {

    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <List sx={{ padding: '0.25rem !important' }}>
                {SORTBY.map((option) => (
                    <ListItem button key={option.id} onClick={() => onSelectOption(option.id)} sx={{ '& .MuiListItemText-root': { width: '130px' } }}>
                        <ListItemText primary={option.name} sx={{ '& .MuiTypography-root': { fontSize: '0.875em', lineHeight: '21px' } }} />
                    </ListItem>
                ))}
            </List>
        </Popover>
    );
};

export default SortByPopover;
