import React from 'react';
import { Card, Avatar, Box, Typography, Skeleton } from '@mui/material';

const ChatConnectionsSkeletion = () => {
    const rows = Array.from({ length: 10 });
    return (
        <Box>
            {rows.map((_, index) => (
                <Card key={index} variant="outlined" sx={{ display: 'flex', alignItems: 'center', p: 1, mb: 1, borderRadius: '50rem' }}>
                    <Skeleton animation="wave" variant="circular" width={48} height={48} />
                    <Box sx={{ ml: 2, flexGrow: 1 }}>
                        <Skeleton animation="wave" width="40%" />
                        <Skeleton animation="wave" width="60%" />
                    </Box>
                    <Skeleton animation="wave" width="20%" />
                </Card>
            ))}
        </Box>
    );
}

export default ChatConnectionsSkeletion
