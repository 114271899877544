import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import EventRFPSection from "./EventRFPSection";
import ResourceCenter from "./ResourceCenter";
import SocialMediaFooter from "./SocialMediaFooter";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const RightSidebar = () => {
    const navigate = useNavigate();
    const { socialMedia, form , master, users,} = useSelector((state) => { return state; });
    return (
        <>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <EventRFPSection navigate ={navigate} socialMedia={socialMedia} master={master} isLoggedIn={users?.isLoggedIn}/>
                </Grid>
                {/* <Grid item xs={12}>
                    <ResourceCenter socialMedia={socialMedia} />
                </Grid> */}
                <Grid item xs={12}>
                    <SocialMediaFooter navigate={navigate} />
                </Grid>
            </Grid>
        </>
    );
}

export default RightSidebar;
