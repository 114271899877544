import { Dialog, Divider, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import CommonButton2 from '../../../../common/components/CommonButton2';
import cancel from "../../../../assets/icons/Cancel.svg"
import { setCreatePost, setPostAudience, setPostStatus, setShowActivity, setShowPostThematic, setTagPost } from '../../../../store/slices/SocialMediaSlice';
import { createPost } from '../../actions';
import { useDispatch } from 'react-redux';

const DiscardAlert = ({ events, open, onClose, Content1,title, ButtonText2, ButtonText1, handleDiscard, handleCancel,handleConfirm}) => {
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    const dispatch = useDispatch();
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }
    const handleSaveDraft = () => {
        onClose();
    };

    return (
        <>
            <Dialog open={open} onClose={handleDlgClose} PaperProps={{ sx: { minWidth: isXsScreen ? '600px' : '300px', borderRadius: '24px' } }}>
                <Box className={`${SocialMediaCSS.discardPost} ${SocialMediaCSS.discardHeader}`}>
                    <Box >
                        <Typography className={SocialMediaCSS.DiscardTitle} >
                            {title}
                        </Typography>
                    </Box>
                    <Box className={SocialMediaCSS.cancelIcon} onClick={onClose}>
                        <img src={cancel} className={SocialMediaCSS.createPost} />
                    </Box>
                </Box>
                <Divider />
                <Box className={`${SocialMediaCSS.discardPost} ${SocialMediaCSS.paddingConfirm}`} >
                    <Typography sx={{ color: '#666', fontWeight: '400' }} >
                        {Content1}
                    </Typography>

                </Box>
                <Divider />
                <Box className={`${SocialMediaCSS.discardPost} ${SocialMediaCSS.f} ${SocialMediaCSS.jc_fend}`} sx={{ gap: '10px' }}>

                    <CommonButton2
                        label={ButtonText2}
                        name={ButtonText2}
                        //startIcon={<img src={theme} className={SocialMediaCSS.discardPostBtnImg}/>}
                        type="button"
                        color="primary"
                        variant='outlined'
                        style={{
                            padding: '6px 24px', fontSize: '14px'
                        }}
                        onClick={() => handleCancel()}
                    />

                    <CommonButton2
                        label={ButtonText1}
                        name={ButtonText1}
                        //startIcon={<img src={save} className={SocialMediaCSS.savePostBtnImg}/>}
                        type="button"
                        color="primary"
                        variant='contained'
                        style={{
                            padding: '6px 24px', fontSize: '14px', background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%',
                            // opacity: textExceeded ? 0.5 : 1,
                            // pointerEvents: textExceeded ? 'none' : 'auto'
                        }}
                        onClick={() => handleSaveDraft()}
                    // disabled={textExceeded}
                    />
                </Box>
            </Dialog >
        </>
    )
}

export default DiscardAlert;