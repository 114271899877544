import React, { useEffect } from "react";
import { Box, Grid, Typography, Skeleton } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import { getConnectionData } from "../../actions";
import InvitePartners from "./InvitePartners";
import ConnectionSkeleton from "./ConnectionSkeleton";
import MasterSlice from "../../../../store/slices/MasterSlice";

const ConnectionSummary = ({ socialMedia, users, dispatch, master, form }) => {
    useEffect(() => {
        dispatch(getConnectionData());
    }, [dispatch]);

    const companyTypes = Array.isArray(master?.connectionTypes) ? master.connectionTypes : [];
    const connectionTypeCount = socialMedia?.myDatabase?.Connection_type_count;
    const keysToCount = ["ngo", "companies", "csr", "agency", "academia", "personal"];
    let totalCount = 0;

    if (Array.isArray(connectionTypeCount)) {
        totalCount = connectionTypeCount.reduce((sum, obj) => {
            return sum + keysToCount?.reduce((acc, key) => {
                return acc + (obj[key] || 0);
            }, 0);
        }, 0);
    }
    return (
        <Box className={`${SocialMediaCSS.smCard} ${SocialMediaCSS.titleCard}`}>
            <Grid container>
                <Grid item xs={12} className={SocialMediaCSS.data}>
                    <Box className={SocialMediaCSS.MyDatabase}>
                        <Typography className={SocialMediaCSS.databaseTypo}>Connects</Typography>
                    </Box>
                    <Typography className={SocialMediaCSS.connects}>
                        {master.isLoad ? <Skeleton width="20%" /> : totalCount}
                    </Typography>
                </Grid>

                {!socialMedia.isConnectsCardLoading ? (
                    companyTypes.map((user, index) => {
                        const connectionData = socialMedia?.myDatabase?.Connection_type_count?.find(data => data.hasOwnProperty(user.key));
                        const userCount = connectionData ? connectionData[user.key] : 0;

                        return (
                            <Grid item xs={12} className={SocialMediaCSS.userCard} key={index}>
                                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`}>
                                    <Box className={SocialMediaCSS.userPadding}>
                                        <img src={user.image} className={SocialMediaCSS.userTypeimage} alt={`${user.value} icon`} />
                                    </Box>
                                    <Typography className={SocialMediaCSS.userType}>{user.value}</Typography>
                                </Box>
                                <Typography className={SocialMediaCSS.usercount}>{userCount}</Typography>
                            </Grid>
                        );
                    })
                ) : (
                    <ConnectionSkeleton />
                )}
            </Grid>
            <InvitePartners dispatch={dispatch} form={form} />
        </Box>
    );
};

export default ConnectionSummary;
