import { Grid, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { change, initialize } from "redux-form";
import EditIcon from '../../../assets/icons/EditBlue.svg';
import bvokalLogo from '../../../assets/images/BvokalNew.svg';
import CommonButton2 from "../../../common/components/CommonButton2";
import CommonInputNew from "../../../common/components/CommonInputNew";
import CommonButtonNew from '../../../common/components/RectangularInputs/CommonButtonNew';
import ReduxForm from '../../../common/components/ReduxForm';
import * as clientValidations from '../../../common/validations';
import { completeRegistration, resendOTP } from '../actions';

import theme from '../../../assets/material-ui/theme';
import RegCSS from '../common/RegistrationCSS/RegCSS.module.css';
import SignUpMailSentForm from "../common/SignupPages/SignUpMailSentForm";



export default function SignUpMailSent() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector((state) => {
        return state.users;
    })
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [orgLabel, setOrgLabel] = useState(true);
    const [remainingTime, setRemainingTime] = useState(0);

    const handleEditClick = () => {
        navigate('/signup', { state: { name: location.state.name, email: user.tempUserData.email, user_type_id: user.tempUserData.user_type_id } });
    };
    const handleResendClick = () => {
        // Disable the button and set a timer to re-enable it after 1 minute.
        setIsResendDisabled(true);
        setRemainingTime(60); // Set initial remaining time to 60 seconds (1 minute).
        const timerInterval = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime === 1) {
                    clearInterval(timerInterval); // Stop the timer when it reaches 0.
                    setIsResendDisabled(false); // Re-enable the button.
                }
                return prevTime - 1;
            });
        }, 1000); // Update the remaining time every 1 second.

        // Dispatch the resendActivationEmail action.
        // dispatch(resendActivationEmail(user.tempUserData.email));
        dispatch(resendOTP(user.tempUserData.email))
        dispatch(change('signUPMailSent', 'signUpOtp', ''));
    };
    const handleSubmit = (values) => {
        dispatch(completeRegistration(values, navigate));
    }
    useEffect(() => {
        setRemainingTime(60); // Set initial remaining time to 60 seconds (1 minute).
        const timerInterval = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime === 1) {
                    clearInterval(timerInterval); // Stop the timer when it reaches 0.
                    setIsResendDisabled(false); // Re-enable the button.
                }
                return prevTime - 1;
            });
        }, 1000);
        dispatch(initialize("signUPMailSent", {}, false, { updateUnregisteredFields: false }));
        // if (user?.tempUserData && Array.isArray(user.userType)) {
        //     const searchUserType = user?.tempUserData && user?.userType.find((data) => data.id === user?.tempUserData?.user_type_id).user_type;
        //     if (searchUserType) {
        //         setOrgLabel(searchUserType);
        //     }
        // }
    }, []);

    return (
        <Grid container className={RegCSS.signUpMailRoot}>
            <Grid item xs={12}>
                <Grid container spacing={5}>
                    <Grid item xs={12} textAlign={'center'}>
                        <img src={bvokalLogo} height={50} />
                    </Grid>
                    <Grid item xs={12} className={RegCSS.signUpMailGrid}>
                        <Typography className={RegCSS.signUpMailTypo}>
                            We have emailed a code to:
                        </Typography>
                        <Box className={RegCSS.signUpMailBox}>
                            <Typography className={RegCSS.signUpMailTypo2}>
                                {user.tempUserData && user.tempUserData.email}
                            </Typography>
                            <img src={EditIcon} height={24} style={{ cursor: 'pointer' }} onClick={handleEditClick} />
                        </Box>
                        <Typography className={RegCSS.signUpMailTypo3}>
                            If you haven't received the account verification Code, please click on the 'Resend Code’ button.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '30px !important' }}>
                        <SignUpMailSentForm
                            RegCSS={RegCSS}
                            handleSubmit={handleSubmit}
                            handleResendClick={handleResendClick}
                            isResendDisabled={isResendDisabled}
                            remainingTime={remainingTime}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}  
