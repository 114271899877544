import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import calender from "../../../../assets/icons/calender.svg";
import social_rfp from "../../../../assets/icons/social_rfp.svg";
import time from "../../../../assets/icons/clock_Solid.svg";
import Nature from "../../../../assets/images/Bovokal.svg"
import { decodeHTMLEntities, formatTime, getDate, getResponseDate } from "../../../../common/utils";
import CommonInitials from "../../../../common/components/CommonInitials";
import { useNavigate } from "react-router";
import CommonButton2 from "../../../../common/components/CommonButton2";
import PostHeader from "./PostHeader";
import EventBookmarkHeader from "./EventBookMarkHeader";

const EventBookMark = ({ post,socialMedia,users,master }) => {
    const navigate = useNavigate();


    // const handleRfpClick = () => {
    //     navigate(`/social-media/rfp/${post?.gid}`);
    // }

    return (
        <>
            <Box className={SocialMediaCSS.postCard}>
                {/* <Box className={SocialMediaCSS.postHeader}>
                    <Box>
                        <CommonInitials
                            name={post?.event_user_details?.initials}
                            bgColor={post?.event_user_details?.colour_code}
                            divStyle={{ padding: '0px', maxHeight: '130px', maxWidth: '130px', minHeight: '34px', minWidth: '34px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                            typoStyle={{ fontSize: '14px', lineHeight: '32px', }}
                            avatarStyle={{ width: '34px', height: '34px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                            src={post?.event_user_details?.image}
                            companyLogo={post?.event_user_details?.image}
                            avatar={true}
                        />
                    </Box>
                    <Box className={SocialMediaCSS.postHeaderInfo}>
                        <Typography className={SocialMediaCSS.postReshareHeader} >
                            {post?.event_user_details?.pname}
                        </Typography>
                        <Typography className={SocialMediaCSS.postReshareTagline} >
                            {post?.event_user_details?.tagline}
                        </Typography>
                    </Box>
                </Box> */}
                  <EventBookmarkHeader post={post} users={users} master={master} socialMedia={socialMedia} />
                {/* <Divider /> */}
                <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.fd_column}`} pt={0} width="100%">
                    <Box>
                        <img src={post?.event_image} className={SocialMediaCSS.eventSharImage} />
                    </Box>
                    {/* <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>
                        <Typography className={SocialMediaCSS.rfpshrthmEvent}>{post?.event_thematic_area}</Typography>
                        <Typography className={SocialMediaCSS.rfpshrEvent}>{post?.event_start_date}</Typography>
                    </Box> */}
                    <Box pt={1}>
                        <Typography className={SocialMediaCSS.textContent} >{post?.event_title}</Typography>
                    </Box>
                    <Box pt={1}>
                        <Typography className={SocialMediaCSS.rfpEvents} >{post?.event_body}</Typography>
                    </Box>

                    <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`} pt={2}>
                        <Box className={`${SocialMediaCSS.f}`}>
                            <Typography className={SocialMediaCSS.rfpsEvent} sx={{ whiteSpace: 'nowrap' }}>{post?.event_mode}</Typography> &nbsp;
                            <span>&#8226;</span> &nbsp;
                            <Typography className={SocialMediaCSS.rfpsEvent}>{post?.event_attendance} attendance</Typography>
                        </Box>
                        < CommonButton2
                            label="View Event"
                            name="viewEvent"
                            variant="outlined"
                            type="button"
                            onClick={() => navigate(`/social-media/events/${post?.event_gid}`)}
                            style={{ padding: '6px 16px', fontSize: '14px', height: '32px', fontWeight: '600' }}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default EventBookMark;
